<template>
	<v-skeleton-loader height="14" type="text" class="mb-5 mt-2"/>
</template>

<script>
export default {
};
</script>

<style scoped>

</style>
