<template>
	<frp-nested-content-layout v-if="isInitialized">
		<template #title>
			<div class="primary--text text--darken-1 text-h5 mb-2">
				{{ $t("titles.inviteToJoinAnOrganization") }}
			</div>
		</template>

		<template #content>
			<frp-form-card v-if="isInitialized">
				<v-form :ref="refs.form" v-model="formValid">
					<frp-form-card-blocks>
						<frp-form-card-block :title="$t('subheaders.organization')">
							<frp-form-card-block-row>
								<frp-form-card-block-col cols="12" sm="6" md="4" :class="{ 'pb-0': $vuetify.breakpoint.xsOnly }">
									<frp-form-item>
										<frp-text-field :label="$t('fields.inn.label')"
														name="inn"
														autocomplete="inn"
														:errors="counterpartyInnErrors"
														required
														:rules="validation.counterpartyInn"
														:mask="LEGAL_ENTITY_INN_FORMAT"
														:loading="isEgrulLegalEntityLoading"
														v-model="counterpartyInn"
														:placeholder="$t('fields.inn.placeholder')">
										</frp-text-field>
									</frp-form-item>

									<frp-form-item>
										<frp-text-field :label="$t('fields.ogrn.label')"
														required
														name="ogrn"
														:mask="OGRN_FORMAT"
														disabled
														v-model="counterpartyOgrn"
														:placeholder="$t('fields.ogrn.placeholder')"></frp-text-field>
									</frp-form-item>

									<frp-form-item>
										<frp-text-field :label="$t('fields.kpp.label')"
														name="kpp"
														required
														:mask="KPP_FORMAT"
														disabled
														v-model="counterpartyKpp"
														:placeholder="$t('fields.kpp.placeholder')"></frp-text-field>
									</frp-form-item>

									<frp-form-item>
										<frp-text-field :label="$t('fields.opf.label')"
														name="opf"
														required
														disabled
														v-model="counterpartyOpf"
														:placeholder="$t('fields.opf.placeholder')"></frp-text-field>
									</frp-form-item>
								</frp-form-card-block-col>

								<frp-form-card-block-col offset-md="2" cols="12" sm="6" md="4"
														 :class="{ 'pt-0': $vuetify.breakpoint.xsOnly }">
									<frp-form-item>
										<frp-text-field :label="$t('fields.fullName.label')"
														required
														disabled
														v-model="counterpartyLongName"
														:placeholder="$t('fields.fullName.placeholder')">
										</frp-text-field>
									</frp-form-item>

									<frp-form-item>
										<frp-text-field :label="$t('fields.shortName.label')"
														required
														name="organization"
														disabled
														v-model="counterpartyShortName"
														:placeholder="$t('fields.shortName.placeholder')"></frp-text-field>
									</frp-form-item>

									<frp-form-item>
										<frp-date-field
											is-required
											:label="$t('fields.registerDate.label')"
											required
											disabled
											v-model="counterpartyRegistrationDate"
											:max="Date.now()"
											:placeholder="$t('fields.registerDate.placeholder')"/>
									</frp-form-item>

									<frp-form-item>
										<frp-text-field :label="$t('fields.legalAddress.label')"
														name="address"
														disabled
														v-model="counterpartyLegalAddress"
														:placeholder="$t('fields.legalAddress.placeholder')"></frp-text-field>
									</frp-form-item>
								</frp-form-card-block-col>
							</frp-form-card-block-row>
						</frp-form-card-block>
					</frp-form-card-blocks>

					<frp-form-card-blocks>
						<frp-form-card-block :title="$t('subheaders.employee')">
							<frp-form-card-block-row>
								<frp-form-card-block-col cols="12" sm="6" md="4" :class="{ 'pb-0': $vuetify.breakpoint.xsOnly }">
									<frp-form-item>
										<frp-text-field :label="$t('fields.lastName.label')"
														required
														name="lname"
														autocomplete="family-name"
														:rules="validation.lastName"
														v-model="lastName"
														:placeholder="$t('fields.lastName.placeholder')">
										</frp-text-field>
									</frp-form-item>
									<frp-form-item>
										<frp-text-field :label="$t('fields.firstName.label')"
														required
														name="fname"
														autocomplete="given-name"
														:rules="validation.firstName"
														v-model="firstName"
														:placeholder="$t('fields.firstName.placeholder')">
										</frp-text-field>
									</frp-form-item>
									<frp-form-item>
										<frp-text-field :label="$t('fields.middleName.label')"
														:rules="validation.middleName"
														v-model="middleName"
														name="mname"
														autocomplete="additional-name"
														:placeholder="$t('fields.middleName.placeholder')">
										</frp-text-field>
									</frp-form-item>
									<frp-form-item>
										<frp-text-field :label="$t('fields.email.label')"
														required
														name="email"
														autocomplete="email"
														:rules="validation.email"
														disabled
														v-model="email"
														:placeholder="$t('fields.email.placeholder')">
										</frp-text-field>
									</frp-form-item>
								</frp-form-card-block-col>

								<frp-form-card-block-col offset-md="2" cols="12" sm="6" md="4"
														 :class="{ 'pt-0': $vuetify.breakpoint.xsOnly }">
									<frp-form-item>
										<frp-text-field :label="$t('fields.inn.label')"
														:mask="PERSON_INN_FORMAT"
														name="inn"
														required
														autocomplete="inn"
														:rules="validation.inn"
														v-model="inn"
														:placeholder="$t('fields.inn.placeholder')">
										</frp-text-field>
									</frp-form-item>
									<frp-form-item>
										<frp-text-field :label="$t('fields.position.label')"
														required
														name="organization-title"
														autocomplete="organization-title"
														:rules="validation.position"
														v-model="position"
														:placeholder="$t('fields.position.placeholder')">
										</frp-text-field>
									</frp-form-item>
									<frp-form-item>
										<frp-phone-field :label="$t('fields.phone.label')"
														 required
														 name="phone"
														 autocomplete="mobile phone"
														 :rules="validation.phone"
														 v-model.trim="phone"
														 :placeholder="$t('fields.phone.placeholder')">
										</frp-phone-field>
									</frp-form-item>
								</frp-form-card-block-col>
							</frp-form-card-block-row>
						</frp-form-card-block>
					</frp-form-card-blocks>

					<template>
						<frp-form-card-blocks>
							<frp-form-card-block-row class="pb-8">
								<frp-form-card-block-col cols="12">
									<frp-text-caption>
										{{ $t("aliases.userAgreement.pushingButton") }} {{ $t("aliases.userAgreement.send") }}, {{
											$t("aliases.userAgreement.iGiveMyConsentToProcessingMyPersonalData")
										}}
									</frp-text-caption>
									<frp-text-caption>
										{{ $t("aliases.userAgreement.onPersonalData") }}
										<a :href="downloadUserAgreementLink" class="frp-link">
											{{ $t("aliases.userAgreement.consentToProcessingMyPersonalData") }}
										</a>
									</frp-text-caption>
								</frp-form-card-block-col>
							</frp-form-card-block-row>
						</frp-form-card-blocks>
					</template>
				</v-form>

				<frp-form-actions :is-save-disabled="!stateContainsUnsavedChanges || !isFormValid"
								  :is-cancel-disabled="!stateContainsUnsavedChanges"
								  :is-loading-state="isFormSaving || isFormLoadingState"
								  :submit-text="$t('buttons.send')"
								  @cancel="onFormCancel"
								  @submit="submit">
				</frp-form-actions>
			</frp-form-card>
			<frp-bottom-space></frp-bottom-space>
		</template>
	</frp-nested-content-layout>
</template>

<script>
import urls, { urlTemplateParts } from "Api/config/urls";
import FrpDateField from "Components/fields/FrpDateField";
import authorizationMixin from "Mixins/authorizationMixin";
import { namespace, actionTypes, mutationTypes } from "Store/modules/employeeJoin/types";
import FrpTextField from "Components/fields/FrpTextField";
import FrpFormActions from "Components/form/FrpFormActions";
import FrpFormCardBlock from "Components/markup/FrpFormCardBlock";
import FrpFormCardBlockCol from "Components/markup/FrpFormCardBlockCol";
import FrpBottomSpace from "Components/layouts/FrpBottomSpace";
import { masks } from "Constants/masks";
import colorsMixin from "Mixins/colorsMixin";
import formMixin from "Mixins/formMixin";
import storeModuleBasedPage from "Mixins/storeModuleBasedPage";
import { RouteNames } from "Router/routes";
import { KPP_FORMAT, LEGAL_ENTITY_INN_FORMAT, OGRN_FORMAT, PERSON_INN_FORMAT } from "Utils/formats";
import {
	prepareEmailRule,
	prepareLegalEntityMaskedInnValidationRule,
	prepareMaxLengthRule,
	preparePersonMaskedInnValidationRule
} from "Utils/validation";
import { validateLegalEntityInn } from "Utils/validator";
import FrpFormCard from "Components/markup/FrpFormCard";
import FrpFormCardBlockRow from "Components/markup/FrpFormCardBlockRow";
import FrpFormCardBlocks from "Components/markup/FrpFormCardBlocks";
import FrpTextCaption from "Components/typography/FrpTextCaption";
import FrpCheckboxGroup from "Components/fields/FrpCheckboxGroup";
import FrpFormItem from "Components/markup/FrpFormItem";
import FrpPhoneField from "Components/fields/FrpPhoneField";
import FrpNestedContentLayout from "Components/layouts/FrpNestedContentLayout";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapMutations, mapActions } = createNamespacedHelpers(namespace);

export default {
	mixins: [storeModuleBasedPage, colorsMixin, formMixin, authorizationMixin],
	data() {
		return {
			PERSON_INN_FORMAT,
			LEGAL_ENTITY_INN_FORMAT,
			OGRN_FORMAT,
			KPP_FORMAT,
			trusts: [],
			namespace,
			RouteNames,
			masks,
			validation: {
				counterpartyInn: [prepareLegalEntityMaskedInnValidationRule()],
				inn: [preparePersonMaskedInnValidationRule()],
				firstName: [prepareMaxLengthRule({ maxLength: 100 })],
				lastName: [prepareMaxLengthRule({ maxLength: 100 })],
				middleName: [prepareMaxLengthRule({ maxLength: 100 })],
				email: [prepareEmailRule()],
				phone: [],
				position: [prepareMaxLengthRule({ maxLength: 100 })]
			},
			userAgreementFileId: process.env.VUE_APP_USER_AGREEMENT_FILE_ID,
			isResetValidationDisabled: true
		};
	},
	computed: {
		...mapState({
			employee: state => state.employee,
			counterparty: state => state.counterparty,
			isInitialized: state => state.isInitialized,
			isEgrulLegalEntityLoading: state => state.isEgrulLegalEntityLoading
		}),
		counterpartyInnErrors() {
			return this.isRecordUnique ? [] : [`${this.$t("alerts.errors.legalEntityAlreadyExists")}`];
		},
		isInnValid() {
			return validateLegalEntityInn(this.inn);
		},
		counterpartyInn: {
			get() {
				return this.counterparty.inn;
			},
			set(value) {
				if(value !== this.inn)
					this.setCounterpartyInn(value);
			}
		},
		counterpartyOgrn: {
			get() {
				return this.counterparty.ogrn;
			},
			set(value) {
				return this.setCounterpartyOgrn(value);
			}
		},
		counterpartyKpp: {
			get() {
				return this.counterparty.kpp;
			},
			set(value) {
				return this.setCounterpartyKpp(value);
			}
		},
		counterpartyOpf: {
			get() {
				return this.counterparty.opf;
			},
			set(value) {
				return this.setCounterpartyOpf(value);
			}
		},
		counterpartyLongName: {
			get() {
				return this.counterparty.longName;
			},
			set(value) {
				return this.setCounterpartyLongName(value);
			}
		},
		counterpartyShortName: {
			get() {
				return this.counterparty.shortName;
			},
			set(value) {
				return this.setCounterpartyShortName(value);
			}
		},
		counterpartyLegalAddress: {
			get() {
				return this.counterparty.legalAddress;
			},
			set(value) {
				return this.setCounterpartyLegalAddress(value);
			}
		},
		counterpartyRegistrationDate: {
			get() {
				return this.counterparty.registrationDate;
			},
			set(value) {
				return this.setCounterpartyRegistrationDate(value);
			}
		},
		inn: {
			get() {
				return this.employee.inn;
			},
			set(value) {
				this.setInn(value);
			}
		},
		position: {
			get() {
				return this.employee.position;
			},
			set(value) {
				this.setPosition(value);
			}
		},
		firstName: {
			get() {
				return this.employee.firstName;
			},
			set(value) {
				this.setFirstName(value);
			}
		},
		lastName: {
			get() {
				return this.employee.lastName;
			},
			set(value) {
				this.setLastName(value);
			}
		},
		middleName: {
			get() {
				return this.employee.middleName;
			},
			set(value) {
				this.setMiddleName(value);
			}
		},
		phone: {
			get() {
				return this.employee.phone;
			},
			set(value) {
				this.setPhone(value);
			}
		},
		email: {
			get() {
				return this.employee.email;
			},
			set(value) {
				this.setEmail(value);
			}
		},
		downloadUserAgreementLink: {
			get() {
				return urls.storage.file.download.replace(urlTemplateParts.id, this.userAgreementFileId);
			}
		}
	},
	methods: {
		...mapActions({
			save: actionTypes.save
		}),
		...mapMutations({
			setCounterpartyInn: mutationTypes.SET_COUNTERPARTY_INN,
			setCounterpartyOgrn: mutationTypes.SET_COUNTERPARTY_OGRN,
			setCounterpartyKpp: mutationTypes.SET_COUNTERPARTY_KPP,
			setCounterpartyOpf: mutationTypes.SET_COUNTERPARTY_OPF,
			setCounterpartyLongName: mutationTypes.SET_COUNTERPARTY_LONG_NAME,
			setCounterpartyShortName: mutationTypes.SET_COUNTERPARTY_SHORT_NAME,
			setCounterpartyLegalAddress: mutationTypes.SET_COUNTERPARTY_LEGAL_ADDRESS,
			setCounterpartyRegistrationDate: mutationTypes.SET_COUNTERPARTY_REGISTRATION_DATE,
			setInn: mutationTypes.SET_EMPLOYEE_INN,
			setFirstName: mutationTypes.SET_EMPLOYEE_FIRST_NAME,
			setLastName: mutationTypes.SET_EMPLOYEE_LAST_NAME,
			setMiddleName: mutationTypes.SET_EMPLOYEE_MIDDLE_NAME,
			setPosition: mutationTypes.SET_EMPLOYEE_POSITION,
			setEmail: mutationTypes.SET_EMPLOYEE_EMAIL,
			setPhone: mutationTypes.SET_EMPLOYEE_PHONE
		})
	},
	created() {
		this.initializeStore();
	},
	components: {
		FrpDateField,
		FrpTextField,
		FrpFormCardBlockCol,
		FrpFormCardBlock,
		FrpFormActions,
		FrpFormCard,
		FrpFormCardBlockRow,
		FrpFormCardBlocks,
		FrpTextCaption,
		FrpCheckboxGroup,
		FrpFormItem,
		FrpPhoneField,
		FrpNestedContentLayout,
		FrpBottomSpace
	}
};
</script>

<style scoped>

</style>
