import { createMap, createMapper, MappingProfile, forMember, mapFrom, addProfile, fromValue, ignore } from "@automapper/core";
import { classes } from "@automapper/classes";
import { convertIsoToNumber } from "@/utils/dates";
import LegalPersonCounterparty from "@/store/modules/counterparty/modules/legalPersonCounterparty/types/legalPersonCounterparty";
import ApiFnsLegalPerson from "@/api/types/fns/apiFnsLegalPerson";

const mapper = createMapper({
	strategyInitializer: classes()
});

const legalPersonProfile: MappingProfile = (mapper) => {
	createMap(mapper, ApiFnsLegalPerson, LegalPersonCounterparty,
		forMember(d => d.inn, mapFrom(x => x.inn)),
		forMember(d => d.ogrnIp, mapFrom(x => x.ogrn)),
		forMember(d => d.fullName, mapFrom(x => x.fullName)),
		forMember(d => d.registrationDate, mapFrom(x => convertIsoToNumber(x.registrationDate))),
		forMember(d => d.isRfrp, fromValue(false)),
		forMember(d => d.isLeasingCompany, fromValue(false)),
		forMember(d => d.isPledgeExpertCompany, fromValue(false)),
		forMember(d => d.isVkmExpertCompany, fromValue(false)),
		forMember(d => d.isFrpAssigneeCompany, fromValue(false)),
		forMember(d => d.description, ignore())
	);
};

addProfile(mapper, legalPersonProfile);

export default mapper;
