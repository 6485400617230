import {
	namespace,
	actionTypes,
	mutationTypes
} from "@/store/modules/counterparty/modules/counterpartyMasterAccount/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import FormMixinBuilder from "@/store/shared/form";
import SnapshotOptions from "@/store/shared/snapshot/snapshotOptions";
import stateSnapshotKeys from "@/store/shared/snapshot/keys";
import SnapshotMixinBuilder from "@/store/shared/snapshot";
import router from "@/router";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import CounterpartyMasterAccountState
	from "@/store/modules/counterparty/modules/counterpartyMasterAccount/types/counterpartyMasterAccountState";
import { AccountController } from "@/api/account";
import mapper from "@/store/modules/counterparty/modules/counterpartyMasterAccount/mapper";
import CounterpartyMasterAccount from "@/store/modules/counterparty/modules/counterpartyMasterAccount/types/counterpartyMasterAccount";
import counterpartyMasterAccountEmailModule from "@/store/modules/counterparty/modules/counterpartyMasterAccount/modules/email";
import { resolveAction, resolveMutation, resolveNestedState } from "@/utils/vuexModules";
import embeddedEmailFormTypes from "@/store/shared/embeddedEmailForm/types";
import alertService, { AlertKeys } from "@/store/modules/alerts/services/alertService";
import CounterpartyState from "@/store/modules/counterparty/types/counterpartyState";
import storeManager from "@/store/manager";
import { CounterpartyType } from "@/types/CounterpartyType";
import { LegalEntityController } from "@/api/counterparty/legalEntity";
import { LegalPersonController } from "@/api/counterparty/legalPerson";
import AbortService from "@/services/abortService";
import ApiAccount from "@/api/types/account/apiAccount";

const abortService = new AbortService();
const accountController = new AccountController(abortService);
const legalPersonController = new LegalPersonController(abortService);
const legalEntityController = new LegalEntityController(abortService);

const formMixin = (new FormMixinBuilder()).build();
const baseMixin = (new BaseMixinBuilder(abortService)).build();
const snapshotMixin = (new SnapshotMixinBuilder({
	options: [
		new SnapshotOptions({
			key: stateSnapshotKeys.LAST_SAVED,
			fields: ["account"]
		})
	]
})).build();

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new CounterpartyMasterAccountState(
			formMixin.state(),
			snapshotMixin.state()
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<CounterpartyMasterAccountState, any>>{
	...formMixin.getters,
	...snapshotMixin.getters
};

const actions = <ActionTree<CounterpartyMasterAccountState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...formMixin.actions,
	...snapshotMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit }) {
		await dispatch(actionTypes.initializeBase);

		commit(mutationTypes.SET_COUNTERPARTY_ID, router.currentRoute.params.id);

		await dispatch(actionTypes.fetch);

		commit(mutationTypes.SET_IS_INITIALIZED, true);
		commit(mutationTypes.SET_STATE_SNAPSHOT, stateSnapshotKeys.LAST_SAVED);
	},
	async [actionTypes.destroy]({ dispatch }) {
		await dispatch(resolveAction(counterpartyMasterAccountEmailModule.namespace, embeddedEmailFormTypes.actionTypes.destroy));
		await dispatch(actionTypes.destroyBase);
	},
	async [actionTypes.fetch]({ commit, state, dispatch, rootState }) {
		commit(mutationTypes.SET_IS_FORM_LOADING, true);

		try {
			let { counterparty } = resolveNestedState<CounterpartyState>(rootState, storeManager.counterparty.namespace);

			let persistedProfile;

			switch (counterparty.type) {
				case CounterpartyType.LEGAL_ENTITY:
					persistedProfile = await legalEntityController.getLegalEntityProfile(counterparty.id);
					break;
				case CounterpartyType.LEGAL_PERSON:
					persistedProfile = await legalPersonController.getEntrepreneurMasterAccountProfile(counterparty.id);
					break;
			}

			if(persistedProfile) {
				let profile = persistedProfile.profile;
				let { account } = await accountController.getAccount(profile.accountId);

				commit(mutationTypes.SET_ACCOUNT_ID, profile.accountId);

				commit(mutationTypes.SET_ACCOUNT, mapper.map(account, ApiAccount, CounterpartyMasterAccount));

				dispatch(resolveAction(counterpartyMasterAccountEmailModule.namespace,
					embeddedEmailFormTypes.actionTypes.initialize), { email: profile.email });
			} else {
				dispatch(resolveAction(counterpartyMasterAccountEmailModule.namespace,
					embeddedEmailFormTypes.actionTypes.initialize), {});
			}
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
			commit(mutationTypes.SET_IS_FORM_DISABLED, true);
		} finally {
			commit(mutationTypes.SET_IS_FORM_LOADING, false);
		}
	},
	async [actionTypes.updateStatus]({ commit, state }) {
		commit(mutationTypes.SET_IS_STATUS_SAVING, true);

		try {
			const { isActive } = await accountController.updateAccountStatus(state.accountId, !state.account.isActive);

			if(isActive)
				alertService.addInfo(AlertKeys.ACCOUNT_ACTIVATED);
			else
				alertService.addInfo(AlertKeys.ACCOUNT_DEACTIVATED);

			commit(mutationTypes.SET_IS_ACTIVE, isActive);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_STATUS_SAVING, false);
		}
	}
};

const mutations = <MutationTree<CounterpartyMasterAccountState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	...formMixin.mutations,
	...snapshotMixin.mutations,
	[mutationTypes.SET_COUNTERPARTY_ID](state, value) {
		state.counterpartyId = value;
	},
	[mutationTypes.SET_ACCOUNT_ID](state, value) {
		state.accountId = value;
	},
	[mutationTypes.SET_ACCOUNT](state, value) {
		state.account = value;
	},
	[mutationTypes.SET_ACCOUNT](state, value) {
		state.account = value;
	},
	[mutationTypes.SET_IS_STATUS_SAVING](state, value) {
		state.isStatusSaving = value;
	},
	[mutationTypes.SET_IS_ACTIVE](state, value) {
		state.account.isActive = value;
	}
};

const modules = {
	[counterpartyMasterAccountEmailModule.namespace]: {
		...counterpartyMasterAccountEmailModule
	}
};

const subscribe = (store: any) => {
	counterpartyMasterAccountEmailModule.subscribe(store);
};

export {
	namespace, state, getters, actions, mutations, subscribe
};

const counterpartyMasterAccountModule = {
	modules, namespace, state, getters, actions, mutations, subscribe, namespaced: true
};

export default counterpartyMasterAccountModule;
