export default class BaseStepMixinBuilder {
	constructor() {
	}

	build() {
		return {
			mutations: {},
			actions: {}
		};
	}
}
