<template>
	<frp-list-layout v-if="can(Permissions.GLOBAL_ACCOUNTS_READ)">
		<template #title>
			{{ $t("titles.users") }}
		</template>

		<template #search>
			<frp-text-field :ref="searchTextFieldRef"
							v-model="searchQuery"
							hide-details
							:rules="validation.searchQuery"
							autocomplete="off"
							icon="ico_search"
							:placeholder="$t('placeholders.search')">
			</frp-text-field>
		</template>

		<template #content>
			<v-data-table :headers="headers"
						  :items="items"
						  :loading-text="$t('tables.loading')"
						  hide-default-footer
						  :loading="isItemsLoading"
						  item-key="id"
						  :mobile-breakpoint="mobileBreakpoint"
						  :items-per-page="-1"
						  :custom-sort="sortItems"
						  must-sort
						  :options="options"
						  @click:row="handleItemSelect"
						  :item-class="() => 'text-body-2 cy-table-row'"
						  class="frp-table pt-4 clickable-rows d-flex flex-column">
				<template #item.isActive="{item}">
					<frp-status-label :value="item.isActive"></frp-status-label>
				</template>
			</v-data-table>
		</template>

		<template #pagination>
			<frp-pagination v-if="!isItemsLoading"
							v-model="page"
							:length="Math.ceil(paging.total / paging.pageSize)">
			</frp-pagination>
		</template>
	</frp-list-layout>
</template>

<script>
import { RouteNames } from "@/router/routes";
import FrpListLayout from "Components/layouts/FrpListLayout";
import FrpTextField from "Components/fields/FrpTextField";
import FrpPagination from "Components/common/FrpPagination";
import FrpStatusLabel from "Components/common/FrpStatusLabel";
import authorizationMixin from "Mixins/authorizationMixin";
import { actionTypes, getterTypes, mutationTypes, namespace } from "Store/modules/accounts/types";
import { createNamespacedHelpers } from "vuex";
import { listMixin } from "Mixins/listMixin";
import colorsMixin from "Mixins/colorsMixin";
import storeModuleBasedPage from "Mixins/storeModuleBasedPage";

const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);

export default {
	mixins: [listMixin, colorsMixin, storeModuleBasedPage, authorizationMixin],
	data() {
		return {
			namespace,
			headers: [
				{
					text: this.$t("tables.login"),
					value: "login",
					class: "text-caption",
					width: "25%"
				},
				{
					text: this.$t("tables.email"),
					value: "email",
					class: "text-caption",
					width: "25%"
				},
				{
					text: this.$t("tables.status"),
					value: "isActive",
					class: "text-caption",
					width: "25%"
				},
				{
					text: this.$t("tables.createdAt"),
					value: "createdAt",
					class: "text-caption",
					width: "25%"
				}
			]
		};
	},
	computed: {
		...mapGetters({
			items: getterTypes.formattedItems,
			isNoData: getterTypes.isListingEmpty
		})
	},
	methods: {
		handleItemSelect({ id }) {
			this.$router.push({ name: RouteNames.ACCOUNT, params: { id } });
		}
	},
	created() {
		this.initializeStore();
	},
	components: {
		FrpListLayout,
		FrpTextField,
		FrpPagination,
		FrpStatusLabel
	}
};
</script>
