<template>
	<frp-nested-content-layout v-if="isInitialized">
		<template #title>
			<span class="text-h5 primary--text text--darken-1">
				{{ title }}
			</span>
		</template>
		<template #content>
			<template>
				<frp-tabs>
					<v-tab key="general"
						   v-if="can(Permissions.GLOBAL_OFFICE_READ)"
						   class="text-none"
						   :to="{ name: RouteNames.OFFICE_GENERAL, params: { id } }">
						{{ $t("subheaders.general") }}
					</v-tab>
					<v-tab key="employees"
						   v-if="can(Permissions.GLOBAL_OFFICE_EMPLOYEES_READ)"
						   class="text-none"
						   :to="{ name: RouteNames.OFFICE_EMPLOYEES, params: { id } }">
						{{ $t("subheaders.employees") }}
					</v-tab>
				</frp-tabs>
				<router-view :key="$route.meta.key || $route.name"/>
				<frp-bottom-space></frp-bottom-space>
			</template>
		</template>
	</frp-nested-content-layout>
	<frp-office-loader v-else></frp-office-loader>
</template>

<script>
import FrpCustomTitleLoader from "@/components/loaders/common/FrpCustomTitleLoader";
import FrpTabsLoader from "@/components/loaders/common/FrpTabsLoader";
import authorizationMixin from "@/mixins/authorizationMixin";
import { RouteNames } from "@/router/routes";
import FrpNestedContentLayout from "Components/layouts/FrpNestedContentLayout";
import FrpTabs from "Components/tabs/FrpTabs";
import storeManager from "Store/manager";
import FrpPageNotFound from "Views/errors/FrpPageNotFound";
import FrpOfficeLoader from "Views/office/FrpOfficeLoader";
import FrpOfficeGeneral from "Views/office/sections/FrpOfficeGeneral";
import FrpOfficeEmployees from "Views/office/sections/FrpOfficeEmployees";
import FrpBottomSpace from "Components/layouts/FrpBottomSpace";
import storeModuleBasedPage from "Mixins/storeModuleBasedPage";
import colorsMixin from "Mixins/colorsMixin";
import { createNamespacedHelpers } from "vuex";
import { namespace } from "Store/modules/office/types";

const { mapState, mapMutations, mapActions } = createNamespacedHelpers(namespace);
const generalModuleHelpers = createNamespacedHelpers(storeManager.office.general.namespace);

export default {
	mixins: [storeModuleBasedPage, colorsMixin, authorizationMixin],
	data() {
		return {
			namespace,
			RouteNames
		};
	},
	computed: {
		...mapState({
			id: state => state.id,
			title: state => state.title,
			office: state => state.office,
			isInitialized: state => state.isInitialized
		}),
		isCreateMode() {
			return !this.$route.params.id;
		},
		shouldShowTabs() {
			return !this.isCreateMode;
		},
		id() {
			return this.$route.params.id;
		}
	},
	async created() {
		await this.initializeStore({ id: this.id });
	},
	components: {
		FrpTabsLoader,
		FrpCustomTitleLoader,
		FrpTabs,
		FrpNestedContentLayout,
		FrpOfficeLoader,
		FrpOfficeGeneral,
		FrpBottomSpace,
		FrpOfficeEmployees,
		FrpPageNotFound
	}
};
</script>
