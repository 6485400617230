import { createMap, createMapper, MappingProfile, forMember, mapFrom, addProfile, ignore } from "@automapper/core";
import { classes } from "@automapper/classes";
import CounterpartyMasterAccount from "@/store/modules/counterparty/modules/counterpartyMasterAccount/types/counterpartyMasterAccount";
import ApiAccount from "@/api/types/account/apiAccount";

const mapper = createMapper({
	strategyInitializer: classes()
});

const masterAccountProfile: MappingProfile = (mapper) => {
	createMap(mapper, ApiAccount, CounterpartyMasterAccount,
		forMember(d => d.login, mapFrom(x => x.login)),
		forMember(d => d.email, mapFrom(x => x.email)),
		forMember(d => d.isActive, mapFrom(x => x.isActive)),
		forMember(d => d.isEmailConfirmed, mapFrom(x => x.isEmailConfirmed))
	);
};

addProfile(mapper, masterAccountProfile);

export default mapper;
