<template>
	<frp-btn v-on="$listeners"
			 :loading="loading"
			 :disabled="disabled"
			 :class="{'ml-auto': mlAuto}"
			 :color="isActive ? 'secondary' : 'primary'">
		{{ isActive ? "Деактивировать" : "Активировать" }}
	</frp-btn>
</template>

<script>
import FrpBtn from "Components/buttons/FrpBtn";

export default {
	name: "FrpToggleActivityBtn",
	components: { FrpBtn },
	props: {
		loading: Boolean,
		isActive: {
			required: true,
			type: Boolean
		},
		mlAuto: {
			type: Boolean,
			default: true
		},
		disabled: Boolean
	}
};
</script>

<style scoped>

</style>
