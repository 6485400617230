<template>
	<v-menu offset-y nudge-top="10" top v-model="isMenuOpened">
		<template v-slot:activator="{ on, attrs }">
			<frp-btn :class="btnClass" @click="handleClick" :disabled="disabled" :loading="loading">
				{{ selectedItem.text }}
				<v-icon
					:disabled="loading"
					v-bind="attrs"
					v-on="on"
					:class="[isMenuOpened ? 'arrow-up' : 'arrow-down']"
					right
					dark>
					mdi-chevron-down
				</v-icon>
			</frp-btn>
		</template>
		<v-list>
			<v-list-item v-for="(item, index) in items"
						 @click="() => selectItem(item.value)"
						 :key="index">
				<v-list-item-title>{{ item.text }}</v-list-item-title>
			</v-list-item>
		</v-list>
	</v-menu>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn";

export default {
	model: {
		prop: "value",
		event: "update:value"
	},
	props: {
		btnClass: String,
		value: String,
		disabled: Boolean,
		loading: Boolean,
		items: {
			type: Array,
			required: true
		}
	},
	data() {
		return {
			isMenuOpened: false
		};
	},
	computed: {
		selectedItem() {
			return this.items.find(x => x.value === this.value);
		}
	},
	methods: {
		selectItem(value) {
			return this.$emit("update:value", value);
		},
		handleClick() {
			this.$emit("click");
		}
	},
	components: { FrpBtn }
};
</script>

<style scoped>
.arrow-down {
	transition: transform .3s ease-in-out !important;
}

.arrow-up {
	transform: rotate(180deg);
}
</style>
