import { TrustStatusType } from "@/types/TrustStatusType";

export default class Trust {
	constructor(
		public id: string,
		public status: TrustStatusType,
		public expireAt: number,
		public createdAt: number,
		public isSigned: boolean,
		public acceptedAt: number,
		public declinedAt: number,
		public isMCHD: boolean
	)
	{
	}
}
