<template>
	<v-container fluid class="pa-0" :class="fixed ? '' : 'd-flex'">
		<v-row style="bottom: 0; left: 0; width: 100%" class="white py-2"
			   :style="fixed ? 'position: fixed' : ''" :class="fixed ? 'pr-3 ma-0' : ''">
			<v-col class="d-flex justify-end" :class="fixed ? 'mr-5' : ''">
				<frp-btn data-cy="cancel"
						 color="primary"
						 outlined
						 @click="dialogEnabled = true"
						 :disabled="isCancelDisabled || isLoadingState">
					{{ $t("buttons.cancel") }}
				</frp-btn>

				<slot name="submit-action">
					<frp-btn data-cy="submit"
							 dark
							 elevation="0"
							 :color="submitColor"
							 @click="onFormSubmit"
							 :loading="isLoadingState"
							 :disabled="isSaveDisabled || isLoadingState">
						{{ submitText || $t("buttons.save") }}
					</frp-btn>
				</slot>

				<frp-confirm-dialog v-if="dialogEnabled"
									@submit="onCancelFormSubmit"
									@cancel="dialogEnabled = false"
									:title="$t('dialogs.confirmCancelFormChanges.title')"
									:description="$t('dialogs.confirmCancelFormChanges.description')">
				</frp-confirm-dialog>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import FrpBtn from "Components/buttons/FrpBtn";
import FrpConfirmDialog from "Components/dialogs/FrpConfirmDialog";

export default {
	props: {
		isLoadingState: Boolean,
		isCancelDisabled: Boolean,
		isSaveDisabled: Boolean,
		fixed: {
			type: Boolean,
			default: true
		},
		submitText: String,
		submitColor: {
			type: String,
			default: "secondary"
		}
	},
	data() {
		return {
			dialogEnabled: false
		};
	},
	methods: {
		onCancelFormSubmit() {
			this.dialogEnabled = false;
			this.$emit("cancel");
		},
		onFormSubmit() {
			this.$emit("submit");
		}
	},
	components: {
		FrpBtn,
		FrpConfirmDialog
	}
};
</script>
