<template>
	<frp-master-account-access-application-base-step :title="$t('titles.attachDocumentsList')">
		<v-container>
			<v-row class="mb-6" no-gutters>
				<v-col>
					<span class="text-body-2">
						{{
							`${$t("common.masterAccountAccessDocumentsStep.toConfirmRightPerformActionsOnBehalfOf")} ${CounterpartyType.LEGAL_ENTITY ===
							counterpartyType ?
								$t("common.masterAccountAccessDocumentsStep.legalEntity") :
								$t("common.masterAccountAccessDocumentsStep.legalPerson")},
								${$t("common.masterAccountAccessDocumentsStep.attachDocumentsListBelow")}.`
						}}
					</span>
				</v-col>
			</v-row>
			<v-row>
				<frp-form-col>
					<frp-dropzone :formats="['png', 'jpg', 'pdf']"
								  :type="STORAGE_INN_NAMESPACE"
								  data-cy="snils"
								  :label="$t('fields.headPassportScanOrCertificateInn.label')"
								  :label-classes="$vuetify.breakpoint.mdAndDown ? 'mb-2' : 'mb-1'"
								  max-size="100" @file:add="addFile($event, STORAGE_INN_NAMESPACE)"
								  @file:delete="deleteFile(STORAGE_INN_NAMESPACE)">
					</frp-dropzone>
				</frp-form-col>
				<frp-form-col v-if="counterpartyType === CounterpartyType.LEGAL_ENTITY">
					<frp-dropzone :formats="['png', 'jpg', 'pdf']"
								  :type="STORAGE_APPOINTMENT_HEAD_ORDER"
								  :label="$t('fields.appointmentHeadOrderOrExtractFromEmployeeHiringOrderOrTrust.label')"
								  :label-classes="$vuetify.breakpoint.mdAndDown ? 'mb-5' : 'mb-4'"
								  data-cy="order"
								  max-size="100"
								  @file:add="addFile($event, STORAGE_APPOINTMENT_HEAD_ORDER)"
								  @file:delete="deleteFile(STORAGE_APPOINTMENT_HEAD_ORDER)">
					</frp-dropzone>
				</frp-form-col>
			</v-row>
		</v-container>
	</frp-master-account-access-application-base-step>
</template>

<script>
import FrpTextField from "Components/fields/FrpTextField";
import { actionTypes, mutationTypes } from "Store/modules/masterAccountAccess/modules/documentsStep/types";
import { CounterpartyType } from "Types/CounterpartyType";
import FrpMasterAccountAccessApplicationBaseStep from "Views/public/masterAccountAccess/steps/FrpMasterAccountAccessApplicationBaseStep";
import { createNamespacedHelpers } from "vuex";
import storeManager from "@/store/manager/index";
import FrpDetailsSubheader from "Components/details/FrpDetailsSubheader";
import FrpIcon from "Components/icon/FrpIcon";
import colorsMixin from "Mixins/colorsMixin";
import FrpDropzone from "Components/dropzone/FrpDropzone";
import FrpFormCol from "Components/form/FrpFormCol";
import storeModuleBasedPage from "Mixins/storeModuleBasedPage";
import { STORAGE_APPOINTMENT_HEAD_ORDER, STORAGE_INN_NAMESPACE } from "Constants/storage";

const {
	mapState,
	mapMutations,
	mapActions
} = createNamespacedHelpers(storeManager.masterAccountAccess.documentsStep.namespace);
const { mapState: mapSelectCounterpartyStepState } = createNamespacedHelpers(storeManager.masterAccountAccess.selectCounterpartyStep.namespace);

export default {
	mixins: [colorsMixin, storeModuleBasedPage],
	data() {
		return {
			CounterpartyType,
			namespace: storeManager.masterAccountAccess.documentsStep.namespace,
			STORAGE_APPOINTMENT_HEAD_ORDER,
			STORAGE_INN_NAMESPACE
		};
	},
	computed: {
		...mapState({
			state: state => state
		}),
		...mapSelectCounterpartyStepState({
			counterpartyType: state => state.counterpartyType
		}),
		isFormValid: {
			get() {
				return this.state.isFormValid;
			},
			set(value) {
				this.setIsFormValid(value);
			}
		}
	},
	methods: {
		...mapActions({
			processFile: actionTypes.processFile
		}),
		async addFile(file, docType) {
			await this.processFile({ fileId: file.id, docType });
		},
		deleteFile(docType) {
			this.state.documents[docType] = null;
		},
		...mapMutations({
			setIsFormValid: mutationTypes.SET_IS_FORM_VALID
		})
	},
	async created() {
		await this.initializeStore();
	},
	components: {
		FrpMasterAccountAccessApplicationBaseStep,
		FrpFormCol,
		FrpIcon,
		FrpDetailsSubheader,
		FrpTextField,
		FrpDropzone
	}
};
</script>

<style lang="scss">

</style>

