import ListingModel from "@/store/shared/listing/models/listingModel";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchModel from "@/store/shared/search/models/searchModel";
import ApiOfficeEmployeePersistedBase from "@/api/types/office/apiOfficeEmployeePersistedBase";
import OfficeEmployeesFilter from "@/store/modules/office/modules/officeEmployees/types/officeEmployeesFilter";
import RouteState from "@/store/shared/route/types/routeState";
import ApiRole from "@/api/types/roles/apiRole";
import IPageState from "@/store/shared/base/types/pageState";

export default class OfficeEmployeesState implements IPageState {
	constructor(
		public listing: ListingModel<ApiOfficeEmployeePersistedBase>,
		public sorting: SortingModel<String>,
		public paging: PagingModel,
		public search: SearchModel,
		public filter: OfficeEmployeesFilter,
		public route: RouteState,
		public roles: ApiRole[] = [],
		public rolesIsLoading: boolean = false,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false
	)
	{
	}
}
