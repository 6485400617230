import ApiProfileAssignment from "@/api/types/account/apiProfileAssignment";
import ApiAccountProfilePersistedBase from "@/api/types/account/apiAccountProfilePersistedBase";
import ApiCounterpartyPersistedBase from "@/api/types/counterparty/apiCounterpartyPersistedBase";
import ApiCounterpartyEmployeePersistedBase from "@/api/types/counterparty/apiCounterpartyEmployeePersistedBase";
import ApiOfficePersistedBase from "@/api/types/office/apiOfficePersisted";
import { ProfileType } from "@/types/ProfileType";
import IPageState from "@/store/shared/base/types/pageState";

export default class ProfileAssignmentState implements IPageState {
	constructor(
		public profile: ApiAccountProfilePersistedBase = new ApiAccountProfilePersistedBase(),
		public counterparty: ApiCounterpartyPersistedBase = new ApiCounterpartyPersistedBase(),
		public employee: ApiCounterpartyEmployeePersistedBase = new ApiCounterpartyEmployeePersistedBase(),
		public profileType: ProfileType = ProfileType.UNKNOWN,
		public office: ApiOfficePersistedBase = new ApiOfficePersistedBase(),
		public details: ApiProfileAssignment = new ApiProfileAssignment(),
		public isLoading: boolean = false,
		public isAssigning: boolean = false,
		public isAssigned: boolean = false,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public id: string = ""
	)
	{
	}
}
