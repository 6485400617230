<template>
	<span class="text-h6 primary--text text--darken-1 mb-5">
		<slot></slot>
	</span>
</template>

<script>
export default {
};
</script>
