import {
	namespace,
	actionTypes,
	mutationTypes,
	getterTypes
} from "@/store/modules/counterpartyEmployee/modules/profile/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import FormMixinBuilder from "@/store/shared/form";
import SnapshotOptions from "@/store/shared/snapshot/snapshotOptions";
import stateSnapshotKeys from "@/store/shared/snapshot/keys";
import SnapshotMixinBuilder from "@/store/shared/snapshot";
import { resolveNestedState } from "@/utils/vuexModules";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import storeManager from "@/store/manager";
import AbortService from "@/services/abortService";
import CounterpartyEmployeeProfileState from "@/store/modules/counterpartyEmployee/modules/profile/types/counterpartyEmployeeProfileState";
import CounterpartyEmployeeState from "@/store/modules/counterpartyEmployee/types/counterpartyEmployeeState";
import NotDefinedException from "@/exceptions/notDefinedException";
import alertService, { AlertKeys } from "@/store/modules/alerts/services/alertService";
import { AuthorizationController } from "@/api/authorization";
import { AccountController } from "@/api/account";

const abortService = new AbortService();

const authorizationController = new AuthorizationController(abortService);
const accountController = new AccountController(abortService);

const formMixin = (new FormMixinBuilder()).build();
const baseMixin = (new BaseMixinBuilder(abortService)).build();
const snapshotMixin = (new SnapshotMixinBuilder({
	options: [
		new SnapshotOptions({
			key: stateSnapshotKeys.LAST_SAVED,
			fields: ["profile"]
		})
	]
})).build();

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new CounterpartyEmployeeProfileState(
			formMixin.state(),
			snapshotMixin.state()
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<CounterpartyEmployeeProfileState, any>>{
	...formMixin.getters,
	...snapshotMixin.getters
};

const actions = <ActionTree<CounterpartyEmployeeProfileState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...formMixin.actions,
	...snapshotMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit, rootState }) {
		await dispatch(actionTypes.initializeBase);

		const { profile: persistedProfile } = resolveNestedState<CounterpartyEmployeeState>(rootState,
			storeManager.counterpartyEmployee.namespace);
		if(!persistedProfile) throw new NotDefinedException("persistedProfile");

		const { id, profile } = persistedProfile;
		const { accountId } = profile;

		commit(mutationTypes.SET_ID, id);
		commit(mutationTypes.SET_ACCOUNT_ID, accountId);
		commit(mutationTypes.SET_PROFILE, profile);

		commit(mutationTypes.SET_IS_INITIALIZED, true);
		commit(mutationTypes.SET_STATE_SNAPSHOT, stateSnapshotKeys.LAST_SAVED);
	},
	async [actionTypes.requestPhoneConfirmation]({ state, commit }) {
		commit(mutationTypes.SET_IS_REQUEST_PHONE_CONFIRMATION_OPERATION_EXECUTING, true);
		try {
			await authorizationController.requestPhoneConfirmation(state.id);

			alertService.addInfo(AlertKeys.PHONE_CONFIRMATION_REQUEST_SUCCESS_CREATED);

			commit(mutationTypes.SET_IS_PROFILE_PHONE_CONFIRMED, false);
		} catch (e) {
			console.error(e);
			AlertHelper.handleGeneralRequestErrors(e);
		} finally {
			commit(mutationTypes.SET_IS_REQUEST_PHONE_CONFIRMATION_OPERATION_EXECUTING, false);
		}
	},
	async [actionTypes.toggleSignatureRequired]({ commit, state }, value) {
		commit(mutationTypes.SET_IS_SIGNATURE_REQUIRED_TOGGLE_LOADING, true);

		try {
			commit(mutationTypes.SET_IS_SIGNATURE_REQUIRED, value);
			await accountController.toggleSignatureRequired(state.id, value);

			alertService.addInfo(AlertKeys.SUCCESS_UPDATED_INFO);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_SIGNATURE_REQUIRED_TOGGLE_LOADING, false);
		}
	}
};

const mutations = <MutationTree<CounterpartyEmployeeProfileState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	...formMixin.mutations,
	...snapshotMixin.mutations,
	[mutationTypes.SET_ID](state, value) {
		state.id = value;
	},
	[mutationTypes.SET_IS_PROFILE_PHONE_CONFIRMED](state, value) {
		state.profile.isPhoneConfirmed = value;
	},
	[mutationTypes.SET_IS_SIGNATURE_REQUIRED](state, value) {
		state.profile.isSignatureRequired = value;
	},
	[mutationTypes.SET_IS_SIGNATURE_REQUIRED_TOGGLE_LOADING](state, value) {
		state.isSignatureRequiredToggleLoading = value;
	},
	[mutationTypes.SET_ACCOUNT_ID](state, value) {
		state.accountId = value;
	},
	[mutationTypes.SET_PROFILE](state, value) {
		state.profile = value;
	},
	[mutationTypes.SET_IS_REQUEST_PHONE_CONFIRMATION_OPERATION_EXECUTING](state, value) {
		state.isRequestPhoneConfirmationOperationExecuting = value;
	}
};

export {
	namespace, state, getters, actions, mutations
};

const counterpartyEmployeeProfileModule = {
	namespace, state, getters, actions, mutations, namespaced: true
};

export default counterpartyEmployeeProfileModule;
