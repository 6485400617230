import {
	namespace,
	actionTypes,
	mutationTypes
} from "@/store/modules/employeeJoin/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation/index";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import alertService, { AlertKeys } from "@/store/modules/alerts/services/alertService";
import { LegalEntityController } from "@/api/counterparty/legalEntity";
import AbortService from "@/services/abortService";
import EmployeeJoinState from "@/store/modules/employeeJoin/types/employeeJoinState";
import stateSnapshotKeys from "@/store/shared/snapshot/keys";
import { resolveAction, resolveMutation, resolveNestedState } from "@/utils/vuexModules";
import legalEntityMapper from "@/store/modules/counterparty/modules/legalEntityCounterparty/mapper";
import mapper from "@/store/modules/employeeJoin/mapper";
import FormMixinBuilder from "@/store/shared/form";
import SnapshotMixinBuilder from "@/store/shared/snapshot";
import SnapshotOptions from "@/store/shared/snapshot/snapshotOptions";
import { validateLegalEntityInn } from "@/utils/validator";
import LegalEntityCounterparty from "@/store/modules/counterparty/modules/legalEntityCounterparty/types/legalEntityCounterparty";
import { FnsController } from "@/api/fns";
import { EmployeeJoinApplicationController } from "@/api/employeeJoinApplication";
import ApiLegalEntityIdentifiers from "@/api/types/legalEntity/apiLegalEntityIdentifiers";
import UserState from "@/store/modules/user/types/userState";
import storeManager from "@/store/manager";
import ApiLegalEntity from "@/api/types/legalEntity/apiLegalEntity";

const abortService = new AbortService();
const legalEntityController = new LegalEntityController(abortService);
const fnsController = new FnsController(abortService);
const employeeJoinApplicationController = new EmployeeJoinApplicationController(abortService);

const formMixin = (new FormMixinBuilder()).build();
const snapshotMixin = (new SnapshotMixinBuilder({
	options: [
		new SnapshotOptions({
			key: stateSnapshotKeys.LAST_SAVED,
			fields: ["employee", "counterparty"]
		})
	]
})).build();

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new EmployeeJoinState(
			formMixin.state(),
			snapshotMixin.state()
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();
const baseMixin = (new BaseMixinBuilder(abortService)).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<EmployeeJoinState, any>>{
	...formMixin.getters,
	...snapshotMixin.getters
};

const actions = <ActionTree<EmployeeJoinState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...formMixin.actions,
	...snapshotMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit, rootState })
	{
		await dispatch(actionTypes.initializeBase);

		let { user } = resolveNestedState<UserState>(rootState, storeManager.user.namespace);

		commit(mutationTypes.SET_EMPLOYEE_EMAIL, user.account.email);
		commit(mutationTypes.SET_IS_INITIALIZED, true);
		commit(mutationTypes.SET_STATE_SNAPSHOT, stateSnapshotKeys.LAST_SAVED);
	},
	async [actionTypes.save]({ commit, state }) {
		commit(mutationTypes.SET_IS_FORM_SAVING, true);

		try {
			await employeeJoinApplicationController.createLegalEntityEmployeeJoinApplications({
				counterpartyIdentifiers: mapper.map(state.counterparty, LegalEntityCounterparty, ApiLegalEntityIdentifiers),
				employee: state.employee
			});

			alertService.addInfo(AlertKeys.EMPLOYEE_JOIN_APPLICATION_CREATED);
			commit(mutationTypes.SET_STATE_SNAPSHOT, stateSnapshotKeys.LAST_SAVED);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_FORM_SAVING, false);
		}
	},
	async [actionTypes.handleInnInput]({ state, commit }) {
		if(!validateLegalEntityInn(state.counterparty.inn))
			return;

		try {
			commit(mutationTypes.SET_IS_FORM_READONLY, true);
			commit(mutationTypes.SET_IS_EGRUL_LEGAL_ENTITY_LOADING, true);

			let suggestion = await legalEntityController.getLegalEntitySuggestion(state.counterparty.inn);

			let apiEgrulLegalEntity = suggestion ?
				await fnsController.getEgrulLegalEntity(suggestion.inn, suggestion.ogrn, suggestion.kpp) :
				null;

			if(apiEgrulLegalEntity) {
				let legalEntity = legalEntityMapper.map(apiEgrulLegalEntity, ApiLegalEntity, LegalEntityCounterparty);

				commit(mutationTypes.SET_COUNTERPARTY, legalEntity);
			} else {
				alertService.addCustomError(`Не удалось найти организацию с ИНН ${state.counterparty.inn} в ЕГРЮЛ`);
			}
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_FORM_READONLY, false);
			commit(mutationTypes.SET_IS_EGRUL_LEGAL_ENTITY_LOADING, false);
		}
	}
};

const mutations = <MutationTree<EmployeeJoinState>>{
	...stateManipulationMixin.mutations,
	...formMixin.mutations,
	...snapshotMixin.mutations,
	...baseMixin.mutations,
	[mutationTypes.SET_IS_EGRUL_LEGAL_ENTITY_LOADING](state, value) {
		state.isEgrulLegalEntityLoading = value;
	},
	[mutationTypes.SET_COUNTERPARTY](state, value) {
		state.counterparty = value;
	},
	[mutationTypes.SET_COUNTERPARTY_INN](state, value) {
		state.counterparty.inn = value;
	},
	[mutationTypes.SET_COUNTERPARTY_OGRN](state, value) {
		state.counterparty.ogrn = value;
	},
	[mutationTypes.SET_COUNTERPARTY_KPP](state, value) {
		state.counterparty.kpp = value;
	},
	[mutationTypes.SET_COUNTERPARTY_OPF](state, value) {
		state.counterparty.opf = value;
	},
	[mutationTypes.SET_COUNTERPARTY_LONG_NAME](state, value) {
		state.counterparty.longName = value;
	},
	[mutationTypes.SET_COUNTERPARTY_SHORT_NAME](state, value) {
		state.counterparty.shortName = value;
	},
	[mutationTypes.SET_COUNTERPARTY_LEGAL_ADDRESS](state, value) {
		state.counterparty.legalAddress = value;
	},
	[mutationTypes.SET_COUNTERPARTY_REGISTRATION_DATE](state, value) {
		state.counterparty.registrationDate = value;
	},
	[mutationTypes.SET_EMPLOYEE](state, value) {
		state.employee = value;
	},
	[mutationTypes.SET_EMPLOYEE_INN](state, value) {
		state.employee.inn = value;
	},
	[mutationTypes.SET_EMPLOYEE_FIRST_NAME](state, value) {
		state.employee.firstName = value;
	},
	[mutationTypes.SET_EMPLOYEE_MIDDLE_NAME](state, value) {
		state.employee.middleName = value;
	},
	[mutationTypes.SET_EMPLOYEE_LAST_NAME](state, value) {
		state.employee.lastName = value;
	},
	[mutationTypes.SET_EMPLOYEE_EMAIL](state, value) {
		state.employee.email = value;
	},
	[mutationTypes.SET_EMPLOYEE_PHONE](state, value) {
		state.employee.phone = value;
	},
	[mutationTypes.SET_EMPLOYEE_POSITION](state, value) {
		state.employee.position = value;
	}
};

const subscribe = (store: any) => {
	const { commit, dispatch } = store;

	store.subscribe(async ({ type, payload }: any, state: any) => {
		switch (type) {
			case  resolveMutation(namespace, mutationTypes.SET_COUNTERPARTY_INN):
			{
				await dispatch(resolveAction(namespace, actionTypes.handleInnInput));
				break;
			}

			default:
				break;
		}
	});
};

export {
	namespace, state, getters, actions, mutations, subscribe
};

const employeeJoinModule = {
	namespace, state, getters, actions, mutations, subscribe, namespaced: true
};

export default employeeJoinModule;
