<template>
	<span class="text-subtitle-2 mb-4">
		<slot></slot>
	</span>
</template>

<script>
export default {
	name: "FrpDetailsSubheader"
}
</script>

<style scoped>

</style>
