export default class ApiGetCounterpartiesParameters {
	take: number;
	skip: number;
	query: String;
	sortField: String;
	sortingDirection: String;
	counterpartyTypes: String[];

	constructor(
		take: number,
		skip: number,
		query: String,
		sortField: String,
		sortingDirection: String,
		counterpartyTypes: String[]
	){
		this.take = take;
		this.skip = skip;
		this.query = query;
		this.sortField = sortField;
		this.sortingDirection = sortingDirection;
		this.counterpartyTypes = counterpartyTypes;
	}
}
