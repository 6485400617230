import AbortService from "@/services/abortService";
import storeManager from "@/store/manager";
import {
	namespace,
	actionTypes,
	mutationTypes,
	getterTypes
} from "@/store/modules/masterAccountAccess/modules/documentsStep/types";
import DocumentsStepStateModel from "@/store/modules/masterAccountAccess/modules/documentsStep/models/documentsStepStateModel";
import BaseMixinBuilder from "@/store/shared/base";
import BaseStepMixinBuilder from "@/store/shared/baseStep";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { STORAGE_APPOINTMENT_HEAD_ORDER, STORAGE_INN_NAMESPACE } from "@/constants/storage";
import { CounterpartyType } from "@/types/CounterpartyType";
import { resolveNestedState } from "@/utils/vuexModules";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import baseMixinTypes from "@/store/shared/base/types";

const abortService = new AbortService();

const baseStepMixin = (new BaseStepMixinBuilder()).build();

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new DocumentsStepStateModel();
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();
const baseMixin = (new BaseMixinBuilder(abortService)).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<DocumentsStepStateModel, any>>{
	[getterTypes.isStepCompleted]: (state, _, rootState) => {
		const { counterpartyType } = resolveNestedState(rootState, storeManager.masterAccountAccess.selectCounterpartyStep.namespace);

		switch (counterpartyType) {
			case CounterpartyType.LEGAL_ENTITY:
				return state.documents.inn && state.documents.order;
			case CounterpartyType.LEGAL_PERSON:
				return state.documents.inn;
		}
	}
};

const actions = <ActionTree<DocumentsStepStateModel, any>>{
	...baseStepMixin.actions,
	...stateManipulationMixin.actions,
	...baseMixin.actions,
	[actionTypes.initialize]({ dispatch, commit }) {
		dispatch(baseMixinTypes.actionTypes.initializeBase);

		commit(mutationTypes.SET_IS_INITIALIZED, true);
	},
	async [actionTypes.processFile]({ dispatch, commit, rootState }, { fileId, docType }) {
		switch (docType) {
			case STORAGE_INN_NAMESPACE:
				commit(mutationTypes.SET_INN_DOCUMENT, fileId);
				break;
			case STORAGE_APPOINTMENT_HEAD_ORDER:
				commit(mutationTypes.SET_ORDER_DOCUMENT, fileId);
		}
	}
};

const mutations = <MutationTree<DocumentsStepStateModel>>{
	...stateManipulationMixin.mutations,
	...baseStepMixin.mutations,
	...baseMixin.mutations,
	[mutationTypes.SET_IS_FORM_VALID](state, value) {
		state.isFormValid = value;
	},
	[mutationTypes.SET_INN_DOCUMENT](state, value) {
		state.documents.inn = value;
	},
	[mutationTypes.SET_ORDER_DOCUMENT](state, value) {
		state.documents.order = value;
	}
};

export {
	namespace, state, getters, actions, mutations
};

const documentsStepModule = {
	namespace, state, getters, actions, mutations, namespaced: true
};

export default documentsStepModule;
