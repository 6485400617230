<template>
	<div>
		<v-subheader v-if="label" class="pa-0 mb-1 grey--text text-caption" style="height: fit-content">
			<span>{{ label }}</span>
			<span v-if="required">*</span>
		</v-subheader>
		<v-autocomplete v-model="country"
						:placeholder="placeholder"
						:background-color="colors.white.base"
						:items="countries"
						dense
						:rules="[...defaultRules, ...rules]"
						:readonly="readonly"
						class="frp-text-field text-body-2"
						:disabled="disabled"
						outlined
						required>
			<template v-slot:selection="{item}">
				<frp-country-flag :value="item.value"></frp-country-flag>
				<span>{{ item.text }}</span>
			</template>
			<template v-slot:item="{item}">
				<frp-country-flag :value="item.value"></frp-country-flag>
				<span>{{ item.text }}</span>
			</template>
		</v-autocomplete>
	</div>
</template>
<script>
import FrpCountryFlag from "Components/common/FrpCountryFlag";
import colorsMixin from "Mixins/colorsMixin";
import countries from "i18n-iso-countries";
import local from "i18n-iso-countries/langs/ru.json";
import { requiredRule } from "Utils/validation";

countries.registerLocale(local);

export default {
	mixins: [colorsMixin],
	model: {
		prop: "countryCode",
		event: "update:countryCode"
	},
	props: {
		placeholder: {
			default: "Выберите страну",
			type: String
		},
		excludeCountries: {
			type: Array,
			default: () => []
		},
		rules: {
			type: Array,
			default: () => []
		},
		label: String,
		countryCode: String,
		readonly: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		},
		required: Boolean
	},
	computed: {
		defaultRules() {
			if(this.required)
				return [requiredRule()];
			else
				return [];
		},
		country: {
			get() {
				return this.countryCode;
			},
			set(value) {
				this.updateCountry(value);
			}
		},
		countries() {
			let countriesObject = countries.getNames("ru", { select: "official" });
			let items = Object.entries(countriesObject).map(([value, text]) => ({ value, text }));

			items = items.filter(x => !this.excludeCountries.includes(x.value));

			return items;
		}
	},
	methods: {
		updateCountry(value) {
			this.$emit("update:countryCode", value);
		}
	},
	components: {
		FrpCountryFlag
	}
};
</script>

