import {
	namespace,
	actionTypes,
	mutationTypes
} from "@/store/modules/account/modules/general/types";
import BaseMixinBuilder from "@/store/shared/base";
import FormMixinBuilder from "@/store/shared/form";
import SnapshotMixinBuilder from "@/store/shared/snapshot";
import stateSnapshotKeys from "@/store/shared/snapshot/keys";
import SnapshotOptions from "@/store/shared/snapshot/snapshotOptions";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import AccountGeneralState from "@/store/modules/account/modules/general/types/accountGeneralState";
import { mapAccount } from "@/store/modules/account/mapper";
import { resolveAction } from "@/utils/vuexModules";
import embeddedEmailFormTypes from "@/store/shared/embeddedEmailForm/types";
import accountGeneralEmailModule from "@/store/modules/account/modules/general/modules/email";
import InvalidArgumentException from "@/exceptions/invalidArgumentException";
import alertService, { AlertKeys } from "@/store/modules/alerts/services/alertService";
import { AccountController } from "@/api/account";
import AbortService from "@/services/abortService";
import HttpNotFoundException from "@/exceptions/httpNotFoundException";
import accountGeneralPasswordModule from "@/store/modules/account/modules/general/modules/password";
import embeddedPasswordFormTypes from "@/store/shared/embeddedPasswordForm/types";
import rootTypes from "@/store/types";
import { PageModeType } from "@/store/types/pageModeType";
import AccessForbiddenException from "@/exceptions/accessForbiddenException";

const abortService = new AbortService();
const accountController = new AccountController(abortService);

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new AccountGeneralState(snapshotMixin.state(), formMixin.state());
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();
const baseMixin = (new BaseMixinBuilder(abortService)).build();
const formMixin = (new FormMixinBuilder()).build();

const snapshotMixin = (new SnapshotMixinBuilder({
	options: [
		new SnapshotOptions({
			key: stateSnapshotKeys.LAST_SAVED,
			fields: ["account"]
		})
	]
})).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<AccountGeneralState, any>>{
	...snapshotMixin.getters
};

const actions = <ActionTree<AccountGeneralState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...formMixin.actions,
	...snapshotMixin.actions,
	async [actionTypes.initialize]({ commit, rootState, state, dispatch }, { id }) {
		await dispatch(actionTypes.initializeBase);

		if(!id)
			throw new InvalidArgumentException("id", id);

		commit(mutationTypes.SET_ID, id);
		await dispatch(actionTypes.fetch);

		commit(mutationTypes.SET_IS_INITIALIZED, true);
		commit(mutationTypes.SET_STATE_SNAPSHOT, stateSnapshotKeys.LAST_SAVED);
	},
	async [actionTypes.destroy]({ dispatch }) {
		await dispatch(resolveAction(accountGeneralEmailModule.namespace, embeddedEmailFormTypes.actionTypes.destroy));
		await dispatch(resolveAction(accountGeneralPasswordModule.namespace, embeddedPasswordFormTypes.actionTypes.destroy));
		await dispatch(actionTypes.destroyBase);
	},
	async [actionTypes.fetch]({ commit, state, dispatch }) {
		commit(mutationTypes.SET_IS_FORM_LOADING, true);

		try {
			let apiAccount = await accountController.getAccount(state.id);
			commit(mutationTypes.SET_ACCOUNT, mapAccount(apiAccount.account));

			dispatch(resolveAction(accountGeneralEmailModule.namespace,
				embeddedEmailFormTypes.actionTypes.initialize), { email: apiAccount.account.email });
			dispatch(resolveAction(accountGeneralPasswordModule.namespace,
				embeddedPasswordFormTypes.actionTypes.initialize));
		} catch (error) {
			switch (error.constructor) {
				case HttpNotFoundException:
					commit(rootTypes.mutationTypes.SET_PAGE_MODE, PageModeType.PAGE_NOT_FOUND, { root: true });
					break;
				case AccessForbiddenException:
					commit(rootTypes.mutationTypes.SET_PAGE_MODE, PageModeType.ACCESS_DENIED, { root: true });
					break;
				default:
					AlertHelper.handleGeneralRequestErrors(error);
					break;
			}

			commit(mutationTypes.SET_IS_FORM_DISABLED, true);
		} finally {
			commit(mutationTypes.SET_IS_FORM_LOADING, false);
		}
	},
	async [actionTypes.updateStatus]({ commit, state }) {
		commit(mutationTypes.SET_IS_STATUS_SAVING, true);

		try {
			const { isActive } = await accountController.updateAccountStatus(state.id, !state.account.isActive);

			if(isActive)
				alertService.addInfo(AlertKeys.ACCOUNT_ACTIVATED);
			else
				alertService.addInfo(AlertKeys.ACCOUNT_DEACTIVATED);

			commit(mutationTypes.SET_IS_ACTIVE, isActive);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_STATUS_SAVING, false);
		}
	},
	async [actionTypes.resetPassword]({ commit, state }) {
		commit(mutationTypes.SET_IS_RESET_PASSWORD_OPERATION_EXECUTING, true);

		try {
			await accountController.resetAccountPassword(state.id);

			alertService.addInfo(AlertKeys.PASSWORD_RESET_SUCCESSFULLY);

		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_RESET_PASSWORD_OPERATION_EXECUTING, false);
		}
	}
};

const mutations = <MutationTree<AccountGeneralState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	...formMixin.mutations,
	...snapshotMixin.mutations,
	[mutationTypes.SET_ACCOUNT](state, value) {
		state.account = value;
	},
	[mutationTypes.SET_LOGIN](state, value) {
		state.account.login = value;
	},
	[mutationTypes.SET_IS_ACTIVE](state, value) {
		state.account.isActive = value;
	},
	[mutationTypes.SET_IS_STATUS_SAVING](state, value) {
		state.isStatusSaving = value;
	},
	[mutationTypes.SET_ID](state, value) {
		state.id = value;
	},
	[mutationTypes.SET_IS_RESET_PASSWORD_OPERATION_EXECUTING](state, value) {
		state.isResetPasswordOperationExecuting = value;
	}
};

const modules = {
	[accountGeneralEmailModule.namespace]: {
		...accountGeneralEmailModule
	},
	[accountGeneralPasswordModule.namespace]: {
		...accountGeneralPasswordModule
	}
};

const subscribe = (store: any) => {
	accountGeneralEmailModule.subscribe(store);
};

export {
	namespace, state, getters, actions, mutations, subscribe
};

const accountGeneralModule = {
	namespace, state, getters, actions, mutations, subscribe, modules, namespaced: true
};

export default accountGeneralModule;
