import baseMixinTypes from "@/store/shared/base/types";

export const namespace = "apps";

export const getterTypes = {};

export const actionTypes = {
	...baseMixinTypes.actionTypes,
	fetch: "fetch",
	filterApps: "filterApps"
};

export const mutationTypes = {
	...baseMixinTypes.mutationTypes,
	SET_ITEMS: "SET_ITEMS",
	SET_IS_LOADING: "SET_IS_LOADING"
};

const AppsTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default AppsTypes;
