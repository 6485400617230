import { IDictionary } from "@/types/core/dictionary";

export default class ApiCertificate {
	name: string;
	issuer: string;
	friendlyName: string;
	serialNumber: string;
	signatureAlgorithm: string;
	thumbprint: string;
	subject: string;
	subjectAttributesByName: IDictionary<string>;
	subjectAttributesByOid: IDictionary<string>;
	issuerAttributesByName: IDictionary<string>;
	issuerAttributesByOid: IDictionary<string>;
	notAfter: string;
	notBefore: string;
	version: string;
	certificate: string;

	constructor(
		name: string,
		issuer: string,
		friendlyName: string,
		serialNumber: string,
		signatureAlgorithm: string,
		thumbprint: string,
		subject: string,
		subjectAttributesByName: IDictionary<string>,
		subjectAttributesByOid: IDictionary<string>,
		issuerAttributesByName: IDictionary<string>,
		issuerAttributesByOid: IDictionary<string>,
		notAfter: string,
		notBefore: string,
		version: string,
		certificate: string)
	{
		this.name = name;
		this.issuer = issuer;
		this.friendlyName = friendlyName;
		this.serialNumber = serialNumber;
		this.signatureAlgorithm = signatureAlgorithm;
		this.thumbprint = thumbprint;
		this.subject = subject;
		this.subjectAttributesByName = subjectAttributesByName;
		this.subjectAttributesByOid = subjectAttributesByOid;
		this.issuerAttributesByName = issuerAttributesByName;
		this.issuerAttributesByOid = issuerAttributesByOid;
		this.notAfter = notAfter;
		this.notBefore = notBefore;
		this.version = version;
		this.certificate = certificate;
	}
}

