<template>
	<v-form :readonly="isFormSaving || isFormReadonly"
			:disabled="isFormDisabled"
			v-if="isInitialized"
			:ref="refs.form"
			v-model="formValid">
		<frp-form-card>
			<frp-form-card-blocks>
				<frp-form-card-block :title="$t('titles.general')">
					<frp-form-card-block-row>
						<frp-form-card-block-col cols="12" sm="6" md="4" :class="{ 'pb-0': $vuetify.breakpoint.xsOnly }">
							<frp-form-item>
								<frp-text-field :label="$t('fields.inn.label')"
												name="inn"
												autocomplete="inn"
												required
												data-cy="inn"
												:rules="validation.inn"
												:mask="LEGAL_ENTITY_INN_FORMAT"
												:loading="isEgrulLegalEntityLoading"
												v-model="inn"
												:disabled="!can(Permissions.GLOBAL_OFFICE_UPDATE)"
												:placeholder="$t('fields.inn.placeholder')">
								</frp-text-field>
							</frp-form-item>
							<frp-form-item>
								<frp-text-field v-model="ogrn"
												name="ogrn"
												autocomplete="ogrn"
												required
												:rules="validation.ogrn"
												:mask="OGRN_FORMAT"
												:label="$t('fields.ogrn.label')"
												:disabled="!can(Permissions.GLOBAL_OFFICE_UPDATE)"
												:placeholder="$t('fields.ogrn.placeholder')">
								</frp-text-field>
							</frp-form-item>
							<frp-form-item>
								<frp-text-field v-model="kpp"
												name="kpp"
												autocomplete="kpp"
												required
												:mask="KPP_FORMAT"
												:rules="validation.kpp"
												:label="$t('fields.kpp.label')"
												:disabled="!can(Permissions.GLOBAL_OFFICE_UPDATE)"
												:placeholder="$t('fields.kpp.placeholder')">
								</frp-text-field>
							</frp-form-item>

						</frp-form-card-block-col>

						<frp-form-card-block-col offset-md="2" cols="12" sm="6" md="4" :class="{ 'pt-0': $vuetify.breakpoint.xsOnly }">
							<frp-form-item>
								<frp-text-field v-model="longName"
												autocomplete="off"
												required
												:rules="validation.longName"
												:label="$t('fields.fullName.label')"
												:disabled="!can(Permissions.GLOBAL_OFFICE_UPDATE)"
												:placeholder="$t('fields.fullName.placeholder')">
								</frp-text-field>
							</frp-form-item>
							<frp-form-item>
								<frp-text-field v-model="shortName"
												required
												name="organization"
												autocomplete="organization"
												:rules="validation.shortName"
												:label="$t('fields.shortName.label')"
												:disabled="!can(Permissions.GLOBAL_OFFICE_UPDATE)"
												:placeholder="$t('fields.shortName.placeholder')">
								</frp-text-field>
							</frp-form-item>
							<frp-form-item>
								<frp-textarea :label="$t('fields.description.label')"
											  autocomplete="off"
											  :rules="validation.description"
											  :placeholder="$t('fields.description.placeholderAdd')"
											  outlined
											  :disabled="!can(Permissions.GLOBAL_OFFICE_UPDATE)"
											  v-model="description">
								</frp-textarea>
							</frp-form-item>
						</frp-form-card-block-col>
					</frp-form-card-block-row>
				</frp-form-card-block>

			</frp-form-card-blocks>

			<frp-form-actions v-if="can(Permissions.GLOBAL_OFFICE_UPDATE)"
							  :is-save-disabled="!stateContainsUnsavedChanges || !isFormValid"
							  :is-cancel-disabled="!stateContainsUnsavedChanges"
							  :is-loading-state="isFormSaving || isFormLoadingState"
							  @cancel="onFormCancel"
							  @submit="submit">
			</frp-form-actions>
		</frp-form-card>
	</v-form>
	<frp-office-general-loader v-else></frp-office-general-loader>
</template>

<script>
import authorizationMixin from "@/mixins/authorizationMixin";
import FrpTextField from "Components/fields/FrpTextField";
import FrpTextarea from "Components/fields/FrpTextarea";
import FrpFormActions from "Components/form/FrpFormActions";
import FrpFormCardBlock from "Components/markup/FrpFormCardBlock";
import FrpFormCardBlockCol from "Components/markup/FrpFormCardBlockCol";
import FrpFormCardBlockRow from "Components/markup/FrpFormCardBlockRow";
import FrpFormCardBlocks from "Components/markup/FrpFormCardBlocks";
import FrpFormItem from "Components/markup/FrpFormItem";
import FrpFormCard from "Components/markup/FrpFormCard";
import FrpBottomSpace from "Components/layouts/FrpBottomSpace";
import storeManager from "@/store/manager/index";
import { actionTypes, mutationTypes } from "Store/modules/office/modules/general/types";
import storeModuleBasedPage from "Mixins/storeModuleBasedPage";
import formMixin from "Mixins/formMixin";
import { KPP_FORMAT, LEGAL_ENTITY_INN_FORMAT, OGRN_FORMAT } from "Utils/formats";
import {
	prepareLegalEntityInnRule, prepareLegalEntityMaskedInnValidationRule, prepareMaskedKppValidationRule, prepareMaskedOgrnValidationRule,
	prepareMaxLengthRule
} from "Utils/validation";
import FrpOfficeGeneralLoader from "Views/office/sections/FrpOfficeGeneralLoader";
import { createNamespacedHelpers } from "vuex";
import { masks } from "Constants/masks";
import { maxLength } from "Constants/validation";

const { mapState, mapMutations, mapActions } = createNamespacedHelpers(storeManager.office.general.namespace);

export default {
	mixins: [storeModuleBasedPage, formMixin, authorizationMixin],
	data() {
		return {
			namespace: storeManager.office.general.namespace,
			masks,
			KPP_FORMAT,
			LEGAL_ENTITY_INN_FORMAT,
			OGRN_FORMAT,
			validation: {
				inn: [prepareLegalEntityMaskedInnValidationRule()],
				ogrn: [prepareMaskedOgrnValidationRule()],
				kpp: [prepareMaskedKppValidationRule()],
				shortName: [prepareMaxLengthRule({ maxLength: maxLength.long })],
				longName: [prepareMaxLengthRule({ maxLength: maxLength.long })],
				description: [prepareMaxLengthRule({ maxLength: maxLength.long })]
			}
		};
	},
	computed: {
		...mapState({
			office: state => state.office,
			isInitialized: state => state.isInitialized,
			isEgrulLegalEntityLoading: state => state.isEgrulLegalEntityLoading
		}),
		inn: {
			get() {
				return this.office.inn;
			},
			set(value) {
				// TODO Из-за маски происходит повторный вызов set
				if(value !== this.office.inn) {
					this.setInn(value);
				}
			}
		},
		ogrn: {
			get() {
				return this.office.ogrn;
			},
			set(value) {
				this.setOgrn(value);
			}
		},
		kpp: {
			get() {
				return this.office.kpp;
			},
			set(value) {
				this.setKpp(value);
			}
		},
		longName: {
			get() {
				return this.office.longName;
			},
			set(value) {
				this.setLongName(value);
			}
		},
		shortName: {
			get() {
				return this.office.shortName;
			},
			set(value) {
				this.setShortName(value);
			}
		},
		description: {
			get() {
				return this.office.description;
			},
			set(value) {
				this.SetDescription(value);
			}
		}
	},
	methods: {
		...mapActions({
			cancelChanges: actionTypes.cancelChanges,
			save: actionTypes.save
		}),
		...mapMutations({
			setInn: mutationTypes.SET_INN,
			setOgrn: mutationTypes.SET_OGRN,
			setKpp: mutationTypes.SET_KPP,
			setLongName: mutationTypes.SET_LONG_NAME,
			setShortName: mutationTypes.SET_SHORT_NAME,
			SetDescription: mutationTypes.SET_DESCRIPTION
		})
	},
	created() {
		this.initializeStore({ id: this.$route.params.id });
	},
	components: {
		FrpOfficeGeneralLoader,
		FrpTextField,
		FrpTextarea,
		FrpFormActions,
		FrpFormItem,
		FrpFormCardBlockCol,
		FrpFormCardBlockRow,
		FrpFormCardBlock,
		FrpFormCardBlocks,
		FrpFormCard,
		FrpBottomSpace
	}
};
</script>

<style scoped>

</style>
