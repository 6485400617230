import { IMiddleware } from "@/types/core/middleware";
import { Route } from "vue-router/types/router";
import SecurityService from "@/services/auth/securityService";
import jwtDecode from "jwt-decode";
import { RouteNames } from "@/router/routes";

const securityService = new SecurityService();

export default class CheckEmailConfirmationMiddleware implements IMiddleware {
	constructor() {

	}

	async invoke(to: Route, from: Route) {

		try {
			let access_token = await securityService.getToken();

			if(access_token) {
				let { email }: { email: string } = jwtDecode(access_token);
				if(!email && to.name !== RouteNames.CONFIRM_EMAIL) {
					return { name: RouteNames.CONFIRM_EMAIL };
				}
			}
		} catch (e) {
			console.error(e);
		}
	}
}
