import { TrustStatusType } from "@/types/TrustStatusType";
import ApiCounterpartyEmployeeTrust from "@/api/types/counterparty/trust/apiCounterpartyEmployeeTrust";
import { TrustActionerKind } from "@/types/TrustActionerKind";

export const getTrustActionerKind = (trust: ApiCounterpartyEmployeeTrust) => {
	if(trust.status === TrustStatusType.CREATED && (trust.cancellerOfficeEmployeeId || trust.cancellerCounterpartyEmployeeId))
		return TrustActionerKind.CANCELLER;

	if(trust.status === TrustStatusType.ACCEPTED)
		return TrustActionerKind.ACCEPTOR;

	if(trust.status === TrustStatusType.DECLINED)
		return TrustActionerKind.DECLINER;
};
