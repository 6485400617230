import { prepareUrl } from "@/utils/prepareUrlQuery";
import urls from "@/api/config/urls";
import { plainToInstance } from "class-transformer";
import ApiLegalEntity from "@/api/types/legalEntity/apiLegalEntity";
import ApiFnsLegalPerson from "@/api/types/fns/apiFnsLegalPerson";
import HttpNotFoundException from "@/exceptions/httpNotFoundException";
import ApiLegalEntityFnsUpdatePersisted from "@/api/types/fns/apiLegalEntityFnsUpdatePersisted";
import ApiLegalPersonFnsUpdate from "@/api/types/fns/apiLegalPersonFnsUpdate";
import BaseController from "@/api/shared/baseController";
import AbortService from "@/services/abortService";

export class FnsController extends BaseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}


	getEgrulLegalEntity = async (inn: string, ogrn: string, kpp: string): Promise<ApiLegalEntity | null> => {
		try {
			let result = await this.client.get<object>(prepareUrl(urls.fns.getEgrulLegalEntity, {
				inn, ogrn, kpp
			}));
			return plainToInstance(ApiLegalEntity, result);
		} catch (e) {
			if(e instanceof HttpNotFoundException)
				return null;
			else
				throw e;
		}
	};

	getEgripLegalPerson = async (inn: string): Promise<ApiFnsLegalPerson | null> => {
		try {
			let result = await this.client.get<object>(prepareUrl(urls.fns.getEgripLegalPersonByInn, {
				inn
			}));
			return plainToInstance(ApiFnsLegalPerson, result);
		} catch (e) {
			if(e instanceof HttpNotFoundException)
				return null;
			else
				throw e;
		}
	};

	getLegalPersonFnsUpdate = async (inn: string, ogrn: string): Promise<ApiLegalPersonFnsUpdate | null> => {
		try {
			let result = await this.client.get<{ upToDateEntrepreneurPersisted: object }>(prepareUrl(urls.fns.upToDate.getLegalPerson,
				{
					inn, ogrn
				}));
			return plainToInstance(ApiLegalPersonFnsUpdate, result);
		} catch (e) {
			if(e instanceof HttpNotFoundException)
				return null;
			else
				throw e;
		}
	};

	getLegalEntityFnsUpdate = async (inn: string, ogrn: string, kpp: string): Promise<ApiLegalEntityFnsUpdatePersisted | null> => {
		try {
			let { upToDateLegalEntityPersisted } = await this.client.get<{ upToDateLegalEntityPersisted: object }>(prepareUrl(urls.fns.upToDate.getLegalEntity,
				{
					inn, ogrn, kpp
				}));
			return plainToInstance(ApiLegalEntityFnsUpdatePersisted, upToDateLegalEntityPersisted);
		} catch (e) {
			if(e instanceof HttpNotFoundException)
				return null;
			else
				throw e;
		}
	};

	enableLegalPersonFnsUpdate = async (inn: string, ogrn: string, externalId: string): Promise<void> => {
		await this.client.put(prepareUrl(urls.fns.upToDate.enableLegalPerson, {
			inn, ogrn, externalId
		}), null);
	};


	disableLegalPersonFnsUpdate = async (inn: string, ogrn: string, externalId: string): Promise<void> => {
		await this.client.delete(prepareUrl(urls.fns.upToDate.disableLegalPerson, {
			inn, ogrn, externalId
		}));
	};

	enableLegalEntityFnsUpdate = async (inn: string, ogrn: string, kpp: string, externalId: string): Promise<void> => {
		await this.client.put(prepareUrl(urls.fns.upToDate.enableLegalEntity, {
			inn, ogrn, kpp, externalId
		}), null);
	};

	disableLegalEntityFnsUpdate = async (inn: string, ogrn: string, kpp: string, externalId: string): Promise<void> => {
		await this.client.delete(prepareUrl(urls.fns.upToDate.disableLegalEntity, {
			inn, ogrn, kpp, externalId
		}));
	};

	updateLegalEntityViaFns = async (inn: string, ogrn: string, kpp: string, externalId: string): Promise<void> => {
		await this.client.post(prepareUrl(urls.fns.upToDate.updateLegalEntity, {
			inn, ogrn, kpp, externalId
		}), null);
	};

	updateLegalPersonViaFns = async (inn: string, ogrn: string, externalId: string): Promise<void> => {
		await this.client.post(prepareUrl(urls.fns.upToDate.updateLegalPerson, {
			inn, ogrn, externalId
		}), null);
	};

}
