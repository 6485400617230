import IPageState from "@/store/shared/base/types/pageState";

export default class OfficeState implements IPageState {
	constructor(
		public id = "",
		public title = "",
		public isInitialized = false,
		public isDestroyed: boolean = false
	)
	{
	}
}
