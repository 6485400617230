<template>
	<v-container fluid>
		<v-row v-if="this.$slots.title" class="align-center">
			<template v-if="this.$slots['title-append']">
				<v-col cols="auto" sm="6" style="flex-basis: auto">
					<slot name="title"></slot>
				</v-col>
			</template>
			<template v-else>
				<v-col>
					<slot name="title"></slot>
				</v-col>
			</template>

			<v-col md="2" sm="4" class="align-center" v-if="this.$slots['title-append']">
				<slot name="title-append"></slot>
			</v-col>
			<v-col class="d-flex justify-end" v-if="this.$slots['title-append-outer']">
				<v-spacer></v-spacer>
				<slot name="title-append-outer"></slot>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<slot name="content"></slot>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {};
</script>
