import { ConfirmPhoneModeType } from "@/store/modules/confirmPhone/types/confirmPhoneModeType";
import FormState from "@/store/shared/form/models/formState";
import IPageState from "@/store/shared/base/types/pageState";

export default class ConfirmPhoneState implements IPageState {
	constructor(
		public form: FormState,
		public phone: string,
		public mode: ConfirmPhoneModeType,
		public code: string,
		public codeError: string,
		public isProfilePhoneConfirmationRequiredOperationExecuting: boolean,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false
	)
	{
	}
}
