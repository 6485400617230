<template>
	<v-card class="pa-6 pb-1 d-flex align-start flex-wrap"
			:style="{ 'column-gap': $vuetify.breakpoint.lgAndDown ? '56px' : '128px' }" elevation="0">
		<v-container fluid>
			<slot></slot>
		</v-container>
	</v-card>
</template>

<script>
export default {};
</script>

<style scoped>

</style>
