import FormState from "@/store/shared/form/models/formState";
import ApiEntrepreneurEmployeeProfile from "@/api/types/account/profile/apiEntrepreneurEmployeeProfile";
import ApiLegalEntityEmployeeProfile from "@/api/types/account/profile/apiLegalEntityEmployeeProfile";
import { ApiAccountProfileBase } from "@/api/types/account/profile/apiAccountProfileBase";
import IPageState from "@/store/shared/base/types/pageState";

type CounterpartyEmployeeProfile = ApiEntrepreneurEmployeeProfile | ApiLegalEntityEmployeeProfile | ApiAccountProfileBase;

export default class CounterpartyEmployeeProfileState implements IPageState {
	constructor(
		public form: FormState,
		public snapshot: object,
		public profile: CounterpartyEmployeeProfile = new ApiAccountProfileBase(),
		public id: string = "",
		public accountId: string = "",
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public isRequestPhoneConfirmationOperationExecuting: boolean = false,
		public isSignatureRequiredToggleLoading: boolean = false
	)
	{
	}
}
