<template>
	<v-container fluid>
		<v-row v-if="breadcrumbs">
			<v-col class="pt-4">
				<frp-breadcrumbs :items="breadcrumbs"></frp-breadcrumbs>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<span class="text-h5 blue--text text--darken-4">
					<slot name="title"></slot>
				</span>
			</v-col>
		</v-row>
		<v-row class="mt-1">
			<v-col cols="12" sm="4">
				<slot name="search"></slot>
			</v-col>
			<v-col cols="12" sm="4" class="px-sm-0 pt-0 pt-sm-3">
				<slot name="filter"></slot>
			</v-col>
			<v-col v-if="$vuetify.breakpoint.smAndUp"
				   cols="12"
				   sm="4"
				   class="d-flex justify-end">
				<slot name="side-btn"></slot>
			</v-col>
			<v-col v-else
				   cols="12"
				   sm="8"
				   style="height: 52px"
				   class="pt-0">
				<slot name="side-btn"></slot>
			</v-col>
		</v-row>
		<v-row class="mt-0">
			<v-col>
				<slot name="content"></slot>
			</v-col>
		</v-row>
		<v-row class="mt-1">
			<v-col class="d-flex justify-end">
				<slot name="pagination"></slot>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>

import FrpBreadcrumbs from "Components/common/FrpBreadcrumbs";
export default {
	components: { FrpBreadcrumbs },
	props: {
		breadcrumbs: Array
	}
};
</script>
