<template>
	<v-badge
		:value="selectedFilteringParametersCount"
		:content="selectedFilteringParametersCount"
		color="secondary"
		overlap>
		<frp-btn outlined :block="$vuetify.breakpoint.xsOnly"
				 color="primary"
				 dark
				 v-bind="activator.attrs"
				 v-on="activator.on">
			<frp-icon src="ico_filter"
					  color="primary"
					  class="mr-3"/>
			{{ $t("content.filter") }}
		</frp-btn>
	</v-badge>
</template>

<script>
import FrpBtn from "Components/buttons/FrpBtn";
import FrpIcon from "Components/icon/FrpIcon";

export default {
	props: {
		activator: Object,
		selectedFilteringParametersCount: {
			type: Number,
			default: 0
		}
	},
	components: { FrpIcon, FrpBtn }
};
</script>

<style scoped>

</style>
