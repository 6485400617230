import {
	namespace,
	actionTypes,
	mutationTypes
} from "@/store/modules/account/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import accountGeneralModule from "@/store/modules/account/modules/general";
import accountGeneralTypes from "@/store/modules/account/modules/general/types";
import accountProfilesTypes from "@/store/modules/account/modules/profiles/types";
import accountProfilesModule from "@/store/modules/account/modules/profiles";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import AccountState from "@/store/modules/account/types/accountState";
import { mapAccount } from "@/store/modules/account/mapper";
import { AccountController } from "@/api/account";
import AbortService from "@/services/abortService";
import HttpNotFoundException from "@/exceptions/httpNotFoundException";
import rootTypes from "@/store/types";
import { PageModeType } from "@/store/types/pageModeType";
import AccessForbiddenException from "@/exceptions/accessForbiddenException";

const abortService = new AbortService();
const accountController = new AccountController(abortService);

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new AccountState();
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();
const baseMixin = (new BaseMixinBuilder(abortService)).build();


const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<AccountState, any>>{};

const actions = <ActionTree<AccountState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	async [actionTypes.initialize]({ dispatch, state, commit }, { id }) {
		await dispatch(actionTypes.initializeBase);

		if(id) {
			commit(mutationTypes.SET_ID, id);
			await dispatch(actionTypes.fetch);
		}

		commit(mutationTypes.SET_IS_INITIALIZED, true);
	},
	async [actionTypes.fetch]({ state, commit }) {
		commit(mutationTypes.SET_IS_LOADING, true);

		try {
			let apiAccount = await accountController.getAccount(state.id);
			commit(mutationTypes.SET_ACCOUNT, mapAccount(apiAccount.account));
		} catch (error) {
			switch (error.constructor) {
				case HttpNotFoundException:
					commit(rootTypes.mutationTypes.SET_PAGE_MODE, PageModeType.PAGE_NOT_FOUND, { root: true });
					break;
				case AccessForbiddenException:
					commit(rootTypes.mutationTypes.SET_PAGE_MODE, PageModeType.ACCESS_DENIED, { root: true });
					break;
				default:
					AlertHelper.handleGeneralRequestErrors(error);
					break;
			}
		} finally {
			commit(mutationTypes.SET_IS_LOADING, false);
		}
	}
};

const mutations = <MutationTree<AccountState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	[mutationTypes.SET_ACCOUNT](state, value) {
		state.account = value;
	},
	[mutationTypes.SET_IS_LOADING](state, value) {
		state.isLoading = value;
	},
	[mutationTypes.SET_ID](state, value) {
		state.id = value;
	}
};

const modules = {
	[accountGeneralTypes.namespace]: {
		...accountGeneralModule
	},
	[accountProfilesTypes.namespace]: {
		...accountProfilesModule
	}
};


const subscribe = (store: any) => {
	accountGeneralModule.subscribe(store);
};

export {
	namespace, state, getters, actions, mutations, modules, subscribe
};

const accountModule = {
	namespace, state, getters, actions, mutations, namespaced: true, modules, subscribe
};

export default accountModule;
