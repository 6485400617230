import officeGeneralModule from "@/store/modules/office/modules/general";
import officeGeneralTypes from "@/store/modules/office/modules/general/types";
import {
	namespace,
	actionTypes,
	mutationTypes
} from "@/store/modules/office/types";
import OfficeState from "@/store/modules/office/types/officeState";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { GetterTree, MutationTree, ActionTree, Store } from "vuex";
import officeEmployeesTypes from "@/store/modules/office/modules/officeEmployees/types";
import officeEmployeesModule from "@/store/modules/office/modules/officeEmployees";
import { OfficeController } from "@/api/office";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import AbortService from "@/services/abortService";
import HttpNotFoundException from "@/exceptions/httpNotFoundException";
import rootTypes from "@/store/types";
import { PageModeType } from "@/store/types/pageModeType";
import AccessForbiddenException from "@/exceptions/accessForbiddenException";

const abortService = new AbortService();
const officeController = new OfficeController(abortService);

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new OfficeState();
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();
const baseMixin = (new BaseMixinBuilder(abortService)).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<OfficeState, any>>{};

const actions = <ActionTree<OfficeState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	async [actionTypes.initialize]({ dispatch, state, commit }) {
		await dispatch(actionTypes.initializeBase);

		await dispatch(actionTypes.fetch);

		commit(mutationTypes.SET_IS_INITIALIZED, true);
	},
	async [actionTypes.fetch]({ dispatch, commit, rootState, state }) {
		try {
			let apiOffice = await officeController.getOffice(process.env.VUE_APP_HEAD_OFFICE_ID);

			commit(mutationTypes.SET_TITLE, apiOffice.office.shortName);
		} catch (error) {
			switch (error.constructor) {
				case HttpNotFoundException:
					commit(rootTypes.mutationTypes.SET_PAGE_MODE, PageModeType.PAGE_NOT_FOUND, { root: true });
					break;
				case AccessForbiddenException:
					commit(rootTypes.mutationTypes.SET_PAGE_MODE, PageModeType.ACCESS_DENIED, { root: true });
					break;
				default:
					AlertHelper.handleGeneralRequestErrors(error);
					break;
			}
		}
	}
};

const mutations = <MutationTree<OfficeState>>{
	...stateManipulationMixin.mutations,
	...baseMixin.mutations,
	[mutationTypes.SET_ID](state, value) {
		state.id = value;
	},
	[mutationTypes.SET_TITLE](state, value) {
		state.title = value;
	}
};

const modules = {
	...baseMixin.mutations,
	[officeGeneralTypes.namespace]: {
		...officeGeneralModule
	},
	[officeEmployeesTypes.namespace]: {
		...officeEmployeesModule
	}
};

const subscribe = (store: any) => {
	officeGeneralModule.subscribe(store);

	officeEmployeesModule.initializeSubscribersManager(store);
};

export {
	namespace, state, getters, actions, mutations, modules, subscribe
};

const officeModule = {
	namespace, state, getters, actions, mutations, namespaced: true, modules, subscribe
};

export default officeModule;
