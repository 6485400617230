import { IMiddleware } from "@/types/core/middleware";
import { Route } from "vue-router/types/router";
import SecurityService from "@/services/auth/securityService";
import jwtDecode from "jwt-decode";
import { RouteNames } from "@/router/routes";

const securityService = new SecurityService();

export default class CheckPhoneConfirmationMiddleware implements IMiddleware {
	constructor() {

	}

	async invoke(to: Route, from: Route) {
		const freeRoutes = [RouteNames.CONFIRM_PHONE.toString()];
		if(freeRoutes.includes(to.name || ""))
			return;

		try {
			let user = await securityService.getUser();
			if(user.profile && user.profile.phone && !user.profile.isPhoneConfirmed) {
				return { name: RouteNames.CONFIRM_PHONE };
			}
		} catch (e) {
			console.error(e);
		}
	}
}
