import baseMixinTypes from "@/store/shared/base/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";
import formTypes from "@/store/shared/form/types";
import snapshotMixinTypes from "@/store/shared/snapshot/types";

export const namespace = "kpi-main-info";

export const getterTypes = {
	...formTypes.getterTypes,
	...snapshotMixinTypes.getterTypes,
	isReadMode: "isReadMode",
	isEditMode: "isEditMode",
	formattedEditableItemPeriods: "formattedEditableItemPeriods"
};

export const actionTypes = {
	...stateManipulationMixinTypes.actionTypes,
	...baseMixinTypes.actionTypes,
	...formTypes.actionTypes,
	...snapshotMixinTypes.actionTypes,
	fetch: "fetch",
	remapSortCriteriaItems: "remapSortCriteriaItems",
	deleteKpi: "deleteKpi",
	addEditableItemCriteria: "addEditableItemCriteria",
	deleteEditableItemCriteria: "deleteEditableItemCriteria",
	addEditableItemPeriods: "addEditableItemPeriods",
	deleteEditableItemFullPeriod: "deleteEditableItemFullPeriod",
	deleteEditableItemPeriodsByQuarter: "deleteEditableItemPeriodsByQuarter",
	setRegularIndicator: "setRegularIndicator"
};

export const mutationTypes = {
	...stateManipulationMixinTypes.mutationTypes,
	...baseMixinTypes.mutationTypes,
	...formTypes.mutationTypes,
	...snapshotMixinTypes.mutationTypes,
	SET_ID: "SET_ID",
	SET_MODE: "SET_MODE",
	SET_IS_KPI_MAIN_INFO_FORM_SAVING: "SET_IS_KPI_MAIN_INFO_FORM_SAVING",
	SET_IS_KPI_DELETING: "SET_IS_KPI_DELETING",
	SET_IS_KPI_MAIN_INFO_FORM_VALID: "SET_IS_KPI_MAIN_INFO_FORM_VALID",
	RESET_EDITABLE_ITEM: "RESET_EDITABLE_ITEM",
	SET_EDITABLE_ITEM: "SET_EDITABLE_ITEM",
	SET_EDITABLE_ITEM_TITLE: "SET_EDITABLE_ITEM_TITLE",
	SET_EDITABLE_ITEM_METHODOLOGY: "SET_EDITABLE_ITEM_METHODOLOGY",
	SET_EDITABLE_ITEM_IS_REGULAR: "SET_EDITABLE_ITEM_IS_REGULAR",
	SET_EDITABLE_ITEM_CRITERIAS: "SET_EDITABLE_ITEM_CRITERIAS",
	SET_EDITABLE_ITEM_STAFF_IDS: "SET_EDITABLE_ITEM_STAFF_IDS",
	SET_EDITABLE_ITEM_PERIODS: "SET_EDITABLE_ITEM_PERIODS",
	DELETE_EDITABLE_ITEM_PERIODS_ITEM: "DELETE_EDITABLE_ITEM_PERIODS_ITEM",
	SET_KPI_MAIN_INFO_UPDATED: "SET_KPI_MAIN_INFO_UPDATED"
};

const kpiMainInfoTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default kpiMainInfoTypes;
