<template>
	<frp-internal-list-layout>
		<template #title>
			{{ $t("titles.employees") }}
		</template>

		<template #side-btn>
			<frp-btn v-if="can([Permissions.GLOBAL_COUNTERPARTY_EMPLOYEE_CREATE, Permissions.OWN_COUNTERPARTY_EMPLOYEE_CREATE])"
					 class="frp-xs-full-width" @click="handleCreateItem">
				{{ $t("buttons.addEmployee") }}
			</frp-btn>
		</template>

		<template #content>
			<v-data-table :headers="headers"
						  :items="items"
						  :items-per-page="-1"
						  :loading-text="$t('tables.loading')"
						  hide-default-footer
						  :loading="isItemsLoading"
						  item-key="id"
						  :mobile-breakpoint="mobileBreakpoint"
						  must-sort
						  :options="options"
						  @click:row="handleItemSelect"
						  :item-class="() => 'text-body-2'"
						  class="frp-table clickable-rows elevation-0 mt-2 d-flex flex-column">
				<template #item.isActive="{item}">
					<frp-status-label :value="item.isActive"></frp-status-label>
				</template>
				<template #item.trust="{item}">
					<frp-status-tag @click.stop
									:color="setTrustStatusColor(item.trust)"
									:text="$t(`aliases.counterpartyEmployeeTrustStatus.${item.trust}`)"
									:name="RouteNames.COUNTERPARTY_EMPLOYEE_TRUSTS"
									:params="{ employeeId: item.id, counterpartyId: $route.params.id }">
					</frp-status-tag>
				</template>
			</v-data-table>
		</template>
	</frp-internal-list-layout>
</template>

<script>
import FrpStatusTag from "@/components/buttons/FrpStatusTag.vue";
import authorizationMixin from "@/mixins/authorizationMixin";
import storeModuleBasedPage from "@/mixins/storeModuleBasedPage";
import { RouteNames } from "@/router/routes";
import { CounterpartyEmployeeTrustStatus } from "@/types/CounterpartyEmployeeTrustStatus";
import FrpBtn from "Components/buttons/FrpBtn";
import storeManager from "@/store/manager/index";
import { mutationTypes, actionTypes, getterTypes } from "Store/modules/counterparty/modules/counterpartyEmployees/types";
import { createNamespacedHelpers } from "vuex";
import { listMixin } from "Mixins/listMixin";
import colorsMixin from "Mixins/colorsMixin";
import FrpTextField from "Components/fields/FrpTextField";
import FrpStatusLabel from "Components/common/FrpStatusLabel";
import FrpInternalListLayout from "Components/layouts/FrpInternalListLayout";

const namespace = storeManager.counterparty.employees.namespace;
const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);

export default {
	mixins: [storeModuleBasedPage, listMixin, colorsMixin, authorizationMixin],
	data() {
		return {
			namespace,
			RouteNames,
			headers: [
				{
					text: this.$t("tables.number"),
					value: "number",
					class: "text-caption",
					width: "14%"
				},
				{
					text: this.$t("tables.fullName"),
					value: "fullName",
					class: "text-caption",
					width: "17%"
				},
				{
					text: this.$t("tables.email"),
					value: "email",
					class: "text-caption",
					width: "17%"
				},
				{
					text: this.$t("tables.position"),
					value: "position",
					class: "text-caption",
					width: "17%"
				},
				{
					text: this.$t("tables.status"),
					value: "isActive",
					class: "text-caption",
					width: "17%"
				},
				{
					text: this.$t("tables.trust"),
					value: "trust",
					class: "text-caption",
					width: "17%",
					sortable: true,
					sort: (a, b) => {
						const localeA = this.$t(`aliases.counterpartyEmployeeTrustStatus.${a}`)
						const localeB = this.$t(`aliases.counterpartyEmployeeTrustStatus.${b}`)
						return String(localeA).localeCompare(String(localeB));
					}
				}
			]
		};
	},
	computed: {
		...mapGetters({
			items: getterTypes.formattedItems,
			isNoData: getterTypes.isListingEmpty
		})
	},
	methods: {
		handleItemSelect({ id }) {
			this.$router.push({
				name: RouteNames.COUNTERPARTY_EMPLOYEE,
				params: { employeeId: id, counterpartyId: this.$route.params.id }
			});
		},
		handleCreateItem() {
			this.$router.push({
				name: RouteNames.COUNTERPARTY_EMPLOYEE_CREATE,
				params: { counterpartyId: this.$route.params.id }
			});
		},
		setTrustStatusColor(status) {
			switch (status) {
				case CounterpartyEmployeeTrustStatus.ABSENT:
				case CounterpartyEmployeeTrustStatus.REJECTED:
				case CounterpartyEmployeeTrustStatus.OUTDATED:
					return this.colors.secondary.base;
				case CounterpartyEmployeeTrustStatus.REVIEW:
					return this.colors.warning.base;
				case CounterpartyEmployeeTrustStatus.ACTIVE:
					return this.colors.green.base;
			}
		}
	},
	async created() {
		await this.initializeStore({ id: this.$route.params.id });
	},
	components: {
		FrpStatusTag,
		FrpInternalListLayout,
		FrpTextField,
		FrpBtn,
		FrpStatusLabel
	}
};
</script>
