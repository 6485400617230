import IPageState from "@/store/shared/base/types/pageState";
import ApiEmployeeJoinApplicationPersisted from "@/api/types/employeeJoinApplication/apiEmployeeJoinApplicationPersisted";

export default class EmployeeJoinApplicationDetailsState implements IPageState {
	constructor(
		public id: string = "",
		public details = new ApiEmployeeJoinApplicationPersisted(),
		public isLoading: boolean = false,
		public isApproving: boolean = false,
		public isRejecting: boolean = false,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false
	)
	{
	}
}
