<template>
	<frp-form-card>
		<frp-form-card-blocks>
			<frp-form-card-block>
				<template #title>
					<frp-form-card-block-row>
						<frp-form-card-block-col cols="12" sm="6" md="4">
							<frp-form-item>
								<frp-form-block-title-loader/>
							</frp-form-item>
						</frp-form-card-block-col>
					</frp-form-card-block-row>
				</template>

				<frp-form-card-block-row>
					<frp-form-card-block-col cols="12" sm="6" md="4">
						<frp-form-item v-for="i in 2" :key="i">
							<frp-input-loader/>
						</frp-form-item>
					</frp-form-card-block-col>
				</frp-form-card-block-row>
			</frp-form-card-block>
		</frp-form-card-blocks>
	</frp-form-card>
</template>

<script>
import FrpFormCard from "Components/markup/FrpFormCard";
import FrpFormCardBlocks from "Components/markup/FrpFormCardBlocks";
import FrpFormCardBlock from "Components/markup/FrpFormCardBlock";
import FrpFormCardBlockRow from "Components/markup/FrpFormCardBlockRow";
import FrpFormCardBlockCol from "Components/markup/FrpFormCardBlockCol";
import FrpFormItem from "Components/markup/FrpFormItem";
import FrpCheckboxLoader from "Components/loaders/common/FrpCheckboxLoader";
import FrpInputLoader from "Components/loaders/form/FrpInputLoader";
import FrpFormBlockTitleLoader from "Components/loaders/form/FrpFormBlockTitleLoader";

export default {
	components: {
		FrpFormCardBlocks,
		FrpFormCard,
		FrpFormCardBlock,
		FrpFormCardBlockCol,
		FrpFormCardBlockRow,
		FrpFormItem,
		FrpInputLoader,
		FrpFormBlockTitleLoader,
		FrpCheckboxLoader
	}
};
</script>
