import { addProfile, createMap, createMapper, forMember, mapFrom, MappingProfile } from "@automapper/core";
import { classes } from "@automapper/classes";
import CertificateInfo from "@/types/certificateInfo";
import ApiCertificate from "@/api/types/signature/apiCertificate";
import { OID_CP_FIRST_AND_PARTONYMIC_NAMES, OID_CP_LAST_NAME } from "@/constants/signatureIdentifiers";
import { capitalizeFirstLetter } from "@/utils/formatting";
import { convertIsoToNumber, formatDate } from "@/utils/dates";
import { friendlyDateFormat } from "@/utils/formats";

const mapper = createMapper({
	strategyInitializer: classes()
});

const certificateInfoProfile: MappingProfile = (mapper) => {
	createMap(mapper, ApiCertificate, CertificateInfo,
		forMember(d => d.owner, mapFrom(x => {
			const firstAndPatronymicNames = x.subjectAttributesByOid[OID_CP_FIRST_AND_PARTONYMIC_NAMES].toLowerCase()
																									   .split(" ");
			const fullName = [
				x.subjectAttributesByOid[OID_CP_LAST_NAME].toLowerCase(),
				firstAndPatronymicNames[0],
				firstAndPatronymicNames[1]
			];

			return fullName.map(x => capitalizeFirstLetter(x))
						   .join(" ");
		})),
		forMember(d => d.thumbprint, mapFrom(x => x.thumbprint)),
		forMember(d => d.expireDate, mapFrom(x => formatDate(convertIsoToNumber(x.notAfter), friendlyDateFormat)))
	);
};

addProfile(mapper, certificateInfoProfile);

export default mapper;
