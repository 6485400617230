import FormatException from "@/exceptions/formatException";
import { phone } from "phone";

export const processPhone = (value: string) => {
	let { phoneNumber, isValid } = phone(value);

	// Бекенд должен хранить либо информацию о стране телефона, либо приводить все к международному формату (правильный вариант).
	// Пока этого нет, пытается обработать только номера РФ в стиле 8***
	if(!isValid) {
		let result = phone(value, { country: "RU", validateMobilePrefix: false });
		if(!result.isValid)
			throw new FormatException("phone", value);

		({ phoneNumber } = result);
	}

	return phoneNumber;
};

export const isPhoneValid = ({ value, country }: { value: string, country: string }) => {
	let { isValid } = phone(value, { country, validateMobilePrefix: false});

	return isValid;
};
