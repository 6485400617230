import { difference } from "@/utils/difference";
import { Dictionary } from "vue-router/types/router";
import { plainToInstance } from "class-transformer";
import { validateOrReject } from "class-validator";
import EmployeeJoinApplicationsRouteQuery from "@/store/modules/applications/employeeJoin/list/types/employeeJoinApplicationsRouteQuery";
import EmployeeJoinApplicationsState from "@/store/modules/applications/employeeJoin/list/types/employeeJoinApplicationsState";

export default class EmployeeJoinApplicationsRouteQueryService {
	defaultRouteQuery: EmployeeJoinApplicationsRouteQuery;

	constructor(defaultRouteQuery: EmployeeJoinApplicationsRouteQuery) {
		this.defaultRouteQuery = defaultRouteQuery;
	}

	resolveRouteQueryDictionary(state: EmployeeJoinApplicationsState) {
		let query = new EmployeeJoinApplicationsRouteQuery(
			state.search.query,
			state.filter.status ? state.filter.status.join(",") : ""
		);

		return difference(this.defaultRouteQuery, query);
	}

	async resolveRouteQuery(query: Dictionary<String>) {
		try {
			let result = plainToInstance(EmployeeJoinApplicationsRouteQuery, {
				...this.defaultRouteQuery,
				...query
			}, { enableImplicitConversion: true });

			await validateOrReject(result);

			const filterStatus = result.filterStatus.length ? result.filterStatus.split(",") : [];

			return { ...result, filterStatus };
		} catch (e) {
			console.error(e);
			return this.defaultRouteQuery;
		}
	}
}
