import { actionTypes, getterTypes, mutationTypes, namespace } from "@/store/modules/counterpartyEmployee/modules/trust/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import FormMixinBuilder from "@/store/shared/form";
import SnapshotOptions from "@/store/shared/snapshot/snapshotOptions";
import stateSnapshotKeys from "@/store/shared/snapshot/keys";
import SnapshotMixinBuilder from "@/store/shared/snapshot";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import alertService, { AlertKeys } from "@/store/modules/alerts/services/alertService";
import storeManager from "@/store/manager";
import CounterpartyEmployeeTrustState from "@/store/modules/counterpartyEmployee/modules/trust/types/counterpartyEmployeeTrustState";
import CounterpartyEmployeeTrust from "@/store/modules/counterpartyEmployee/modules/trust/types/counterpartyEmployeeTrust";
import { TrustStatusType } from "@/types/TrustStatusType";
import mapper from "@/store/modules/counterpartyEmployee/modules/trust/mapper";
import trustsMapper from "@/store/modules/counterpartyEmployee/modules/trusts/mapper";
import certificateInfoMapper from "@/store/shared/certificates/mapper";
import officeEmployeeMapper from "@/store/modules/officeEmployee/mapper";
import FileMeta from "@/store/shared/storage/types/fileMeta";
import ApiFile from "@/api/types/storage/apiFile";
import { STORAGE_TRUST_NAMESPACE } from "@/constants/storage";
import storageMapper from "@/store/shared/storage/mapper";
import ApiCounterpartyEmployeeTrustCreateRequest from "@/api/types/counterparty/trust/apiCounterpartyEmployeeTrustCreateRequest";
import router from "@/router";
import { resolveNestedState } from "@/utils/vuexModules";
import CounterpartyEmployeeState from "@/store/modules/counterpartyEmployee/types/counterpartyEmployeeState";
import { isNull, mergeWith } from "lodash";
import { OfficeController } from "@/api/office";
import OfficeEmployee from "@/store/modules/officeEmployee/types/officeEmployee";
import { formatFullName } from "@/utils/formatting";
import { formatDate } from "@/utils/dates";
import { dateFormat, dateTimeFormat } from "@/utils/formats";
import { TrustController } from "@/api/counterparty/trust";
import AbortService from "@/services/abortService";
import { StorageController } from "@/api/storage";
import HttpNotFoundException from "@/exceptions/httpNotFoundException";
import Trust from "@/store/modules/counterpartyEmployee/modules/trusts/types/trust";
import { RouteNames } from "@/router/routes";
import ApiCounterpartyEmployeeTrust from "@/api/types/counterparty/trust/apiCounterpartyEmployeeTrust";
import ApiCounterpartyEmployeeTrustPersisted from "@/api/types/counterparty/trust/apiCounterpartyEmployeeTrustPersisted";
import ApiFileMeta from "@/api/types/storage/apiFileMeta";
import { ApiOfficeEmployeeBase } from "@/api/types/office/apiOfficeEmployee";
import { CryptoproController } from "@/api/cryptopro";
import ApiCertificate from "@/api/types/signature/apiCertificate";
import CertificateInfo from "@/types/certificateInfo";
import rootTypes from "@/store/types";
import { PageModeType } from "@/store/types/pageModeType";
import AccessForbiddenException from "@/exceptions/accessForbiddenException";
import { Permissions } from "@/constants/permissions";
import { AuthorizationScopeType } from "@/types/authorization/authorizationScopeType";
import PermissionsService from "@/services/permissionsService";
import { getTrustActionerKind } from "@/utils/getTrustActionerKind";
import { TrustActionerKind } from "@/types/TrustActionerKind";
import CounterpartyEmployeeTrustActioners
	from "@/store/modules/counterpartyEmployee/modules/trust/types/counterpartyEmployeeTrustActioners";

const abortService = new AbortService();
const storageController = new StorageController(abortService);
const trustController = new TrustController(abortService);
const officeController = new OfficeController(abortService);
const cryptoproController = new CryptoproController(abortService);
const permissionsService = new PermissionsService();
const formMixin = (new FormMixinBuilder()).build();
const baseMixin = (new BaseMixinBuilder(abortService)).build();
const snapshotMixin = (new SnapshotMixinBuilder({
	options: [
		new SnapshotOptions({
			key: stateSnapshotKeys.LAST_SAVED,
			fields: ["trust"]
		})
	]
})).build();

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new CounterpartyEmployeeTrustState(
			formMixin.state(),
			snapshotMixin.state()
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<CounterpartyEmployeeTrustState, any>>{
	...formMixin.getters,
	...snapshotMixin.getters,
	[getterTypes.details]: state => {
		return {
			...state.trust,
			acceptedEmployeeName: state.trust.acceptorOfficeEmployeeId ?
				formatFullName(state.acceptedEmployee) :
				"",
			declinedEmployeeName: state.trust.declinerOfficeEmployeeId ?
				formatFullName(state.declinedEmployee) :
				"",
			canceledEmployeeName: state.trust.cancellerOfficeEmployeeId ?
				formatFullName(state.canceledEmployee) :
				"",
			acceptedDate: formatDate(state.trust.acceptedAt, dateTimeFormat),
			declinedDate: formatDate(state.trust.declinedAt, dateTimeFormat),
			canceledDate: formatDate(state.trust.canceledAt, dateTimeFormat),
			expireDate: formatDate(state.trust.expireAt, dateFormat)
		};
	},
	[getterTypes.isSnilsEmpty]: (state, getters, rootState) => {
		const { employee: { snils } } = resolveNestedState<CounterpartyEmployeeState>(rootState,
			storeManager.counterpartyEmployee.namespace);

		return !snils;
	}
};

const actions = <ActionTree<CounterpartyEmployeeTrustState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...formMixin.actions,
	...snapshotMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit, state }, payload: { id: string, employeeId: string, counterpartyId: string }) {
		await dispatch(actionTypes.initializeBase);

		commit(mutationTypes.SET_COUNTERPARTY_ID, payload.counterpartyId);
		commit(mutationTypes.SET_EMPLOYEE_ID, payload.employeeId);

		commit(mutationTypes.SET_ID, payload.id);
		await dispatch(actionTypes.fetch);

		if(payload.id)
			await dispatch(actionTypes.fetchChangeStatusEmployeeInfo);

		commit(mutationTypes.SET_IS_INITIALIZED, true);
		commit(mutationTypes.SET_STATE_SNAPSHOT, stateSnapshotKeys.LAST_SAVED);
	},
	async [actionTypes.handleTrustFileUpload]({ state, commit, dispatch }, file) {
		if(!file) {
			commit(mutationTypes.SET_TRUST_FILE_META, new FileMeta());
			return;
		}

		try {
			commit(mutationTypes.SET_TRUST_FILE_META_IS_LOADING, true);

			const { name, type } = file;
			let meta = await storageController.createTemperFile(new ApiFile(file, name, STORAGE_TRUST_NAMESPACE, type, ""));

			commit(mutationTypes.SET_TRUST_FILE_META, storageMapper.map(meta, ApiFileMeta, FileMeta));
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_TRUST_FILE_META_IS_LOADING, false);
		}
	},
	async [actionTypes.save]({ dispatch, commit, rootState, state }) {
		commit(mutationTypes.SET_IS_FORM_SAVING, true);

		let counterpartyEmployeeState = resolveNestedState<CounterpartyEmployeeState>(rootState,
			storeManager.counterpartyEmployee.namespace);
		const { id: employeeId, counterpartyId } = counterpartyEmployeeState;

		try {
			if(state.id) {
				// TODO update

				alertService.addInfo(AlertKeys.SUCCESS_UPDATED_INFO);
				commit(mutationTypes.SET_STATE_SNAPSHOT, stateSnapshotKeys.LAST_SAVED);
			} else {
				let createRequest = mergeWith({},
					mapper.map(state, CounterpartyEmployeeTrustState, ApiCounterpartyEmployeeTrustCreateRequest),
					mapper.map(counterpartyEmployeeState, CounterpartyEmployeeState, ApiCounterpartyEmployeeTrustCreateRequest),
					(o, s) => isNull(s) ? o : s);

				const scope = await permissionsService.check([Permissions.GLOBAL_COUNTERPARTY_EMPLOYEE_TRUST_CREATE])
					? AuthorizationScopeType.GLOBAL
					: AuthorizationScopeType.OWN;

				let id = await trustController.createCounterpartyEmployeeTrust(counterpartyId, employeeId, createRequest, scope);
				commit(mutationTypes.SET_ID, id);

				alertService.addInfo(AlertKeys.SUCCESS_CREATED_INFO);

				commit(mutationTypes.SET_STATE_SNAPSHOT, stateSnapshotKeys.LAST_SAVED);
				await router.push({ name: RouteNames.COUNTERPARTY_EMPLOYEE_TRUSTS, params: { counterpartyId, id: employeeId } });
			}
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_FORM_SAVING, false);
		}
	},
	async [actionTypes.fetch]({ commit, dispatch, state, rootState }) {
		commit(mutationTypes.SET_IS_FORM_LOADING, true);

		try {
			let { id: employeeId, counterpartyId } = resolveNestedState<CounterpartyEmployeeState>(rootState,
				storeManager.counterpartyEmployee.namespace);

			if(state.id) {
				const scope = await permissionsService.check([Permissions.GLOBAL_COUNTERPARTY_EMPLOYEE_TRUST_READ])
					? AuthorizationScopeType.GLOBAL
					: AuthorizationScopeType.OWN;

				let { id, trust } = await trustController.getCounterpartyEmployeeTrust(state.id, employeeId, counterpartyId, scope);

				const trustActionerKind = getTrustActionerKind(trust);

				if(trustActionerKind) {
					const trustActionerFullName = await trustController.getCounterpartyTrustActioner(
						id,
						employeeId,
						counterpartyId,
						trustActionerKind
					);

					switch(trustActionerKind) {
						case TrustActionerKind.ACCEPTOR: {
							commit(mutationTypes.SET_TRUST_ACCEPTOR_ACTIONER, trustActionerFullName);
							break;
						}
						case TrustActionerKind.CANCELLER: {
							commit(mutationTypes.SET_TRUST_CANCELLER_ACTIONER, trustActionerFullName);
							break;
						}
						case TrustActionerKind.DECLINER: {
							commit(mutationTypes.SET_TRUST_DECLINER_ACTIONER, trustActionerFullName);
							break;
						}
					}

				}
				commit(mutationTypes.SET_TRUST, mapper.map(trust, ApiCounterpartyEmployeeTrust, CounterpartyEmployeeTrust));

				if(trust.isSigned) {
					await dispatch(actionTypes.fetchCertificateInfo);
				}
			} else {
				const scope = await permissionsService.check([Permissions.GLOBAL_COUNTERPARTY_EMPLOYEE_TRUSTS_READ])
					? AuthorizationScopeType.GLOBAL
					: AuthorizationScopeType.OWN;

				let apiTrusts = await trustController.getCounterpartyEmployeeTrusts(counterpartyId, employeeId, scope);
				let trusts = apiTrusts.map(x => trustsMapper.map(x, ApiCounterpartyEmployeeTrustPersisted, Trust));
				let currentActiveTrust = trusts.find(x => x.expireAt > new Date().getTime() && x.status === TrustStatusType.ACCEPTED);

				if(currentActiveTrust)
					commit(mutationTypes.SET_CURRENT_ACTIVE_TRUST_ID, currentActiveTrust.id);
			}
		} catch (error) {
			switch (error.constructor) {
				case HttpNotFoundException:
					commit(rootTypes.mutationTypes.SET_PAGE_MODE, PageModeType.PAGE_NOT_FOUND, { root: true });
					break;
				case AccessForbiddenException:
					commit(rootTypes.mutationTypes.SET_PAGE_MODE, PageModeType.ACCESS_DENIED, { root: true });
					break;
				default:
					AlertHelper.handleGeneralRequestErrors(error);
					break;
			}
		} finally {
			commit(mutationTypes.SET_IS_FORM_LOADING, false);
		}
	},
	async [actionTypes.fetchCertificateInfo]({ commit, state }) {
		const { signature } = await storageController.getFileMeta(state.trust.fileId);

		try {
			const [certificate] = await cryptoproController.getSignerCertificate(signature);

			commit(mutationTypes.SET_CERTIFICATE_INFO, certificateInfoMapper.map(certificate, ApiCertificate, CertificateInfo));

		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		}

	},
	async [actionTypes.fetchChangeStatusEmployeeInfo]({ commit, state, rootState }) {
		// TODO Методы закрыты авторизацией, нужно добавлять сведения в основной метод
		return;

		if(!state.trust.acceptorOfficeEmployeeId && !state.trust.declinerOfficeEmployeeId && !state.trust.cancellerOfficeEmployeeId) return;

		commit(mutationTypes.SET_IS_FORM_LOADING, true);

		const acceptorEmployeeId = state.trust.acceptorOfficeEmployeeId;
		const declinerEmployeeId = state.trust.declinerOfficeEmployeeId;
		const cancellerEmployeeId = state.trust.cancellerOfficeEmployeeId;

		try {
			if(state.id) {

				if(acceptorEmployeeId) {
					let { employee } = await officeController.getOfficeEmployeeById(acceptorEmployeeId);
					commit(mutationTypes.SET_ACCEPTED_EMPLOYEE, officeEmployeeMapper.map(employee, ApiOfficeEmployeeBase, OfficeEmployee));
				}

				if(declinerEmployeeId) {
					let { employee } = await officeController.getOfficeEmployeeById(declinerEmployeeId);
					commit(mutationTypes.SET_DECLINED_EMPLOYEE, officeEmployeeMapper.map(employee, ApiOfficeEmployeeBase, OfficeEmployee));
				}

				if(cancellerEmployeeId) {
					let { employee } = await officeController.getOfficeEmployeeById(cancellerEmployeeId);
					commit(mutationTypes.SET_CANCELED_EMPLOYEE, officeEmployeeMapper.map(employee, ApiOfficeEmployeeBase, OfficeEmployee));
				}

			}
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
			commit(mutationTypes.SET_IS_FORM_DISABLED, true);
		} finally {
			commit(mutationTypes.SET_IS_FORM_LOADING, false);
		}
	},
	async [actionTypes.acceptTrust]({ commit, state, rootState, dispatch }) {
		commit(mutationTypes.SET_IS_ACCEPTING, true);

		try {
			let counterpartyEmployeeState = resolveNestedState<CounterpartyEmployeeState>(rootState,
				storeManager.counterpartyEmployee.namespace);

			await trustController.acceptCounterpartyEmployeeTrust(counterpartyEmployeeState.counterpartyId,
				counterpartyEmployeeState.id, state.id);

			commit(mutationTypes.RESET_TRUST_ACTIONERS);
			await dispatch(actionTypes.fetch);

			alertService.addInfo(AlertKeys.TRUST_ACCEPTED);
			await dispatch(actionTypes.fetchChangeStatusEmployeeInfo);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_ACCEPTING, false);
		}
	},
	async [actionTypes.removeTrustFile]({ commit }) {
		commit(mutationTypes.SET_TRUST_FILE_META, new FileMeta());
	},
	async [actionTypes.declineTrust]({ commit, state, rootState, dispatch }) {
		commit(mutationTypes.SET_IS_DECLINING, true);

		try {
			let counterpartyEmployeeState = resolveNestedState<CounterpartyEmployeeState>(rootState,
				storeManager.counterpartyEmployee.namespace);

			await trustController.declineCounterpartyEmployeeTrust(counterpartyEmployeeState.counterpartyId,
				counterpartyEmployeeState.id, state.id, state.declineReason);

			commit(mutationTypes.RESET_TRUST_ACTIONERS);
			await dispatch(actionTypes.fetch);

			alertService.addInfo(AlertKeys.TRUST_DECLINED);
			await dispatch(actionTypes.fetchChangeStatusEmployeeInfo);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_DECLINING, false);
		}
	},
	async [actionTypes.cancelTrustAccept]({ commit, state, rootState, dispatch }) {
		commit(mutationTypes.SET_IS_CANCELLING, true);

		try {
			let counterpartyEmployeeState = resolveNestedState<CounterpartyEmployeeState>(rootState,
				storeManager.counterpartyEmployee.namespace);

			await trustController.cancelCounterpartyEmployeeTrust(counterpartyEmployeeState.counterpartyId,
				counterpartyEmployeeState.id, state.id);

			commit(mutationTypes.RESET_TRUST_ACTIONERS);
			await dispatch(actionTypes.fetch);

			alertService.addInfo(AlertKeys.TRUST_ACCEPT_CANCELED);
			await dispatch(actionTypes.fetchChangeStatusEmployeeInfo);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_CANCELLING, false);
		}
	}
};

const mutations = <MutationTree<CounterpartyEmployeeTrustState>>{
	...stateManipulationMixin.mutations,
	...baseMixin.mutations,
	...formMixin.mutations,
	...snapshotMixin.mutations,
	[mutationTypes.SET_ID](state, value) {
		state.id = value;
	},
	[mutationTypes.SET_TRUST](state, value) {
		state.trust = value;
	},
	[mutationTypes.SET_TRUST_EXPIRE_AT](state, value) {
		state.trust.expireAt = value;
	},
	[mutationTypes.SET_TRUST_TEMP_FILE_ID](state, value) {
		state.trust.tempFileId = value;
	},
	[mutationTypes.SET_TRUST_FILE_ID](state, value) {
		state.trust.fileId = value;
	},
	[mutationTypes.SET_TRUST_IS_SIGNED](state, value) {
		state.trust.isSigned = value;
	},
	[mutationTypes.SET_TRUST_ACCEPTED_AT](state, value) {
		state.trust.acceptedAt = value;
	},
	[mutationTypes.SET_TRUST_ACCEPTOR_OFFICE_EMPLOYEE_ID](state, value) {
		state.trust.acceptorOfficeEmployeeId = value;
	},
	[mutationTypes.SET_TRUST_DECLINED_AT](state, value) {
		state.trust.declinedAt = value;
	},
	[mutationTypes.SET_TRUST_DECLINER_OFFICE_EMPLOYEE_ID](state, value) {
		state.trust.declinerOfficeEmployeeId = value;
	},
	[mutationTypes.SET_TRUST_STATUS](state, value) {
		state.trust.status = value;
	},
	[mutationTypes.SET_TRUST_CANCELLER_OFFICE_EMPLOYEE_ID](state, value) {
		state.trust.cancellerOfficeEmployeeId = value;
	},
	[mutationTypes.SET_TRUST_CANCELED_AT](state, value) {
		state.trust.canceledAt = value;
	},
	[mutationTypes.SET_CERTIFICATE_INFO](state, value) {
		state.certificateInfo = value;
	},
	[mutationTypes.SET_COUNTERPARTY_ID](state, value) {
		state.counterpartyId = value;
	},
	[mutationTypes.SET_EMPLOYEE_ID](state, value) {
		state.employeeId = value;
	},
	[mutationTypes.SET_TRUST_FILE_META](state, value) {
		state.trustFileMeta = value;
	},
	[mutationTypes.SET_TRUST_FILE_META_IS_LOADING](state, value) {
		state.trustFileMeta.isLoading = value;
	},
	[mutationTypes.SET_IS_DECLINING](state, value) {
		state.isDeclining = value;
	},
	[mutationTypes.SET_IS_ACCEPTING](state, value) {
		state.isAccepting = value;
	},
	[mutationTypes.SET_IS_CANCELLING](state, value) {
		state.isCancelling = value;
	},
	[mutationTypes.SET_ACCEPTED_EMPLOYEE](state, value) {
		state.acceptedEmployee = value;
	},
	[mutationTypes.SET_DECLINED_EMPLOYEE](state, value) {
		state.declinedEmployee = value;
	},
	[mutationTypes.SET_CANCELED_EMPLOYEE](state, value) {
		state.canceledEmployee = value;
	},
	[mutationTypes.SET_CURRENT_ACTIVE_TRUST_ID](state, value) {
		state.currentActiveTrustId = value;
	},
	[mutationTypes.RESET_DECLINE_REASON](state) {
		state.declineReason = "";
	},
	[mutationTypes.SET_DECLINE_REASON](state, value) {
		state.declineReason = value;
	},
	[mutationTypes.RESET_TRUST_ACTIONERS](state) {
		state.trustActioners = new CounterpartyEmployeeTrustActioners();
	},
	[mutationTypes.SET_TRUST_ACCEPTOR_ACTIONER](state, value) {
		state.trustActioners.acceptorFullname = value;
	},
	[mutationTypes.SET_TRUST_CANCELLER_ACTIONER](state, value) {
		state.trustActioners.cancellerFullname = value;
	},
	[mutationTypes.SET_TRUST_DECLINER_ACTIONER](state, value) {
		state.trustActioners.declinerFullname = value;
	}
};

export {
	namespace, state, getters, actions, mutations
};

const counterpartyEmployeeTrustModule = {
	namespace, state, getters, actions, mutations, namespaced: true
};

export default counterpartyEmployeeTrustModule;
