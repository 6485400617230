import {
	mutationTypes,
	actionTypes,
	getterTypes,
	namespace
} from "@/store/modules/masterAccountAccess/modules/signApplicationStep/types";
import BaseMixinBuilder from "@/store/shared/base";
import BaseStepMixinBuilder from "@/store/shared/baseStep";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import SignApplicationStepState from "@/store/modules/masterAccountAccess/modules/signApplicationStep/types/signApplicationStepState";
import { ApplicationStepOrders } from "@/store/modules/masterAccountAccess/types/ApplicationStepOrders";
import AbortService from "@/services/abortService";
import { resolveNestedState } from "@/utils/vuexModules";
import SelectCounterpartyStepState
	from "@/store/modules/masterAccountAccess/modules/selectCounterpartyStep/types/selectCounterpartyStepState";
import storeManager from "@/store/manager";
import { CounterpartyType } from "@/types/CounterpartyType";
import baseMixinTypes from "@/store/shared/base/types";

const abortService = new AbortService();

const baseStepMixin = (new BaseStepMixinBuilder()).build();

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new SignApplicationStepState();
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();
const baseMixin = (new BaseMixinBuilder(abortService)).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<SignApplicationStepState, any>>{
	[getterTypes.isStepCompleted]: (state, getters, rootState) => {
		const { counterpartyType } = resolveNestedState<SelectCounterpartyStepState>(rootState,
			storeManager.masterAccountAccess.selectCounterpartyStep.namespace);

		if(counterpartyType === CounterpartyType.LEGAL_ENTITY) {
			return getters[getterTypes.isSigned];
		} else {
			return true;
		}

	},
	[getterTypes.isSigned]: (state) => {
		return !!state.encodedApplicationString && !!state.encodedDetachedSignatureString;
	}
};

const actions = <ActionTree<SignApplicationStepState, any>>{
	...stateManipulationMixin.actions,
	...baseStepMixin.actions,
	...baseMixin.actions,
	[actionTypes.initialize]({ dispatch, commit }) {
		dispatch(baseMixinTypes.actionTypes.initializeBase);

		commit(mutationTypes.SET_IS_INITIALIZED, true);
	}
};

const mutations = <MutationTree<SignApplicationStepState>>{
	...stateManipulationMixin.mutations,
	...baseStepMixin.mutations,
	...baseMixin.mutations,
	[mutationTypes.SET_ENCODED_APPLICATION_STRING](state, value) {
		state.encodedApplicationString = value;
	},
	[mutationTypes.SET_ENCODED_DETACHED_SIGNATURE_STRING](state, value) {
		state.encodedDetachedSignatureString = value;
	}
};

export {
	namespace, state, getters, actions, mutations
};

const signApplicationStepModule = {
	namespace, state, getters, actions, mutations, namespaced: true
};

export default signApplicationStepModule;
