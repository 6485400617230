import ApiOffice from "@/api/types/office/apiOffice";
import { Type } from "class-transformer";

export default class ApiOfficePersistedBase {
	id: string;
	createdAt: string;
	updatedAt: string;
	@Type(() => ApiOffice)
	office: ApiOffice;

	constructor(id = "", createdAt = "", updatedAt = "", office = new ApiOffice()) {
		this.id = id;
		this.createdAt = createdAt;
		this.updatedAt = updatedAt;
		this.office = office;
	}
}
