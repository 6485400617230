<template>
	<v-container fluid
				 class="pa-0 pa-md-3 full-height align-start flex-column justify-center"
				 fill-height>
		<slot></slot>
	</v-container>
</template>

<script>

import FrpSpinnerLoader from "@/components/loaders/common/FrpSpinnerLoader.vue";

export default {
	components: { FrpSpinnerLoader }
};
</script>
