<template>
	<frp-nested-content-layout>
		<template #title>
			<v-row class="d-flex align-center justify-start pb-1">
				<v-col class="loader-title pb-0">
					<frp-custom-title-loader :width="400" :height="20"/>
				</v-col>
				<v-col class="pb-0">
					<frp-btn-loader :height="40"/>
				</v-col>
			</v-row>
		</template>
		<template #content>
			<frp-tabs-loader></frp-tabs-loader>

			<frp-office-employee-general-loader></frp-office-employee-general-loader>
		</template>
	</frp-nested-content-layout>
</template>

<script>
import FrpBackRouteLoader from "@/components/loaders/common/FrpBackRouteLoader";
import FrpCustomTitleLoader from "@/components/loaders/common/FrpCustomTitleLoader";
import FrpTabsLoader from "@/components/loaders/common/FrpTabsLoader";
import FrpTitleLoader from "@/components/loaders/common/FrpTitleLoader";
import FrpBtnLoader from "@/components/loaders/form/FrpBtnLoader";
import { RouteNames } from "@/router/routes";
import FrpOfficeEmployeeGeneralLoader from "@/views/officeEmployee/FrpOfficeEmployeeGeneralLoader";
import FrpNestedContentLayout from "Components/layouts/FrpNestedContentLayout";
import FrpFormCard from "Components/markup/FrpFormCard";
import FrpFormCardBlocks from "Components/markup/FrpFormCardBlocks";
import FrpFormCardBlock from "Components/markup/FrpFormCardBlock";
import FrpFormCardBlockRow from "Components/markup/FrpFormCardBlockRow";
import FrpFormCardBlockCol from "Components/markup/FrpFormCardBlockCol";
import FrpFormItem from "Components/markup/FrpFormItem";
import FrpCheckboxLoader from "Components/loaders/common/FrpCheckboxLoader";
import FrpInputLoader from "Components/loaders/form/FrpInputLoader";
import FrpFormBlockTitleLoader from "Components/loaders/form/FrpFormBlockTitleLoader";
import FrpSwitchLoader from "Components/loaders/form/FrpSwitchLoader";
import FrpTextareaLoader from "Components/loaders/form/FrpTextareaLoader";

export default {
	data() {
		return {
			RouteNames
		};
	},
	components: {
		FrpBtnLoader,
		FrpCustomTitleLoader,
		FrpTabsLoader,
		FrpBackRouteLoader,
		FrpTitleLoader,
		FrpNestedContentLayout,
		FrpFormCardBlocks,
		FrpFormCard,
		FrpFormCardBlock,
		FrpFormCardBlockCol,
		FrpFormCardBlockRow,
		FrpFormItem,
		FrpInputLoader,
		FrpFormBlockTitleLoader,
		FrpCheckboxLoader,
		FrpSwitchLoader,
		FrpTextareaLoader,
		FrpOfficeEmployeeGeneralLoader
	}
};
</script>
<style scoped>
.loader-title {
	max-width: 400px !important;
}
</style>
