import urls, { urlTemplateParts } from "@/api/config/urls";
import { prepareUrl } from "@/utils/prepareUrlQuery";
import { plainToInstance } from "class-transformer";
import BaseController from "@/api/shared/baseController";
import AbortService from "@/services/abortService";
import ApiGetEmployeeJoinApplicationsParametersBase from "@/api/types/employeeJoinApplication/apiGetEmployeeJoinApplicationsParameters";
import ApiEmployeeJoinApplications from "@/api/types/employeeJoinApplication/apiEmployeeJoinApplications";
import ApiEmployeeJoinApplicationPersisted from "@/api/types/employeeJoinApplication/apiEmployeeJoinApplicationPersisted";
import EmployeeJoinApplicationStatus from "@/api/types/employeeJoinApplication/employeeJoinApplicationStatus";
import ApiEmployeeJoinApplicationCreate from "@/api/types/employeeJoinApplication/apiEmployeeJoinApplicationCreate";
import { AuthorizationScopeType } from "@/types/authorization/authorizationScopeType";

export class EmployeeJoinApplicationController extends BaseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}

	createLegalEntityEmployeeJoinApplications = async (payload: ApiEmployeeJoinApplicationCreate): Promise<string> => {
		return await this.client.post(urls.applications.employeeJoin.createLegalEntityEmployeeJoinApplication, payload);
	};

	getEmployeeJoinApplications = async (parameters: ApiGetEmployeeJoinApplicationsParametersBase,
		scope = AuthorizationScopeType.GLOBAL): Promise<ApiEmployeeJoinApplications> => {
		const url = scope === AuthorizationScopeType.GLOBAL
			? prepareUrl(urls.applications.employeeJoin.getEmployeeJoinApplications, parameters)
			: prepareUrl(urls.applications.employeeJoin.getOwnEmployeeJoinApplications, parameters);

		let data = await this.client.get<{ requests: object[], totalCount: number }>(url, {});

		return new ApiEmployeeJoinApplications(plainToInstance(ApiEmployeeJoinApplicationPersisted, data.requests));
	};

	getEmployeeJoinApplication = async (id: string, scope = AuthorizationScopeType.GLOBAL): Promise<ApiEmployeeJoinApplicationPersisted> =>
	{
		const url = scope === AuthorizationScopeType.GLOBAL
			? urls.applications.employeeJoin.getApplicationDetails.replace(urlTemplateParts.id, id)
			: urls.applications.employeeJoin.getOwnApplicationDetails.replace(urlTemplateParts.id, id);

		let data = await this.client.get<object>(url);

		return plainToInstance(ApiEmployeeJoinApplicationPersisted, data);
	};

	updateEmployeeJoinApplicationStatus = async (id: string, status: EmployeeJoinApplicationStatus,
		scope = AuthorizationScopeType.GLOBAL) =>
	{
		const url = scope === AuthorizationScopeType.GLOBAL
			? urls.applications.employeeJoin.updateStatus.replace(urlTemplateParts.id, id)
			: urls.applications.employeeJoin.updateOwnStatus.replace(urlTemplateParts.id, id);

		return await this.client.put(url, {
			status
		}, {});
	};
}
