import FormState from "@/store/shared/form/models/formState";
import CounterpartyEmployee from "@/store/modules/counterpartyEmployee/modules/general/types/counterpartyEmployee";
import FileMeta from "@/store/shared/storage/types/fileMeta";
import IPageState from "@/store/shared/base/types/pageState";

export default class CounterpartyEmployeeGeneralState implements IPageState {
	constructor(
		public form: FormState,
		public snapshot: object,
		public employee: CounterpartyEmployee = new CounterpartyEmployee(),
		public id: string = "",
		public counterpartyId: string = "",
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public snilsFileMeta: FileMeta = new FileMeta(),
		public isSnilsUploadAvailable: boolean = false,
		public lastSavedSnilsFileId: string = "",
		public isSnilsRecognizing = false,
		public isMasterAccountExitDialogOpened = false
	)
	{
	}
}
