import {
	namespace,
	actionTypes,
	mutationTypes,
	getterTypes
} from "@/store/modules/officeEmployee/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import FormMixinBuilder from "@/store/shared/form";
import SnapshotOptions from "@/store/shared/snapshot/snapshotOptions";
import stateSnapshotKeys from "@/store/shared/snapshot/keys";
import SnapshotMixinBuilder from "@/store/shared/snapshot";
import OfficeEmployeeState from "@/store/modules/officeEmployee/types/officeEmployeeState";
import { OfficeController } from "@/api/office";
import OfficeEmployee from "@/store/modules/officeEmployee/types/officeEmployee";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import mapper from "@/store/modules/officeEmployee/mapper";
import AbortService from "@/services/abortService";
import HttpNotFoundException from "@/exceptions/httpNotFoundException";
import officeEmployeeGeneralModule from "@/store/modules/officeEmployee/modules/general";
import officeEmployeeProfileModule from "@/store/modules/officeEmployee/modules/profile";
import alertService, { AlertKeys } from "@/store/modules/alerts/services/alertService";
import { ApiOfficeEmployeeBase } from "@/api/types/office/apiOfficeEmployee";
import rootTypes from "@/store/types";
import { PageModeType } from "@/store/types/pageModeType";
import AccessForbiddenException from "@/exceptions/accessForbiddenException";

const abortService = new AbortService();
const officeController = new OfficeController(abortService);

const formMixin = (new FormMixinBuilder()).build();
const baseMixin = (new BaseMixinBuilder(abortService)).build();
const snapshotMixin = (new SnapshotMixinBuilder({
	options: [
		new SnapshotOptions({
			key: stateSnapshotKeys.LAST_SAVED,
			fields: ["employee"]
		})
	]
})).build();

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new OfficeEmployeeState(
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<OfficeEmployeeState, any>>{};

const actions = <ActionTree<OfficeEmployeeState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...formMixin.actions,
	...snapshotMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit, state }, { id }: { id: string | null }) {
		await dispatch(actionTypes.initializeBase);

		commit(mutationTypes.SET_OFFICE_ID, process.env.VUE_APP_HEAD_OFFICE_ID);

		if(id)
			commit(mutationTypes.SET_ID, id);

		await dispatch(actionTypes.fetch);

		commit(mutationTypes.SET_IS_INITIALIZED, true);
	},
	async [actionTypes.fetch]({ commit, state, rootState }) {
		try {

			if(state.id) {
				let { employee } = await officeController.getOfficeEmployee(state.id, state.officeId);
				commit(mutationTypes.SET_EMPLOYEE, mapper.map(employee, ApiOfficeEmployeeBase, OfficeEmployee));
				commit(mutationTypes.SET_EMPLOYEE_IS_ACTIVE, employee.isActive);

				let { office } = await officeController.getOffice(state.officeId);
				commit(mutationTypes.SET_OFFICE, office);

				let profile = await officeController.getOfficeEmployeeProfile(state.officeId, state.id);
				commit(mutationTypes.SET_PROFILE, profile);
			}
		} catch (error) {
			switch (error.constructor) {
				case HttpNotFoundException:
					commit(rootTypes.mutationTypes.SET_PAGE_MODE, PageModeType.PAGE_NOT_FOUND, { root: true });
					break;
				case AccessForbiddenException:
					commit(rootTypes.mutationTypes.SET_PAGE_MODE, PageModeType.ACCESS_DENIED, { root: true });
					break;
				default:
					AlertHelper.handleGeneralRequestErrors(error);
					break;
			}
		}
	},
	async [actionTypes.activateEmployee]({ commit, state }) {
		commit(mutationTypes.SET_IS_STATUS_SAVING, true);

		try {
			await officeController.activateOfficeEmployee(state.officeId, state.id);

			commit(mutationTypes.SET_EMPLOYEE_IS_ACTIVE, true);
			alertService.addInfo(AlertKeys.PROFILE_ACTIVATED);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_STATUS_SAVING, false);
		}
	},
	async [actionTypes.deactivateEmployee]({ commit, state }) {
		commit(mutationTypes.SET_IS_STATUS_SAVING, true);

		try {
			await officeController.deactivateOfficeEmployee(state.officeId, state.id);

			commit(mutationTypes.SET_EMPLOYEE_IS_ACTIVE, false);
			alertService.addInfo(AlertKeys.PROFILE_DEACTIVATED);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_STATUS_SAVING, false);
		}
	}

};

const mutations = <MutationTree<OfficeEmployeeState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	...formMixin.mutations,
	...snapshotMixin.mutations,
	[mutationTypes.SET_ID](state, value) {
		state.id = value;
	},
	[mutationTypes.SET_OFFICE](state, value) {
		state.office = value;
	},
	[mutationTypes.SET_OFFICE_ID](state, value) {
		state.officeId = value;
	},
	[mutationTypes.SET_EMPLOYEE](state, value) {
		state.employee = value;
	},
	[mutationTypes.SET_PROFILE](state, value) {
		state.profile = value;
	},
	[mutationTypes.SET_EMPLOYEE_IS_ACTIVE](state, value) {
		state.employee.isActive = value;
	},
	[mutationTypes.SET_IS_STATUS_SAVING](state, value) {
		state.employee.isStatusSaving = value;
	}
};

const modules = {
	[officeEmployeeGeneralModule.namespace]: {
		...officeEmployeeGeneralModule
	},
	[officeEmployeeProfileModule.namespace]: {
		...officeEmployeeProfileModule
	}
};

export {
	namespace, state, getters, actions, mutations, modules
};

const officeEmployeeModule = {
	namespace, state, getters, actions, mutations, namespaced: true, modules
};

export default officeEmployeeModule;
