<template>
	<frp-nested-content-layout>
		<template #title>
			<v-row class="d-flex align-center justify-start pb-1">
				<v-col class="loader-title pb-0">
					<frp-custom-title-loader :width="500" :height="20"/>
				</v-col>
				<v-col class="pb-0">
					<frp-btn-loader :height="40"/>
				</v-col>
			</v-row>
		</template>
		<template #content>
			<frp-tabs-loader></frp-tabs-loader>

			<template v-if="isGeneralPage">
				<frp-counterparty-employee-general-loader/>
			</template>

			<template v-else-if="isProfilePage">
				<frp-counterparty-employee-profile-loader/>
			</template>

			<template v-else-if="isTrustsPage">
				<frp-counterparty-employee-trusts-loader/>
			</template>

		</template>
	</frp-nested-content-layout>
</template>

<script>
import FrpBackRouteLoader from "@/components/loaders/common/FrpBackRouteLoader";
import FrpTabsLoader from "@/components/loaders/common/FrpTabsLoader";
import FrpNestedContentLayout from "Components/layouts/FrpNestedContentLayout";
import FrpCustomTitleLoader from "Components/loaders/common/FrpCustomTitleLoader";
import FrpBtnLoader from "Components/loaders/form/FrpBtnLoader";
import FrpFormCard from "Components/markup/FrpFormCard";
import FrpFormCardBlocks from "Components/markup/FrpFormCardBlocks";
import FrpFormCardBlock from "Components/markup/FrpFormCardBlock";
import FrpFormCardBlockRow from "Components/markup/FrpFormCardBlockRow";
import FrpFormCardBlockCol from "Components/markup/FrpFormCardBlockCol";
import FrpFormItem from "Components/markup/FrpFormItem";
import FrpSwitchLoader from "Components/loaders/form/FrpSwitchLoader";
import FrpTextareaLoader from "Components/loaders/form/FrpTextareaLoader";
import { RouteNames } from "Router/routes";
import FrpCounterpartyEmployeeGeneralLoader from "Views/counterpartyEmployee/FrpCounterpartyEmployeeGeneralLoader";
import FrpCounterpartyEmployeeProfileLoader from "Views/counterpartyEmployee/FrpCounterpartyEmployeeProfileLoader";
import FrpCounterpartyEmployeeTrustsLoader from "Views/counterpartyEmployee/FrpCounterpartyEmployeeTrustsLoader";

export default {
	data() {
		return {
			RouteNames
		};
	},
	computed: {
		isGeneralPage() {
			return this.$route.name === RouteNames.COUNTERPARTY_EMPLOYEE_GENERAL;
		},
		isProfilePage() {
			return this.$route.name === RouteNames.COUNTERPARTY_EMPLOYEE_PROFILE;
		},
		isTrustsPage() {
			return this.$route.name === RouteNames.COUNTERPARTY_EMPLOYEE_TRUSTS;
		}
	},
	components: {
		FrpBtnLoader,
		FrpCounterpartyEmployeeTrustsLoader,
		FrpCounterpartyEmployeeProfileLoader,
		FrpCounterpartyEmployeeGeneralLoader,
		FrpCustomTitleLoader,
		FrpTabsLoader,
		FrpBackRouteLoader,
		FrpNestedContentLayout,
		FrpFormCardBlocks,
		FrpFormCard,
		FrpFormCardBlock,
		FrpFormCardBlockCol,
		FrpFormCardBlockRow,
		FrpFormItem,
		FrpSwitchLoader,
		FrpTextareaLoader
	}
};
</script>
<style scoped>
.loader-title {
	max-width: 500px !important;
}
</style>
