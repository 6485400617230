import { createMap, createMapper, MappingProfile, forMember, mapFrom, addProfile, fromValue, ignore } from "@automapper/core";
import { classes } from "@automapper/classes";
import { ApiCounterpartyEmployeeBase } from "@/api/types/counterparty/apiCounterpartyEmployee";
import CounterpartyEmployee from "@/store/modules/counterpartyEmployee/modules/general/types/counterpartyEmployee";

const mapper = createMapper({
	strategyInitializer: classes()
});

const counterpartyGeneralProfile: MappingProfile = (mapper) => {
	createMap(mapper, CounterpartyEmployee, ApiCounterpartyEmployeeBase,
		forMember(d => d.counterpartyId, ignore()),
		forMember(d => d.email, mapFrom(x => x.email)),
		forMember(d => d.phone, mapFrom(x => x.phone)),
		forMember(d => d.position, mapFrom(x => x.position)),
		forMember(d => d.snils, mapFrom(x => x.snils)),
		forMember(d => d.firstName, mapFrom(x => x.firstName)),
		forMember(d => d.middleName, mapFrom(x => x.middleName)),
		forMember(d => d.lastName, mapFrom(x => x.lastName)),
		forMember(d => d.isActive, mapFrom(x => x.isActive)),
		forMember(d => d.hasLoanAgreementsAccess, mapFrom(x => x.hasLoanAgreementsAccess))
	);
};

addProfile(mapper, counterpartyGeneralProfile);

export default mapper;
