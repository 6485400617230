import ListingModel from "@/store/shared/listing/models/listingModel";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import Trust from "@/store/modules/counterpartyEmployee/modules/trusts/types/trust";
import IPageState from "@/store/shared/base/types/pageState";

export default class TrustsState implements IPageState {
	constructor(
		public listing: ListingModel<Trust>,
		public sorting: SortingModel<String>,
		public paging: PagingModel,
		public id: string = "",
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false
	)
	{
	}
}


