import { createMap, createMapper, MappingProfile, forMember, mapFrom, addProfile, fromValue } from "@automapper/core";
import { classes } from "@automapper/classes";
import { ApiOfficeEmployeeBase } from "@/api/types/office/apiOfficeEmployee";
import OfficeEmployee from "@/store/modules/officeEmployee/modules/general/types/officeEmployee";

const mapper = createMapper({
	strategyInitializer: classes()
});

const officeEmployeeGeneralProfile: MappingProfile = (mapper) => {
	createMap(mapper, OfficeEmployee, ApiOfficeEmployeeBase,
		forMember(d => d.position, mapFrom(x => x.position)),
		forMember(d => d.phone, mapFrom(x => x.phone)),
		forMember(d => d.extensionPhoneNumber, mapFrom(x => x.extensionPhoneNumber)),
		forMember(d => d.snils, mapFrom(x => x.snils)),
		forMember(d => d.firstName, mapFrom(x => x.firstName)),
		forMember(d => d.middleName, mapFrom(x => x.middleName)),
		forMember(d => d.lastName, mapFrom(x => x.lastName)),
		forMember(d => d.description, mapFrom(x => x.description)),
		forMember(d => d.email, mapFrom(x => x.email)),
		forMember(d => d.isActive, mapFrom(x => x.isActive)),
		forMember(d => d.roles, mapFrom(x => x.roles))
	);

	createMap(mapper, ApiOfficeEmployeeBase, OfficeEmployee,
		forMember(d => d.position, mapFrom(x => x.position)),
		forMember(d => d.phone, mapFrom(x => x.phone)),
		forMember(d => d.extensionPhoneNumber, mapFrom(x => x.extensionPhoneNumber)),
		forMember(d => d.snils, mapFrom(x => x.snils)),
		forMember(d => d.firstName, mapFrom(x => x.firstName)),
		forMember(d => d.middleName, mapFrom(x => x.middleName)),
		forMember(d => d.lastName, mapFrom(x => x.lastName)),
		forMember(d => d.description, mapFrom(x => x.description)),
		forMember(d => d.email, mapFrom(x => x.email)),
		forMember(d => d.isActive, mapFrom(x => x.isActive)),
		forMember(d => d.roles, mapFrom(x => x.roles))
	);
};

addProfile(mapper, officeEmployeeGeneralProfile);

export default mapper;
