import FormState from "@/store/shared/form/models/formState";
import LegalEntityCounterparty from "@/store/modules/counterparty/modules/legalEntityCounterparty/types/legalEntityCounterparty";
import LegalEntityFnsUpdate from "@/store/modules/counterparty/modules/legalEntityCounterparty/types/legalEntityFnsUpdate";
import IPageState from "@/store/shared/base/types/pageState";

export default class LegalEntityCounterpartyState implements IPageState {
	constructor(
		public form: FormState,
		public snapshot: object,
		public counterparty: LegalEntityCounterparty = new LegalEntityCounterparty(),
		public fnsUpdate: LegalEntityFnsUpdate = new LegalEntityFnsUpdate(),
		public id: string = "",
		public isEgrulLegalEntityLoading: boolean = false,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public isUpdateViaFnsOperationExecuting: boolean = false,
		public heads: number[] = [],
		public isCounterpartySuccessCreatedDialogOpened: boolean = false
	)
	{
	}
}
