<template>
	<v-app>
		<v-container class="fill-height pa-0" fluid>
			<v-row class="fill-height pa-0" no-gutters>
				<v-col cols="6" lg="7" class="fill-height" style="position:fixed" v-if="$vuetify.breakpoint.smAndUp">
					<v-img src="/img/images/auth-image.jpg" class="fill-height"></v-img>
				</v-col>
				<v-col cols="12"
					   sm="6" offset-sm="6"
					   offset-lg="7" lg="5"
					   class="pa-0">
					<router-view :key="$route.meta.key || $route.name"></router-view>
					<frp-alerts></frp-alerts>
				</v-col>
			</v-row>
		</v-container>
	</v-app>
</template>

<script>
import FrpAlerts from "Components/alerts/FrpAlerts";

export default {
	components: {
		FrpAlerts
	}
};
</script>
