export default class ApiCounterpartyEmployeeTrustCreateRequest {
	constructor(
		public counterpartyId: string,
		public trusteeEmployeeId: string,
		public trusteeSnils: string,
		public expireAt: string,
		public tempFileId: string,
		public isMCHD: boolean
	)
	{
	}
}
