export default class ApiLegalEntityIdentifiers {
	inn: string;
	ogrn: string;
	kpp: string;

	constructor(inn: string, ogrn: string, kpp: string) {
		this.inn = inn;
		this.ogrn = ogrn;
		this.kpp = kpp;
	}
}
