export default class ApiGetAccountsParameters {
	take: number;
	skip: number;
	query: String;
	sortField: String;
	sortingDirection: String;

	constructor(take: number,
		skip: number,
		query: String,
		sortField: String,
		sortingDirection: String)
	{
		this.take = take;
		this.skip = skip;
		this.query = query;
		this.sortField = sortField;
		this.sortingDirection = sortingDirection;
	}
}
