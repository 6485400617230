<template>
	<div class="pb-2">
		<v-subheader v-if="label" class="pa-0 mb-1 grey--text text-caption" style="height: fit-content">
			<span>{{ label }}</span>
			<span v-if="required">*</span>
		</v-subheader>
		<v-otp-input :readonly="readonly"
					 @input="$emit('update:value', $event)"
					 :value="value"
					 :type="type"
					 :style="error ? '' : 'padding-bottom: 8.8px'"
					 :error-messages="error"
					 validate-on-blur
					 :rules="[...defaultRules, ...rules]"
					 v-on="$listeners"
					 v-bind="$attrs"
					 :length="length"
					 :autocomplete="autocomplete"
					 :name="name"
					 :disabled="disabled"
					 :hide-spin-buttons="hideSpinButtons">
		</v-otp-input>
		<div v-if="error" class="text-caption error--text mt-n2 px-4">{{ error }}</div>
	</div>
</template>

<script>
import FrpIcon from "Components/icon/FrpIcon";
import { prepareMinLengthRule, requiredRule } from "Utils/validation";

export default {
	inheritAttrs: false,
	model: {
		prop: "value",
		event: "update:value"
	},
	props: {
		error: String,
		rules: {
			type: Array,
			default: () => []
		},
		readonly: Boolean,
		value: String | Number,
		type: {
			default: "number"
		},
		label: String,
		autocomplete: String,
		name: String,
		disabled: Boolean,
		hideSpinButtons: Boolean,
		required: Boolean,
		length: {
			type: Number | String,
			default: 6
		}
	},
	computed: {
		defaultRules() {
			if(this.required)
				return [requiredRule(), prepareMinLengthRule({ minLength: this.length })];
			else
				return [];
		}
	},
	components: {
		FrpIcon
	}
};
</script>
