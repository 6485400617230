<template>
	<v-responsive>
		<template v-if="loading">
			<frp-details-group>
				<frp-details-subheader-loader></frp-details-subheader-loader>
				<frp-details-item-loader></frp-details-item-loader>
				<frp-details-item-loader></frp-details-item-loader>
				<frp-details-item-loader></frp-details-item-loader>
			</frp-details-group>
		</template>
		<template v-else>
			<frp-details-group>
				<frp-details-section-title v-if="title">{{ title }}</frp-details-section-title>
				<frp-details-subheader>{{ $t("details.titles.general") }}</frp-details-subheader>
				<frp-details-item :title="$t('details.titles.fullPersonName')" :value="details.fullName"></frp-details-item>
				<frp-details-item :title="$t('details.titles.inn')" :value="details.inn"></frp-details-item>
				<frp-details-item :title="$t('details.titles.ogrn')" :value="details.ogrn || details.ogrnIp"></frp-details-item>
			</frp-details-group>
		</template>
	</v-responsive>
</template>

<script>
import ApiLegalPersonCounterparty from "@/api/types/counterparty/apiLegalPersonCounterparty";
import ApiLegalEntity from "Api/types/legalEntity/apiLegalEntity";
import FrpDetailsGroup from "Components/details/FrpDetailsGroup";
import FrpDetailsItem from "Components/details/FrpDetailsItem";
import FrpDetailsSectionTitle from "Components/details/FrpDetailsSectionTitle";
import FrpDetailsSubheader from "Components/details/FrpDetailsSubheader";
import FrpDetailsItemLoader from "Components/loaders/details/FrpDetailsItemLoader";
import FrpDetailsSubheaderLoader from "Components/loaders/details/FrpDetailsSubheaderLoader";

export default {
	props: {
		details: ApiLegalPersonCounterparty,
		title: String,
		loading: Boolean
	},
	components: {
		FrpDetailsSectionTitle,
		FrpDetailsItemLoader,
		FrpDetailsSubheaderLoader,
		FrpDetailsItem,
		FrpDetailsSubheader,
		FrpDetailsGroup
	}
};
</script>
