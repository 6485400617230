<template>
	<v-card class="pa-8 pb-1 d-flex align-start flex-wrap pt-9"
			:style="{ 'column-gap': $vuetify.breakpoint.lgAndDown ? '56px' : '128px' }" elevation="0">
		<v-row class="d-flex flex-wrap">
			<frp-details-section-column>
				<frp-details-group>
					<frp-details-section-title-loader></frp-details-section-title-loader>
					<frp-details-item-loader v-for="(_, i) in 3" :key="i"></frp-details-item-loader>
				</frp-details-group>
			</frp-details-section-column>
		</v-row>
	</v-card>
</template>

<script>
import FrpDetailsItemLoader from "Components/loaders/details/FrpDetailsItemLoader";
import FrpDetailsGroup from "Components/details/FrpDetailsGroup";
import FrpDetailsSectionTitleLoader from "Components/loaders/details/FrpDetailsSectionTitleLoader";
import FrpFileLoader from "Components/loaders/common/FrpFileLoader";
import FrpDetailsSectionColumn from "Components/details/FrpDetailsSectionColumn";

export default {
	components: {
		FrpDetailsItemLoader,
		FrpDetailsGroup,
		FrpDetailsSectionTitleLoader,
		FrpFileLoader,
		FrpDetailsSectionColumn
	}
};
</script>
