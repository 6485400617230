import { Transform } from "class-transformer";
import { convertIsoToNumber } from "@/utils/dates";

export default class LegalPersonFnsUpdate {
	@Transform(({ value }) => convertIsoToNumber(value))
	lastChecked: number;
	@Transform(({ value }) => convertIsoToNumber(value))
	lastUpdated: number;
	isEnabled: boolean;
	externalId: string;
	inn: string;
	ogrn: string;
	type: string;

	constructor(
		lastChecked: number = 0,
		lastUpdated: number = 0,
		isEnabled: boolean = true,
		externalId: string = "",
		inn: string = "",
		ogrn: string = "",
		type: string = ""
	)
	{
		this.lastChecked = lastChecked;
		this.lastUpdated = lastUpdated;
		this.isEnabled = isEnabled;
		this.externalId = externalId;
		this.inn = inn;
		this.ogrn = ogrn;
		this.type = type;
	}
}
