import { TrustStatusType } from "@/types/TrustStatusType";

export default class CounterpartyEmployeeTrust {
	constructor(
		public expireAt: number = 0,
		public tempFileId: string = "",
		public fileId: string = "",
		public isSigned: boolean = false,
		public acceptedAt: number = 0,
		public acceptorOfficeEmployeeId: string = "",
		public declinedAt: number = 0,
		public declinerOfficeEmployeeId: string = "",
		public status: TrustStatusType = TrustStatusType.UNDEFINED,
		public canceledAt: number = 0,
		public cancellerOfficeEmployeeId: string = "",
		public declineReason: string = "",
		public isMCHD: boolean = false
	)
	{
	}
}
