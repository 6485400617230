import {
	namespace,
	actionTypes,
	mutationTypes,
	getterTypes
} from "@/store/modules/office/modules/officeEmployees/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import SortingMixinBuilder from "@/store/shared/sorting";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import ListingMixinBuilder from "@/store/shared/listing";
import ListingModel from "@/store/shared/listing/models/listingModel";
import PagingMixinBuilder from "@/store/shared/paging";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchMixinBuilder from "@/store/shared/search";
import SearchModel from "@/store/shared/search/models/searchModel";
import { sortingOrderType } from "@/store/shared/sorting/models/types/sortingOrderType";
import { resolveAction, resolveMutation, resolveNestedState } from "@/utils/vuexModules";
import { GetterTree, MutationTree, ActionTree, Store, MutationPayload } from "vuex";
import OfficeEmployeesState from "@/store/modules/office/modules/officeEmployees/types/officeEmployeesState";
import storeManager from "@/store/manager/index";
import { OfficeController } from "@/api/office";
import ApiOfficeEmployeePersistedBase from "@/api/types/office/apiOfficeEmployeePersistedBase";
import router from "@/router";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import OfficeEmployeesRouteQuery from "@/store/modules/office/modules/officeEmployees/types/officeEmployeesRouteQuery";
import OfficeEmployeesRouteQueryService from "@/store/modules/office/modules/officeEmployees/services/officeEmployeesRouteQueryService";
import RouteMixinBuilder from "@/store/shared/route";
import BatchService from "@/services/batchService";
import OfficeEmployeesFilter from "@/store/modules/office/modules/officeEmployees/types/officeEmployeesFilter";
import SubscribersManager from "@/store/manager/subscribersManager";
import routeTypes from "@/store/shared/route/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";
import { mapApiGetOfficeEmployeesParameters } from "@/store/modules/office/modules/officeEmployees/mapper";
import { convertIsoToNumber, formatDate } from "@/utils/dates";
import { dateTimeFormat } from "@/utils/formats";
import { formatFullName } from "@/utils/formatting";
import { RoleController } from "@/api/roles";
import AbortService from "@/services/abortService";
import { RouteNames } from "@/router/routes";

const abortService = new AbortService();
const roleController = new RoleController(abortService);
const officeController = new OfficeController(abortService);

const defaultRouteQuery = new OfficeEmployeesRouteQuery(1, "number", sortingOrderType.descending, "", []);

const routeQueryService = new OfficeEmployeesRouteQueryService(defaultRouteQuery);

const updateListingBatchService = new BatchService(({ interval: 100 }));

const routeMixin = (new RouteMixinBuilder<OfficeEmployeesState>()).build();

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new OfficeEmployeesState(
			new ListingModel<ApiOfficeEmployeePersistedBase>({
				items: [],
				isLoadingState: false
			}),
			new SortingModel<String>({
				type: defaultRouteQuery.sort,
				order: defaultRouteQuery.sortDirection
			}),
			new PagingModel({
				total: 0,
				page: 1,
				pageSize: 25
			}),
			new SearchModel({
				query: defaultRouteQuery.query
			}),
			new OfficeEmployeesFilter(
				[]
			),
			routeMixin.state()
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();
const baseMixin = (new BaseMixinBuilder(abortService)).build();
const listingMixin = (new ListingMixinBuilder()).build();
const pagingMixin = (new PagingMixinBuilder()).build();
const sortingMixin = (new SortingMixinBuilder()).build();
const searchMixin = (new SearchMixinBuilder()).build();

const state = (new DefaultStateBuilder()).build();

let subscribersManager: SubscribersManager<OfficeEmployeesState>;

const getters = <GetterTree<OfficeEmployeesState, any>>{
	...listingMixin.getters,
	[getterTypes.formattedItems]: state => {
		return state.listing.items.map(x => {
			return {
				id: x.id,
				fio: formatFullName({ ...x.employee }),
				position: x.employee.position,
				description: x.employee.description,
				isActive: x.employee.isActive,
				roles: x.employee.roles.map((x) => x.description)
						.join(", "),
				createdAt: formatDate(convertIsoToNumber(x.createdAt), dateTimeFormat),
				number: x.number
			};
		});
	},
	[getterTypes.filterRolesItems]: state => {
		return state.roles.map(x => {
			return {
				text: x.description,
				value: `${x.id}`
			};
		});
	}
};

let unsubscribeCallback = () => {
};
let store: Store<{}>;

const initializeSubscribersManager = (value: Store<{}>) => {
	store = value;
	subscribersManager = new SubscribersManager<OfficeEmployeesState>(store);
};

const subscribe = async (mutation: MutationPayload, rootState: any) => {
	const employeesNamespace = storeManager.office.employees.namespace;
	let state = resolveNestedState<OfficeEmployeesState>(rootState, employeesNamespace);
	switch (mutation.type) {
		case resolveMutation(routeTypes.namespace, routeTypes.mutationTypes.ROUTE_CHANGED):
			if((mutation.payload.from.name === mutation.payload.to.name) && !state.route.isPushing)
				await subscribersManager.dispatch(resolveAction(employeesNamespace, actionTypes.processRouteQuery));
			break;
		case resolveMutation(employeesNamespace, mutationTypes.SET_SORTING_TYPE):
		case resolveMutation(employeesNamespace, mutationTypes.SET_SORTING_ORDER):
		case resolveMutation(employeesNamespace, mutationTypes.SET_SEARCH_QUERY):
		case resolveMutation(employeesNamespace, mutationTypes.SET_FILTER_ROLES):
			if(!state.route.isProcessing) {
				await subscribersManager.dispatch(resolveAction(employeesNamespace, actionTypes.resetPagingPage));
			}
			break;
		case resolveMutation(employeesNamespace, mutationTypes.SET_PAGING_PAGE):
		{
			if(!state.route.isProcessing)
				await subscribersManager.dispatch(resolveAction(employeesNamespace, actionTypes.pushRoute));

			updateListingBatchService.push(async () => {
				await subscribersManager.dispatch(resolveAction(employeesNamespace, actionTypes.updateListingItems));
			});

			break;
		}
	}
};

const actions = <ActionTree<OfficeEmployeesState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...listingMixin.actions,
	...pagingMixin.actions,
	...sortingMixin.actions,
	...searchMixin.actions,
	...routeMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit }) {
		await dispatch(actionTypes.initializeBase);

		await dispatch(actionTypes.processRouteQuery);
		await dispatch(actionTypes.reconstituteRoute);

		await Promise.all([
			dispatch(actionTypes.updateListingItems),
			dispatch(actionTypes.fetchRoles)
		]);

		unsubscribeCallback = subscribersManager.subscribe(subscribe);
		commit(mutationTypes.SET_IS_INITIALIZED, true);
	},
	async [actionTypes.updateListingItems]({ state, commit, rootState }) {
		commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, true);

		const id = process.env.VUE_APP_HEAD_OFFICE_ID;
		// const id = router.currentRoute.params.id;

		try {
			let { employees, totalCount } = await officeController.getOfficeEmployees(id, mapApiGetOfficeEmployeesParameters(state));

			commit(mutationTypes.SET_LISTING_ITEMS, employees);
			commit(mutationTypes.SET_PAGING_TOTAL, totalCount);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, false);
		}
	},
	async [actionTypes.fetchRoles]({ commit }) {
		commit(mutationTypes.SET_ROLES_IS_LOADING, true);

		try {
			const roles = await roleController.getOfficeEmployeeRoles();

			commit(mutationTypes.SET_ROLES, roles);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_ROLES_IS_LOADING, false);
		}
	},
	async [actionTypes.processRouteQuery]({ rootState, commit }) {
		commit(mutationTypes.SET_IS_ROUTE_PROCESSING, true);

		let routeQuery = await routeQueryService.resolveRouteQuery(rootState.route.query);

		commit(mutationTypes.SET_PAGING_PAGE, routeQuery.page);
		commit(mutationTypes.SET_SORTING_ORDER, routeQuery.sortDirection);
		commit(mutationTypes.SET_SORTING_TYPE, routeQuery.sort);
		commit(mutationTypes.SET_SEARCH_QUERY, routeQuery.query);
		commit(mutationTypes.SET_FILTER_ROLES, routeQuery.filterRoles);

		commit(mutationTypes.SET_IS_ROUTE_PROCESSING, false);
	},
	async [actionTypes.pushRoute]({ state, commit }) {
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, true);

		await router.push({
			name: RouteNames.OFFICE_EMPLOYEES,
			query: routeQueryService.resolveRouteQueryDictionary(state)
		})
					.catch(() => {
					});

		commit(mutationTypes.SET_IS_ROUTE_PUSHING, false);
	},
	async [actionTypes.reconstituteRoute]({ state, commit }) {
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, true);

		await router.replace({
			name: RouteNames.OFFICE_EMPLOYEES,
			query: routeQueryService.resolveRouteQueryDictionary(state)
		})
					.catch(() => {
					});

		commit(mutationTypes.SET_IS_ROUTE_PUSHING, false);
	},
	async [actionTypes.destroy]({ dispatch }) {
		unsubscribeCallback();
		await dispatch(actionTypes.destroyBase);
	}
};

const mutations = <MutationTree<OfficeEmployeesState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	...listingMixin.mutations,
	...pagingMixin.mutations,
	...sortingMixin.mutations,
	...searchMixin.mutations,
	...routeMixin.mutations,
	[mutationTypes.SET_FILTER_ROLES](state, value) {
		state.filter.roles = value;
	},
	[mutationTypes.SET_ROLES](state, value) {
		state.roles = value;
	},
	[mutationTypes.SET_ROLES_IS_LOADING](state, value) {
		state.rolesIsLoading = value;
	}
};

export {
	namespace, state, getters, actions, mutations, initializeSubscribersManager
};

const officeEmployeesModule = {
	namespace, state, getters, actions, mutations, namespaced: true, initializeSubscribersManager
};

export default officeEmployeesModule;
