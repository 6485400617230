import { IsIn, IsArray } from "class-validator";
import { AVAILABLE_FILTER_TYPE } from "@/store/modules/counterparties/constants";

export default class CounterpartiesFilter {
	@IsArray()
	@IsIn(AVAILABLE_FILTER_TYPE, {
		each: true
	})
	type: String[]

	constructor(type: String[]) {
		this.type = type;
	}
}
