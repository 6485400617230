<template>
	<frp-nested-content-layout v-if="isInitialized">
		<template #title>
			<frp-subtitle-five>
				{{ title }}
			</frp-subtitle-five>
		</template>
		<template #title-append v-if="visibleToggleActivityStatusBtn">
			<frp-toggle-activity-btn
				v-if="can([Permissions.GLOBAL_COUNTERPARTY_EMPLOYEE_STATUS_UPDATE, Permissions.OWN_COUNTERPARTY_EMPLOYEE_STATUS_UPDATE])"
				:is-active="isActive"
				:loading="isStatusSaving"
				class="elevation-0 frp-xs-full-width"
				@click="toggleActivityStatus">
			</frp-toggle-activity-btn>
		</template>
		<template #title-append-outer>
			<frp-btn v-if="can(Permissions.GLOBAL_ACCOUNT_READ) && crossLink"
					 :to="crossLink"
					 outlined
					 class="frp-xs-full-width">
				{{ $t("buttons.goToAccount") }}
			</frp-btn>
		</template>
		<template #content>
			<template v-if="shouldShowTabs">
				<frp-tabs>
					<v-tab key="general"
						   v-if="can([Permissions.GLOBAL_COUNTERPARTY_EMPLOYEE_READ, Permissions.OWN_COUNTERPARTY_EMPLOYEE_READ])"
						   :to="{ name: RouteNames.COUNTERPARTY_EMPLOYEE_GENERAL, params: { counterpartyId, id  } }"
						   class="text-none">
						{{ $t("subheaders.general") }}
					</v-tab>
					<v-tab key="profile"
						   v-if="can([Permissions.GLOBAL_COUNTERPARTY_EMPLOYEE_PROFILE_READ, Permissions.OWN_COUNTERPARTY_EMPLOYEE_PROFILE_READ]) && !isMasterAccount"
						   :to="{ name: RouteNames.COUNTERPARTY_EMPLOYEE_PROFILE, params: { counterpartyId, id  } }"
						   class="text-none">
						{{ $t("subheaders.profile") }}
					</v-tab>
					<v-tab key="trusts" :to="{ name: RouteNames.COUNTERPARTY_EMPLOYEE_TRUSTS, params: { counterpartyId, id  } }"
						   v-if="can([Permissions.GLOBAL_COUNTERPARTY_EMPLOYEE_TRUSTS_READ, Permissions.OWN_COUNTERPARTY_EMPLOYEE_TRUSTS_READ])"
						   class="text-none">
						{{ $t("subheaders.trusts") }}
					</v-tab>
				</frp-tabs>
			</template>
			<frp-router-view></frp-router-view>
			<frp-bottom-space></frp-bottom-space>
		</template>
	</frp-nested-content-layout>
	<frp-counterparty-employee-loader v-else></frp-counterparty-employee-loader>
</template>

<script>
import FrpSubtitleFive from "@/components/typography/FrpSubtitleFive";
import FrpBtn from "@/components/buttons/FrpBtn";
import FrpToggleActivityBtn from "@/components/buttons/FrpToggleActivityBtn";
import authorizationMixin from "@/mixins/authorizationMixin";
import { RouteNames } from "@/router/routes";
import { actionTypes } from "@/store/modules/counterpartyEmployee/types";
import FrpRouterView from "Components/common/FrpRouterView";
import FrpRadioCardGroup from "Components/fields/FrpRadioCardGroup";
import FrpTextField from "Components/fields/FrpTextField";
import FrpNestedContentLayout from "Components/layouts/FrpNestedContentLayout";
import FrpBottomSpace from "Components/layouts/FrpBottomSpace";
import FrpFormCard from "Components/markup/FrpFormCard";
import FrpTabs from "Components/tabs/FrpTabs";
import FrpFormCardBlock from "Components/markup/FrpFormCardBlock";
import FrpFormCardBlockCol from "Components/markup/FrpFormCardBlockCol";
import FrpFormCardBlockRow from "Components/markup/FrpFormCardBlockRow";
import FrpFormCardBlocks from "Components/markup/FrpFormCardBlocks";
import FrpFormItem from "Components/markup/FrpFormItem";
import FrpPageNotFound from "Views/errors/FrpPageNotFound";
import FrpCounterpartyEmployeeLoader from "Views/counterpartyEmployee/FrpCounterpartyEmployeeLoader";
import colorsMixin from "Mixins/colorsMixin";
import storeModuleBasedPage from "Mixins/storeModuleBasedPage";
import storeManager from "Store/manager";
import { getterTypes } from "Store/modules/counterpartyEmployee/types";
import { getterTypes as userGetterTypes } from "Store/modules/user/types";
import { formatFullName } from "Utils/formatting";
import { createNamespacedHelpers } from "vuex";

const namespace = storeManager.counterpartyEmployee.namespace;
const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);
const trustModuleHelpers = createNamespacedHelpers(storeManager.counterpartyEmployee.trust.namespace);
const userHelpers = createNamespacedHelpers(storeManager.user.namespace);

export default {
	mixins: [storeModuleBasedPage, colorsMixin, authorizationMixin],
	data() {
		return {
			namespace,
			RouteNames
		};
	},
	computed: {
		...mapState({
			id: state => state.id,
			isInitialized: state => state.isInitialized,
			profilePersisted: state => state.profile,
			employee: state => state.employee,
			counterparty: state => state.counterparty,
			counterpartyId: state => state.counterpartyId,
			isActive: state => state.employee.isActive,
			isStatusSaving: state => state.employee.isStatusSaving
		}),
		...mapGetters({
			counterpartyTitle: getterTypes.counterpartyTitle
		}),
		...userHelpers.mapGetters({
			isMasterAccount: userGetterTypes.isMasterAccount
		}),
		...trustModuleHelpers.mapState({
			trustId: state => state.id
		}),
		crossLink() {
			if(!this.profilePersisted || !this.profilePersisted.profile.accountId)
				return;

			return {
				name: RouteNames.ACCOUNT,
				params: {
					id: this.profilePersisted.profile.accountId
				}
			};
		},
		isCreateMode() {
			return !this.$route.params.employeeId;
		},
		shouldShowTabs() {
			return !this.isCreateMode && !(this.$route.name === RouteNames.COUNTERPARTY_EMPLOYEE_TRUST_CREATE
				|| this.$route.name === RouteNames.COUNTERPARTY_EMPLOYEE_TRUST);
		},
		title() {
			switch (this.$route.name) {
				case RouteNames.COUNTERPARTY_EMPLOYEE_CREATE:
					return this.$t("titles.createEmployee");
				case RouteNames.COUNTERPARTY_EMPLOYEE_TRUSTS:
				case RouteNames.COUNTERPARTY_EMPLOYEE_PROFILE:
				case RouteNames.COUNTERPARTY_EMPLOYEE_GENERAL:
					return (this.counterpartyTitle ? `${this.$t("titles.employee")} ${this.counterpartyTitle} - ` : "") +
						formatFullName(this.employee);
				case RouteNames.COUNTERPARTY_EMPLOYEE_TRUST_CREATE:
					return this.$t("titles.createTrust");
				case RouteNames.COUNTERPARTY_EMPLOYEE_TRUST:
					return this.$t("titles.trust");
			}
		},
		visibleToggleActivityStatusBtn() {
			return this.profilePersisted && (this.$route.name === RouteNames.COUNTERPARTY_EMPLOYEE_GENERAL || this.$route.name ===
				RouteNames.COUNTERPARTY_EMPLOYEE_TRUSTS || this.$route.name === RouteNames.COUNTERPARTY_EMPLOYEE_PROFILE);
		}
	},
	methods: {
		...mapActions({
			activateEmployee: actionTypes.activateEmployee,
			deactivateEmployee: actionTypes.deactivateEmployee
		}),
		async toggleActivityStatus() {
			if(this.isActive)
				await this.deactivateEmployee();
			else
				await this.activateEmployee();
		}
	},
	created() {
		this.initializeStore({ id: this.$route.params.employeeId, counterpartyId: this.$route.params.counterpartyId });
	},
	components: {
		FrpToggleActivityBtn,
		FrpBtn,
		FrpTabs,
		FrpRouterView,
		FrpRadioCardGroup,
		FrpTextField,
		FrpFormItem,
		FrpFormCardBlockCol,
		FrpFormCardBlockRow,
		FrpFormCardBlock,
		FrpFormCardBlocks,
		FrpFormCard,
		FrpNestedContentLayout,
		FrpBottomSpace,
		FrpCounterpartyEmployeeLoader,
		FrpPageNotFound,
		FrpSubtitleFive
	}
};
</script>
