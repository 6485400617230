import CounterpartiesRouteQuery from "@/store/modules/counterparties/types/counterpartiesRouteQuery";
import CounterpartiesState from "@/store/modules/counterparties/types/counterpartiesState";
import { difference } from "@/utils/difference";
import { Dictionary } from "vue-router/types/router";
import { plainToInstance } from "class-transformer";
import { validateOrReject } from "class-validator";
import { parseArrayParameter, parseQuery } from "@/utils/query";

export default class CounterpartiesRouteQueryService {
	defaultRouteQuery: CounterpartiesRouteQuery;

	constructor(defaultRouteQuery: CounterpartiesRouteQuery) {
		this.defaultRouteQuery = defaultRouteQuery;
	}

	resolveRouteQueryDictionary(state: CounterpartiesState) {
		let query = new CounterpartiesRouteQuery(
			state.paging.page,
			state.sorting.type,
			state.sorting.order,
			state.search.query,
			state.filter.type
		);

		return difference(this.defaultRouteQuery, query);
	}

	async resolveRouteQuery(query: Dictionary<any>) {
		try {
			let result = plainToInstance(CounterpartiesRouteQuery, {
				...this.defaultRouteQuery,
				...query,
				filterType: parseArrayParameter(query.filterType)
			}, { enableImplicitConversion: true });

			await validateOrReject(result);

			return result;
		} catch (e) {
			console.error(e);
			return this.defaultRouteQuery;
		}
	}
}
