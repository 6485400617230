import CounterpartyEmployee from "@/store/modules/counterpartyEmployee/types/counterpartyEmployee";
import ApiAccountProfilePersistedBase from "@/api/types/account/apiAccountProfilePersistedBase";
import ApiCounterpartyBase from "@/api/types/counterparty/apiCounterpartyBase";
import ApiLegalEntityCounterparty from "@/api/types/counterparty/apiLegalEntityCounterparty";
import ApiPhysicalPersonCounterparty from "@/api/types/counterparty/apiPhysicalPersonCounterparty";
import ApiForeignOrganizationCounterparty from "@/api/types/counterparty/apiForeignOrganizationCounterparty";
import ApiLegalPersonCounterparty from "@/api/types/counterparty/apiLegalPersonCounterparty";
import IPageState from "@/store/shared/base/types/pageState";

export type CounterpartyType =
	| ApiLegalEntityCounterparty
	| ApiPhysicalPersonCounterparty
	| ApiForeignOrganizationCounterparty
	| ApiLegalPersonCounterparty
	| ApiCounterpartyBase;

export default class CounterpartyEmployeeState implements IPageState {
	constructor(
		public id: string = "",
		public counterpartyId: string = "",
		public counterparty: CounterpartyType = new ApiCounterpartyBase(),
		public employee: CounterpartyEmployee = new CounterpartyEmployee(),
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public profile: ApiAccountProfilePersistedBase | null = null
	)
	{
	}
}
