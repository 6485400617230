<template>
	<v-app>
		<v-navigation-drawer v-if="!hiddenNavBar"
							 v-model="drawer"
							 class="frp-navigation"
							 :clipped="$vuetify.breakpoint.lgAndUp"
							 :mini-variant-width="88"
							 :width="320"
							 app>
			<frp-text-field :style="{ 'background-color': colors.blue.darken4 }"
							:placeholder="$t('placeholders.search')"
							v-model="search"
							hide-details
							icon="ico_search"
							class="px-4 py-3">
			</frp-text-field>
			<v-list class="pt-0 frp-menu"
					v-if="isInitialized"
					:key="menuKey"
					:expand="!!search">
				<v-list-group color="white"
							  v-for="(group, i) of filteredItems"
							  v-if="can(group.children ? group.children.map(x => x.permission) : [])"
							  :key="i"
							  :class="{ 'frp-menu-active-group': checkIsGroupActive(group) }"
							  :value="!!search || checkIsGroupActive(group)">
					<template #activator>
						<v-list-item-action class="mr-2 pl-1">
							<frp-icon :color="colors.white.base" src="ico_square"></frp-icon>
						</v-list-item-action>

						<v-list-item-content>
							<v-list-item-title class="text-subtitle-2 white--text text--darken-1">
								{{ group.text }}
							</v-list-item-title>
						</v-list-item-content>
					</template>

					<v-list-item-group>
						<v-list-item v-for="(child, j) in group.children"
									 v-if="can(child.permission)"
									 :value="`${i}_${j}`"
									 :to="child.routeConfig"
									 :class="{
									 	'frp-menu-active-item': child.routeConfig.name === $route.name,
									 	'frp-menu-active-child-item': child.childrenRoutes && child.childrenRoutes.includes($route.name)
									 }"
									 link
									 :key="child.text">
							<v-list-item-content>
								<v-list-item-title class="text-subtitle-2 white--text text--darken-1 pl-3" v-text="child.text">
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list-item-group>
				</v-list-group>
			</v-list>
		</v-navigation-drawer>

		<v-app-bar app
				   :clipped-left="$vuetify.breakpoint.lgAndUp"
				   elevation="2"
				   color="white">
			<v-app-bar-nav-icon v-if="$vuetify.breakpoint.mdAndDown && !hiddenNavBar"
								@click.stop="drawer = !drawer" class="ml-0"></v-app-bar-nav-icon>
			<router-link :to="{ name: RouteNames.ROOT }" v-if="$vuetify.breakpoint.smAndUp">
				<v-toolbar-title style="cursor: pointer">
					<frp-icon src="ico_logo" :color="colors.secondary.base"></frp-icon>
				</v-toolbar-title>
			</router-link>
			<v-spacer></v-spacer>
			<v-menu bottom
					nudge-bottom="20"
					max-width="250"
					offset-y
					ref="menu"
					v-if="isInitialized && $vuetify.breakpoint.mdAndUp"
					content-class="elevation-5 menu-transition">
				<template v-slot:activator="{ on, attrs }" v-if="user.profiles.length > 1">
					<v-btn max-width="300"
						   :loading="isSelectProfileOperationExecuting"
						   class="text-none profile-btn text-left justify-end user-menu mr-7 pr-2 pl-5 text-subtitle-2 primary--text text--darken-1"
						   v-ripple="false"
						   v-bind="attrs"
						   v-on="on" text outlined>
						<span class="text-truncate">
							{{ user.profile.title }}
						</span>
						<frp-icon class="ml-2" src="ico_chevron-down" :color="colors.primary.base"></frp-icon>
					</v-btn>
				</template>
				<v-list class="py-2" v-mutate="fixMenu" :disabled="isSelectProfileOperationExecuting">
					<v-list-item-group>
						<v-list-item v-for="(profile, i) in userActiveProfiles" :key="i"
									 :disabled="user.profile && (profile.number === user.profile.number)"
									 @click="() => handleSelectProfile(profile)">
							<div class="d-flex flex-column align-start">
								<span class="text-subtitle-2 grey--text ">{{ profile.title }}</span>
							</div>
							<v-spacer></v-spacer>
							<frp-icon v-if="user.profile && (profile.number === user.profile.number)"
									  class="ml-6" src="ico_check"
									  :color="colors.green.base"></frp-icon>
						</v-list-item>
					</v-list-item-group>
				</v-list>
			</v-menu>
			<div class="d-flex mr-8" v-if="!isInitialized">
				<v-skeleton-loader height="40" width="40" type="image" class="rounded-circle mr-1"/>
				<div class="d-flex flex-column justify-center mr-1" style="gap: 2px">
					<v-skeleton-loader height="10" width="100" type="image" class="mb-1 rounded-lg"/>
					<v-skeleton-loader height="10" width="100" type="image" class="rounded-lg"/>
				</div>
			</div>
			<v-menu bottom
					left
					nudge-bottom="20"
					offset-y
					content-class="elevation-5"
					v-else>
				<template v-slot:activator="{ on, attrs }">
					<v-btn class="text-none text-left justify-end user-menu"
						   v-ripple="false"
						   v-bind="attrs"
						   data-cy="user-menu"
						   v-on="on" color="primary" text>
						<div class="d-flex">
							<v-avatar class="mr-1" v-if="user.account">
								<v-icon dark size="40" :alt="user.account.login">
									mdi-account-circle
								</v-icon>
							</v-avatar>

							<div class="d-flex flex-column justify-center mr-1" style="gap: 2px">
								<span class="text-subtitle-2 primary--text text--darken-1">{{ user.account.login }}</span>
								<span class="text-caption grey--text" v-if="user.profile">{{ user.profile.title }}</span>
							</div>
						</div>
						<frp-icon src="ico_chevron-down" :color="colors.primary.base"></frp-icon>
					</v-btn>
				</template>
				<v-list>
					<v-list-item @click="logout" data-cy="logout">
						<v-icon :color="colors.primary.lighten1">mdi-logout</v-icon>
						<v-list-item-title class="ml-2">{{ $t("buttons.logout") }}</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
		</v-app-bar>

		<v-main>
			<v-container fluid
						 class="pa-0 pa-md-3 full-height flex-nowrap align-start flex-column justify-start"
						 fill-height
						 v-if="isInitialized"
						 :class="$route.name === RouteNames.APPS ? 'frp-apps-background' : 'white darken-2'">
				<template v-if="pageMode === PageModeType.OK">
					<frp-breadcrumbs v-if="profile && $route.name !== RouteNames.APPS"
									 :namespace="storeManager.breadcrumbs.namespace"
									 style="flex: 0">
					</frp-breadcrumbs>
					<router-view :key="$route.meta.key || $route.name"/>
				</template>
				<frp-page-not-found v-else-if="pageMode === PageModeType.PAGE_NOT_FOUND"></frp-page-not-found>
				<frp-access-denied-error v-else-if="pageMode === PageModeType.ACCESS_DENIED"></frp-access-denied-error>
				<frp-alerts substraction-width="320"/>
			</v-container>
		</v-main>
		<frp-digital-signature-dialog @signed="handleSign"
									  :payload="dataToBeSigned"
									  v-model="dialog"
									  :mode="DigitalSignatureDialogModeType.SIGN">
		</frp-digital-signature-dialog>
	</v-app>
</template>

<script>
import FrpDigitalSignatureDialog, {DigitalSignatureDialogModeType} from "@/components/digitalSignature/FrpDigitalSignatureDialog";
import {i18n} from "@/plugins";
import {findAllByAncestor, RouteNames} from "@/router/routes";
import SecurityService from "@/services/auth/securityService";
import rootTypes from "@/store/types";
import {PageModeType} from "@/store/types/pageModeType";
import FrpApps from "@/views/apps/FrpApps.vue";
import FrpAccessDeniedError from "@/views/errors/FrpAccessDeniedError";
import FrpPageNotFound from "@/views/errors/FrpPageNotFound";
import FrpBreadcrumbs from "Components/common/FrpBreadcrumbs";
import authorizationMixin from "Mixins/authorizationMixin";
import colorsMixin from "Mixins/colorsMixin";
import FrpIcon from "Components/icon/FrpIcon";
import storeManager from "Store/manager";
import FrpTextField from "Components/fields/FrpTextField";
import FrpAlerts from "Components/alerts/FrpAlerts";
import {namespace} from "Store/modules/user";
import {actionTypes, getterTypes} from "Store/modules/user/types";
import {ProfileType} from "Types/ProfileType";
import {createNamespacedHelpers, mapState, mapMutations} from "vuex";
import {Permissions} from "Constants/permissions";

const securityService = new SecurityService();

const userHelpers = createNamespacedHelpers(namespace);

export default {
	mixins: [colorsMixin, authorizationMixin],
	metaInfo: {
		title: i18n.t("titles.industrialDevelopmentOffice")
	},
	data() {
		return {
			PageModeType,
			drawer: null,
			storeManager,
			RouteNames,
			search: "",
			items: [],
			menuKey: 0,
			isSelectProfileOperationExecuting: false,
			dialog: false,
			DigitalSignatureDialogModeType,
			selectedProfileId: null
		};
	},
	computed: {
		...mapState({
			pageMode: state => state.pageMode
		}),
		...userHelpers.mapState({
			user: state => state.user,
			isInitialized: state => state.isInitialized
		}),
		...userHelpers.mapGetters({
			isMasterAccount: getterTypes.isMasterAccount,
			isCounterpartyEmployee: getterTypes.isCounterpartyEmployee
		}),
		dataToBeSigned() {
			return {
				profileId: this.selectedProfileId,
				signedAt: Math.round(Date.now() / 1000)
			};
		},
		filteredItems() {
			let res = this.items;
			if (this.search) {
				res = this.items.filter((x) => x.children.some((y) => y.text.toLowerCase().includes(this.search.toLowerCase())));
				res = res.map((x) => ({
					...x,
					children: x.children.filter((y) => y.text.toLowerCase().includes(this.search.toLowerCase()))
				}));
			}
			return res;
		},
		userActiveProfiles() {
			return this.user.profiles.filter(x => x.isActive);
		},
		hiddenNavBar() {
			return !this.can(Permissions.GLOBAL_COUNTERPARTIES_READ) || this.$route.name === RouteNames.APPS;
		}
	},
	methods: {
		...mapMutations({
			resetPageMode: rootTypes.mutationTypes.RESET_PAGE_MODE
		}),
		...userHelpers.mapActions({
			initializeStore: actionTypes.initialize
		}),
		async handleSign(payload) {
			await securityService.selectProfile(this.selectedProfileId, payload);
			await this.$router.go(this.$router.currentRoute);
		},
		async handleSelectProfile(profile) {
			try {
				this.isSelectProfileOperationExecuting = true;

				this.selectedProfileId = profile.id;

				if (profile.isSignatureRequired) {
					this.dialog = true;
				} else {
					await securityService.selectProfile(this.selectedProfileId);
					await this.$router.go(this.$router.currentRoute);
				}
			} catch (e) {
				console.error(e);
			} finally {
				this.isSelectProfileOperationExecuting = false;
			}
		},
		checkIsGroupActive(group) {
			return group.children.some(child => child.routeConfig.name === this.$route.name ||
				(child.childrenRoutes && child.childrenRoutes.includes(this.$route.name)));
		},
		async updateItems() {
			let user = await this.getUser();

			const items = [
				{
					text: this.$t("navigation.admin.index"),
					children: [
						{
							text: this.$t("navigation.admin.counterparties"),
							permission: Permissions.GLOBAL_COUNTERPARTIES_READ,
							routeConfig: {name: RouteNames.COUNTERPARTIES},
							childrenRoutes: findAllByAncestor(RouteNames.COUNTERPARTIES).map(x => x.model.name)
						},
						{
							text: this.$t("navigation.admin.office"),
							permission: Permissions.GLOBAL_OFFICE_READ,
							routeConfig: {name: RouteNames.OFFICE},
							childrenRoutes: findAllByAncestor(RouteNames.OFFICE).map(x => x.model.name)
						},
						{
							text: this.$t("navigation.admin.accounts"),
							permission: Permissions.GLOBAL_ACCOUNTS_READ,
							routeConfig: {name: RouteNames.ACCOUNTS},
							childrenRoutes: findAllByAncestor(RouteNames.ACCOUNTS).map(x => x.model.name)
						}
					]
				},
				{
					text: this.$t("navigation.applications.index"),
					children: [
						{
							permission: Permissions.GLOBAL_MASTER_ACCOUNT_APPLICATIONS_READ,
							text: this.$t("navigation.applications.masterAccountAccess"),
							routeConfig: {name: RouteNames.APPLICATIONS_MASTER_ACCOUNT_ACCESSES},
							childrenRoutes: findAllByAncestor(RouteNames.APPLICATIONS_MASTER_ACCOUNT_ACCESSES).map(x => x.model.name)
						}
					]
				}
			];

			this.items = items;
		},
		logout() {
			this.signOut();
		},
		fixMenu() {
			this.$refs.menu.activate();
		}
	},
	watch: {
		"$route"(to, from) {
			if (!to || !from)
				return;

			if (to.name !== from.name)
				this.resetPageMode();
		}
	},
	async beforeRouteUpdate(to, from, next) {
		this.search = "";
		await next();
		this.menuKey++;
	},
	async created() {
		await this.initializeStore();
		await this.updateItems();
	},
	components: {
		FrpApps,
		FrpAccessDeniedError,
		FrpPageNotFound,
		FrpBreadcrumbs,
		FrpDigitalSignatureDialog,
		FrpIcon,
		FrpTextField,
		FrpAlerts
	}
};
</script>

<style lang="scss">
.frp-navigation {
	background-color: var(--v-primary-darken1) !important;
}

.frp-menu {
	.v-list-group__header {
		background-color: var(--v-blue-darken4);
	}

	.v-list-item__icon .v-icon {
		font-size: 16px;
		color: var(--v-white-base);
	}

	.v-list-group__items {
		background-color: var(--v-blue-darken3);

		.v-ripple__container {
			background-color: var(--v-white-base);
			opacity: 0.12;
		}
	}

	.v-list-item {
		&::before {
			background-color: transparent;
		}

		&:hover {
			&::before {
				background-color: var(--v-white-base);
				opacity: 0.12;
			}
		}

		.v-list-item__content {
			padding: 21px 0;
		}
	}

	.frp-menu-active-group {
		.v-list-group__header {
			background-color: var(--v-secondary-base);

			&:hover {
				&::before {
					opacity: 0;
				}
			}
		}
	}
}

.frp-menu-active-item,
.frp-menu-active-child-item {
	&::after {
		content: "";
		height: 100%;
		width: 4px;
		position: absolute;
		left: 0;
		top: 0;
		background-color: var(--v-secondary-base);
	}
}

.user-menu {
	&::before {
		opacity: 0 !important;
	}
}

.profile-btn {
	border-color: var(--v-white-darken2);

	.v-btn__content {
		max-width: 100% !important;
	}
}

.menu-transition {
	transition: opacity 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.frp-apps-background {
	background-color: var(--v-primary-lighten3);
}
</style>
