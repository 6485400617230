import { mutationTypes, actionTypes, getterTypes, namespace } from "@/store/modules/confirmEmail/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import router from "@/router";
import { ConfirmEmailModeType } from "@/store/modules/confirmEmail/types/confirmEmailModeType";
import ConfirmEmailState from "@/store/modules/confirmEmail/types/confirmEmailState";
import FormMixinBuilder from "@/store/shared/form";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import SecurityService from "@/services/auth/securityService";
import { AuthorizationController } from "@/api/authorization";
import AbortService from "@/services/abortService";

const abortService = new AbortService();
const authorizationController = new AuthorizationController(abortService);

const securityService = new SecurityService();
const formMixin = (new FormMixinBuilder()).build();
const baseMixin = (new BaseMixinBuilder(abortService)).build();

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new ConfirmEmailState(
			formMixin.state(),
			"",
			ConfirmEmailModeType.INITIAL
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<ConfirmEmailState, any>>{
	...formMixin.getters
};


const actions = <ActionTree<ConfirmEmailState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...formMixin.actions,
	async [actionTypes.initialize]({ commit, dispatch }) {
		await dispatch(actionTypes.initializeBase);

		if(router.currentRoute.query.code)
			commit(mutationTypes.SET_CODE, router.currentRoute.query.code);
	},
	async [actionTypes.confirmEmail]({ commit, state }) {
		commit(mutationTypes.SET_IS_FORM_SAVING, true);

		try {
			await authorizationController.confirmEmail(state.code);

			commit(mutationTypes.SET_MODE, ConfirmEmailModeType.SUCCESS);

			await securityService.signinSilent();
		} catch (error) {
			console.error(error);
			commit(mutationTypes.SET_CODE_ERROR, "Код неверный");
		} finally {
			commit(mutationTypes.SET_IS_FORM_SAVING, false);
		}
	},
	async [actionTypes.getNewCode]() {
		try {
			await authorizationController.getNewConfirmEmailCode();
		} catch (error) {
			AlertHelper.handleGeneralRequestErrors(error);
		}
	}
};

const mutations = <MutationTree<ConfirmEmailState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	...formMixin.mutations,
	[mutationTypes.SET_EMAIL](state, value) {
		state.email = value;
	},
	[mutationTypes.SET_MODE](state, value) {
		state.mode = value;
	},
	[mutationTypes.SET_CODE](state, value) {
		state.code = value;
	},
	[mutationTypes.SET_CODE_ERROR](state, value) {
		state.codeError = value;
	}
};

export {
	namespace, state, getters, actions, mutations
};

const confirmEmailModule = {
	namespace, state, getters, actions, mutations, namespaced: true
};

export default confirmEmailModule;
