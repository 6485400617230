<template>
	<div class="d-flex align-center mb-7 mt-2">
		<v-skeleton-loader height="20" width="20" tile type="image" class="mb-0 mr-2"/>
		<v-skeleton-loader height="16" width="150" type="image" class="mb-0 rounded-lg"/>
	</div>
</template>

<script>
export default {
};
</script>
