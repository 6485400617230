import EmbeddedSnilsFormModuleBuilder from "@/store/shared/embeddedSnilsForm";
import { LegalEntityController } from "@/api/counterparty/legalEntity";
import { StorageController } from "@/api/storage";
import { ActionTree, Module } from "vuex";
import EmbeddedSnilsFormState from "@/store/shared/embeddedSnilsForm/types/embeddedSnilsFormState";
import { actionTypes, mutationTypes } from "@/store/shared/embeddedSnilsForm/types";
import LegalEntityCounterpartyState from "@/store/modules/counterparty/modules/legalEntityCounterparty/types/legalEntityCounterpartyState";
import { resolveNestedState } from "@/utils/vuexModules";
import storeManager from "@/store/manager";
import { plainToInstance } from "class-transformer";
import ApiLegalEntityHeadSnilsCreateRequest from "@/api/types/counterparty/snils/apiLegalEntityHeadSnilsCreateRequest";
import FileMeta from "@/store/shared/storage/types/fileMeta";
import ApiFile from "@/api/types/storage/apiFile";
import { STORAGE_SNILS_NAMESPACE } from "@/constants/storage";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import { EmbeddedFormEditModeType } from "@/store/shared/embeddedForm/types/embeddedFormEditModeType";
import mapper from "@/store/shared/storage/mapper";
import { SnilsRecognitionController } from "@/api/snils/recognition";
import NotDefinedException from "@/exceptions/notDefinedException";
import ApiFileMeta from "@/api/types/storage/apiFileMeta";
import { Permissions } from "@/constants/permissions";
import { AuthorizationScopeType } from "@/types/authorization/authorizationScopeType";
import PermissionsService from "@/services/permissionsService";

export default class LegalEntityCounterpartyHeadEmbeddedSnilsFormModuleBuilder {

	constructor() {
	}

	build() {
		const { abortService, module: embeddedSnilsFormModule } = (new EmbeddedSnilsFormModuleBuilder()).build();

		const permissionsService = new PermissionsService();
		const legalEntityController = new LegalEntityController(abortService);
		const storageController = new StorageController(abortService);

		const actions = <ActionTree<EmbeddedSnilsFormState, any>>{
			...embeddedSnilsFormModule.actions,
			async [actionTypes.update]({ dispatch, commit, state, rootState }) {
				let { id: legalEntityId } = resolveNestedState<LegalEntityCounterpartyState>(rootState,
					storeManager.counterparty.legalEntity.namespace);

				const updateScope = await permissionsService.check([Permissions.GLOBAL_COUNTERPARTY_LEGAL_ENTITY_HEAD_SNILS_FILE_UPDATE])
					? AuthorizationScopeType.GLOBAL
					: AuthorizationScopeType.OWN;

				const request = new ApiLegalEntityHeadSnilsCreateRequest(legalEntityId, state.ownerId, state.snilsFileMeta.id);
				await legalEntityController.updateLegalEntityHeadSnils(request, updateScope);

				const scope = await permissionsService.check([Permissions.GLOBAL_COUNTERPARTY_LEGAL_ENTITY_HEAD_SNILS_FILE_READ])
					? AuthorizationScopeType.GLOBAL
					: AuthorizationScopeType.OWN;

				let apiHeadSnils = await legalEntityController.getLegalEntityHeadSnils(legalEntityId, state.ownerId, scope);
				if(!apiHeadSnils) throw new NotDefinedException("snilsPersisted");

				commit(mutationTypes.SET_SNILS_FILE_ID, apiHeadSnils.snils.fileId);

				commit(mutationTypes.UPDATE_LAST_SAVED_META);
			},
			async [actionTypes.create]({ dispatch, commit, state, rootState }) {
				let { id: legalEntityId }: LegalEntityCounterpartyState = resolveNestedState(rootState,
					storeManager.counterparty.legalEntity.namespace);

				let request = plainToInstance(ApiLegalEntityHeadSnilsCreateRequest, {
					legalEntityId,
					legalEntityHeadId: state.ownerId,
					temporaryFileId: state.snilsFileMeta.id
				});

				const createScope = await permissionsService.check([Permissions.GLOBAL_COUNTERPARTY_LEGAL_ENTITY_HEAD_SNILS_FILE_CREATE])
					? AuthorizationScopeType.GLOBAL
					: AuthorizationScopeType.OWN;

				let { id } = await legalEntityController.createLegalEntityHeadSnils(request, createScope);

				const readScope = await permissionsService.check([Permissions.GLOBAL_COUNTERPARTY_LEGAL_ENTITY_HEAD_SNILS_FILE_READ])
					? AuthorizationScopeType.GLOBAL
					: AuthorizationScopeType.OWN;

				let apiHeadSnils = await legalEntityController.getLegalEntityHeadSnils(legalEntityId, state.ownerId, readScope);
				if(!apiHeadSnils) throw new NotDefinedException("snilsPersisted");

				commit(mutationTypes.SET_SNILS_FILE_ID, apiHeadSnils.snils.fileId);

				commit(mutationTypes.SET_ID, id);

				commit(mutationTypes.UPDATE_LAST_SAVED_META);
			},
			async [actionTypes.fetch]({ state, commit, rootState }) {
				if(!state.ownerId) {
					commit(mutationTypes.SET_FORM_EDIT_MODE, EmbeddedFormEditModeType.CREATE);
					return;
				}

				if(!await permissionsService.check([Permissions.GLOBAL_COUNTERPARTY_LEGAL_ENTITY_HEAD_SNILS_FILE_READ,
					Permissions.OWN_COUNTERPARTY_LEGAL_ENTITY_HEAD_SNILS_FILE_READ]))
					return;

				try {
					let { id: legalEntityId }: LegalEntityCounterpartyState = resolveNestedState(rootState,
						storeManager.counterparty.legalEntity.namespace);

					const readScope = await permissionsService.check([Permissions.GLOBAL_COUNTERPARTY_LEGAL_ENTITY_HEAD_SNILS_FILE_READ])
						? AuthorizationScopeType.GLOBAL
						: AuthorizationScopeType.OWN;

					let apiHeadSnils = await legalEntityController.getLegalEntityHeadSnils(legalEntityId, state.ownerId, readScope);

					if(apiHeadSnils) {
						let apiFileMeta = await storageController.getFileMeta(apiHeadSnils.snils.fileId);

						commit(mutationTypes.SET_ID, apiHeadSnils.id);

						commit(mutationTypes.SET_SNILS_FILE_META, mapper.map(apiFileMeta, ApiFileMeta, FileMeta));

						commit(mutationTypes.UPDATE_LAST_SAVED_META);
						commit(mutationTypes.SET_FORM_EDIT_MODE, EmbeddedFormEditModeType.UPDATE);
					} else {
						commit(mutationTypes.SET_FORM_EDIT_MODE, EmbeddedFormEditModeType.CREATE);
					}
				} catch (error) {
					console.error(error);
					AlertHelper.handleGeneralRequestErrors(error);
				}
			}
		};

		const counterpartyLegalEntitySnilsModule: Module<EmbeddedSnilsFormState, any> = {
			...embeddedSnilsFormModule,
			actions
		};

		return {
			module: counterpartyLegalEntitySnilsModule
		};

	}
}
