<template>
	<frp-nested-content-layout v-if="isInitialized">
		<template #title>
			<div class="primary--text text--darken-1 text-h5 mb-2">
				{{ title }}
			</div>
		</template>
		<template #content>
			<template v-if="isCreateMode">
				<frp-subtitle-two class="mb-2">{{ $t("content.chooseType") }}</frp-subtitle-two>
				<frp-radio-card-group v-model="counterpartyType" class="mb-6" :items="counterpartyOptions"></frp-radio-card-group>
				<frp-subtitle-two class="mb-2">{{ $t("content.enterGeneral") }}</frp-subtitle-two>
			</template>
			<template v-else>
				<v-tabs :color="colors.primary.darken1"
						slider-color="secondary"
						class="mt-n2 mb-6 overflow-hidden frp-tabs"
						style="border-radius: 4px"
						:background-color="colors.white.base" :class="{ 'flex-column': $vuetify.breakpoint.smAndDown }">
					<v-tab key="general" :to="{ name: RouteNames.COUNTERPARTY_GENERAL, params: { id: counterparty.id } }"
						   class="text-none">
						{{ $t("subheaders.general") }}
					</v-tab>
					<v-tab key="employees"
						   v-if="isEmployeesTabEnabled && can([Permissions.GLOBAL_COUNTERPARTY_EMPLOYEES_READ, Permissions.OWN_COUNTERPARTY_EMPLOYEES_READ])"
						   :to="{ name: RouteNames.COUNTERPARTY_EMPLOYEES, params: { id: counterparty.id } }"
						   class="text-none">
						{{ $t("subheaders.employees") }}
					</v-tab>
					<v-tab key="master-account"
						   v-if="isEmployeesTabEnabled && can(Permissions.GLOBAL_COUNTERPARTY_MASTER_ACCOUNT_READ)"
						   :disabled="![CounterpartyType.LEGAL_ENTITY, CounterpartyType.LEGAL_PERSON].includes(counterpartyType)"
						   :to="{ name: RouteNames.COUNTERPARTY_MASTER_ACCOUNT, params: { id: counterparty.id } }"
						   class="text-none">
						{{ $t("subheaders.masterAccount") }}
					</v-tab>
				</v-tabs>
			</template>
			<router-view :key="$route.meta.key || $route.name"/>
			<frp-bottom-space></frp-bottom-space>
		</template>
	</frp-nested-content-layout>
	<frp-counterparty-loader v-else/>
</template>

<script>
import { CounterpartyController } from "@/api/counterparty";
import ApiLegalEntityCounterpartyPersisted from "@/api/types/counterparty/apiLegalEntityCounterpartyPersisted";
import ApiLegalPersonCounterpartyPersisted from "@/api/types/counterparty/apiLegalPersonCounterpartyPersisted";
import authorizationMixin from "@/mixins/authorizationMixin";
import { RouteNames } from "@/router/routes";
import AbortService from "@/services/abortService";
import storeManager from "@/store/manager";
import { getterTypes } from "@/store/modules/user/types";
import { AuthorizationScopeType } from "@/types/authorization/authorizationScopeType";
import FrpRadioCardGroup from "Components/fields/FrpRadioCardGroup";
import FrpTextField from "Components/fields/FrpTextField";
import FrpNestedContentLayout from "Components/layouts/FrpNestedContentLayout";
import FrpBottomSpace from "Components/layouts/FrpBottomSpace";
import FrpFormCard from "Components/markup/FrpFormCard";
import FrpSubtitleTwo from "Components/typography/FrpSubtitleTwo";
import FrpFormCardBlock from "Components/markup/FrpFormCardBlock";
import FrpFormCardBlockCol from "Components/markup/FrpFormCardBlockCol";
import FrpFormCardBlockRow from "Components/markup/FrpFormCardBlockRow";
import FrpFormCardBlocks from "Components/markup/FrpFormCardBlocks";
import FrpFormItem from "Components/markup/FrpFormItem";
import FrpPageNotFound from "Views/errors/FrpPageNotFound";
import { Permissions } from "Constants/permissions";
import colorsMixin from "Mixins/colorsMixin";
import storeModuleBasedPage from "Mixins/storeModuleBasedPage";
import { CounterpartyType } from "Types/CounterpartyType";
import { ProfileType } from "Types/ProfileType";
import FrpCounterpartyLoader from "Views/counterparty/FrpCounterpartyLoader";
import { createNamespacedHelpers } from "vuex";
import { mutationTypes, namespace } from "Store/modules/counterparty/types";

const abortService = new AbortService();
const counterpartyController = new CounterpartyController(abortService);

const { mapState, mapMutations, mapActions } = createNamespacedHelpers(namespace);

const userHelpers = createNamespacedHelpers(storeManager.user.namespace);

export default {
	mixins: [storeModuleBasedPage, colorsMixin, authorizationMixin],
	data() {
		return {
			Permissions,
			namespace,
			RouteNames,
			CounterpartyType
		};
	},
	computed: {
		...mapState({
			counterparty: state => state.counterparty,
			title: state => state.title,
			isInitialized: state => state.isInitialized,
			isCounterpartyLoading: state => state.isCounterpartyLoading
		}),
		...userHelpers.mapGetters({
			isMasterAccount: getterTypes.isMasterAccount
		}),
		counterpartyType: {
			get() {
				return this.counterparty.type;
			},
			set(value) {
				this.setCounterpartyType(value);
			}
		},
		isEmployeesTabEnabled() {
			return this.counterpartyType !== CounterpartyType.PHYSICAL_PERSON && this.counterpartyType !==
				CounterpartyType.FOREIGN_ORGANIZATION;
		},
		counterpartyOptions() {
			return [
				{
					label: this.$t("aliases.counterpartyType.LegalEntity"),
					value: CounterpartyType.LEGAL_ENTITY
				},
				{
					label: this.$t("aliases.counterpartyType.Entrepreneur"),
					value: CounterpartyType.LEGAL_PERSON
				},
				{
					label: this.$t("aliases.counterpartyType.ForeignOrganization"),
					value: CounterpartyType.FOREIGN_ORGANIZATION
				},
				{
					label: this.$t("aliases.counterpartyType.Individual"),
					value: CounterpartyType.PHYSICAL_PERSON
				}
			];
		},
		isCreateMode() {
			return !this.$route.params.id;
		},
		isEmployeesPage() {
			return this.$route.name === this.RouteNames.COUNTERPARTY_EMPLOYEES;
		}
	},
	methods: {
		...mapMutations({
			setCounterpartyType: mutationTypes.SET_COUNTERPARTY_TYPE
		})
	},
	async created() {
		let id = this.$route.params.id;
		await this.initializeStore({ id });
	},
	components: {
		FrpSubtitleTwo,
		FrpRadioCardGroup,
		FrpTextField,
		FrpFormItem,
		FrpFormCardBlockCol,
		FrpFormCardBlockRow,
		FrpFormCardBlock,
		FrpFormCardBlocks,
		FrpFormCard,
		FrpNestedContentLayout,
		FrpCounterpartyLoader,
		FrpBottomSpace,
		FrpPageNotFound
	}
};
</script>

<style scoped>

</style>
