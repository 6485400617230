<template>
	<v-card elevation="0" class="mt-9">
		<v-container fluid class="pa-0">
			<v-row class="align-center px-5 pt-2">
				<v-col>
				<span class="text-h5 blue--text text--darken-4">
					<slot name="title"></slot>
				</span>
				</v-col>
				<v-col class="d-flex justify-end">
					<slot name="side-btn"></slot>
				</v-col>
			</v-row>
			<v-row class="pt-0">
				<v-col class="py-0">
					<slot name="content"></slot>
				</v-col>
			</v-row>
		</v-container>
	</v-card>
</template>

<script>

export default {
};
</script>
