import SubscribersManager from "@/store/manager/subscribersManager";
import {
	namespace,
	actionTypes,
	mutationTypes,
	getterTypes
} from "@/store/modules/accounts/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import SortingMixinBuilder from "@/store/shared/sorting";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import ListingMixinBuilder from "@/store/shared/listing";
import ListingModel from "@/store/shared/listing/models/listingModel";
import PagingMixinBuilder from "@/store/shared/paging";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchMixinBuilder from "@/store/shared/search";
import SearchModel from "@/store/shared/search/models/searchModel";
import { sortingOrderType } from "@/store/shared/sorting/models/types/sortingOrderType";
import { resolveAction, resolveMutation, resolveNestedState } from "@/utils/vuexModules";
import router from "@/router";
import { ActionTree, GetterTree, MutationPayload, MutationTree } from "vuex";
import BatchService from "@/services/batchService";
import routeTypes from "@/store/shared/route/types";
import RouteMixinBuilder from "@/store/shared/route";
import { Store } from "vuex";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import { convertIsoToNumber, formatDate } from "@/utils/dates";
import { dateTimeFormat } from "@/utils/formats";
import AccountsState from "@/store/modules/accounts/types/accountsState";
import AccountsRouteQuery from "@/store/modules/accounts/types/accountsRouteQuery";
import AccountsRouteQueryService from "@/store/modules/accounts/services/accountsRouteQueryService";
import ApiAccountPersistedBase from "@/api/types/account/apiAccountPersisted";
import { AccountController } from "@/api/account";
import { mapApiGetAccountsParameters } from "@/store/modules/accounts/mapper";
import AbortService from "@/services/abortService";
import { RouteNames } from "@/router/routes";

const abortService = new AbortService();
const accountController = new AccountController(abortService);

const defaultRouteQuery = new AccountsRouteQuery(1, "createdAt", sortingOrderType.descending, "");

const accountsRouteQueryService = new AccountsRouteQueryService(defaultRouteQuery);

const updateListingBatchService = new BatchService(({ interval: 100 }));

const routeMixin = (new RouteMixinBuilder<AccountsState>()).build();

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new AccountsState(
			new ListingModel<ApiAccountPersistedBase>({
				items: [],
				isLoadingState: false
			}),
			new SortingModel<String>({
				type: defaultRouteQuery.sort,
				order: defaultRouteQuery.sortDirection
			}),
			new PagingModel({
				total: 0,
				page: defaultRouteQuery.page,
				pageSize: 25
			}),
			new SearchModel({
				query: defaultRouteQuery.query
			}),
			routeMixin.state()
		);
	}
}


const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();
const baseMixin = (new BaseMixinBuilder(abortService)).build();
const listingMixin = (new ListingMixinBuilder()).build();
const pagingMixin = (new PagingMixinBuilder()).build();
const sortingMixin = (new SortingMixinBuilder()).build();
const searchMixin = (new SearchMixinBuilder()).build();


const state = (new DefaultStateBuilder()).build();

let subscribersManager: SubscribersManager<AccountsState>;

const getters = <GetterTree<AccountsState, any>>{
	...listingMixin.getters,
	[getterTypes.formattedItems]: state => {
		return state.listing.items.map(x => {
			return {
				...x,
				createdAt: formatDate(convertIsoToNumber(x.createdAt), dateTimeFormat),
				login: x.account.login,
				email: x.account.email,
				isActive: x.account.isActive
			};
		});
	}
};

let unsubscribeCallback = () => {
};
let store: Store<{}>;

const initializeSubscribersManager = (value: Store<{}>) => {
	store = value;
	subscribersManager = new SubscribersManager<AccountsState>(store);
};

const subscribe = async (mutation: MutationPayload, rootState: any) => {
	let state = resolveNestedState<AccountsState>(rootState, namespace);
	switch (mutation.type) {
		case resolveMutation(routeTypes.namespace, routeTypes.mutationTypes.ROUTE_CHANGED):
			if((mutation.payload.from.name === mutation.payload.to.name) && !state.route.isPushing)
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.processRouteQuery));
			break;
		case resolveMutation(namespace, mutationTypes.SET_SORTING_TYPE):
		case resolveMutation(namespace, mutationTypes.SET_SORTING_ORDER):
		case resolveMutation(namespace, mutationTypes.SET_SEARCH_QUERY):
			if(!state.route.isProcessing) {
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.resetPagingPage));
			}
			break;
		case resolveMutation(namespace, mutationTypes.SET_PAGING_PAGE):
		{
			if(!state.route.isProcessing)
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.pushRoute));

			updateListingBatchService.push(async () => {
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.updateListingItems));
			});

			break;
		}
	}
};

const actions = <ActionTree<AccountsState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...listingMixin.actions,
	...pagingMixin.actions,
	...sortingMixin.actions,
	...searchMixin.actions,
	...routeMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit }) {
		await dispatch(actionTypes.initializeBase);

		await dispatch(actionTypes.processRouteQuery);
		await dispatch(actionTypes.reconstituteRoute);
		await dispatch(actionTypes.updateListingItems);

		unsubscribeCallback = subscribersManager.subscribe(subscribe);

		commit(mutationTypes.SET_IS_INITIALIZED, true);
	},
	async [actionTypes.destroy]({ dispatch }) {
		unsubscribeCallback();
		await dispatch(actionTypes.destroyBase);
	},
	async [actionTypes.updateListingItems]({ commit, state, dispatch }) {
		commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, true);

		try {
			let { accounts, totalCount } = await accountController.getAccounts(mapApiGetAccountsParameters(state));

			commit(mutationTypes.SET_LISTING_ITEMS, accounts);
			commit(mutationTypes.SET_PAGING_TOTAL, totalCount);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, false);
		}
	},
	async [actionTypes.processRouteQuery]({ rootState, commit }) {
		commit(mutationTypes.SET_IS_ROUTE_PROCESSING, true);

		let routeQuery = await accountsRouteQueryService.resolveRouteQuery(rootState.route.query);

		commit(mutationTypes.SET_PAGING_PAGE, routeQuery.page);
		commit(mutationTypes.SET_SORTING_ORDER, routeQuery.sortDirection);
		commit(mutationTypes.SET_SORTING_TYPE, routeQuery.sort);
		commit(mutationTypes.SET_SEARCH_QUERY, routeQuery.query);

		commit(mutationTypes.SET_IS_ROUTE_PROCESSING, false);
	},
	async [actionTypes.pushRoute]({ state, commit }) {
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, true);

		await router.push({ name: RouteNames.ACCOUNTS, query: accountsRouteQueryService.resolveRouteQueryDictionary(state) })
					.catch(() => {
					});

		commit(mutationTypes.SET_IS_ROUTE_PUSHING, false);
	},
	async [actionTypes.reconstituteRoute]({ state, commit }) {
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, true);

		await router.replace({ name: RouteNames.ACCOUNTS, query: accountsRouteQueryService.resolveRouteQueryDictionary(state) })
					.catch(() => {
					});

		commit(mutationTypes.SET_IS_ROUTE_PUSHING, false);
	}
};

const mutations = <MutationTree<AccountsState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	...listingMixin.mutations,
	...pagingMixin.mutations,
	...sortingMixin.mutations,
	...searchMixin.mutations,
	...routeMixin.mutations
};


export {
	namespace, state, getters, actions, mutations, initializeSubscribersManager
};

const accountsModule = {
	namespace, state, getters, actions, mutations, initializeSubscribersManager, namespaced: true
};

export default accountsModule;
