import OfficeEmployeesState from "@/store/modules/office/modules/officeEmployees/types/officeEmployeesState";
import ApiGetOfficeEmployeesParameters from "@/api/types/office/apiGetOfficeEmployeesParameters";

export const mapApiGetOfficeEmployeesParameters = (state: OfficeEmployeesState) => {
	return new ApiGetOfficeEmployeesParameters(state.paging.pageSize,
		(state.paging.pageSize * (state.paging.page - 1)),
		state.search.query,
		state.sorting.type,
		state.sorting.order,
		state.filter.roles);
};
