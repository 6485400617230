import { ApiOfficeEmployeeBase } from "@/api/types/office/apiOfficeEmployee";
import { Type } from "class-transformer";

export default class ApiOfficeEmployeePersistedBase {
	id: string;
	createdAt: string;
	updatedAt: string;
	number: number;
	@Type(() => ApiOfficeEmployeeBase)
	employee: ApiOfficeEmployeeBase;

	constructor(id: string, createdAt: string, updatedAt: string, employee: ApiOfficeEmployeeBase, number: number) {
		this.id = id;
		this.createdAt = createdAt;
		this.updatedAt = updatedAt;
		this.employee = employee;
		this.number = number;
	}
}
