import { createMap, createMapper, MappingProfile, forMember, mapFrom, addProfile, fromValue } from "@automapper/core";
import { classes } from "@automapper/classes";
import ApiOffice from "@/api/types/office/apiOffice";
import Office from "@/store/modules/office/modules/general/types/office";
import ApiLegalEntity from "@/api/types/legalEntity/apiLegalEntity";

const mapper = createMapper({
	strategyInitializer: classes()
});

const officeProfile: MappingProfile = (mapper) => {
	createMap(mapper, ApiOffice, Office,
		forMember(d => d.inn, mapFrom(x => x.inn)),
		forMember(d => d.ogrn, mapFrom(x => x.ogrn)),
		forMember(d => d.kpp, mapFrom(x => x.kpp)),
		forMember(d => d.shortName, mapFrom(x => x.shortName)),
		forMember(d => d.longName, mapFrom(x => x.longName)),
		forMember(d => d.description, mapFrom(x => x.description))
	);

	createMap(mapper, ApiLegalEntity, Office,
		forMember(d => d.inn, mapFrom(x => x.inn)),
		forMember(d => d.ogrn, mapFrom(x => x.ogrn)),
		forMember(d => d.kpp, mapFrom(x => x.kpp)),
		forMember(d => d.shortName, mapFrom(x => x.shortName)),
		forMember(d => d.longName, mapFrom(x => x.longName)),
		forMember(d => d.description, fromValue(""))
	);

	createMap(mapper, Office, ApiOffice,
		forMember(d => d.inn, mapFrom(x => x.inn)),
		forMember(d => d.ogrn, mapFrom(x => x.ogrn)),
		forMember(d => d.kpp, mapFrom(x => x.kpp)),
		forMember(d => d.shortName, mapFrom(x => x.shortName)),
		forMember(d => d.longName, mapFrom(x => x.longName)),
		forMember(d => d.description, mapFrom(x => x.description))
	);
};

addProfile(mapper, officeProfile);

export default mapper;
