<template>
	<frp-list-layout v-if="can(Permissions.GLOBAL_COUNTERPARTIES_READ)">
		<template #title>
			{{ $t("titles.counterparties") }}
		</template>

		<template #search>
			<frp-combobox :items="searchSuggestions"
						  :ref="searchTextFieldRef"
						  @select:value="handleItemSelect"
						  v-model="searchQuery"
						  hide-details
						  :rules="validation.searchQuery"
						  icon="ico_search"
						  :placeholder="$t('placeholders.search')">

			</frp-combobox>
		</template>

		<template #filter>
			<frp-filter v-model="isFilterOpened">
				<template #activator="{ on, attrs }">
					<frp-filter-btn :selected-filtering-parameters-count="selectedFilteringParametersCount"
									:activator="{ on, attrs }">
					</frp-filter-btn>
				</template>

				<template #content>
					<frp-checkbox-group :values="filter.type.value"
										class="px-0"
										@update:values="setInternalFilterType"
										:items="filter.type.items">
					</frp-checkbox-group>
				</template>

				<template #actions>
					<frp-btn outlined color="primary" @click="resetFilter" :disabled="isFilterEmpty && !isFilterChanged">
						{{ $t("buttons.clear") }}
					</frp-btn>
					<frp-btn @click="applyFilter" :disabled="!isFilterChanged">
						{{ $t("buttons.accept") }}
					</frp-btn>
				</template>
			</frp-filter>
		</template>

		<template #side-btn>
			<frp-btn @click="handleCreateItem"
					 v-if="can(Permissions.GLOBAL_COUNTERPARTY_CREATE)"
					 color="secondary"
					 class="frp-xs-full-width">
				{{ $t("buttons.addCounterparty") }}
			</frp-btn>
		</template>

		<template #content>
			<v-data-table :headers="headers"
						  :items="items"
						  :loading-text="$t('tables.loading')"
						  hide-default-footer
						  :loading="isItemsLoading"
						  item-key="id"
						  :mobile-breakpoint="mobileBreakpoint"
						  :items-per-page="-1"
						  :custom-sort="sortItems"
						  must-sort
						  :options="options"
						  @click:row="handleItemSelect"
						  :item-class="() => 'text-body-2'"
						  class="frp-table pt-4 clickable-rows d-flex flex-column">
			</v-data-table>
		</template>

		<template #pagination>
			<frp-pagination v-if="!isItemsLoading"
							v-model="page"
							:length="Math.ceil(paging.total / paging.pageSize)">
			</frp-pagination>
		</template>
	</frp-list-layout>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn";
import FrpFilterBtn from "@/components/buttons/FrpFilterBtn";
import FrpCombobox from "@/components/fields/FrpCombobox";
import { RouteNames } from "@/router/routes";
import { Permissions } from "Constants/permissions";
import authorizationMixin from "Mixins/authorizationMixin";
import storeModuleBasedPage from "Mixins/storeModuleBasedPage";
import { namespace } from "Store/modules/counterparties";
import { actionTypes, getterTypes, mutationTypes } from "Store/modules/counterparties/types";
import { createNamespacedHelpers } from "vuex";
import { listMixin } from "Mixins/listMixin";
import colorsMixin from "Mixins/colorsMixin";
import FrpListLayout from "Components/layouts/FrpListLayout";
import FrpTextField from "Components/fields/FrpTextField";
import FrpPagination from "Components/common/FrpPagination";
import FrpFilter from "Components/common/FrpFilter";
import FrpCheckboxGroup from "Components/fields/FrpCheckboxGroup";
import FrpIcon from "Components/icon/FrpIcon";

const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);

export default {
	mixins: [listMixin, colorsMixin, storeModuleBasedPage, authorizationMixin],
	data() {
		return {
			namespace,
			headers: [
				{
					text: this.$t("tables.title"),
					value: "title",
					class: "text-caption",
					width: "30%"
				},
				{
					text: this.$t("tables.inn"),
					value: "inn",
					class: "text-caption",
					width: "10%"
				},
				{
					text: this.$t("tables.ogrn"),
					value: "ogrn",
					class: "text-caption",
					width: "10%"
				},
				{
					text: this.$t("tables.type"),
					value: "counterpartyType",
					class: "text-caption",
					width: "25%"
				},
				{
					text: this.$t("tables.createdAt"),
					value: "createdAt",
					class: "text-caption",
					width: "15%"
				}
			],
			internalFilterValues: {
				type: []
			}
		};
	},
	computed: {
		...mapGetters({
			items: getterTypes.formattedItems,
			isNoData: getterTypes.isListingEmpty
		}),
		...mapState({
			searchSuggestions: state => state.searchSuggestions
		}),
		filter() {
			return {
				type: {
					value: this.internalFilterValues.type,
					items: [
						{ text: this.$t("aliases.counterpartyType.LegalEntity"), value: "legalEntity" },
						{ text: this.$t("aliases.counterpartyType.Entrepreneur"), value: "entrepreneur" },
						{ text: this.$t("aliases.counterpartyType.Individual"), value: "individual" },
						{ text: this.$t("aliases.counterpartyType.ForeignOrganization"), value: "foreignOrganization" }
					]
				}
			};
		}
	},
	methods: {
		...mapMutations({
			setFilterType: mutationTypes.SET_FILTER_TYPE
		}),
		handleItemSelect({ id }) {
			if(id)
				this.$router.push({ name: RouteNames.COUNTERPARTY, params: { id } });
		},
		handleCreateItem() {
			this.$router.push({ name: RouteNames.COUNTERPARTY_CREATE });
		},
		setInternalFilterType(value) {
			this.internalFilterValues.type = value;
		},
		resetFilter() {
			this.setFilterType([]);
			this.isFilterOpened = false;
		},
		setInternalFilterValues() {
			this.internalFilterValues.type = this.filterValues.type && [...this.filterValues.type];
		},
		applyFilter() {
			this.setFilterType(this.internalFilterValues.type);
			this.isFilterOpened = false;
		}
	},
	created() {
		this.initializeStore();
	},
	components: {
		FrpCombobox,
		FrpFilterBtn,
		FrpBtn,
		FrpListLayout,
		FrpTextField,
		FrpPagination,
		FrpFilter,
		FrpCheckboxGroup,
		FrpIcon
	}
};
</script>
