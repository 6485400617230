import FileMeta from "@/store/shared/storage/types/fileMeta";

export default class LegalEntityCounterpartyHead {
	id: string;
	inn: string;
	snils: string;
	isSnilsRecognizing: boolean;
	fullName: string;
	position: string;
	description: string;

	constructor(
		id: string = "",
		inn: string = "",
		snils: string = "",
		snilsFileMeta: FileMeta = new FileMeta(),
		fullName: string = "",
		position: string = "",
		description: string = "")
	{
		this.id = id;
		this.inn = inn;
		this.snils = snils;
		this.fullName = fullName;
		this.position = position;
		this.description = description;
	}

}

