import accountModule from "@/store/modules/account";
import accountsModule from "@/store/modules/accounts";
import confirmEmailModule from "@/store/modules/confirmEmail";
import counterpartiesModule from "@/store/modules/counterparties";
import counterpartyModule from "@/store/modules/counterparty";
import counterpartyEmployeeModule from "@/store/modules/counterpartyEmployee";
import masterAccountAccessModule from "@/store/modules/masterAccountAccess";
import masterAccountAccessTypes from "@/store/modules/masterAccountAccess/types";
import alertsModule from "@/store/modules/alerts";
import { registerAlertService } from "@/store/modules/alerts/services/alertService";
import officeEmployeeModule from "@/store/modules/officeEmployee";
import profileAssignmentModule from "@/store/modules/profileAssignment";
import userModule from "@/store/modules/user";
import masterAccountAccessApplicationsModule from "@/store/modules/applications/masterAccountAccess/list";
import masterAccountAccessApplicationDetailsModule from "@/store/modules/applications/masterAccountAccess/details";
import officeModule from "@/store/modules/office";
import Vue from "vue";
import Vuex, { ActionTree, MutationTree } from "vuex";
import breadcrumbsModule from "@/store/modules/breadcrumbs";
import employeeJoinApplicationsModule from "@/store/modules/applications/employeeJoin/list";
import employeeJoinApplicationDetailsModule from "@/store/modules/applications/employeeJoin/details";
import employeeJoinModule from "@/store/modules/employeeJoin";
import confirmPhoneModule from "@/store/modules/confirmPhone";
import appsModule from "@/store/modules/apps"
import RootState from "@/store/types/rootState";
import { mutationTypes } from "@/store/types";
import { PageModeType } from "@/store/types/pageModeType";

Vue.use(Vuex);

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new RootState(PageModeType.OK);
	}
}

const state = (new DefaultStateBuilder()).build();

const actions = <ActionTree<RootState, any>>{};

const mutations = <MutationTree<RootState>>{
	[mutationTypes.SET_IS_APP_LOADING](state, value) {
		state.isAppLoading = value;
	},
	[mutationTypes.SET_PAGE_MODE](state, value) {
		state.pageMode = value;
	},
	[mutationTypes.RESET_PAGE_MODE](state) {
		state.pageMode = PageModeType.OK;
	},
	[mutationTypes.SET_PAGE_MODE_NOT_FOUND](state) {
		state.pageMode = PageModeType.PAGE_NOT_FOUND;
	},
	[mutationTypes.SET_PAGE_MODE_ACCESS_FORBIDDEN](state) {
		state.pageMode = PageModeType.ACCESS_DENIED;
	}
};

const store = new Vuex.Store({
	state,
	mutations,
	actions,
// @ts-ignore
	modules: {
		[appsModule.namespace]: {
			...appsModule
		},
		[breadcrumbsModule.namespace]: {
			...breadcrumbsModule
		},
		[masterAccountAccessTypes.namespace]: {
			...masterAccountAccessModule
		},
		[confirmEmailModule.namespace]: {
			...confirmEmailModule
		},
		[confirmPhoneModule.namespace]: {
			...confirmPhoneModule
		},
		[alertsModule.namespace]: {
			...alertsModule
		},
		[userModule.namespace]: {
			...userModule
		},
		[masterAccountAccessApplicationsModule.namespace]: {
			...masterAccountAccessApplicationsModule
		},
		[masterAccountAccessApplicationDetailsModule.namespace]: {
			...masterAccountAccessApplicationDetailsModule
		},
		[officeEmployeeModule.namespace]: {
			...officeEmployeeModule
		},
		[counterpartiesModule.namespace]: {
			...counterpartiesModule
		},
		[counterpartyModule.namespace]: {
			...counterpartyModule
		},
		[counterpartyEmployeeModule.namespace]: {
			...counterpartyEmployeeModule
		},
		[officeModule.namespace]: {
			...officeModule
		},
		[accountsModule.namespace]: {
			...accountsModule
		},
		[accountModule.namespace]: {
			...accountModule
		},
		[profileAssignmentModule.namespace]: {
			...profileAssignmentModule
		},
		[employeeJoinModule.namespace]: {
			...employeeJoinModule
		},
		[employeeJoinApplicationsModule.namespace]: {
			...employeeJoinApplicationsModule
		},
		[employeeJoinApplicationDetailsModule.namespace]: {
			...employeeJoinApplicationDetailsModule
		}
	}
});

const registerSubscribers = (store: any) => {
	officeModule.subscribe(store);
	counterpartyModule.subscribe(store);
	accountModule.subscribe(store);
	counterpartyEmployeeModule.subscribe(store);
	employeeJoinModule.subscribe(store);
	breadcrumbsModule.subscribe(store);

	counterpartiesModule.initializeSubscribersManager(store);
	masterAccountAccessApplicationsModule.initializeSubscribersManager(store);
	employeeJoinApplicationsModule.initializeSubscribersManager(store);
	accountsModule.initializeSubscribersManager(store);
};
registerAlertService(store);
registerSubscribers(store);

export default store;
