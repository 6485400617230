<template>
	<v-form v-if="isInitialized" :readonly="isFormSaving || isFormReadonly" :disabled="isFormDisabled" :ref="refs.form" v-model="formValid">
		<frp-form-card>
			<frp-form-card-blocks v-if="can(Permissions.GLOBAL_COUNTERPARTY_FNS_READ)">
				<frp-form-card-block :title="$t('titles.fns')">
					<frp-form-card-block-row style="min-height: 68px">
						<frp-form-card-block-col cols="12" class="d-flex flex-wrap pt-4 mt-2">
							<frp-switch :label="$t('fields.switch.enableFnsUpdate.label')"
										class="mt-0 pt-0 mr-5"
										:disabled="!can(Permissions.GLOBAL_COUNTERPARTY_FNS_UPDATE)"
										v-model="isFnsUpdateEnabled">
							</frp-switch>
							<v-fade-transition v-if="isCreateMode">
								<frp-alert class="mb-md-0 ml-md-8 mt-2 mt-md-0"
										   v-if="isFnsUpdateEnabled && !isInnValid"
										   :text="$t('alerts.errors.requiredInnForLegalEntity')">
								</frp-alert>
							</v-fade-transition>
							<div v-else-if="fnsUpdate.isEnabled"
								 class="d-flex ml-md-15 mt-2 mb-1 mt-md-0">
								<div class="d-flex flex-column justify-center">
									<frp-text-caption class="mr-3">{{ $t("content.lastUpdateTime") }}</frp-text-caption>
									<frp-text-caption class="mr-3 mt-2">{{ $t("content.lastCheckedTime") }}</frp-text-caption>
								</div>
								<div class="d-flex flex-column ml-4">
									<frp-text-body-two class="">{{ fnsLastUpdated || $t("content.generalNotUpdated") }}</frp-text-body-two>
									<frp-text-body-two class=" mt-2">{{ fnsLastChecked || $t("content.generalNotUpdated") }}</frp-text-body-two>
								</div>
							</div>

						</frp-form-card-block-col>
						<frp-form-card-block-col v-if="!isCreateMode && can(Permissions.GLOBAL_COUNTERPARTY_FNS_FORCE_UPDATE)" cols="12"
												 class="d-flex align-center flex-wrap pt-4">
							<frp-btn
								class="mt-0 pt-0 mb-2"
								:loading="isUpdateViaFnsOperationExecuting"
								@click="updateViaFns">
								{{ $t("buttons.updateViaFns") }}
							</frp-btn>
						</frp-form-card-block-col>
					</frp-form-card-block-row>
				</frp-form-card-block>
			</frp-form-card-blocks>
			<frp-form-card-blocks>
				<frp-form-card-block :title="$t('titles.general')">
					<frp-form-card-block-row>
						<frp-form-card-block-col cols="12" sm="6" md="4" :class="{ 'pb-0': $vuetify.breakpoint.xsOnly }">
							<frp-form-item>
								<frp-text-field :label="$t('fields.inn.label')"
												name="inn"
												autocomplete="inn"
												:errors="innErrors"
												required
												:rules="validation.inn"
												:mask="LEGAL_ENTITY_INN_FORMAT"
												:disabled="!hasEditPermissions || (!isCreateMode && isFnsUpdateEnabled)"
												:loading="isEgrulLegalEntityLoading"
												v-model="inn"
												:placeholder="$t('fields.inn.placeholder')">
								</frp-text-field>
							</frp-form-item>

							<frp-form-item>
								<frp-text-field :label="$t('fields.ogrn.label')"
												required
												name="ogrn"
												autocomplete="ogrn"
												:rules="validation.ogrn"
												:mask="OGRN_FORMAT"
												:disabled="!hasEditPermissions || isFnsUpdateEnabled"
												v-model="ogrn"
												:placeholder="$t('fields.ogrn.placeholder')"></frp-text-field>
							</frp-form-item>

							<frp-form-item>
								<frp-text-field :label="$t('fields.kpp.label')"
												name="kpp"
												autocomplete="kpp"
												required
												:mask="KPP_FORMAT"
												:rules="validation.kpp"
												:disabled="!hasEditPermissions ||  isFnsUpdateEnabled"
												v-model="kpp"
												:placeholder="$t('fields.kpp.placeholder')"></frp-text-field>
							</frp-form-item>

							<frp-form-item>
								<frp-text-field :label="$t('fields.opf.label')"
												name="opf"
												autocomplete="opf"
												required
												:disabled="!hasEditPermissions ||  isFnsUpdateEnabled"
												:rules="validation.opf"
												v-model="opf"
												:placeholder="$t('fields.opf.placeholder')"></frp-text-field>
							</frp-form-item>
						</frp-form-card-block-col>

						<frp-form-card-block-col offset-md="2" cols="12" sm="6" md="4" :class="{ 'pt-0': $vuetify.breakpoint.xsOnly }">
							<frp-form-item>
								<frp-text-field :label="$t('fields.fullName.label')"
												required
												autocomplete="off"
												:disabled="!hasEditPermissions ||  isFnsUpdateEnabled"
												:rules="validation.longName"
												v-model="longName"
												:placeholder="$t('fields.fullName.placeholder')">
								</frp-text-field>
							</frp-form-item>

							<frp-form-item>
								<frp-text-field :label="$t('fields.shortName.label')"
												required
												name="organization"
												autocomplete="organization"
												:disabled="!hasEditPermissions ||  isFnsUpdateEnabled"
												:rules="validation.shortName"
												v-model="shortName"
												:placeholder="$t('fields.shortName.placeholder')"></frp-text-field>
							</frp-form-item>

							<frp-form-item>
								<frp-date-field
									:label="$t('fields.registerDate.label')"
									:disabled="!hasEditPermissions || isFnsUpdateEnabled"
									:rules="validation.registrationDate"
									v-model="registrationDate"
									:max="Date.now()"
									:placeholder="$t('fields.registerDate.placeholder')"/>
							</frp-form-item>

							<frp-form-item>
								<frp-text-field :label="$t('fields.legalAddress.label')"
												name="address"
												autocomplete="address"
												:disabled="!hasEditPermissions || isFnsUpdateEnabled"
												:rules="validation.legalAddress"
												v-model="legalAddress"
												:placeholder="$t('fields.legalAddress.placeholder')"></frp-text-field>
							</frp-form-item>
						</frp-form-card-block-col>
					</frp-form-card-block-row>
				</frp-form-card-block>
			</frp-form-card-blocks>


			<frp-form-card-blocks v-if="!isCounterpartyEmployee">
				<frp-form-card-block :title="$t('titles.characteristics')">
					<frp-form-card-block-row>
						<frp-form-card-block-col cols="12" sm="6" md="4" :class="{ 'pb-0': $vuetify.breakpoint.xsOnly }">
							<frp-form-item>
								<frp-switch :label="$t('fields.switch.frp.label')"
											class="mb-6"
											v-model="isRfrp"
											:disabled="!hasEditPermissions">
								</frp-switch>
							</frp-form-item>
							<frp-form-item>
								<frp-switch :label="$t('fields.switch.leasingCompany.label')"
											class="mb-6"
											v-model="isLeasingCompany"
											:disabled="!hasEditPermissions">
								</frp-switch>
							</frp-form-item>
							<frp-form-item>
								<frp-switch :label="$t('fields.switch.collateralExpertOrganisation.label')"
											class="mb-6"
											v-model="isPledgeExpertCompany"
											:disabled="!hasEditPermissions">
								</frp-switch>
							</frp-form-item>
							<frp-form-item>
								<frp-switch :label="$t('fields.switch.vkmExpertCompany.label')"
											class="mb-6"
											v-model="isVkmExpertCompany"
											:disabled="!hasEditPermissions">
								</frp-switch>
							</frp-form-item>
							<frp-form-item>
								<frp-switch :label="$t('fields.switch.frpAssigneeCompany.label')"
											class="mb-6"
											v-model="isFrpAssigneeCompany"
											:disabled="!hasEditPermissions">
								</frp-switch>
							</frp-form-item>
						</frp-form-card-block-col>
					</frp-form-card-block-row>
				</frp-form-card-block>
			</frp-form-card-blocks>

			<template v-if="can([Permissions.GLOBAL_COUNTERPARTY_HEADS_READ, Permissions.OWN_COUNTERPARTY_HEADS_READ])">
				<frp-legal-entity-general-head v-for="head of heads" :key="head"
											   :is-create-mode="isCreateMode"
											   :id="head">
				</frp-legal-entity-general-head>
			</template>

			<v-row class="mt-4" v-if="hasEditPermissions">
				<v-col class="pa-0">
					<frp-btn :disabled="isFnsUpdateEnabled"
							 @click="handleAddHead"
							 class="mt-4 mb-5">
						{{ $t("buttons.addHead") }}
					</frp-btn>
				</v-col>
			</v-row>
			<frp-form-actions v-if="hasEditPermissions"
							  :is-save-disabled="!stateContainsUnsavedChanges || !isFormValid"
							  :is-cancel-disabled="!stateContainsUnsavedChanges"
							  :is-loading-state="isFormSaving || isFormLoadingState"
							  @cancel="onFormCancel"
							  @submit="submit">
			</frp-form-actions>
			<frp-confirm-dialog v-if="isUnsavedChangesDialogEnabled"
								@submit="handleUpdateViaFnsWithUnsavedChanges"
								@cancel="isUnsavedChangesDialogEnabled = false"
								:title="$t('dialogs.confirmOperationWithUnsavedChanges.title')"
								:description="$t('dialogs.confirmOperationWithUnsavedChanges.description')">
			</frp-confirm-dialog>
		</frp-form-card>

		<frp-dialog v-model="isCounterpartySuccessCreatedDialogOpened" persistent>
			<template #content>
					<v-row>
						<v-col align="center">
							<v-img width="122" height="122" :src="successCreatedImgSrc" alt="success created"></v-img>
						</v-col>
					</v-row>
					<v-row>
						<v-col align="center">
							<span class="text-h6 primary--text text--darken-1 mb-5">{{ $t("alerts.info.organisationWasCreated") }}</span>
						</v-col>
					</v-row>
					<v-row>
						<v-col class="d-flex justify-center">
							<frp-message-box icon="ico_info">{{ $t("alerts.info.youCanCloseCurrentBrowserPage") }}</frp-message-box>
						</v-col>
					</v-row>
			</template>
		</frp-dialog>
	</v-form>
	<frp-legal-entity-general-loader v-else></frp-legal-entity-general-loader>
</template>

<script>
import { getterTypes as userGetterTypes } from "@/store/modules/user/types";
import FrpMessageBox from "@/components/common/FrpMessageBox.vue";
import FrpDetailsSectionTitle from "@/components/details/FrpDetailsSectionTitle.vue";
import FrpDialog from "@/components/dialogs/FrpDialog.vue";
import { StorageController } from "Api/storage";
import FrpAlert from "Components/alerts/FrpAlert";
import FrpEmbeddedSnilsForm from "Components/embeddedForms/FrpEmbeddedSnilsForm";
import FrpFileField from "Components/fields/FrpFileField";
import FrpTextarea from "Components/fields/FrpTextarea";
import FrpBtn from "Components/buttons/FrpBtn";
import FrpDetailsItem from "Components/details/FrpDetailsItem";
import FrpCombobox from "Components/fields/FrpCombobox";
import FrpDateField from "Components/fields/FrpDateField";
import FrpFieldHelp from "Components/fields/FrpFieldHelp";
import FrpRadioCardGroup from "Components/fields/FrpRadioCardGroup";
import FrpTextField from "Components/fields/FrpTextField";
import FrpFormActions from "Components/form/FrpFormActions";
import FrpIcon from "Components/icon/FrpIcon";
import FrpNestedContentLayout from "Components/layouts/FrpNestedContentLayout";
import FrpBottomSpace from "Components/layouts/FrpBottomSpace";
import FrpFormCard from "Components/markup/FrpFormCard";
import FrpTextBodyTwo from "Components/typography/FrpTextBodyTwo";
import FrpTextCaption from "Components/typography/FrpTextCaption";
import FrpFormCardBlock from "Components/markup/FrpFormCardBlock";
import FrpFormCardBlockCol from "Components/markup/FrpFormCardBlockCol";
import FrpFormCardBlockRow from "Components/markup/FrpFormCardBlockRow";
import FrpFormCardBlocks from "Components/markup/FrpFormCardBlocks";
import FrpFormItem from "Components/markup/FrpFormItem";
import FrpSwitch from "Components/fields/FrpSwitch";
import FrpConfirmDialog from "Components/dialogs/FrpConfirmDialog";
import { Permissions } from "Constants/permissions";
import authorizationMixin from "Mixins/authorizationMixin";
import colorsMixin from "Mixins/colorsMixin";
import formMixin from "Mixins/formMixin";
import storeModuleBasedPage from "Mixins/storeModuleBasedPage";
import { masks } from "Constants/masks";
import { RouteNames } from "Router/routes";
import { checkSnilsOnlyChecksum } from "ru-validation-codes";
import AbortService from "Services/abortService";
import storeManager from "Store/manager";
import { actionTypes, mutationTypes } from "Store/modules/counterparty/modules/legalEntityCounterparty/types";
import { formatDate } from "Utils/dates";
import { dateTimeFormat, KPP_FORMAT, LEGAL_ENTITY_INN_FORMAT, OGRN_FORMAT, PERSON_INN_FORMAT, SNILS_FORMAT } from "Utils/formats";
import {
	preparePersonMaskedInnValidationRule,
	prepareSnilsRule,
	prepareMaxLengthRule, prepareLegalEntityMaskedInnValidationRule, prepareMaskedOgrnValidationRule, prepareMaskedKppValidationRule
} from "Utils/validation";
import { validateLegalEntityInn } from "Utils/validator";
import FrpLegalEntityGeneralHead from "Views/counterparty/sections/FrpLegalEntityGeneralHead";
import FrpLegalEntityGeneralLoader from "Views/counterparty/sections/FrpLegalEntityGeneralLoader";
import { createNamespacedHelpers } from "vuex";
import { maxLength } from "Constants/validation";


const abortService = new AbortService();
const storageController = new StorageController(abortService);

const namespace = storeManager.counterparty.legalEntity.namespace;
const { mapState, mapMutations, mapActions } = createNamespacedHelpers(namespace);

const userHelpers = createNamespacedHelpers(storeManager.user.namespace);

export default {
	mixins: [storeModuleBasedPage, colorsMixin, formMixin, authorizationMixin],
	name: "frp-legal-entity-general",
	data() {
		return {
			SNILS_FORMAT,
			namespace,
			PERSON_INN_FORMAT,
			LEGAL_ENTITY_INN_FORMAT,
			OGRN_FORMAT,
			KPP_FORMAT,
			RouteNames,
			masks,
			isUnsavedChangesDialogEnabled: false,
			validation: {
				inn: [prepareLegalEntityMaskedInnValidationRule()],
				ogrn: [prepareMaskedOgrnValidationRule()],
				kpp: [prepareMaskedKppValidationRule()],
				registrationDate: [],
				legalAddress: [prepareMaxLengthRule({ maxLength: maxLength.long })],
				shortName: [prepareMaxLengthRule({ maxLength: maxLength.long })],
				longName: [prepareMaxLengthRule({ maxLength: maxLength.long })],
				opf: [prepareMaxLengthRule({ maxLength: maxLength.long })]
			},
			successCreatedImgSrc: `${process.env.VUE_APP_BASE_URL}/img/images/ico_success.png`
		};
	},
	computed: {
		...mapState({
			counterparty: state => state.counterparty,
			isUpdateViaFnsOperationExecuting: state => state.isUpdateViaFnsOperationExecuting,
			fnsUpdate: state => state.fnsUpdate,
			heads: state => state.heads,
			isEgrulLegalEntityLoading: state => state.isEgrulLegalEntityLoading,
			isInitialized: state => state.isInitialized,
			isCounterpartySuccessCreatedDialogOpened: state => state.isCounterpartySuccessCreatedDialogOpened
		}),
		...userHelpers.mapGetters({
			isCounterpartyEmployee: userGetterTypes.isCounterpartyEmployee
		}),
		hasEditPermissions() {
			return this.isCreateMode ? this.can(Permissions.GLOBAL_COUNTERPARTY_CREATE) : this.can(Permissions.GLOBAL_COUNTERPARTY_UPDATE);
		},
		innErrors() {
			return this.isRecordUnique ? [] : [`${this.$t("alerts.errors.legalEntityAlreadyExists")}`];
		},
		isFnsUpdateEnabled: {
			get() {
				return this.fnsUpdate.isEnabled;
			},
			set(value) {
				this.setIsFnsUpdateEnabled(value);
			}
		},
		fnsLastUpdated() {
			return this.fnsUpdate && formatDate(this.fnsUpdate.lastUpdated, dateTimeFormat);
		},
		fnsLastChecked() {
			return this.fnsUpdate && formatDate(this.fnsUpdate.lastChecked, dateTimeFormat);
		},
		inn: {
			get() {
				return this.counterparty.inn;
			},
			set(value) {
				if(value !== this.inn)
					this.setInn(value);
			}
		},
		isInnValid() {
			return validateLegalEntityInn(this.inn);
		},
		ogrn: {
			get() {
				return this.counterparty.ogrn;
			},
			set(value) {
				this.setOgrn(value);
			}
		},
		kpp: {
			get() {
				return this.counterparty.kpp;
			},
			set(value) {
				this.setKpp(value);
			}
		},
		opf: {
			get() {
				return this.counterparty.opf;
			},
			set(value) {
				this.setOpf(value);
			}
		},
		longName: {
			get() {
				return this.counterparty.longName;
			},
			set(value) {
				this.setLongName(value);
			}
		},
		shortName: {
			get() {
				return this.counterparty.shortName;
			},
			set(value) {
				this.setShortName(value);
			}
		},
		legalAddress: {
			get() {
				return this.counterparty.legalAddress;
			},
			set(value) {
				this.setLegalAddress(value);
			}
		},
		registrationDate: {
			get() {
				return this.counterparty.registrationDate;
			},
			set(value) {
				this.setRegistrationDate(value && value.getTime());
			}
		},
		isRfrp: {
			get() {
				return this.counterparty.isRfrp;
			},
			set(value) {
				this.setIsRfrp(value);
			}
		},
		isLeasingCompany: {
			get() {
				return this.counterparty.isLeasingCompany;
			},
			set(value) {
				this.setIsLeasingCompany(value);
			}
		},
		isPledgeExpertCompany: {
			get() {
				return this.counterparty.isPledgeExpertCompany;
			},
			set(value) {
				this.setIsPledgeExpertCompany(value);
			}
		},
		isVkmExpertCompany: {
			get() {
				return this.counterparty.isVkmExpertCompany;
			},
			set(value) {
				this.setIsVkmExpertCompany(value);
			}
		},
		isFrpAssigneeCompany: {
			get() {
				return this.counterparty.isFrpAssigneeCompany;
			},
			set(value) {
				this.setIsFrpAssigneeCompany(value);
			}
		}
	},
	methods: {
		...mapActions({
			save: actionTypes.save,
			addHead: actionTypes.addHead,
			removeHead: actionTypes.removeHead,
			downloadSnils: actionTypes.downloadSnils,
			updateViaFnsInternal: actionTypes.updateViaFns
		}),
		...mapMutations({
			setIsFnsUpdateEnabled: mutationTypes.SET_IS_FNS_UPDATE_ENABLED,
			setInn: mutationTypes.SET_COUNTERPARTY_INN,
			setOgrn: mutationTypes.SET_COUNTERPARTY_OGRN,
			setKpp: mutationTypes.SET_COUNTERPARTY_KPP,
			setOpf: mutationTypes.SET_COUNTERPARTY_OPF,
			setLongName: mutationTypes.SET_COUNTERPARTY_LONG_NAME,
			setShortName: mutationTypes.SET_COUNTERPARTY_SHORT_NAME,
			setLegalAddress: mutationTypes.SET_COUNTERPARTY_LEGAL_ADDRESS,
			setRegistrationDate: mutationTypes.SET_COUNTERPARTY_REGISTRATION_DATE,
			setIsRfrp: mutationTypes.SET_COUNTERPARTY_IS_RFRP,
			setIsLeasingCompany: mutationTypes.SET_COUNTERPARTY_IS_LEASING_COMPANY,
			setIsPledgeExpertCompany: mutationTypes.SET_COUNTERPARTY_IS_PLEDGE_EXPERT_COMPANY,
			setIsVkmExpertCompany: mutationTypes.SET_COUNTERPARTY_IS_VKM_EXPERT_COMPANY,
			setIsFrpAssigneeCompany: mutationTypes.SET_COUNTERPARTY_IS_FRP_ASSIGNEE_COMPANY,
			removeHead: mutationTypes.REMOVE_HEAD
		}),
		handleAddHead() {
			this.addHead();
		},
		validateSnils(v) {
			return checkSnilsOnlyChecksum(v);
		},
		updateViaFns() {
			if(this.stateContainsUnsavedChanges) {
				this.isUnsavedChangesDialogEnabled = true;
			} else {
				this.updateViaFnsInternal();
			}
		},
		async handleUpdateViaFnsWithUnsavedChanges() {
			this.isUnsavedChangesDialogEnabled = false;
			await this.updateViaFnsInternal();
		}
	},
	created() {
		this.initializeStore({ id: this.$route.params.id });
	},
	components: {
		FrpMessageBox,
		FrpDetailsSectionTitle,
		FrpDialog,
		FrpAlert,
		FrpIcon,
		FrpFileField,
		FrpTextarea,
		FrpFormActions,
		FrpFieldHelp,
		FrpDetailsItem,
		FrpCombobox,
		FrpBtn,
		FrpDateField,
		FrpRadioCardGroup,
		FrpTextField,
		FrpFormItem,
		FrpFormCardBlockCol,
		FrpFormCardBlockRow,
		FrpFormCardBlock,
		FrpFormCardBlocks,
		FrpFormCard,
		FrpNestedContentLayout,
		FrpBottomSpace,
		FrpTextCaption,
		FrpTextBodyTwo,
		FrpLegalEntityGeneralLoader,
		FrpSwitch,
		FrpConfirmDialog,
		FrpEmbeddedSnilsForm,
		FrpLegalEntityGeneralHead
	}
};
</script>

<style scoped>

</style>
