<template>
	<v-container fluid class="pa-0">
		<v-row style="position: fixed; bottom: 0; left: 0; width: 100%" class="white ma-0 py-2 pr-3">
			<v-col class="d-flex justify-end mr-5">
				<frp-btn data-cy="reject-btn"
						 outlined
						 color="primary"
						 v-if="!declinePermissions || can(declinePermissions)"
						 :loading="isRejecting"
						 @click="$emit('reject')"
						 :disabled="isRejectDisabled">
					{{ rejectBtnText }}
				</frp-btn>

				<frp-btn v-if="!acceptPermissions || can(acceptPermissions)"
						 data-cy="approve-btn"
						 @click="$emit('approve')"
						 :loading="isApproving"
						 :disabled="isApproveDisabled">
					{{ approveBtnText }}
				</frp-btn>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import FrpBtn from "Components/buttons/FrpBtn";
import authorizationMixin from "Mixins/authorizationMixin";

export default {
	mixins: [authorizationMixin],
	components: { FrpBtn },
	props: {
		isApproving: Boolean,
		isRejecting: Boolean,
		isApproveDisabled: Boolean,
		isRejectDisabled: Boolean,
		acceptPermissions: [Array, String],
		declinePermissions: [Array, String],
		approveBtnText: {
			default: "Одобрить"
		},
		rejectBtnText: {
			default: "Отклонить"
		}
	}
};
</script>
