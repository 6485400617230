export default class Office {
	inn: string;
	ogrn: string;
	kpp: string;
	shortName: string;
	longName: string;
	description: string;

	constructor(
		inn: string = "",
		ogrn: string = "",
		kpp: string = "",
		shortName: string = "",
		longName: string = "",
		description: string = ""
	)
	{
		this.inn = inn;
		this.ogrn = ogrn;
		this.kpp = kpp;
		this.shortName = shortName;
		this.longName = longName;
		this.description = description;
	}
}
