import Middleware from "@/router/middleware/middleware";
import { NavigationGuard, NavigationGuardNext, Route, VueRouter } from "vue-router/types/router";
import AuthenticationMiddleware from "@/router/middleware/items/authenticationMiddleware";
import CheckEmailConfirmationMiddleware from "@/router/middleware/items/checkEmailConfirmationMiddleware";
import RedirectMiddleware from "@/router/middleware/items/redirectMiddleware";
import PermissionsMiddleware from "@/router/middleware/items/permissionsMiddleware";
import CheckPhoneConfirmationMiddleware from "@/router/middleware/items/checkPhoneConfirmationMiddleware";
import store from "@/store";
import rootTypes from "@/store/types";

export const configureMiddleware = (router: VueRouter) => {
	router.beforeEach(async (to: Route, from: Route, next: NavigationGuardNext) => {
		const middleware = new Middleware(to, from, next);

		const publicRoute = to.matched.some(record => record.meta.public);

		if(!publicRoute) {
			middleware.useMiddleware(AuthenticationMiddleware);
			middleware.useMiddleware(CheckEmailConfirmationMiddleware);
			middleware.useMiddleware(CheckPhoneConfirmationMiddleware);
			middleware.useMiddleware(RedirectMiddleware);
			middleware.useMiddleware(PermissionsMiddleware);
		}

		await middleware.run();

		store.commit(rootTypes.mutationTypes.SET_IS_APP_LOADING, false);
	});
};
