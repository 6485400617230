import { difference } from "@/utils/difference";
import { Dictionary } from "vue-router/types/router";
import { plainToInstance } from "class-transformer";
import { validateOrReject } from "class-validator";
import { parseArrayParameter, parseQuery } from "@/utils/query";
import OfficeEmployeesRouteQuery from "@/store/modules/office/modules/officeEmployees/types/officeEmployeesRouteQuery";
import OfficeEmployeesState from "@/store/modules/office/modules/officeEmployees/types/officeEmployeesState";

export default class OfficeEmployeesRouteQueryService {
	defaultRouteQuery: OfficeEmployeesRouteQuery;

	constructor(defaultRouteQuery: OfficeEmployeesRouteQuery) {
		this.defaultRouteQuery = defaultRouteQuery;
	}

	resolveRouteQueryDictionary(state: OfficeEmployeesState) {
		let query = new OfficeEmployeesRouteQuery(
			state.paging.page,
			state.sorting.type,
			state.sorting.order,
			state.search.query,
			state.filter.roles
		);

		return difference(this.defaultRouteQuery, query);
	}

	async resolveRouteQuery(query: Dictionary<any>) {
		try {
			let result = plainToInstance(OfficeEmployeesRouteQuery, {
				...this.defaultRouteQuery,
				...query,
				filterRoles: parseArrayParameter(query.filterRoles)
			}, { enableImplicitConversion: true });

			await validateOrReject(result);

			return result;
		} catch (e) {
			console.error(e);
			return this.defaultRouteQuery;
		}
	}
}
