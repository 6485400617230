<template>
	<frp-form-card>
		<frp-form-card-blocks>
			<frp-form-card-block>
				<template #title>
					<frp-form-card-block-row>
						<frp-form-card-block-col cols="12" sm="6" md="4">
							<frp-form-item>
								<frp-form-block-title-loader/>
							</frp-form-item>
						</frp-form-card-block-col>
					</frp-form-card-block-row>
				</template>

				<frp-form-card-block-row>
					<frp-form-card-block-col cols="12" sm="6" md="4">
						<frp-form-item v-for="i in 3" :key="i">
							<frp-input-loader/>
						</frp-form-item>
						<frp-form-item>
							<frp-textarea-loader/>
						</frp-form-item>
						<frp-form-item>
							<frp-switch-loader/>
						</frp-form-item>
					</frp-form-card-block-col>
					<frp-form-card-block-col offset-md="2" cols="12" sm="6" md="4" :class="{ 'pt-0': $vuetify.breakpoint.xsOnly }">
						<frp-form-item v-for="i in 5" :key="i">
							<frp-input-loader/>
						</frp-form-item>
					</frp-form-card-block-col>
				</frp-form-card-block-row>
			</frp-form-card-block>
		</frp-form-card-blocks>

		<frp-form-card-blocks>
			<frp-form-card-block>
				<template #title>
					<frp-form-card-block-row>
						<frp-form-card-block-col cols="12" sm="6" md="4">
							<frp-form-item>
								<frp-form-block-title-loader/>
							</frp-form-item>
						</frp-form-card-block-col>
					</frp-form-card-block-row>
				</template>

				<frp-form-card-block-row>
					<frp-form-card-block-col v-for="i in 5" :key="i" cols="12" sm="6" md="4"
											 :class="{ 'py-0': $vuetify.breakpoint.xsOnly }">
						<frp-checkbox-loader v-for="i in 5" :key="i"/>
					</frp-form-card-block-col>
				</frp-form-card-block-row>
			</frp-form-card-block>
		</frp-form-card-blocks>
	</frp-form-card>
</template>

<script>
import FrpBackRouteLoader from "@/components/loaders/common/FrpBackRouteLoader";
import FrpTitleLoader from "@/components/loaders/common/FrpTitleLoader";
import { RouteNames } from "@/router/routes";
import FrpNestedContentLayout from "Components/layouts/FrpNestedContentLayout";
import FrpFormCard from "Components/markup/FrpFormCard";
import FrpFormCardBlocks from "Components/markup/FrpFormCardBlocks";
import FrpFormCardBlock from "Components/markup/FrpFormCardBlock";
import FrpFormCardBlockRow from "Components/markup/FrpFormCardBlockRow";
import FrpFormCardBlockCol from "Components/markup/FrpFormCardBlockCol";
import FrpFormItem from "Components/markup/FrpFormItem";
import FrpCheckboxLoader from "Components/loaders/common/FrpCheckboxLoader";
import FrpInputLoader from "Components/loaders/form/FrpInputLoader";
import FrpFormBlockTitleLoader from "Components/loaders/form/FrpFormBlockTitleLoader";
import FrpSwitchLoader from "Components/loaders/form/FrpSwitchLoader";
import FrpTextareaLoader from "Components/loaders/form/FrpTextareaLoader";

export default {
	data() {
		return {
			RouteNames
		};
	},
	components: {
		FrpBackRouteLoader,
		FrpTitleLoader,
		FrpNestedContentLayout,
		FrpFormCardBlocks,
		FrpFormCard,
		FrpFormCardBlock,
		FrpFormCardBlockCol,
		FrpFormCardBlockRow,
		FrpFormItem,
		FrpInputLoader,
		FrpFormBlockTitleLoader,
		FrpCheckboxLoader,
		FrpSwitchLoader,
		FrpTextareaLoader
	}
};
</script>
