import FormState from "@/store/shared/form/models/formState";
import ApiOfficeEmployeeRoles from "@/api/types/roles/apiOfficeEmployeeRoles";
import OfficeEmployee from "@/store/modules/officeEmployee/modules/general/types/officeEmployee";
import ApiAccountProfilePersistedBase from "@/api/types/account/apiAccountProfilePersistedBase";
import IPageState from "@/store/shared/base/types/pageState";

export default class OfficeEmployeeGeneralState implements IPageState {
	constructor(
		public form: FormState,
		public snapshot: object,
		public employee: OfficeEmployee = new OfficeEmployee(),
		public officeId: string = "",
		public id: string = "",
		public roles: ApiOfficeEmployeeRoles[] = [],
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public profile: ApiAccountProfilePersistedBase | null = null
	)
	{
	}
}
