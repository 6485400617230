<template>
	<div class="d-flex flex-column mb-4">
		<v-skeleton-loader max-width="128" height="17" type="text"/>
		<v-skeleton-loader height="18" type="text"/>
	</div>
</template>

<script>
export default {
	name: "FrpDetailsItemLoader"
}
</script>

<style scoped>

</style>
