import { actionTypes, mutationTypes, namespace } from "@/store/modules/counterparty/modules/legalEntityCounterparty/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import LegalEntityCounterpartyState from "@/store/modules/counterparty/modules/legalEntityCounterparty/types/legalEntityCounterpartyState";
import FormMixinBuilder from "@/store/shared/form";
import SnapshotOptions from "@/store/shared/snapshot/snapshotOptions";
import stateSnapshotKeys from "@/store/shared/snapshot/keys";
import LegalEntityCounterparty from "@/store/modules/counterparty/modules/legalEntityCounterparty/types/legalEntityCounterparty";
import SnapshotMixinBuilder from "@/store/shared/snapshot";
import { resolveAction, resolveMutation, resolveNestedState, splitNamespace } from "@/utils/vuexModules";
import router from "@/router";
import { plainToInstance } from "class-transformer";
import ApiLegalEntityCounterparty from "@/api/types/counterparty/apiLegalEntityCounterparty";
import storeManager, { resolveDynamicNamespace } from "@/store/manager/index";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import alertService, { AlertKeys } from "@/store/modules/alerts/services/alertService";
import LegalEntityFnsUpdate from "@/store/modules/counterparty/modules/legalEntityCounterparty/types/legalEntityFnsUpdate";
import { validateKpp, validateLegalEntityInn, validateOgrn } from "@/utils/validator";
import mapper from "@/store/modules/counterparty/modules/legalEntityCounterparty/mapper";
import ApiLegalEntityCounterpartyPersisted from "@/api/types/counterparty/apiLegalEntityCounterpartyPersisted";
import { CounterpartyController } from "@/api/counterparty";
import { LegalEntityController } from "@/api/counterparty/legalEntity";
import { FnsController } from "@/api/fns";
import AbortService from "@/services/abortService";
import userTypes from "@/store/modules/user/types";
import UserState from "@/store/modules/user/types/userState";
import NotDefinedException from "@/exceptions/notDefinedException";
import { RouteNames } from "@/router/routes";
import LegalEntityCounterpartyHeadModuleBuilder
	from "@/store/modules/counterparty/modules/legalEntityCounterparty/modules/legalEntityCounterpartyHead";
import LegalEntityCounterpartyHeadState
	from "@/store/modules/counterparty/modules/legalEntityCounterparty/modules/legalEntityCounterpartyHead/types/legalEntityCounterpartyHeadState";
import legalEntityCounterpartyHeadTypes
	from "@/store/modules/counterparty/modules/legalEntityCounterparty/modules/legalEntityCounterpartyHead/types";
import ApiLegalEntityCounterpartyHeadPersisted from "@/api/types/counterparty/apiLegalEntityCounterpartyHeadPersisted";
import ApiLegalEntityCounterpartyHead from "@/api/types/counterparty/apiLegalEntityCounterpartyHead";
import ApiLegalEntity from "@/api/types/legalEntity/apiLegalEntity";
import LegalEntityCounterpartyHead
	from "@/store/modules/counterparty/modules/legalEntityCounterparty/modules/legalEntityCounterpartyHead/types/legalEntityCounterpartyHead";
import { Permissions } from "@/constants/permissions";
import { AuthorizationScopeType } from "@/types/authorization/authorizationScopeType";
import PermissionsService from "@/services/permissionsService";
import { counterpartySnapshotKeys } from "@/store/modules/counterparty/types/counterpartySnapshotKeys";

const abortService = new AbortService();
const fnsController = new FnsController(abortService);
const legalEntityController = new LegalEntityController(abortService);
const counterpartyController = new CounterpartyController(abortService);
const permissionsService = new PermissionsService();
const MAX_HEADS_COUNT = 10;

const prepareHeadSnapshotKeyFields = () => {
	return [...Array(MAX_HEADS_COUNT)].map((x, i) => `${resolveDynamicNamespace(legalEntityCounterpartyHeadTypes.namespace, i)}.head`);
};

const formMixin = (new FormMixinBuilder()).build();
const baseMixin = (new BaseMixinBuilder(abortService)).build();
const snapshotMixin = (new SnapshotMixinBuilder({
	options: [
		new SnapshotOptions({
			key: stateSnapshotKeys.LAST_SAVED, fields: ["counterparty", "fnsUpdate", "heads", "snapshot", ...prepareHeadSnapshotKeyFields()]
		}),
		new SnapshotOptions({
			key: counterpartySnapshotKeys.FNS, fields: ["fnsUpdate"]
		})
	]
})).build();

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new LegalEntityCounterpartyState(
			formMixin.state(),
			snapshotMixin.state()
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<LegalEntityCounterpartyState, any>>{
	...formMixin.getters, ...snapshotMixin.getters
};

const actions = <ActionTree<LegalEntityCounterpartyState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...formMixin.actions,
	...snapshotMixin.actions,
	async [actionTypes.destroy]({ dispatch, state }) {
		await dispatch(actionTypes.destroyHeadModules);
		await dispatch(actionTypes.destroyBase);
	},
	async [actionTypes.initialize]({ dispatch, commit, state }, { id }: { id: string | null }) {
		await dispatch(actionTypes.initializeBase);

		if(id) {
			commit(mutationTypes.SET_ID, id);
			await dispatch(actionTypes.fetch);
		} else {
			await dispatch(actionTypes.addHead, new ApiLegalEntityCounterpartyHeadPersisted("", new ApiLegalEntityCounterpartyHead()));
		}

		commit(mutationTypes.SET_IS_INITIALIZED, true);
		commit(mutationTypes.SET_STATE_SNAPSHOT, counterpartySnapshotKeys.FNS);
		commit(mutationTypes.SET_STATE_SNAPSHOT, stateSnapshotKeys.LAST_SAVED);
	},
	async [actionTypes.checkIsRecordUnique]({ commit, state }) {
		if(!validateLegalEntityInn(state.counterparty.inn) || !validateOgrn(state.counterparty.ogrn) ||
			!validateKpp(state.counterparty.kpp))
		{
			commit(mutationTypes.SET_IS_RECORD_UNIQUE, true);
			return;
		}

		try {
			commit(mutationTypes.SET_IS_RECORD_UNIQUE_CHECK_IN_PROGRESS, true);

			const exists = await legalEntityController.checkLegalEntityCounterpartyIsExists(state.counterparty.inn,
				state.counterparty.ogrn,
				state.counterparty.kpp);

			commit(mutationTypes.SET_IS_RECORD_UNIQUE, !exists);
		} catch (error) {
			AlertHelper.handleGeneralRequestErrors(error);
			commit(mutationTypes.SET_IS_RECORD_UNIQUE, true);
		} finally {
			commit(mutationTypes.SET_IS_RECORD_UNIQUE_CHECK_IN_PROGRESS, false);
		}
	},
	async [actionTypes.addHead]({ state, dispatch, commit }, head: ApiLegalEntityCounterpartyHeadPersisted | undefined) {
		// Плохое решение, в Pinia такого не будет
		const headIndex = state.heads.length ? Math.max(...state.heads) + 1 : 0;

		const headModuleNamespace = storeManager.counterparty.legalEntity.heads.resolveNamespace(headIndex);

		if(this.hasModule(splitNamespace(headModuleNamespace))) {
			await this.unregisterModule(splitNamespace(headModuleNamespace));
		}

		let builder = new LegalEntityCounterpartyHeadModuleBuilder(headModuleNamespace);
		// Регистрация динамического модуля руководителя организации
		this.registerModule(splitNamespace(headModuleNamespace), builder.build());

		await dispatch(resolveAction(headModuleNamespace, legalEntityCounterpartyHeadTypes.actionTypes.initialize), head, { root: true });

		// Идентификатор руководителя понадобится для поиска по нему дочерних модулей
		commit(mutationTypes.ADD_HEAD, headIndex);
	},
	async [actionTypes.removeHead]({ state, dispatch, commit }, index: number) {
		commit(mutationTypes.REMOVE_HEAD, index);
	},
	async [actionTypes.destroyHeadModules]({ state, dispatch, commit }) {
		for (const index of [...Array(MAX_HEADS_COUNT).keys()]) {
			const headModuleNamespace = storeManager.counterparty.legalEntity.heads.resolveNamespace(index);

			if(this.hasModule(splitNamespace(headModuleNamespace))) {
				await dispatch(resolveAction(headModuleNamespace, actionTypes.destroy), null, { root: true });

				commit(mutationTypes.REMOVE_HEAD, index);

				await this.unregisterModule(splitNamespace(headModuleNamespace));
			}
		}
	},
	async [actionTypes.fetchHeads]({ state, dispatch, commit }) {
		const scope = await permissionsService.check([Permissions.GLOBAL_COUNTERPARTY_HEADS_READ])
			? AuthorizationScopeType.GLOBAL
			: AuthorizationScopeType.OWN;

		let apiHeads = await legalEntityController.getLegalEntityHeads(state.id, scope);

		for (const head of apiHeads) {
			await dispatch(actionTypes.addHead, head);
		}
	},
	async [actionTypes.fetch]({ dispatch, commit, state }) {
		commit(mutationTypes.SET_IS_FORM_LOADING, true);

		try {
			const scope = await permissionsService.check([Permissions.GLOBAL_COUNTERPARTY_READ])
				? AuthorizationScopeType.GLOBAL
				: AuthorizationScopeType.OWN;

			let counterpartyPersisted = await counterpartyController.getCounterparty<ApiLegalEntityCounterpartyPersisted>(state.id,
				ApiLegalEntityCounterpartyPersisted, scope);

			let counterparty = mapper.map(counterpartyPersisted, ApiLegalEntityCounterpartyPersisted, LegalEntityCounterparty);


			const hasFnsReadPermission = await permissionsService.check([Permissions.GLOBAL_COUNTERPARTY_FNS_READ]);

			if(hasFnsReadPermission) {
				let apiFnsUpdate = await fnsController.getLegalEntityFnsUpdate(counterparty.inn, counterparty.ogrn, counterparty.kpp);

				if(apiFnsUpdate) {
					let fnsUpdate = plainToInstance(LegalEntityFnsUpdate, {
						...apiFnsUpdate.upToDateLegalEntity, isEnabled: true
					});

					commit(mutationTypes.SET_FNS_UPDATE, fnsUpdate);
				} else {
					commit(mutationTypes.SET_IS_FNS_UPDATE_ENABLED, false);
				}
			}

			await dispatch(actionTypes.destroyHeadModules);

			await dispatch(actionTypes.fetchHeads);

			commit(mutationTypes.SET_COUNTERPARTY, counterparty);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
			commit(mutationTypes.SET_IS_FORM_DISABLED, true);
		} finally {
			commit(mutationTypes.SET_IS_FORM_LOADING, false);
		}
	},
	async [actionTypes.handleInnInput]({ state, commit, dispatch }) {
		if(!validateLegalEntityInn(state.counterparty.inn)) return;

		try {
			commit(mutationTypes.SET_IS_FORM_READONLY, true);
			commit(mutationTypes.SET_IS_EGRUL_LEGAL_ENTITY_LOADING, true);

			let suggestion = await legalEntityController.getLegalEntitySuggestion(state.counterparty.inn);
			let apiEgrulLegalEntity = suggestion &&
				await fnsController.getEgrulLegalEntity(suggestion.inn, suggestion.ogrn, suggestion.kpp);

			if(apiEgrulLegalEntity) {
				let legalEntity = mapper.map(apiEgrulLegalEntity, ApiLegalEntity, LegalEntityCounterparty);

				await dispatch(actionTypes.destroyHeadModules);

				for (const head of apiEgrulLegalEntity.heads) {
					await dispatch(actionTypes.addHead, new ApiLegalEntityCounterpartyHeadPersisted("", head));
				}

				commit(mutationTypes.SET_COUNTERPARTY, legalEntity);
			} else {
				alertService.addCustomError(`Не удалось найти организацию с ИНН ${state.counterparty.inn} в ЕГРЮЛ`);
			}
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_FORM_READONLY, false);
			commit(mutationTypes.SET_IS_EGRUL_LEGAL_ENTITY_LOADING, false);
		}
	},
	async [actionTypes.saveFnsUpdate]({ state }) {
		try {
			let { ogrn, inn, kpp } = state.counterparty;
			if(state.fnsUpdate.isEnabled) {
				await fnsController.enableLegalEntityFnsUpdate(inn, ogrn, kpp, state.id);
			} else {
				await fnsController.disableLegalEntityFnsUpdate(inn, ogrn, kpp, state.id);
			}
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
			throw error;
		}
	},
	async [actionTypes.save]({ dispatch, commit, rootState, state, getters }, { type, id }) {
		commit(mutationTypes.SET_IS_FORM_SAVING, true);

		const isCreationMode = !state.id;

		try {
			const { user } = resolveNestedState<UserState>(rootState, userTypes.namespace);
			if(!user.profile) throw new NotDefinedException("user.profile");

			let apiCounterparty = mapper.map(state.counterparty, LegalEntityCounterparty, ApiLegalEntityCounterparty);

			let heads = state.heads.map(x => resolveNestedState<LegalEntityCounterpartyHeadState>(rootState,
				storeManager.counterparty.legalEntity.heads.resolveNamespace(x)));

			apiCounterparty.heads = heads.map(x => mapper.map(x.head, LegalEntityCounterpartyHead, ApiLegalEntityCounterpartyHead));

			if(isCreationMode) {
				let id = await counterpartyController.createLegalEntityCounterparty(apiCounterparty);
				commit(mutationTypes.SET_ID, id);

				if(state.fnsUpdate.isEnabled)
					await dispatch(actionTypes.saveFnsUpdate);
			} else {
				await counterpartyController.updateLegalEntityCounterparty(state.id, apiCounterparty);

				if(getters.stateContainsUnsavedChanges(counterpartySnapshotKeys.FNS))
					await dispatch(actionTypes.saveFnsUpdate);
			}

			commit(mutationTypes.SET_STATE_SNAPSHOT, counterpartySnapshotKeys.FNS);
			commit(mutationTypes.SET_STATE_SNAPSHOT, stateSnapshotKeys.LAST_SAVED);

			if(isCreationMode) {
				const hasGlobalPermissionCounterpartyRead = await permissionsService.check([Permissions.GLOBAL_COUNTERPARTY_READ]);

				if(!hasGlobalPermissionCounterpartyRead) {
					commit(mutationTypes.SET_IS_COUNTERPARTY_SUCCESS_CREATED_DIALOG_OPENED, true);
				} else {
					alertService.addInfo(AlertKeys.SUCCESS_CREATED_INFO);
					await router.push({ name: RouteNames.COUNTERPARTY, params: { id: state.id } });
				}
			} else {
				alertService.addInfo(AlertKeys.SUCCESS_UPDATED_INFO);
			}
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_FORM_SAVING, false);
		}
	},
	async [actionTypes.updateViaFns]({ state, commit, dispatch }) {
		commit(mutationTypes.SET_IS_FORM_DISABLED, true);
		commit(mutationTypes.SET_IS_UPDATE_VIA_FNS_OPERATION_EXECUTING, true);

		try {
			let { ogrn, inn, kpp } = state.counterparty;
			await fnsController.updateLegalEntityViaFns(inn, ogrn, kpp, state.id);

			// Временно необходима, так как updateLegalEntityViaFns возвращает ответ не дожидаясь обновления данных
			const delay = 1000;
			await new Promise(resolve => setTimeout(resolve, delay));

			await dispatch(actionTypes.fetch);
			commit(mutationTypes.SET_STATE_SNAPSHOT, counterpartySnapshotKeys.FNS);
			commit(mutationTypes.SET_STATE_SNAPSHOT, stateSnapshotKeys.LAST_SAVED);
			alertService.addInfo(AlertKeys.COUNTERPARTY_SUCCESS_UPDATED_VIA_FNS);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_FORM_DISABLED, false);
			commit(mutationTypes.SET_IS_UPDATE_VIA_FNS_OPERATION_EXECUTING, false);
		}
	}
};

const mutations = <MutationTree<LegalEntityCounterpartyState>>{
	...stateManipulationMixin.mutations,
	...formMixin.mutations,
	...snapshotMixin.mutations,
	...baseMixin.mutations,
	[mutationTypes.SET_ID](state, value) {
		state.id = value;
	},
	[mutationTypes.SET_IS_FNS_UPDATE_ENABLED](state, value) {
		state.fnsUpdate.isEnabled = value;
	},
	[mutationTypes.SET_IS_UPDATE_VIA_FNS_OPERATION_EXECUTING](state, value) {
		state.isUpdateViaFnsOperationExecuting = value;
	},
	[mutationTypes.SET_FNS_UPDATE](state, value) {
		state.fnsUpdate = value;
	},
	[mutationTypes.SET_COUNTERPARTY](state, value) {
		state.counterparty = value;
	},
	[mutationTypes.SET_COUNTERPARTY_INN](state, value) {
		state.counterparty.inn = value;
	},
	[mutationTypes.SET_COUNTERPARTY_OGRN](state, value) {
		state.counterparty.ogrn = value;
	},
	[mutationTypes.SET_COUNTERPARTY_KPP](state, value) {
		state.counterparty.kpp = value;
	},
	[mutationTypes.SET_COUNTERPARTY_OPF](state, value) {
		state.counterparty.opf = value;
	},
	[mutationTypes.SET_COUNTERPARTY_LONG_NAME](state, value) {
		state.counterparty.longName = value;
	},
	[mutationTypes.SET_COUNTERPARTY_SHORT_NAME](state, value) {
		state.counterparty.shortName = value;
	},
	[mutationTypes.SET_COUNTERPARTY_LEGAL_ADDRESS](state, value) {
		state.counterparty.legalAddress = value;
	},
	[mutationTypes.SET_COUNTERPARTY_REGISTRATION_DATE](state, value) {
		state.counterparty.registrationDate = value;
	},
	[mutationTypes.ADD_HEAD](state, value) {
		state.heads.push(value);
	},
	[mutationTypes.REMOVE_HEAD](state, value) {
		state.heads.splice(state.heads.findIndex(x => x === value), 1);
	},
	[mutationTypes.SET_IS_EGRUL_LEGAL_ENTITY_LOADING](state, value) {
		state.isEgrulLegalEntityLoading = value;
	},
	[mutationTypes.SET_COUNTERPARTY_IS_RFRP](state, value) {
		state.counterparty.isRfrp = value;
	},
	[mutationTypes.SET_COUNTERPARTY_IS_LEASING_COMPANY](state, value) {
		state.counterparty.isLeasingCompany = value;
	},
	[mutationTypes.SET_COUNTERPARTY_IS_PLEDGE_EXPERT_COMPANY](state, value) {
		state.counterparty.isPledgeExpertCompany = value;
	},
	[mutationTypes.SET_COUNTERPARTY_IS_VKM_EXPERT_COMPANY](state, value) {
		state.counterparty.isVkmExpertCompany = value;
	},
	[mutationTypes.SET_COUNTERPARTY_IS_FRP_ASSIGNEE_COMPANY](state, value) {
		state.counterparty.isFrpAssigneeCompany = value;
	},
	[mutationTypes.SET_IS_COUNTERPARTY_SUCCESS_CREATED_DIALOG_OPENED](state, value) {
		state.isCounterpartySuccessCreatedDialogOpened = value;
	}
};


const subscribe = (store: any) => {
	const { commit, dispatch } = store;

	store.subscribe(async ({ type, payload }: any, state: any) => {
		switch (type) {
			case  resolveMutation(storeManager.counterparty.legalEntity.namespace, mutationTypes.SET_COUNTERPARTY_INN):
			{
				let legalEntityState = resolveNestedState<LegalEntityCounterpartyState>(state,
					storeManager.counterparty.legalEntity.namespace);

				if(!legalEntityState.id) {
					await dispatch(resolveAction(storeManager.counterparty.legalEntity.namespace, actionTypes.handleInnInput));
					await dispatch(resolveAction(storeManager.counterparty.legalEntity.namespace, actionTypes.checkIsRecordUnique));
				}

				break;
			}
			default:
				break;
		}
	});
};


export {
	namespace, state, getters, actions, mutations, subscribe
};

const legalEntityCounterpartyModule = {
	namespace, state, getters, actions, mutations, subscribe, namespaced: true
};

export default legalEntityCounterpartyModule;
