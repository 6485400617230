import { MasterAccountApplicationMethodType } from "@/store/modules/masterAccountAccess/types/MasterAccountApplicationMethodType";

export default class MasterAccountAccessState {
	currentStepIndex: number;
	isCreateMasterAccountRequestOperationExecuting: boolean;
	applicationMethod: MasterAccountApplicationMethodType;

	constructor(currentStepIndex: number,
		isCreateMasterAccountRequestOperationExecuting: boolean,
		applicationMethod: MasterAccountApplicationMethodType = MasterAccountApplicationMethodType.UNKNOWN)
	{
		this.currentStepIndex = currentStepIndex;
		this.isCreateMasterAccountRequestOperationExecuting = isCreateMasterAccountRequestOperationExecuting;
		this.applicationMethod = applicationMethod;
	}
}
