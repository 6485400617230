import FormState from "@/store/shared/form/models/formState";
import CounterpartyEmployeeTrust from "@/store/modules/counterpartyEmployee/modules/trust/types/counterpartyEmployeeTrust";
import FileMeta from "@/store/shared/storage/types/fileMeta";
import OfficeEmployee from "@/store/modules/officeEmployee/types/officeEmployee";
import IPageState from "@/store/shared/base/types/pageState";
import CertificateInfo from "@/types/certificateInfo";
import CounterpartyEmployeeTrustActioners
	from "@/store/modules/counterpartyEmployee/modules/trust/types/counterpartyEmployeeTrustActioners";

export default class CounterpartyEmployeeTrustState implements IPageState {
	constructor(
		public form: FormState,
		public snapshot: object,
		public trust: CounterpartyEmployeeTrust = new CounterpartyEmployeeTrust(),
		public id: string = "",
		public counterpartyId: string = "",
		public employeeId: string = "",
		public trustFileMeta: FileMeta = new FileMeta(),
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public isDeclining: boolean = false,
		public isAccepting: boolean = false,
		public isCancelling: boolean = false,
		public acceptedEmployee: OfficeEmployee = new OfficeEmployee(),
		public declinedEmployee: OfficeEmployee = new OfficeEmployee(),
		public canceledEmployee: OfficeEmployee = new OfficeEmployee(),
		public currentActiveTrustId: string = "",
		public certificateInfo: CertificateInfo = new CertificateInfo(),
		public declineReason: string = "",
		public trustActioners: CounterpartyEmployeeTrustActioners = new CounterpartyEmployeeTrustActioners()
	)
	{
	}
}
