import baseMixinTypes, { baseActionTypes } from "@/store/shared/base/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";
import formTypes from "@/store/shared/form/types";
import snapshotMixinTypes from "@/store/shared/snapshot/types";

export const namespace = "employee-join";

export const getterTypes = {
	...formTypes.getterTypes,
	...snapshotMixinTypes.getterTypes
};

export const actionTypes = {
	...baseActionTypes,
	...stateManipulationMixinTypes.actionTypes,
	...formTypes.actionTypes,
	...snapshotMixinTypes.actionTypes,
	fetch: "fetch",
	handleInnInput: "handleInnInput"
};

export const mutationTypes = {
	...stateManipulationMixinTypes.mutationTypes,
	...formTypes.mutationTypes,
	...snapshotMixinTypes.mutationTypes,
	...baseMixinTypes.mutationTypes,
	SET_COUNTERPARTY: "SET_COUNTERPARTY",
	SET_IS_EGRUL_LEGAL_ENTITY_LOADING: "SET_IS_EGRUL_LEGAL_ENTITY_LOADING",
	SET_COUNTERPARTY_INN: "SET_COUNTERPARTY_INN",
	SET_COUNTERPARTY_OGRN: "SET_COUNTERPARTY_OGRN",
	SET_COUNTERPARTY_KPP: "SET_COUNTERPARTY_KPP",
	SET_COUNTERPARTY_OPF: "SET_COUNTERPARTY_OPF",
	SET_COUNTERPARTY_LONG_NAME: "SET_COUNTERPARTY_LONG_NAME",
	SET_COUNTERPARTY_SHORT_NAME: "SET_COUNTERPARTY_SHORT_NAME",
	SET_COUNTERPARTY_LEGAL_ADDRESS: "SET_COUNTERPARTY_LEGAL_ADDRESS",
	SET_COUNTERPARTY_REGISTRATION_DATE: "SET_COUNTERPARTY_REGISTRATION_DATE",
	SET_EMPLOYEE: "SET_EMPLOYEE",
	SET_EMPLOYEE_INN: "SET_EMPLOYEE_INN",
	SET_EMPLOYEE_FIRST_NAME: "SET_EMPLOYEE_FIRST_NAME",
	SET_EMPLOYEE_LAST_NAME: "SET_EMPLOYEE_LAST_NAME",
	SET_EMPLOYEE_MIDDLE_NAME: "SET_EMPLOYEE_MIDDLE_NAME",
	SET_EMPLOYEE_POSITION: "SET_EMPLOYEE_POSITION",
	SET_EMPLOYEE_EMAIL: "SET_EMPLOYEE_EMAIL",
	SET_EMPLOYEE_PHONE: "SET_EMPLOYEE_PHONE"
};

const employeeJoinTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default employeeJoinTypes;
