<template>
	<v-tabs :color="colors.primary.darken1"
			slider-color="secondary"
			class="mt-n2 mb-6 overflow-hidden frp-tabs"
			v-model="tab"
			style="border-radius: 4px"
			:background-color="colors.white.base"
			:class="{ 'flex-column': $vuetify.breakpoint.smAndDown }">
		<slot></slot>
	</v-tabs>
</template>

<script>
import colorsMixin from "Mixins/colorsMixin";

export default {
	mixins: [colorsMixin],
	data() {
		return {
			tab: null
		}
	},
	watch: {
		async tab() {
			await this.$nextTick();
			this.tab = this.$route.path;
		}
	}
};
</script>

<style scoped>

</style>
