<template>
	<v-container class="fill-height align-start" fluid>
		<v-row justify="center">
			<v-col v-if="!isFinalStep" cols="12" class="d-flex justify-end mt-9">
				<router-link :to="{ name: RouteNames.ROOT }">
					<frp-icon class="mr-8" src="ico_logo" :color="colors.secondary.base"></frp-icon>
				</router-link>
			</v-col>
			<v-col cols="12" md="10" xl="9" :style="{ 'margin-top': $vuetify.breakpoint.smAndUp ? '80px' : '20px' }">
				<component :key="index"
						   v-for="({index, component}) of availableIndexedSteps"
						   v-if="(index <= currentStepIndex)"
						   v-show="index === currentStepIndex"
						   v-bind:is="component"
						   :readonly="isCreateMasterAccountRequestOperationExecuting">
				</component>
			</v-col>
		</v-row>
		<v-row class="align-self-end  px-3" justify="center">
			<v-col cols="12" md="10" xl="9" class="d-flex justify-end pb-16">
				<frp-btn v-if="!isFinalStep"
						 data-cy="next-step"
						 :disabled="!isNextStepEnabled"
						 :loading="isCreateMasterAccountRequestOperationExecuting"
						 @click="handleNextStep"
						 class="px-16 align-self-end">
					Далее
				</frp-btn>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import { RouteNames } from "@/router/routes";
import FrpIcon from "Components/icon/FrpIcon";
import FrpSelectLegalEntityStep from "Views/public/masterAccountAccess/steps/FrpSelectCounterpartyStep";
import FrpSignApplicationStep from "Views/public/masterAccountAccess/steps/FrpSignApplicationStep";
import FrpDocumentsStep from "Views/public/masterAccountAccess/steps/FrpDocumentsStep";
import FrpContactInformationStep from "Views/public/masterAccountAccess/steps/FrpContactInformationStep";
import FrpFinalStep from "Views/public/masterAccountAccess/steps/FrpFinalStep";
import storeModuleBasedPage from "@/mixins/storeModuleBasedPage";
import { createNamespacedHelpers } from "vuex";
import { namespace, getterTypes, actionTypes, mutationTypes } from "Store/modules/masterAccountAccess/types";
import colorsMixin from "Mixins/colorsMixin";

const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);

export default {
	mixins: [colorsMixin, storeModuleBasedPage],
	data() {
		return {
			namespace,
			RouteNames
		};
	},
	computed: {
		...mapState({
			currentStepIndex: state => state.currentStepIndex,
			isCreateMasterAccountRequestOperationExecuting: state => state.isCreateMasterAccountRequestOperationExecuting,
			isLoadingState: state => state.isLoadingState
		}),
		...mapGetters({
			isFinalStep: getterTypes.isFinalStep,
			currentStep: getterTypes.currentStep,
			availableIndexedSteps: getterTypes.availableIndexedSteps,
			availableSteps: getterTypes.availableSteps,
			isNextStepEnabled: getterTypes.isNextStepEnabled,
			isPrevStepEnabled: getterTypes.isPrevStepEnabled
		})
	},
	methods: {
		...mapActions({
			handleNextStep: actionTypes.handleNextStep
		})
	},
	async created() {
		await this.initializeStore();
	},
	components: {
		FrpBtn,
		FrpIcon,
		FrpSelectLegalEntityStep,
		FrpContactInformationStep,
		FrpDocumentsStep,
		FrpFinalStep,
		FrpSignApplicationStep
	}
};
</script>
