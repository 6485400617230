import ApiRole from "@/api/types/roles/apiRole";

export default class OfficeEmployee {
	position: string;
	snils: string;
	firstName: string;
	middleName: string;
	lastName: string;
	description: string;
	email: string;
	phone: string;
	extensionPhoneNumber: string;
	isActive: boolean;
	roles: ApiRole[];

	constructor(
		position: string = "",
		snils: string = "",
		firstName: string = "",
		middleName: string = "",
		lastName: string = "",
		email: string = "",
		description: string = "",
		phone: string = "",
		extensionPhoneNumber: string = "",
		isActive: boolean = true,
		roles: ApiRole[] = [])
	{
		this.position = position;
		this.snils = snils;
		this.firstName = firstName;
		this.middleName = middleName;
		this.lastName = lastName;
		this.email = email;
		this.description = description;
		this.phone = phone;
		this.extensionPhoneNumber = extensionPhoneNumber;
		this.isActive = isActive;
		this.roles = roles;
	}
}
