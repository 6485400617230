<template>
	<frp-form-card v-if="isInitialized">
		<v-form :readonly="isFormSaving || isFormReadonly" :disabled="isFormDisabled" :ref="refs.form" v-model="formValid">
			<frp-form-card-blocks>
				<frp-form-card-block :title="$t('titles.general')">
					<frp-form-card-block-row>
						<frp-form-card-block-col cols="12" sm="6" md="4" :class="{ 'pb-0': $vuetify.breakpoint.xsOnly }">
							<frp-form-item>
								<frp-text-field :label="$t('fields.lastName.label')"
												required
												name="lname"
												autocomplete="family-name"
												:rules="validation.lastName"
												v-model="lastName"
												:disabled="!hasEditPermissions"
												:placeholder="$t('fields.lastName.placeholder')">
								</frp-text-field>
							</frp-form-item>
							<frp-form-item>
								<frp-text-field :label="$t('fields.firstName.label')"
												name="fname"
												autocomplete="given-name"
												required
												:rules="validation.firstName"
												v-model="firstName"
												:disabled="!hasEditPermissions"
												:placeholder="$t('fields.firstName.placeholder')">
								</frp-text-field>
							</frp-form-item>
							<frp-form-item>
								<frp-text-field :label="$t('fields.middleName.label')"
												name="mname"
												autocomplete="additional-name"
												:rules="validation.middleName"
												v-model="middleName"
												:disabled="!hasEditPermissions"
												:placeholder="$t('fields.middleName.placeholder')">
								</frp-text-field>
							</frp-form-item>
							<frp-form-item>
								<frp-text-field :label="$t('fields.email.label')"
												name="email"
												autocomplete="email"
												required
												:rules="validation.email"
												v-model="email"
												:disabled="!hasEditPermissions || !isCreationMode"
												:placeholder="$t('fields.email.placeholder')">
								</frp-text-field>
							</frp-form-item>
							<frp-form-item>
								<frp-phone-field :label="$t('fields.phone.label')"
												 name="phone"
												 autocomplete="mobile phone"
												 :rules="validation.phone"
												 v-model.trim="phone"
												 :disabled="!hasEditPermissions"
												 :placeholder="$t('fields.phone.placeholder')">
								</frp-phone-field>
							</frp-form-item>
							<frp-form-item>
								<frp-text-field :label="$t('fields.extensionPhoneNumber.label')"
												name="extension-phone-number"
												autocomplete="tel-extension"
												:rules="validation.extensionPhoneNumber"
												v-model="extensionPhoneNumber"
												:disabled="!hasEditPermissions"
												:placeholder="$t('fields.extensionPhoneNumber.placeholder')">
								</frp-text-field>
							</frp-form-item>
						</frp-form-card-block-col>
						<frp-form-card-block-col offset-md="2" cols="12" sm="6" md="4"
												 :class="{ 'pt-0': $vuetify.breakpoint.xsOnly }">
							<frp-form-item>
								<frp-text-field :tooltip-text="$t('common.tooltipTextSnils')"
												:label="$t('fields.snils.label')"
												name="snils"
												autocomplete="snils"
												:mask="SNILS_FORMAT"
												:rules="validation.snils"
												v-model="snils"
												:disabled="!hasEditPermissions"
												:placeholder="$t('fields.snils.placeholderInput')">
								</frp-text-field>
							</frp-form-item>
							<frp-form-item>
								<frp-text-field :label="$t('fields.position.label')"
												name="organization-title"
												autocomplete="organization-title"
												required
												:rules="validation.position"
												v-model="position"
												:disabled="!hasEditPermissions"
												:placeholder="$t('fields.position.placeholder')">
								</frp-text-field>
							</frp-form-item>
							<frp-form-item>
								<frp-textarea :label="$t('fields.description.label')"
											  autocomplete="off"
											  :rules="validation.description"
											  :placeholder="$t('fields.description.placeholderAdd')"
											  outlined
											  :disabled="!hasEditPermissions"
											  v-model="description">
								</frp-textarea>
							</frp-form-item>
						</frp-form-card-block-col>
					</frp-form-card-block-row>
				</frp-form-card-block>
			</frp-form-card-blocks>
			<frp-form-card-blocks>
				<frp-form-card-block :title="$t('titles.roles')">
					<frp-form-card-block-row class="pb-8">
						<frp-form-card-block-col cols="12">
							<frp-checkbox-group :items="roles.map((x) => ({ value: x.id, text: x.description }))"
												:disabled="!hasEditPermissions"
												v-model="employeeRoles"
												columns="5"
												gap="16">
							</frp-checkbox-group>
						</frp-form-card-block-col>
					</frp-form-card-block-row>
				</frp-form-card-block>
			</frp-form-card-blocks>
		</v-form>

		<frp-form-actions v-if="hasEditPermissions"
						  :is-save-disabled="!stateContainsUnsavedChanges || !isFormValid"
						  :is-cancel-disabled="!stateContainsUnsavedChanges"
						  :is-loading-state="isFormSaving || isFormLoadingState"
						  @cancel="onFormCancel"
						  @submit="submit">
			<template v-if="isCreationMode" #submit-action>
				<frp-btn @click="handleCreationFormSubmit"
						 color="secondary"
						 :loading="isFormSaving || isFormLoadingState"
						 :disabled="!stateContainsUnsavedChanges || !isFormValid">
					{{ $t("buttons.add") }}
				</frp-btn>
			</template>
		</frp-form-actions>
	</frp-form-card>
	<frp-office-employee-general-loader v-else></frp-office-employee-general-loader>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import { Permissions } from "@/constants/permissions";
import { maxLength } from "@/constants/validation";
import authorizationMixin from "@/mixins/authorizationMixin";
import { i18n } from "@/plugins";
import { RouteNames } from "@/router/routes";
import { JoiningEmployeeToOfficeMethodType } from "@/store/modules/officeEmployee/modules/general/types/joiningEmployeeToOfficeMethodType";
import FrpOfficeEmployeeGeneralLoader from "@/views/officeEmployee/FrpOfficeEmployeeGeneralLoader";
import FrpCountryAutocomplete from "Components/fields/FrpCountryAutocomplete";
import FrpRadioCardGroup from "Components/fields/FrpRadioCardGroup";
import FrpTextField from "Components/fields/FrpTextField";
import FrpFormActions from "Components/form/FrpFormActions";
import FrpNestedContentLayout from "Components/layouts/FrpNestedContentLayout";
import FrpBottomSpace from "Components/layouts/FrpBottomSpace";
import FrpFormCard from "Components/markup/FrpFormCard";
import FrpFormCardBlock from "Components/markup/FrpFormCardBlock";
import FrpFormCardBlockCol from "Components/markup/FrpFormCardBlockCol";
import FrpFormCardBlockRow from "Components/markup/FrpFormCardBlockRow";
import FrpFormCardBlocks from "Components/markup/FrpFormCardBlocks";
import FrpFormItem from "Components/markup/FrpFormItem";
import FrpTextarea from "Components/fields/FrpTextarea";
import FrpCheckboxGroup from "Components/fields/FrpCheckboxGroup";
import storeManager from "Store/manager";
import FrpPhoneField from "Components/fields/FrpPhoneField";
import FrpSwitch from "@/components/fields/FrpSwitch";
import FrpDropdownBtn from "@/components/buttons/FrpDropdownBtn";
import colorsMixin from "Mixins/colorsMixin";
import formMixin from "Mixins/formMixin";
import storeModuleBasedPage from "Mixins/storeModuleBasedPage";
import { actionTypes, mutationTypes } from "Store/modules/officeEmployee/modules/general/types";
import { SNILS_FORMAT } from "Utils/formats";
import { prepareMaxLengthRule, prepareEmailRule, prepareSnilsRule, onlyNumbersRule, maxLengths as validationMaxLengths } from "Utils/validation";
import { createNamespacedHelpers } from "vuex";
import { masks } from "Constants/masks";

const namespace = storeManager.officeEmployee.general.namespace;
const { mapState, mapMutations, mapActions } = createNamespacedHelpers(namespace);

export default {
	mixins: [storeModuleBasedPage, colorsMixin, formMixin, authorizationMixin],
	data() {
		return {
			SNILS_FORMAT,
			namespace,
			joiningEmployeeToOfficeMethodValue: JoiningEmployeeToOfficeMethodType.REGISTER,
			RouteNames,
			masks,
			validation: {
				firstName: [prepareMaxLengthRule({ maxLength: maxLength.short })],
				lastName: [prepareMaxLengthRule({ maxLength: maxLength.short })],
				middleName: [prepareMaxLengthRule({ maxLength: maxLength.short })],
				description: [prepareMaxLengthRule({ maxLength: maxLength.long })],
				snils: [prepareSnilsRule()],
				email: [prepareEmailRule()],
				phone: [],
				extensionPhoneNumber: [onlyNumbersRule(), prepareMaxLengthRule({ maxLength: validationMaxLengths.extensionPhoneNumber })],
				position: [prepareMaxLengthRule({ maxLength: maxLength.short })]
			},
			isResetValidationDisabled: true
		};
	},
	computed: {
		...mapState({
			employee: state => state.employee,
			officeId: state => state.officeId,
			roles: state => state.roles,
			isInitialized: state => state.isInitialized,
			profilePersisted: state => state.profile
		}),
		hasEditPermissions() {
			return this.isCreationMode ?
				this.can(Permissions.GLOBAL_OFFICE_EMPLOYEE_CREATE) :
				this.can(Permissions.GLOBAL_OFFICE_EMPLOYEE_UPDATE);
		},
		snils: {
			get() {
				return this.employee.snils;
			},
			set(value) {
				this.setSnils(value);
			}
		},
		position: {
			get() {
				return this.employee.position;
			},
			set(value) {
				this.setPosition(value);
			}
		},
		firstName: {
			get() {
				return this.employee.firstName;
			},
			set(value) {
				this.setFirstName(value);
			}
		},
		lastName: {
			get() {
				return this.employee.lastName;
			},
			set(value) {
				this.setLastName(value);
			}
		},
		middleName: {
			get() {
				return this.employee.middleName;
			},
			set(value) {
				this.setMiddleName(value);
			}
		},
		description: {
			get() {
				return this.employee.description;
			},
			set(value) {
				this.setDescription(value);
			}
		},
		employeeRoles: {
			get() {
				return this.employee.roles.map(x => x.id);
			},
			set(selectedRoleIds) {
				this.setRoles(this.roles.filter(x => selectedRoleIds.includes(x.id)));
			}
		},
		phone: {
			get() {
				return this.employee.phone;
			},
			set(value) {
				this.setPhone(value);
			}
		},
		extensionPhoneNumber: {
			get() {
				return this.employee.extensionPhoneNumber;
			},
			set(value) {
				this.setExtensionPhoneNumber(value);
			}
		},
		email: {
			get() {
				return this.employee.email;
			},
			set(value) {
				this.setEmail(value);
			}
		},
		isCreationMode() {
			return this.$route.name === RouteNames.OFFICE_EMPLOYEE_CREATE;
		}
	},
	methods: {
		...mapActions({
			save: actionTypes.save,
			create: actionTypes.create
		}),
		...mapMutations({
			setEmail: mutationTypes.SET_EMPLOYEE_EMAIL,
			setSnils: mutationTypes.SET_EMPLOYEE_SNILS,
			setFirstName: mutationTypes.SET_EMPLOYEE_FIRST_NAME,
			setLastName: mutationTypes.SET_EMPLOYEE_LAST_NAME,
			setMiddleName: mutationTypes.SET_EMPLOYEE_MIDDLE_NAME,
			setPosition: mutationTypes.SET_EMPLOYEE_POSITION,
			setDescription: mutationTypes.SET_EMPLOYEE_DESCRIPTION,
			setRoles: mutationTypes.SET_EMPLOYEE_ROLES,
			setPhone: mutationTypes.SET_EMPLOYEE_PHONE,
			setExtensionPhoneNumber: mutationTypes.SET_EMPLOYEE_EXTENSION_PHONE_NUMBER
		}),
		async handleCreationFormSubmit() {
			await this.create({
				joiningEmployeeToOfficeMethod: this.joiningEmployeeToOfficeMethodValue
			});
		}
	},
	created() {
		this.initializeStore({ id: this.$route.params.employeeId });
	},
	components: {
		FrpBtn,
		FrpFormActions,
		FrpCountryAutocomplete,
		FrpRadioCardGroup,
		FrpTextField,
		FrpFormItem,
		FrpFormCardBlockCol,
		FrpFormCardBlockRow,
		FrpFormCardBlock,
		FrpFormCardBlocks,
		FrpFormCard,
		FrpNestedContentLayout,
		FrpBottomSpace,
		FrpTextarea,
		FrpPhoneField,
		FrpOfficeEmployeeGeneralLoader,
		FrpCheckboxGroup,
		FrpSwitch,
		FrpDropdownBtn
	}
};
</script>

<style scoped>

</style>
