<template>
	<frp-nested-content-layout v-if="isInitialized">
		<template #title>
			<frp-subtitle-five>
				{{ title }}
			</frp-subtitle-five>
		</template>
		<template #title-append v-if="visibleToggleActivityStatusBtn">
			<frp-toggle-activity-btn
				v-if="can(Permissions.GLOBAL_OFFICE_EMPLOYEE_STATUS_UPDATE)"
				:is-active="isActive"
				:loading="isStatusSaving"
				class="elevation-0 frp-xs-full-width"
				:disabled="!profilePersisted.profile.accountId"
				@click="toggleActivityStatus">
			</frp-toggle-activity-btn>
		</template>
		<template #title-append-outer>
			<frp-btn
				v-if="crossLink"
				:to="crossLink"
				class="frp-xs-full-width"
				outlined>
				{{ $t("buttons.goToAccount") }}
			</frp-btn>
		</template>
		<template #content>
			<template v-if="shouldShowTabs">
				<frp-tabs>
					<v-tab key="general" :to="{ name: RouteNames.OFFICE_EMPLOYEE_GENERAL, params: { officeId, id  } }"
						   v-if="can(Permissions.GLOBAL_OFFICE_EMPLOYEE_READ)"
						   class="text-none">
						{{ $t("subheaders.general") }}
					</v-tab>
					<v-tab key="profile"
						   v-if="can(Permissions.GLOBAL_OFFICE_EMPLOYEE_PROFILE_READ)"
						   :to="{ name: RouteNames.OFFICE_EMPLOYEE_PROFILE, params: { officeId, id  } }"
						   class="text-none">
						{{ $t("subheaders.profile") }}
					</v-tab>
				</frp-tabs>
			</template>
			<frp-router-view></frp-router-view>
			<frp-bottom-space></frp-bottom-space>
		</template>
	</frp-nested-content-layout>
	<frp-office-employee-loader v-else></frp-office-employee-loader>
</template>

<script>
import FrpSubtitleFive from "@/components/typography/FrpSubtitleFive";
import FrpBtn from "@/components/buttons/FrpBtn";
import FrpToggleActivityBtn from "@/components/buttons/FrpToggleActivityBtn";
import authorizationMixin from "@/mixins/authorizationMixin";
import { RouteNames } from "@/router/routes";
import { actionTypes } from "@/store/modules/officeEmployee/types";
import FrpOfficeEmployeeLoader from "@/views/officeEmployee/FrpOfficeEmployeeLoader";
import FrpRouterView from "Components/common/FrpRouterView";
import FrpRadioCardGroup from "Components/fields/FrpRadioCardGroup";
import FrpTextField from "Components/fields/FrpTextField";
import FrpNestedContentLayout from "Components/layouts/FrpNestedContentLayout";
import FrpBottomSpace from "Components/layouts/FrpBottomSpace";
import FrpFormCard from "Components/markup/FrpFormCard";
import FrpTabs from "Components/tabs/FrpTabs";
import FrpFormCardBlock from "Components/markup/FrpFormCardBlock";
import FrpFormCardBlockCol from "Components/markup/FrpFormCardBlockCol";
import FrpFormCardBlockRow from "Components/markup/FrpFormCardBlockRow";
import FrpFormCardBlocks from "Components/markup/FrpFormCardBlocks";
import FrpFormItem from "Components/markup/FrpFormItem";
import FrpPageNotFound from "Views/errors/FrpPageNotFound";
import colorsMixin from "Mixins/colorsMixin";
import storeModuleBasedPage from "Mixins/storeModuleBasedPage";
import storeManager from "Store/manager";
import { formatFullName } from "Utils/formatting";
import { createNamespacedHelpers } from "vuex";

const namespace = storeManager.officeEmployee.namespace;
const { mapState, mapMutations, mapActions } = createNamespacedHelpers(namespace);

export default {
	mixins: [storeModuleBasedPage, colorsMixin, authorizationMixin],
	data() {
		return {
			namespace,
			RouteNames
		};
	},
	computed: {
		...mapState({
			id: state => state.id,
			isInitialized: state => state.isInitialized,
			profilePersisted: state => state.profile,
			employee: state => state.employee,
			office: state => state.office,
			officeId: state => state.officeId,
			isActive: state => state.employee.isActive,
			isStatusSaving: state => state.employee.isStatusSaving
		}),
		crossLink() {
			if(!this.profilePersisted)
				return;

			return {
				name: RouteNames.ACCOUNT,
				params: {
					id: this.profilePersisted.profile.accountId
				}
			};
		},
		isCreateMode() {
			return !this.$route.params.employeeId;
		},
		shouldShowTabs() {
			return !this.isCreateMode;
		},
		title() {
			switch (this.$route.name) {
				case RouteNames.OFFICE_EMPLOYEE_CREATE:
					return this.$t("titles.createEmployee");
				case RouteNames.OFFICE_EMPLOYEE_GENERAL:
				case RouteNames.OFFICE_EMPLOYEE_PROFILE:
				{
					return `${this.$t("titles.employee")} ${this.office.shortName} - ${formatFullName(this.employee)}`;
				}
			}
		},
		visibleToggleActivityStatusBtn() {
			return this.profilePersisted && (this.$route.name === RouteNames.OFFICE_EMPLOYEE_GENERAL || this.$route.name ===
				RouteNames.OFFICE_EMPLOYEE_PROFILE);
		}
	},
	methods: {
		...mapActions({
			activateEmployee: actionTypes.activateEmployee,
			deactivateEmployee: actionTypes.deactivateEmployee
		}),
		async toggleActivityStatus() {
			if(this.isActive)
				await this.deactivateEmployee();
			else
				await this.activateEmployee();
		}
	},
	created() {
		this.initializeStore({ id: this.$route.params.employeeId });
	},
	components: {
		FrpToggleActivityBtn,
		FrpBtn,
		FrpTabs,
		FrpRouterView,
		FrpRadioCardGroup,
		FrpTextField,
		FrpFormItem,
		FrpFormCardBlockCol,
		FrpFormCardBlockRow,
		FrpFormCardBlock,
		FrpFormCardBlocks,
		FrpFormCard,
		FrpNestedContentLayout,
		FrpBottomSpace,
		FrpPageNotFound,
		FrpOfficeEmployeeLoader,
		FrpSubtitleFive
	}
};
</script>
