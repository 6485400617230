import {
	namespace,
	actionTypes,
	mutationTypes,
	getterTypes
} from "@/store/modules/account/modules/profiles/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import SortingMixinBuilder from "@/store/shared/sorting";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import ListingMixinBuilder from "@/store/shared/listing";
import ListingModel from "@/store/shared/listing/models/listingModel";
import PagingMixinBuilder from "@/store/shared/paging";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchMixinBuilder from "@/store/shared/search";
import SearchModel from "@/store/shared/search/models/searchModel";
import { sortingOrderType } from "@/store/shared/sorting/models/types/sortingOrderType";
import { resolveAction, resolveMutation, resolveNestedState } from "@/utils/vuexModules";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import storeManager from "@/store/manager/index";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import AccountProfilesState from "@/store/modules/account/modules/profiles/types/accountProfilesState";
import ApiAccountProfilePersistedBase from "@/api/types/account/apiAccountProfilePersistedBase";
import { convertIsoToNumber, formatDate } from "@/utils/dates";
import { dateTimeFormat } from "@/utils/formats";
import { AccountController } from "@/api/account";
import AbortService from "@/services/abortService";
import { LegalPersonController } from "@/api/counterparty/legalPerson";
import { LegalEntityController } from "@/api/counterparty/legalEntity";
import ApiEntrepreneurEmployeeProfile from "@/api/types/account/profile/apiEntrepreneurEmployeeProfile";
import ApiOfficeEmployeeProfile from "@/api/types/account/profile/apiOfficeEmployeeProfile";
import ApiLegalEntityEmployeeProfile from "@/api/types/account/profile/apiLegalEntityEmployeeProfile";
import ApiLegalEntityProfile from "@/api/types/account/profile/apiLegalEntityProfile";
import ApiEntrepreneurProfile from "@/api/types/account/profile/apiEntrepreneurProfile";
import router from "@/router";
import AccountState from "@/store/modules/account/types/accountState";
import { RouteNames } from "@/router/routes";

const abortService = new AbortService();
const accountController = new AccountController(abortService);
const legalPersonController = new LegalPersonController(abortService);
const legalEntityController = new LegalEntityController(abortService);

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new AccountProfilesState(
			new ListingModel<ApiAccountProfilePersistedBase>({
				items: [],
				isLoadingState: false
			}),
			new SortingModel<String>({
				type: "createdAt",
				order: sortingOrderType.descending
			}),
			new PagingModel({
				total: 0,
				page: 1,
				pageSize: 25
			}),
			new SearchModel({
				query: ""
			})
		)
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();
const baseMixin = (new BaseMixinBuilder(abortService)).build();
const listingMixin = (new ListingMixinBuilder()).build();
const pagingMixin = (new PagingMixinBuilder()).build();
const sortingMixin = (new SortingMixinBuilder()).build();
const searchMixin = (new SearchMixinBuilder()).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<AccountProfilesState, any>>{
	...listingMixin.getters,
	[getterTypes.formattedItems]: state => {
		return state.listing.items.map(x => {
			return {
				...x,
				id: x.id,
				title: x.profile.title,
				email: x.profile.email,
				phone: x.profile.phone,
				isActive: x.profile.isActive,
				createdAt: formatDate(convertIsoToNumber(x.createdAt), dateTimeFormat)
			};
		});
	}
};

const actions = <ActionTree<AccountProfilesState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...listingMixin.actions,
	...pagingMixin.actions,
	...sortingMixin.actions,
	...searchMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit }) {
		await dispatch(actionTypes.initializeBase);

		await dispatch(actionTypes.updateListingItems);

		commit(mutationTypes.SET_IS_INITIALIZED, true);
	},
	async [actionTypes.updateListingItems]({ rootState, commit }) {
		commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, true);

		let { id } = resolveNestedState<AccountState>(rootState, storeManager.account.namespace);

		try {
			let profiles: ApiAccountProfilePersistedBase[] = [];
			profiles = await accountController.getAccountProfilesById(id);

			commit(mutationTypes.SET_LISTING_ITEMS, profiles);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, false);
		}
	},
	async [actionTypes.handleSelectProfile]({ dispatch, state, commit }, { profile }) {
		commit(mutationTypes.SET_IS_SELECT_ITEM_HANDLING, true);

		try {
			if(profile instanceof ApiLegalEntityProfile) {
				const counterparty = await legalEntityController.getLegalEntityCounterpartyByIdentifiers(profile.inn,
					profile.ogrn,
					profile.kpp);

				if(counterparty)
					await router.push({ name: RouteNames.COUNTERPARTY_MASTER_ACCOUNT, params: { id: counterparty.id } });
			}

			if(profile instanceof ApiEntrepreneurProfile) {
				const counterparty = await legalPersonController.getLegalPersonByInnOgrn(profile.inn, profile.ogrnIp);

				if(counterparty)
					await router.push({ name: RouteNames.COUNTERPARTY_MASTER_ACCOUNT, params: { id: counterparty.id } });
			}

			if(profile instanceof ApiLegalEntityEmployeeProfile) {
				const counterparty = await legalEntityController.getLegalEntityCounterpartyByIdentifiers(profile.counterpartyInn,
					profile.counterpartyOgrn,
					profile.counterpartyKpp);

				if(counterparty) {
					await router.push({
						name: RouteNames.COUNTERPARTY_EMPLOYEE,
						params: { employeeId: profile.externalId, counterpartyId: counterparty.id }
					});
				}
			}

			if(profile instanceof ApiEntrepreneurEmployeeProfile) {
				const counterparty = await legalPersonController.getLegalPersonByInnOgrn(profile.employerInn, profile.employerOgrn);

				if(counterparty) {
					await router.push({
						name: RouteNames.COUNTERPARTY_EMPLOYEE,
						params: { employeeId: profile.externalId, counterpartyId: counterparty.id }
					});
				}
			}

			if(profile instanceof ApiOfficeEmployeeProfile) {
				await router.push({
					name: RouteNames.OFFICE_EMPLOYEE,
					params: { employeeId: profile.externalId, officeId: process.env.VUE_APP_HEAD_OFFICE_ID }
				});
			}
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_SELECT_ITEM_HANDLING, false);
		}
	}
};


const mutations = <MutationTree<AccountProfilesState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	...listingMixin.mutations,
	...pagingMixin.mutations,
	...sortingMixin.mutations,
	...searchMixin.mutations,
	[mutationTypes.SET_IS_SELECT_ITEM_HANDLING](state, value) {
		state.isSelectItemHandling = value;
	}
};

export {
	namespace, state, getters, actions, mutations
};

const accountProfilesModule = {
	namespace, state, getters, actions, mutations, namespaced: true
};

export default accountProfilesModule;
