import SubscribersManager from "@/store/manager/subscribersManager";
import {
	namespace,
	actionTypes,
	mutationTypes,
	getterTypes
} from "@/store/modules/applications/employeeJoin/list/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import ListingMixinBuilder from "@/store/shared/listing";
import ListingModel from "@/store/shared/listing/models/listingModel";
import SearchMixinBuilder from "@/store/shared/search";
import SearchModel from "@/store/shared/search/models/searchModel";
import { sortingOrderType } from "@/store/shared/sorting/models/types/sortingOrderType";
import { resolveAction, resolveMutation, resolveNestedState } from "@/utils/vuexModules";
import { ActionTree, GetterTree, MutationPayload, MutationTree } from "vuex";
import BatchService from "@/services/batchService";
import RouteMixinBuilder from "@/store/shared/route";
import routeTypes from "@/store/shared/route/types";
import router from "@/router";
import { Store } from "vuex";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import { formatDate } from "@/utils/dates";
import { dateTimeFormat } from "@/utils/formats";
import AbortService from "@/services/abortService";
import { RouteNames } from "@/router/routes";
import EmployeeJoinApplicationsRouteQuery from "@/store/modules/applications/employeeJoin/list/types/employeeJoinApplicationsRouteQuery";
import EmployeeJoinApplicationsRouteQueryService
	from "@/store/modules/applications/employeeJoin/list/services/employeeJoinApplicationsRouteQueryService";
import EmployeeJoinApplicationsState from "@/store/modules/applications/employeeJoin/list/types/employeeJoinApplicationsState";
import EmployeeJoinApplicationsFilter from "@/store/modules/applications/employeeJoin/list/types/employeeJoinApplicationsFilter";
import { EmployeeJoinApplicationController } from "@/api/employeeJoinApplication";
import { mapApiGetEmployeeJoinApplicationsParameters } from "@/store/modules/applications/employeeJoin/list/mapper";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import { formatFullName } from "@/utils/formatting";
import mapper from "@/store/modules/applications/employeeJoin/list/mapper";
import { namespace as userNamespace } from "@/store/modules/user";
import UserState from "@/store/modules/user/types/userState";
import ApiGetEmployeeJoinApplicationsCounterpartyIdentifiers
	from "@/api/types/employeeJoinApplication/apiGetEmployeeJoinApplicationsCounterpartyIdentifiers";
import { ProfileType } from "@/types/ProfileType";
import ApiLegalEntityProfile from "@/api/types/account/profile/apiLegalEntityProfile";
import { Permissions } from "@/constants/permissions";
import { AuthorizationScopeType } from "@/types/authorization/authorizationScopeType";
import PermissionsService from "@/services/permissionsService";

const abortService = new AbortService();
const employeeJoinApplicationController = new EmployeeJoinApplicationController(abortService);
const permissionsService = new PermissionsService();
const defaultRouteQuery = new EmployeeJoinApplicationsRouteQuery("", "");

const routeQueryService = new EmployeeJoinApplicationsRouteQueryService(defaultRouteQuery);

const updateListingBatchService = new BatchService(({ interval: 100 }));

const routeMixin = (new RouteMixinBuilder<EmployeeJoinApplicationsState>()).build();

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new EmployeeJoinApplicationsState(
			new ListingModel({
				items: [],
				isLoadingState: false
			}),
			new SortingModel<String>({
				type: "createdAt",
				order: sortingOrderType.descending
			}),
			new PagingModel({
				total: 0,
				page: 1,
				pageSize: 25
			}),
			new SearchModel({
				query: defaultRouteQuery.query
			}),
			new EmployeeJoinApplicationsFilter(
				[]
			),
			routeMixin.state()
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();
const baseMixin = (new BaseMixinBuilder(abortService)).build();
const listingMixin = (new ListingMixinBuilder()).build();
const searchMixin = (new SearchMixinBuilder()).build();

const state = (new DefaultStateBuilder()).build();

let subscribersManager: SubscribersManager<EmployeeJoinApplicationsState>;

const getters = <GetterTree<EmployeeJoinApplicationsState, any>>{
	...listingMixin.getters,
	[getterTypes.formattedItems]: state => {
		return state.listing.items.map(x => {
			return {
				id: x.id,
				status: x.status,
				fullName: formatFullName(x.employee),
				email: x.employee.email,
				position: x.employee.position,
				createdAt: formatDate(new Date(x.createdAt), dateTimeFormat)
			};
		});
	}
};

let unsubscribeCallback = () => {
};
let store: Store<{}>;

const initializeSubscribersManager = (value: Store<{}>) => {
	store = value;
	subscribersManager = new SubscribersManager<EmployeeJoinApplicationsState>(store);
};

const subscribe = async (mutation: MutationPayload, rootState: any) => {
	let state = resolveNestedState<EmployeeJoinApplicationsState>(rootState, namespace);
	switch (mutation.type) {
		case resolveMutation(routeTypes.namespace, routeTypes.mutationTypes.ROUTE_CHANGED):
			if((mutation.payload.from.name === mutation.payload.to.name) && !state.route.isPushing)
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.processRouteQuery));
			break;
		case resolveMutation(namespace, mutationTypes.SET_SEARCH_QUERY):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_STATUS):
		{
			if(!state.route.isProcessing)
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.pushRoute));

			updateListingBatchService.push(async () => {
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.updateListingItems));
			});

			break;
		}
	}
};

const actions = <ActionTree<EmployeeJoinApplicationsState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...listingMixin.actions,
	...searchMixin.actions,
	...routeMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit, rootState, state }) {
		await dispatch(actionTypes.initializeBase);

		const { user: { profile } } = resolveNestedState<UserState>(rootState,
			userNamespace);

		if(profile) {
			commit(mutationTypes.SET_COUNTERPARTY_IDENTIFIERS,
				mapper.map(profile, ApiLegalEntityProfile, ApiGetEmployeeJoinApplicationsCounterpartyIdentifiers));
		}

		await dispatch(actionTypes.processRouteQuery);
		await dispatch(actionTypes.reconstituteRoute);
		await dispatch(actionTypes.updateListingItems);

		unsubscribeCallback = subscribersManager.subscribe(subscribe);

		commit(mutationTypes.SET_IS_INITIALIZED, true);
	},
	async [actionTypes.updateListingItems]({ commit, state }) {
		commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, true);

		try {
			const scope = await permissionsService.check([Permissions.OWN_EMPLOYEE_JOIN_APPLICATIONS_READ])
				? AuthorizationScopeType.OWN
				: AuthorizationScopeType.GLOBAL;

			let { requests } = await employeeJoinApplicationController.getEmployeeJoinApplications(
				mapApiGetEmployeeJoinApplicationsParameters(state),
				scope);

			commit(mutationTypes.SET_LISTING_ITEMS, requests);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, false);
		}
	},
	async [actionTypes.processRouteQuery]({ rootState, commit }) {
		commit(mutationTypes.SET_IS_ROUTE_PROCESSING, true);

		let routeQuery = await routeQueryService.resolveRouteQuery(rootState.route.query);

		commit(mutationTypes.SET_SEARCH_QUERY, routeQuery.query);
		commit(mutationTypes.SET_FILTER_STATUS, routeQuery.filterStatus);

		commit(mutationTypes.SET_IS_ROUTE_PROCESSING, false);
	},
	async [actionTypes.pushRoute]({ state, commit }) {
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, true);

		await router.push({
			name: RouteNames.APPLICATIONS_EMPLOYEE_JOIN_LIST,
			query: routeQueryService.resolveRouteQueryDictionary(state)
		})
					.catch(() => {
					});

		commit(mutationTypes.SET_IS_ROUTE_PUSHING, false);
	},
	async [actionTypes.reconstituteRoute]({ state, commit }) {
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, true);

		await router.replace({
			name: RouteNames.APPLICATIONS_EMPLOYEE_JOIN_LIST,
			query: routeQueryService.resolveRouteQueryDictionary(state)
		})
					.catch(() => {
					});

		commit(mutationTypes.SET_IS_ROUTE_PUSHING, false);
	},
	async [actionTypes.destroy]({ dispatch }) {
		unsubscribeCallback();
		await dispatch(actionTypes.destroyBase);
	}
};

const mutations = <MutationTree<EmployeeJoinApplicationsState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	...listingMixin.mutations,
	...searchMixin.mutations,
	...routeMixin.mutations,
	[mutationTypes.SET_FILTER_STATUS](state, value) {
		state.filter.status = value;
	},
	[mutationTypes.SET_COUNTERPARTY_IDENTIFIERS](state, value) {
		state.counterpartyIdentifiers = value;
	}
};

export {
	namespace, state, getters, actions, mutations, initializeSubscribersManager
};

const employeeJoinApplicationsModule = {
	namespace, state, getters, actions, mutations, initializeSubscribersManager, namespaced: true
};

export default employeeJoinApplicationsModule;
