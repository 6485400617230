<template>
	<v-container v-if="isInitialized && items.length > 1"
				 class="full-height px-16"
				 fluid>
		<v-row justify="center">
			<v-col cols="auto" class="my-6">
				<span class="text-h6 blue--text text--darken-4">
					{{ $t("content.chooseModuleToContinue") }}
				</span>
			</v-col>
		</v-row>
		<v-row>
			<template v-for="(item, i) in sortedElements">
				<template v-if="item.key && item.items">
					<v-col :key="'group-item' + i"
						   :cols="12"
						   :sm="4"
						   :md="4"
						   :lg="2"
						   :xl="2">
						<frp-tiles-link-group-btn :title="item.key" :items="item.items">
						</frp-tiles-link-group-btn>
					</v-col>
				</template>
				<template v-else>
					<v-col :cols="12"
						   :sm="4"
						   :md="4"
						   :lg="2"
						   :xl="2"
						   :key="'item' + i">
						<frp-tile-link-btn
							:title="item.name"
							:src="item.url">
						</frp-tile-link-btn>
					</v-col>
				</template>

			</template>
		</v-row>
	</v-container>
	<frp-apps-loader v-else/>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import FrpTileLinkBtn from "@/components/buttons/FrpTileLinkBtn.vue";
import FrpIcon from "@/components/icon/FrpIcon.vue";
import authorizationMixin from "@/mixins/authorizationMixin";
import colorsMixin from "@/mixins/colorsMixin";
import storeModuleBasedPage from "@/mixins/storeModuleBasedPage";
import { RouteNames } from "@/router/routes";
import { namespace } from "@/store/modules/apps";
import FrpAppsLoader from "@/views/apps/FrpAppsLoader.vue";
import { groupBy, chain, max, first } from "lodash";
import { createNamespacedHelpers } from "vuex";
import FrpTilesLinkGroupBtn from "@/components/buttons/FrpTilesLinkGroupBtn.vue";

const { mapState, mapMutations, mapActions } = createNamespacedHelpers(namespace);

export default {
	mixins: [colorsMixin, storeModuleBasedPage, authorizationMixin],
	data() {
		return {
			namespace,
			RouteNames,
			first,
			max
		};
	},
	computed: {
		...mapState({
			isInitialized: state => state.isInitialized,
			items: state => state.items
		}),
		groups() {
			const emptyGroupKey = "null";

			let singled = this.items.filter(x => !x.group);

			const grouped = chain(this.items).groupBy(x => x.group).map((value, key) => {
				if(key === emptyGroupKey) return;

				if(value.length > 1) {
					return {
						key: key,
						items: this.sort(value, "displayOrder"),
						displayOrder: max(value.map(x => x.displayOrder))
					};
				} else {
					singled.push(first(value));
				}
			}).filter(x => x).value();

			return [...singled, ...grouped];
		},
		sortedElements() {
			return this.sort(this.groups, "displayOrder");
		}
	},
	methods: {
		sort(elements, sortValue) {
			return elements.sort((a, b) => a[sortValue] - b[sortValue]);
		}
	},
	created() {
		this.initializeStore();
	},
	components: { FrpTilesLinkGroupBtn, FrpAppsLoader, FrpTileLinkBtn, FrpBtn, FrpIcon }
};
</script>
