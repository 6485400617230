<template>
	<v-form v-if="isInitialized" :readonly="isFormSaving || isFormReadonly" :disabled="isFormDisabled" :ref="refs.form"
			v-model.trim="formValid">
		<frp-form-card>
			<frp-form-card-blocks>
				<frp-form-card-block :title="$t('titles.general')">
					<frp-form-card-block-row>
						<frp-form-card-block-col cols="12" sm="6" md="4" :class="{ 'pb-0': $vuetify.breakpoint.xsOnly }">
							<frp-form-item>
								<frp-text-field :label="$t('fields.fullName.label')"
												:loading="isRecordUniqueCheckInProgress"
												required
												autocomplete="off"
												:errors="longNameErrors"
												@change="handleLongNameChange"
												v-model="longName"
												:rules="validation.longName"
												:disabled="!hasEditPermissions"
												:placeholder="$t('fields.fullName.placeholder')">
								</frp-text-field>
							</frp-form-item>
							<frp-form-item>
								<frp-text-field :label="$t('fields.shortName.label')"
												required
												name="organization"
												autocomplete="organization"
												v-model="shortName"
												:rules="validation.shortName"
												:disabled="!hasEditPermissions"
												:placeholder="$t('fields.shortName.placeholder')">
								</frp-text-field>
							</frp-form-item>
						</frp-form-card-block-col>
						<frp-form-card-block-col offset-md="2" cols="12" sm="6" md="4" :class="{ 'pt-0': $vuetify.breakpoint.xsOnly }">
							<frp-form-item>
								<frp-country-autocomplete
									:exclude-countries="['RU']"
									required
									v-model.trim="countryCode"
									:rules="validation.countryCode"
									:label="$t('fields.registerCountry.label')"
									:disabled="!hasEditPermissions"
									:placeholder="$t('fields.registerCountry.placeholder')"/>
							</frp-form-item>
							<frp-form-item>
								<frp-text-field :label="$t('fields.legalAddress.label')"
												required
												name="address"
												autocomplete="address"
												v-model="legalAddress"
												:rules="validation.legalAddress"
												:disabled="!hasEditPermissions"
												:placeholder="$t('fields.legalAddress.placeholder')"></frp-text-field>
							</frp-form-item>
						</frp-form-card-block-col>
					</frp-form-card-block-row>
				</frp-form-card-block>
			</frp-form-card-blocks>

			<frp-form-card-blocks v-if="!isCounterpartyEmployee">
				<frp-form-card-block :title="$t('titles.characteristics')">
					<frp-form-card-block-row>
						<frp-form-card-block-col cols="12" sm="6" md="4" :class="{ 'pb-0': $vuetify.breakpoint.xsOnly }">
							<frp-form-item>
								<frp-switch :label="$t('fields.switch.frp.label')"
											class="mb-6"
											:disabled="!hasEditPermissions"
											v-model="isRfrp">
								</frp-switch>
							</frp-form-item>
							<frp-form-item>
								<frp-switch :label="$t('fields.switch.leasingCompany.label')"
											class="mb-6"
											:disabled="!hasEditPermissions"
											v-model="isLeasingCompany">
								</frp-switch>
							</frp-form-item>
							<frp-form-item>
								<frp-switch :label="$t('fields.switch.collateralExpertOrganisation.label')"
											class="mb-6"
											:disabled="!hasEditPermissions"
											v-model="isPledgeExpertCompany">
								</frp-switch>
							</frp-form-item>
							<frp-form-item>
								<frp-switch :label="$t('fields.switch.vkmExpertCompany.label')"
											class="mb-6"
											v-model="isVkmExpertCompany"
											:disabled="!hasEditPermissions">
								</frp-switch>
							</frp-form-item>
							<frp-form-item>
								<frp-switch :label="$t('fields.switch.frpAssigneeCompany.label')"
											class="mb-6"
											v-model="isFrpAssigneeCompany"
											:disabled="!hasEditPermissions">
								</frp-switch>
							</frp-form-item>
						</frp-form-card-block-col>
					</frp-form-card-block-row>
				</frp-form-card-block>
			</frp-form-card-blocks>

			<frp-form-actions :is-save-disabled="!stateContainsUnsavedChanges || !isFormValid"
							  v-if="hasEditPermissions"
							  :is-cancel-disabled="!stateContainsUnsavedChanges"
							  :is-loading-state="isFormSaving || isFormLoadingState"
							  @cancel="onFormCancel"
							  @submit="submit">
			</frp-form-actions>
		</frp-form-card>

		<frp-dialog v-model="isCounterpartySuccessCreatedDialogOpened" persistent>
			<template #content>
				<v-row>
					<v-col align="center">
						<v-img width="122" height="122" :src="successCreatedImgSrc" alt="success created"></v-img>
					</v-col>
				</v-row>
				<v-row>
					<v-col align="center">
						<span class="text-h6 primary--text text--darken-1 mb-5">{{ $t("alerts.info.organisationWasCreated") }}</span>
					</v-col>
				</v-row>
				<v-row>
					<v-col class="d-flex justify-center">
						<frp-message-box icon="ico_info">{{ $t("alerts.info.youCanCloseCurrentBrowserPage") }}</frp-message-box>
					</v-col>
				</v-row>
			</template>
		</frp-dialog>
	</v-form>
	<frp-foreign-organization-general-loader v-else></frp-foreign-organization-general-loader>
</template>

<script>
import { getterTypes as userGetterTypes } from "@/store/modules/user/types";
import FrpMessageBox from "@/components/common/FrpMessageBox.vue";
import FrpDialog from "@/components/dialogs/FrpDialog.vue";
import FrpCountryAutocomplete from "Components/fields/FrpCountryAutocomplete";
import FrpRadioCardGroup from "Components/fields/FrpRadioCardGroup";
import FrpTextField from "Components/fields/FrpTextField";
import FrpNestedContentLayout from "Components/layouts/FrpNestedContentLayout";
import FrpBottomSpace from "Components/layouts/FrpBottomSpace";
import FrpFormCard from "Components/markup/FrpFormCard";
import FrpTextCaption from "Components/typography/FrpTextCaption";
import FrpFormCardBlock from "Components/markup/FrpFormCardBlock";
import FrpFormCardBlockCol from "Components/markup/FrpFormCardBlockCol";
import FrpFormCardBlockRow from "Components/markup/FrpFormCardBlockRow";
import FrpFormCardBlocks from "Components/markup/FrpFormCardBlocks";
import FrpFormItem from "Components/markup/FrpFormItem";
import FrpFormActions from "Components/form/FrpFormActions";
import FrpSwitch from "Components/fields/FrpSwitch";
import { Permissions } from "Constants/permissions";
import authorizationMixin from "Mixins/authorizationMixin";
import colorsMixin from "Mixins/colorsMixin";
import formMixin from "Mixins/formMixin";
import storeModuleBasedPage from "Mixins/storeModuleBasedPage";
import storeManager from "@/store/manager/index";
import { RouteNames } from "Router/routes";
import { actionTypes, mutationTypes } from "Store/modules/counterparty/modules/foreignOrganizationCounterparty/types";
import { prepareMaxLengthRule } from "Utils/validation";
import FrpForeignOrganizationGeneralLoader from "Views/counterparty/sections/FrpForeignOrganizationGeneralLoader";
import { createNamespacedHelpers } from "vuex";
import { maxLength } from "Constants/validation";

const namespace = storeManager.counterparty.foreignOrganization.namespace;
const { mapState, mapMutations, mapActions } = createNamespacedHelpers(namespace);

const userHelpers = createNamespacedHelpers(storeManager.user.namespace);

export default {
	mixins: [storeModuleBasedPage, colorsMixin, formMixin, authorizationMixin],
	name: "frp-foreign-organization-general",
	data() {
		return {
			namespace,
			RouteNames,
			validation: {
				legalAddress: [prepareMaxLengthRule({ maxLength: maxLength.long })],
				shortName: [prepareMaxLengthRule({ maxLength: maxLength.long })],
				longName: [prepareMaxLengthRule({ maxLength: maxLength.long })],
				countryCode: []
			},
			successCreatedImgSrc: `${process.env.VUE_APP_BASE_URL}/img/images/ico_success.png`
		};
	},
	computed: {
		...mapState({
			counterparty: state => state.counterparty,
			isInitialized: state => state.isInitialized,
			isCounterpartySuccessCreatedDialogOpened: state => state.isCounterpartySuccessCreatedDialogOpened
		}),
		...userHelpers.mapGetters({
			isCounterpartyEmployee: userGetterTypes.isCounterpartyEmployee
		}),
		hasEditPermissions() {
			return this.isCreateMode ? this.can(Permissions.GLOBAL_COUNTERPARTY_CREATE) : this.can(Permissions.GLOBAL_COUNTERPARTY_UPDATE);
		},
		longNameErrors() {
			return this.isRecordUnique ? [] : [this.$t("alerts.errors.organisationAlreadyExists")];
		},
		longName: {
			get() {
				return this.counterparty.longName;
			},
			set(value) {
				this.setLongName(value);
			}
		},
		shortName: {
			get() {
				return this.counterparty.shortName;
			},
			set(value) {
				this.setShortName(value);
			}
		},
		countryCode: {
			get() {
				return this.counterparty.countryCode;
			},
			set(value) {
				this.setCountryCode(value);
			}
		},
		legalAddress: {
			get() {
				return this.counterparty.legalAddress;
			},
			set(value) {
				this.setLegalAddress(value);
			}
		},
		isRfrp: {
			get() {
				return this.counterparty.isRfrp;
			},
			set(value) {
				this.setIsRfrp(value);
			}
		},
		isLeasingCompany: {
			get() {
				return this.counterparty.isLeasingCompany;
			},
			set(value) {
				this.setIsLeasingCompany(value);
			}
		},
		isPledgeExpertCompany: {
			get() {
				return this.counterparty.isPledgeExpertCompany;
			},
			set(value) {
				this.setIsPledgeExpertCompany(value);
			}
		},
		isVkmExpertCompany: {
			get() {
				return this.counterparty.isVkmExpertCompany;
			},
			set(value) {
				this.setIsVkmExpertCompany(value);
			}
		},
		isFrpAssigneeCompany: {
			get() {
				return this.counterparty.isFrpAssigneeCompany;
			},
			set(value) {
				this.setIsFrpAssigneeCompany(value);
			}
		}
	},
	methods: {
		...mapActions({
			checkIsRecordUnique: actionTypes.checkIsRecordUnique,
			save: actionTypes.save
		}),
		...mapMutations({
			setLongName: mutationTypes.SET_COUNTERPARTY_LONG_NAME,
			setShortName: mutationTypes.SET_COUNTERPARTY_SHORT_NAME,
			setCountryCode: mutationTypes.SET_COUNTERPARTY_COUNTRY_CODE,
			setLegalAddress: mutationTypes.SET_COUNTERPARTY_LEGAL_ADDRESS,
			setIsRfrp: mutationTypes.SET_COUNTERPARTY_IS_RFRP,
			setIsLeasingCompany: mutationTypes.SET_COUNTERPARTY_IS_LEASING_COMPANY,
			setIsPledgeExpertCompany: mutationTypes.SET_COUNTERPARTY_IS_PLEDGE_EXPERT_COMPANY,
			setIsVkmExpertCompany: mutationTypes.SET_COUNTERPARTY_IS_VKM_EXPERT_COMPANY,
			setIsFrpAssigneeCompany: mutationTypes.SET_COUNTERPARTY_IS_FRP_ASSIGNEE_COMPANY,
		}),
		async handleLongNameChange() {
			if(this.isCreateMode)
				await this.checkIsRecordUnique();
		}
	},
	created() {
		this.initializeStore({ id: this.$route.params.id });
	},
	components: {
		FrpMessageBox,
		FrpDialog,
		FrpFormActions,
		FrpCountryAutocomplete,
		FrpRadioCardGroup,
		FrpTextField,
		FrpFormItem,
		FrpFormCardBlockCol,
		FrpFormCardBlockRow,
		FrpFormCardBlock,
		FrpFormCardBlocks,
		FrpFormCard,
		FrpNestedContentLayout,
		FrpBottomSpace,
		FrpForeignOrganizationGeneralLoader,
		FrpTextCaption,
		FrpSwitch
	}
};
</script>

<style scoped>

</style>
