<template>
	<frp-form-card-blocks v-if="can([Permissions.GLOBAL_COUNTERPARTY_HEAD_READ, Permissions.OWN_COUNTERPARTY_HEAD_READ])">
		<frp-form-card-block>
			<template #title>
				<frp-form-card-block-row>
					<frp-form-card-block-col cols="12" sm="6" md="4"
											 class="d-flex align-center justify-md-space-between mb-4">
						{{ $t("subheaders.head") }}
						<frp-btn without-padding
								 height="28"
								 small
								 @click="handleRemoveHead"
								 class="px-1 ml-auto"
								 color="secondary"
								 text
								 :disabled="!hasEditPermissions ||  isFnsUpdateEnabled">
							{{ $t("buttons.remove") }}
						</frp-btn>
					</frp-form-card-block-col>
				</frp-form-card-block-row>
			</template>

			<frp-form-card-block-row
				v-if="hasEditPermissions && snilsFileMeta.id && !validateSnils(head.snils)">
				<frp-form-card-block-col cols="12" sm="6" md="4">
					<frp-alert class="d-block mb-md-0"
							   :text="$t('alerts.errors.requiredHeadSnils')">
					</frp-alert>
				</frp-form-card-block-col>
			</frp-form-card-block-row>
			<frp-form-card-block-row>
				<frp-form-card-block-col cols="12" sm="6" md="4" :class="{ 'py-0': $vuetify.breakpoint.xsOnly }">
					<frp-form-item>
						<frp-text-field :label="$t('fields.headFullName.label')"
										name="name"
										autocomplete="name"
										required
										:disabled="!hasEditPermissions || isFnsUpdateEnabled"
										:rules="validation.headFullName"
										v-model="fullName"
										:placeholder="$t('fields.headFullName.placeholder')">
						</frp-text-field>
					</frp-form-item>
					<frp-form-item>
						<frp-text-field :label="$t('fields.headPosition.label')"
										name="organization-title"
										autocomplete="organization-title"
										required
										:rules="validation.headPosition"
										v-model="position"
										:disabled="!hasEditPermissions || isFnsUpdateEnabled"
										:placeholder="$t('fields.headPosition.placeholder')">
						</frp-text-field>
						<frp-form-item>
							<frp-textarea :label="$t('fields.comment.label')"
										  autocomplete="off"
										  :disabled="!hasEditPermissions"
										  :rules="validation.headDescription"
										  :placeholder="$t('fields.comment.placeholder')"
										  v-model="description"
										  outlined>
							</frp-textarea>
						</frp-form-item>
					</frp-form-item>
				</frp-form-card-block-col>
				<frp-form-card-block-col offset-md="2" cols="12" sm="6" md="4" :class="{ 'pt-0': $vuetify.breakpoint.xsOnly }">
					<frp-form-item>
						<frp-text-field :label="$t('fields.headInn.label')"
										name="inn"
										autocomplete="inn"
										required
										:mask="PERSON_INN_FORMAT"
										:rules="validation.headInn"
										:disabled="!hasEditPermissions || isFnsUpdateEnabled"
										v-model="inn"
										:placeholder="$t('fields.headInn.placeholder')">
						</frp-text-field>
					</frp-form-item>

					<frp-form-item>
						<frp-text-field :label="$t('fields.headSnils.label')"
										:disabled="!hasEditPermissions || !can(Permissions.GLOBAL_COUNTERPARTY_LEGAL_ENTITY_HEAD_SNILS_UPDATE)"
										autocomplete="off"
										:mask="SNILS_FORMAT"
										:rules="validation.headSnils"
										v-model="snils"
										:placeholder="$t('fields.headSnils.placeholderInput')">
						</frp-text-field>
					</frp-form-item>
					<frp-form-item
						v-if="can([Permissions.GLOBAL_COUNTERPARTY_LEGAL_ENTITY_HEAD_SNILS_FILE_READ, Permissions.OWN_COUNTERPARTY_LEGAL_ENTITY_HEAD_SNILS_FILE_READ])">
						<frp-embedded-snils-form :readonly="!headId"
												 :create-permissions="[Permissions.GLOBAL_COUNTERPARTY_LEGAL_ENTITY_HEAD_SNILS_FILE_CREATE, Permissions.OWN_COUNTERPARTY_LEGAL_ENTITY_HEAD_SNILS_FILE_CREATE]"
												 :update-permissions="[Permissions.GLOBAL_COUNTERPARTY_LEGAL_ENTITY_HEAD_SNILS_FILE_UPDATE, Permissions.OWN_COUNTERPARTY_LEGAL_ENTITY_HEAD_SNILS_FILE_UPDATE]"
												 :edit-mode-text="$t('content.uploadScanOfSnilsDocumentAnHeadOfOrganization')"
												 :readonly-text="$t('content.availableAfterSaving')"
												 class="mb-1"
												 :namespace="snilsNamespace">
						</frp-embedded-snils-form>
					</frp-form-item>
				</frp-form-card-block-col>
			</frp-form-card-block-row>
		</frp-form-card-block>
	</frp-form-card-blocks>
</template>

<script>
import FrpAlert from "Components/alerts/FrpAlert";
import FrpEmbeddedSnilsForm from "Components/embeddedForms/FrpEmbeddedSnilsForm";
import FrpFileField from "Components/fields/FrpFileField";
import FrpTextarea from "Components/fields/FrpTextarea";
import FrpBtn from "Components/buttons/FrpBtn";
import FrpDetailsItem from "Components/details/FrpDetailsItem";
import FrpCombobox from "Components/fields/FrpCombobox";
import FrpDateField from "Components/fields/FrpDateField";
import FrpFieldHelp from "Components/fields/FrpFieldHelp";
import FrpRadioCardGroup from "Components/fields/FrpRadioCardGroup";
import FrpTextField from "Components/fields/FrpTextField";
import FrpFormActions from "Components/form/FrpFormActions";
import FrpIcon from "Components/icon/FrpIcon";
import FrpNestedContentLayout from "Components/layouts/FrpNestedContentLayout";
import FrpBottomSpace from "Components/layouts/FrpBottomSpace";
import FrpFormCard from "Components/markup/FrpFormCard";
import FrpTextBodyTwo from "Components/typography/FrpTextBodyTwo";
import FrpTextCaption from "Components/typography/FrpTextCaption";
import FrpFormCardBlock from "Components/markup/FrpFormCardBlock";
import FrpFormCardBlockCol from "Components/markup/FrpFormCardBlockCol";
import FrpFormCardBlockRow from "Components/markup/FrpFormCardBlockRow";
import FrpFormCardBlocks from "Components/markup/FrpFormCardBlocks";
import FrpFormItem from "Components/markup/FrpFormItem";
import FrpSwitch from "Components/fields/FrpSwitch";
import FrpConfirmDialog from "Components/dialogs/FrpConfirmDialog";
import { Permissions } from "Constants/permissions";
import authorizationMixin from "Mixins/authorizationMixin";
import colorsMixin from "Mixins/colorsMixin";
import { masks } from "Constants/masks";
import { RouteNames } from "Router/routes";
import { checkSnilsOnlyChecksum } from "ru-validation-codes";
import storeManager from "Store/manager";
import legalEntityCounterpartyHeadEmbeddedSnilsFormModuleTypes
	from "Store/modules/counterparty/modules/legalEntityCounterparty/modules/legalEntityCounterpartyHead/modules/snils/types";
import {
	actionTypes,
	mutationTypes
} from "Store/modules/counterparty/modules/legalEntityCounterparty/modules/legalEntityCounterpartyHead/types";
import legalEntityCounterpartyTypes from "Store/modules/counterparty/modules/legalEntityCounterparty/types";
import { dateTimeFormat, KPP_FORMAT, LEGAL_ENTITY_INN_FORMAT, OGRN_FORMAT, PERSON_INN_FORMAT, SNILS_FORMAT } from "Utils/formats";
import {
	preparePersonMaskedInnValidationRule,
	prepareSnilsRule,
	prepareMaxLengthRule, prepareLegalEntityMaskedInnValidationRule, prepareMaskedOgrnValidationRule, prepareMaskedKppValidationRule
} from "Utils/validation";
import { mapInstanceActions, mapInstanceMutations, mapInstanceState } from "Utils/vuexMapInstanse";
import { resolveNestedNamespace } from "Utils/vuexModules";
import FrpLegalEntityGeneralLoader from "Views/counterparty/sections/FrpLegalEntityGeneralLoader";
import { createNamespacedHelpers } from "vuex";
import { maxLength } from "Constants/validation";

const legalEntityHelpers = createNamespacedHelpers(storeManager.counterparty.legalEntity.namespace);

export default {
	mixins: [colorsMixin, authorizationMixin],
	props: {
		id: {
			type: Number,
			required: true
		},
		isCreateMode: Boolean
	},
	data() {
		return {
			SNILS_FORMAT,
			PERSON_INN_FORMAT,
			RouteNames,
			masks,
			validation: {
				headInn: [preparePersonMaskedInnValidationRule()],
				headPosition: [prepareMaxLengthRule({ maxLength: maxLength.short })],
				headSnils: [prepareSnilsRule()],
				headFullName: [prepareMaxLengthRule({ maxLength: maxLength.short })],
				headDescription: [prepareMaxLengthRule({ maxLength: maxLength.long })]
			}
		};
	},
	computed: {
		...legalEntityHelpers.mapState({
			isFnsUpdateEnabled: state => state.fnsUpdate.isEnabled,
			heads: state => state.heads
		}),
		...mapInstanceState({
			isInitialized: state => state.isInitialized,
			headId: state => state.id,
			head: state => state.head
		}),
		...mapInstanceState({
			snilsFileMeta: state => state.snilsFileMeta
		}, ({ namespace }) => {
			return resolveNestedNamespace(namespace, legalEntityCounterpartyHeadEmbeddedSnilsFormModuleTypes.namespace);
		}),
		hasEditPermissions() {
			return this.isCreateMode ? this.can(Permissions.GLOBAL_COUNTERPARTY_CREATE) : this.can(Permissions.GLOBAL_COUNTERPARTY_UPDATE);
		},
		namespace() {
			return storeManager.counterparty.legalEntity.heads.resolveNamespace(this.id);
		},
		snilsNamespace() {
			return storeManager.counterparty.legalEntity.heads.snils.resolveNamespace(this.id);
		},
		position: {
			get() {
				return this.head.position;
			},
			set(value) {
				this.setHeadPosition(value);
			}
		},
		description: {
			get() {
				return this.head.description;
			},
			set(value) {
				this.setHeadDescription(value);
			}
		},
		fullName: {
			get() {
				return this.head.fullName;
			},
			set(value) {
				this.setHeadFullName(value);
			}
		},
		inn: {
			get() {
				return this.head.inn;
			},
			set(value) {
				this.setHeadInn(value);
			}
		},
		snils: {
			get() {
				return this.head.snils;
			},
			set(value) {
				this.setHeadSnils(value);
			}
		}
	},
	methods: {
		...legalEntityHelpers.mapActions({
			removeHead: legalEntityCounterpartyTypes.actionTypes.removeHead
		}),
		...mapInstanceActions({
			save: actionTypes.save
		}),
		...mapInstanceMutations({
			setHeadPosition: mutationTypes.SET_HEAD_POSITION,
			setHeadDescription: mutationTypes.SET_HEAD_DESCRIPTION,
			setHeadFullName: mutationTypes.SET_HEAD_FULL_NAME,
			setHeadInn: mutationTypes.SET_HEAD_INN,
			setHeadSnils: mutationTypes.SET_HEAD_SNILS
		}),
		handleRemoveHead() {
			this.removeHead(this.id);
		},
		validateSnils(v) {
			return checkSnilsOnlyChecksum(v);
		}
	},
	created() {
	},
	components: {
		FrpAlert,
		FrpIcon,
		FrpFileField,
		FrpTextarea,
		FrpFormActions,
		FrpFieldHelp,
		FrpDetailsItem,
		FrpCombobox,
		FrpBtn,
		FrpDateField,
		FrpRadioCardGroup,
		FrpTextField,
		FrpFormItem,
		FrpFormCardBlockCol,
		FrpFormCardBlockRow,
		FrpFormCardBlock,
		FrpFormCardBlocks,
		FrpFormCard,
		FrpNestedContentLayout,
		FrpBottomSpace,
		FrpTextCaption,
		FrpTextBodyTwo,
		FrpLegalEntityGeneralLoader,
		FrpSwitch,
		FrpConfirmDialog,
		FrpEmbeddedSnilsForm
	}
};
</script>

<style scoped>

</style>
