export default class PhysicalPersonCounterparty {
	inn: string;
	snils: string;
	firstName: string;
	lastName: string;
	middleName: string;
	description: string;
	isRfrp: boolean;
	isLeasingCompany: boolean;
	isPledgeExpertCompany: boolean;
	isVkmExpertCompany: boolean;
	isFrpAssigneeCompany: boolean;

	constructor(
		inn: string = "",
		snils: string = "",
		firstName: string = "",
		lastName: string = "",
		middleName: string = "",
		description: string = "",
		isRfrp: boolean = false,
		isLeasingCompany: boolean = false,
		isPledgeExpertCompany: boolean = false,
		isVkmExpertCompany: boolean = false,
		isFrpAssigneeCompany: boolean = false
	)
	{
		this.inn = inn;
		this.snils = snils;
		this.firstName = firstName;
		this.lastName = lastName;
		this.middleName = middleName;
		this.description = description;
		this.isRfrp = isRfrp;
		this.isLeasingCompany = isLeasingCompany;
		this.isPledgeExpertCompany = isPledgeExpertCompany;
		this.isVkmExpertCompany = isVkmExpertCompany;
		this.isFrpAssigneeCompany = isFrpAssigneeCompany;
	}
}
