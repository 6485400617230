import { Type } from "class-transformer";
import ApiLegalEntityCounterparty from "@/api/types/counterparty/apiLegalEntityCounterparty";
import ApiPhysicalPersonCounterparty from "@/api/types/counterparty/apiPhysicalPersonCounterparty";
import ApiForeignOrganizationCounterparty from "@/api/types/counterparty/apiForeignOrganizationCounterparty";
import ApiLegalPersonCounterparty from "@/api/types/counterparty/apiLegalPersonCounterparty";
import ApiCounterpartyBase from "@/api/types/counterparty/apiCounterpartyBase";
import { CounterpartyType } from "@/types/CounterpartyType";
import { ApiCounterpartyEmployeeBase } from "@/api/types/counterparty/apiCounterpartyEmployee";
import { ApiAccountProfileBase } from "@/api/types/account/profile/apiAccountProfileBase";

type Counterparty =
	ApiLegalEntityCounterparty
	| ApiPhysicalPersonCounterparty
	| ApiForeignOrganizationCounterparty
	| ApiLegalPersonCounterparty
	| ApiCounterpartyBase

export default class ApiProfileAssignment {
	id: string;
	createdAt: string;
	updatedAt: string;

	@Type(() => ApiAccountProfileBase)
	profile: ApiAccountProfileBase;

	@Type(() => ApiCounterpartyEmployeeBase)
	employee: ApiCounterpartyEmployeeBase;

	@Type(() => ApiCounterpartyBase, {
		keepDiscriminatorProperty: true,
		discriminator: {
			property: "type",
			subTypes: [
				{ value: ApiForeignOrganizationCounterparty, name: CounterpartyType.FOREIGN_ORGANIZATION },
				{ value: ApiLegalPersonCounterparty, name: CounterpartyType.LEGAL_PERSON },
				{ value: ApiPhysicalPersonCounterparty, name: CounterpartyType.PHYSICAL_PERSON },
				{ value: ApiLegalEntityCounterparty, name: CounterpartyType.LEGAL_ENTITY }
			]
		}
	})
	counterparty: Counterparty;

	constructor(
		id = "",
		createdAt = "",
		updatedAt = "",
		profile = new ApiAccountProfileBase(),
		employee = new ApiCounterpartyEmployeeBase(),
		counterparty = new ApiCounterpartyBase()
	)
	{
		this.id = id;
		this.createdAt = createdAt;
		this.updatedAt = updatedAt;
		this.profile = profile;
		this.employee = employee;
		this.counterparty = counterparty;
	}
}
