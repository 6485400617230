import ApiLegalEntityMasterAccountAccessApplicationCreate
	from "@/api/types/masterAccountAccessApplication/apiLegalEntityMasterAccountAccessApplicationCreate";
import ApiLegalPersonMasterAccountAccessApplicationCreate
	from "@/api/types/masterAccountAccessApplication/apiLegalPersonMasterAccountAccessApplicationCreate";
import storeManager from "@/store/manager/index";
import BaseStepModel from "@/store/modules/masterAccountAccess/models/baseStepModel";
import contactInformationStepModule from "@/store/modules/masterAccountAccess/modules/contactInformationStep/index";
import ContactInformationStepStateModel
	from "@/store/modules/masterAccountAccess/modules/contactInformationStep/models/contactInformationStepStateModel";
import contactInformationStepTypes from "@/store/modules/masterAccountAccess/modules/contactInformationStep/types";
import contactInformationStepStepTypes from "@/store/modules/masterAccountAccess/modules/contactInformationStep/types";
import documentsStepModule from "@/store/modules/masterAccountAccess/modules/documentsStep";
import DocumentsStepStateModel from "@/store/modules/masterAccountAccess/modules/documentsStep/models/documentsStepStateModel";
import documentsStepTypes from "@/store/modules/masterAccountAccess/modules/documentsStep/types";
import masterAccountAccessFinalStepModule from "@/store/modules/masterAccountAccess/modules/finalStep";
import MasterAccountAccessFinalStepStateModel
	from "@/store/modules/masterAccountAccess/modules/finalStep/models/masterAccountAccessFinalStepStateModel";
import masterAccountAccessFinalStepTypes from "@/store/modules/masterAccountAccess/modules/finalStep/types";
import selectCounterpartyStepModule from "@/store/modules/masterAccountAccess/modules/selectCounterpartyStep";
import SelectCounterpartyStepState
	from "@/store/modules/masterAccountAccess/modules/selectCounterpartyStep/types/selectCounterpartyStepState";
import selectCounterpartyStepTypes from "@/store/modules/masterAccountAccess/modules/selectCounterpartyStep/types";
import signApplicationStepModule from "@/store/modules/masterAccountAccess/modules/signApplicationStep";
import signApplicationStepTypes from "@/store/modules/masterAccountAccess/modules/signApplicationStep/types";
import SignApplicationStepState from "@/store/modules/masterAccountAccess/modules/signApplicationStep/types/signApplicationStepState";
import { actionTypes, getterTypes, mutationTypes, namespace } from "@/store/modules/masterAccountAccess/types";
import BaseMixinBuilder from "@/store/shared/base";
import BaseStepStateModel from "@/store/shared/baseStep/models/baseStepStateModel";
import { resolveGetter, resolveNestedState } from "@/utils/vuexModules";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import MasterAccountAccessState from "@/store/modules/masterAccountAccess/types/masterAccountAccessState";
import ApiSignedMasterAccountAccessApplication from "@/api/types/masterAccountAccessApplication/apiSignedMasterAccountAccessApplication";
import { CounterpartyType } from "@/types/CounterpartyType";
import { MasterAccountAccessApplicationController } from "@/api/masterAccountAccessApplication";
import AbortService from "@/services/abortService";
import ApiLegalEntityMasterAccountAccessFromEmployeeApplicationCreate
	from "@/api/types/masterAccountAccessApplication/apiLegalEntityMasterAccountAccessFromEmployeeApplicationCreate";
import ApiLegalPersonMasterAccountAccessFromEmployeeApplicationCreate
	from "@/api/types/masterAccountAccessApplication/apiLegalPersonMasterAccountAccessFromEmployeeApplicationCreate";
import ApiLegalEntity from "@/api/types/legalEntity/apiLegalEntity";
import ApiFnsLegalPerson from "@/api/types/fns/apiFnsLegalPerson";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import { LegalEntityController } from "@/api/counterparty/legalEntity";
import { LegalPersonController } from "@/api/counterparty/legalPerson";
import ApiLegalEntityIdentifiers from "@/api/types/legalEntity/apiLegalEntityIdentifiers";
import ApiLegalPersonIdentifiers from "@/api/types/counterparty/apiLegalPersonIdentifiers";
import { MasterAccountApplicationMethodType } from "@/store/modules/masterAccountAccess/types/MasterAccountApplicationMethodType";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";

const abortService = new AbortService();
const masterAccountAccessApplicationController = new MasterAccountAccessApplicationController(abortService);
const legalEntityController = new LegalEntityController(abortService);
const legalPersonController = new LegalPersonController(abortService);

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new MasterAccountAccessState(1, false);
	}
}


const baseMixin = (new BaseMixinBuilder(abortService)).build();

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<MasterAccountAccessState, any>>{
	[getterTypes.steps]: state => {
		return Object.keys(state).filter(x => {
			// @ts-ignore
			let value: any = state[x];
			return value instanceof BaseStepModel || value instanceof BaseStepStateModel;
		}).map(x => {
			// @ts-ignore
			return state[x];
		}).sort((a, b) => a.index - b.index);
	},
	[getterTypes.availableIndexedSteps]: (state, getters) => {
		return getters.orderedAvailableSteps.map((x: BaseStepModel, i: number) => {
			return {
				title: x.title,
				component: x.component,
				index: i + 1
			};
		});
	},
	[getterTypes.orderedAvailableSteps]: (state, getters) => {
		return getters.availableSteps.sort((a: BaseStepModel, b: BaseStepModel) => a.order - b.order);
	},
	[getterTypes.availableSteps]: (state, getters) => {
		return getters.steps;
	},
	[getterTypes.isFinalStep]: (state, getters) => {
		return getters.currentStep instanceof MasterAccountAccessFinalStepStateModel;
	},
	[getterTypes.isNextStepEnabled]: (state, getters) => {
		let currentStep = getters.orderedAvailableSteps[state.currentStepIndex - 1];

		switch (currentStep.constructor) {
			case SelectCounterpartyStepState:
			{
				return getters[resolveGetter(selectCounterpartyStepTypes.namespace,
					selectCounterpartyStepTypes.getterTypes.isStepCompleted)];
			}
			case ContactInformationStepStateModel:
			{
				return getters[resolveGetter(contactInformationStepStepTypes.namespace,
					contactInformationStepStepTypes.getterTypes.isStepCompleted)];
			}
			case SignApplicationStepState:
			{
				return getters[resolveGetter(signApplicationStepTypes.namespace,
					signApplicationStepTypes.getterTypes.isStepCompleted)];
			}
			case DocumentsStepStateModel:
			{
				return getters[resolveGetter(documentsStepTypes.namespace, documentsStepTypes.getterTypes.isStepCompleted)];
			}
			case MasterAccountAccessFinalStepStateModel:
			{
				return getters[resolveGetter(masterAccountAccessFinalStepTypes.namespace,
					masterAccountAccessFinalStepTypes.getterTypes.isStepCompleted)];
			}
			default:
				throw new Error();
		}
	},
	[getterTypes.currentStep]: (state, getters) => {
		return getters.orderedAvailableSteps[state.currentStepIndex - 1];
	},
	[getterTypes.isPrevStepEnabled]: state => {
		return state.currentStepIndex !== 1;
	},
	[getterTypes.isSelectCounterpartyStep]: state => {
		return state.currentStepIndex === 1;
	}
};

const actions = <ActionTree<MasterAccountAccessState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	[actionTypes.moveToFinalStep]({ state, getters, commit }) {
		commit(mutationTypes.SET_CURRENT_STEP_INDEX, getters.availableSteps.length);
	},
	[actionTypes.moveToDocumentsStep]({ state, getters, commit }) {
		const orderedAvailableSteps: any[] = getters.orderedAvailableSteps;

		let index = orderedAvailableSteps.findIndex(x => x instanceof DocumentsStepStateModel);

		commit(mutationTypes.SET_CURRENT_STEP_INDEX, index + 1);
	},
	async [actionTypes.handleNextStep]({ commit, dispatch, state, getters, rootState }) {
		let currentStep = getters.orderedAvailableSteps[state.currentStepIndex - 1];

		switch (currentStep.constructor) {
			case ContactInformationStepStateModel:
			{
				const { isMasterAccountAlreadyExists } = resolveNestedState<SelectCounterpartyStepState>(rootState,
					storeManager.masterAccountAccess.selectCounterpartyStep.namespace);

				if(isMasterAccountAlreadyExists) {
					let result = await dispatch(actionTypes.tryCreateApplicationByEmployeeEmail);

					if(result) {
						commit(mutationTypes.SET_APPLICATION_METHOD, MasterAccountApplicationMethodType.BY_EMPLOYEE);
						return await dispatch(actionTypes.moveToFinalStep);
					}
				}

				return await dispatch(actionTypes.moveToNextStep);
			}
			case SignApplicationStepState:
			{
				const { counterpartyType } = resolveNestedState<SelectCounterpartyStepState>(rootState,
					storeManager.masterAccountAccess.selectCounterpartyStep.namespace);

				if(counterpartyType === CounterpartyType.LEGAL_ENTITY) {
					let result = await dispatch(actionTypes.tryCreateSignedApplication);

					if(result) {
						commit(mutationTypes.SET_APPLICATION_METHOD, MasterAccountApplicationMethodType.BY_SIGNATURE);
						return await dispatch(actionTypes.moveToFinalStep);
					} else {
						return;
					}
				} else {
					return dispatch(actionTypes.moveToNextStep);
				}
			}
			case DocumentsStepStateModel:
			{
				let result = await dispatch(actionTypes.tryCreateApplicationByDocuments);

				if(result) {
					commit(mutationTypes.SET_APPLICATION_METHOD, MasterAccountApplicationMethodType.BY_DOCUMENTS);
					return await dispatch(actionTypes.moveToFinalStep);
				} else {
					return;
				}
			}
			default:
				return await dispatch(actionTypes.moveToNextStep);
		}
	},
	[actionTypes.moveToNextStep]({ commit, state }) {
		commit(mutationTypes.SET_CURRENT_STEP_INDEX, state.currentStepIndex + 1);
	},
	async [actionTypes.tryCreateSignedApplication]({ commit, rootState }) {
		commit(mutationTypes.SET_IS_CREATE_MASTER_ACCOUNT_REQUEST_OPERATION_EXECUTING, true);

		let result = false;

		try {
			const signApplicationStepState = resolveNestedState<SignApplicationStepState>(rootState,
				storeManager.masterAccountAccess.signApplicationStep.namespace);

			await masterAccountAccessApplicationController.submitSignedLegalEntityAccessApplication(new ApiSignedMasterAccountAccessApplication(
				signApplicationStepState.encodedApplicationString,
				signApplicationStepState.encodedDetachedSignatureString
			));

			result = true;
		} catch (e) {
			console.error(e);
			AlertHelper.handleGeneralRequestErrors(e);
		} finally {
			commit(mutationTypes.SET_IS_CREATE_MASTER_ACCOUNT_REQUEST_OPERATION_EXECUTING, false);
		}

		return result;
	},
	async [actionTypes.tryCreateApplicationByDocuments]({ commit, rootState }) {
		commit(mutationTypes.SET_IS_CREATE_MASTER_ACCOUNT_REQUEST_OPERATION_EXECUTING, true);

		let result = false;

		try {
			const { documents } = resolveNestedState<DocumentsStepStateModel>(rootState,
				storeManager.masterAccountAccess.documentsStep.namespace);
			const { email } = resolveNestedState<ContactInformationStepStateModel>(rootState,
				storeManager.masterAccountAccess.contactInformationStep.namespace);
			const { counterparty, counterpartyType } = resolveNestedState<SelectCounterpartyStepState>(rootState,
				storeManager.masterAccountAccess.selectCounterpartyStep.namespace);

			if(counterpartyType === CounterpartyType.LEGAL_ENTITY) {
				await masterAccountAccessApplicationController.submitLegalEntityMasterAccountAccessApplication(new ApiLegalEntityMasterAccountAccessApplicationCreate(
					email,
					counterparty as ApiLegalEntity,
					Object.values(documents).filter(x => Boolean(x))
				));
			} else {
				await masterAccountAccessApplicationController.submitLegalPersonMasterAccountAccessApplication(new ApiLegalPersonMasterAccountAccessApplicationCreate(
					email,
					counterparty as ApiFnsLegalPerson,
					Object.values(documents).filter(x => Boolean(x))
				));
			}

			result = true;
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_CREATE_MASTER_ACCOUNT_REQUEST_OPERATION_EXECUTING, false);
		}

		return result;
	},
	async [actionTypes.tryCreateApplicationByEmployeeEmail]({ commit, rootState }) {
		commit(mutationTypes.SET_IS_CREATE_MASTER_ACCOUNT_REQUEST_OPERATION_EXECUTING, true);

		let result = false;

		try {
			const { email } = resolveNestedState<ContactInformationStepStateModel>(rootState,
				storeManager.masterAccountAccess.contactInformationStep.namespace);
			const { counterparty, counterpartyType } = resolveNestedState<SelectCounterpartyStepState>(rootState,
				storeManager.masterAccountAccess.selectCounterpartyStep.namespace);

			if(counterpartyType === CounterpartyType.LEGAL_ENTITY) {
				const legalEntity = counterparty as ApiLegalEntity;
				const identifiers = new ApiLegalEntityIdentifiers(legalEntity.inn, legalEntity.ogrn, legalEntity.kpp);
				let employeeExists = await legalEntityController.checkEmployeeExists(identifiers, email);

				if(employeeExists) {
					await masterAccountAccessApplicationController.submitLegalEntityAccessFromEmployeeApplication(
						new ApiLegalEntityMasterAccountAccessFromEmployeeApplicationCreate(email, counterparty as ApiLegalEntity)
					);

					result = true;
				}
			} else {
				const legalPerson = counterparty as ApiFnsLegalPerson;
				const identifiers = new ApiLegalPersonIdentifiers(legalPerson.inn, legalPerson.ogrn);
				let employeeExists = await legalPersonController.checkEmployeeExists(identifiers, email);

				if(employeeExists) {
					await masterAccountAccessApplicationController.submitLegalPersonAccessFromEmployeeApplication(
						new ApiLegalPersonMasterAccountAccessFromEmployeeApplicationCreate(
							email, counterparty as ApiFnsLegalPerson
						)
					);

					result = true;
				}
			}
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_CREATE_MASTER_ACCOUNT_REQUEST_OPERATION_EXECUTING, false);
		}

		return result;
	}
};

const mutations = <MutationTree<MasterAccountAccessState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	[mutationTypes.SET_CURRENT_STEP_INDEX](state, value) {
		state.currentStepIndex = value;
	},
	[mutationTypes.SET_IS_CREATE_MASTER_ACCOUNT_REQUEST_OPERATION_EXECUTING](state, value) {
		state.isCreateMasterAccountRequestOperationExecuting = value;
	},
	[mutationTypes.SET_APPLICATION_METHOD](state, value) {
		state.applicationMethod = value;
	}
};

const modules = {
	[selectCounterpartyStepTypes.namespace]: {
		...selectCounterpartyStepModule
	},
	[contactInformationStepTypes.namespace]: {
		...contactInformationStepModule
	},
	[documentsStepTypes.namespace]: {
		...documentsStepModule
	},

	[signApplicationStepTypes.namespace]: {
		...signApplicationStepModule
	},
	[masterAccountAccessFinalStepTypes.namespace]: {
		...masterAccountAccessFinalStepModule
	}
};

export {
	namespace, state, getters, actions, mutations, modules
};

const masterAccountAccessModule = {
	namespace, state, getters, actions, mutations, namespaced: true, modules
};

export default masterAccountAccessModule;
