<template>
	<v-skeleton-loader height="14" max-width="180" type="text" class="mb-4"/>
</template>

<script>
export default {
	name: "FrpDetailsSubheaderLoader"
}
</script>

<style scoped>

</style>
