import EmbeddedForm from "@/store/shared/embeddedForm/types/embeddedForm";
import FileMeta from "@/store/shared/storage/types/fileMeta";

export default class EmbeddedSnilsFormState {
	id: string;
	form: EmbeddedForm;
	lastSavedMeta: FileMeta;
	ownerId: string;
	snilsFileMeta: FileMeta;

	constructor(
		id: string,
		form: EmbeddedForm,
		lastSavedMeta: FileMeta = new FileMeta(),
		ownerId: string = "",
		snilsFileMeta: FileMeta = new FileMeta()
	)
	{
		this.id = id;
		this.form = form;
		this.lastSavedMeta = lastSavedMeta;
		this.ownerId = ownerId;
		this.snilsFileMeta = snilsFileMeta;
	}
}
