import { Type } from "class-transformer";
import { ApiCounterpartyUnassignedEmployee } from "@/api/types/counterparty/apiCounterpartyUnassignedEmployee";
import ApiLegalEntityIdentifiers from "@/api/types/legalEntity/apiLegalEntityIdentifiers";
import ApiLegalPersonIdentifiers from "@/api/types/counterparty/apiLegalPersonIdentifiers";
import ApiForeignOrganizationIdentifiers from "@/api/types/counterparty/apiForeignOrganizationIdentifiers";
import { CounterpartyType } from "@/types/CounterpartyType";
import { ProfileType } from "@/types/ProfileType";

abstract class CounterpartyIdentifiersBase {
}

type CounterpartyIdentifiers =
	ApiLegalEntityIdentifiers
	| ApiLegalPersonIdentifiers
	| ApiForeignOrganizationIdentifiers;

export default class ApiEmployeeJoinApplicationPersisted {
	id: string;
	createdAt: string;
	updatedAt: string;
	status: string;
	type: string;

	@Type(() => CounterpartyIdentifiersBase, {
		keepDiscriminatorProperty: true,
		discriminator: {
			property: "type",
			subTypes: [
				{ value: ApiLegalEntityIdentifiers, name: ProfileType.MASTER_ACCOUNT_LEGAL_ENTITY },
				{ value: ApiLegalPersonIdentifiers, name: ProfileType.MASTER_ACCOUNT_ENTREPRENEUR },
				{ value: ApiForeignOrganizationIdentifiers, name: ProfileType.MASTER_ACCOUNT_FOREIGN_ORGANIZATION }
			]
		}
	})
	counterpartyIdentifiers: CounterpartyIdentifiers | null;

	@Type(() => ApiCounterpartyUnassignedEmployee)
	employee: ApiCounterpartyUnassignedEmployee;

	constructor(
		id: string = "",
		createdAt: string = "",
		updatedAt: string = "",
		status: string = "",
		type: string = "",
		counterpartyIdentifiers: CounterpartyIdentifiers | null = null,
		employee: ApiCounterpartyUnassignedEmployee = new ApiCounterpartyUnassignedEmployee()
	)
	{
		this.id = id;
		this.createdAt = createdAt;
		this.updatedAt = updatedAt;
		this.status = status;
		this.type = type;
		this.counterpartyIdentifiers = counterpartyIdentifiers;
		this.employee = employee;
	}
}
