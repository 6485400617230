import { IsArray } from "class-validator";

export default class OfficeEmployeesFilter {
	@IsArray()
	roles: String[]

	constructor(roles: String[]) {
		this.roles = roles;
	}
}
