<template>
	<v-col class="pa-0 pr-8" cols="12" sm="6" md="4">
		<slot></slot>
	</v-col>
</template>

<script>
export default {
};
</script>
