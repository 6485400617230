import ListingModel from "@/store/shared/listing/models/listingModel";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchModel from "@/store/shared/search/models/searchModel";
import MasterAccountAccessApplicationsFilter
	from "@/store/modules/applications/masterAccountAccess/list/types/masterAccountAccessApplicationsFilter";
import RouteState from "@/store/shared/route/types/routeState";
import ApiMasterAccountAccessApplication from "@/api/types/masterAccountAccessApplication/apiMasterAccountAccessApplication";
import IPageState from "@/store/shared/base/types/pageState";

export default class MasterAccountAccessApplicationsState implements IPageState {
	constructor(
		public listing: ListingModel<ApiMasterAccountAccessApplication>,
		public sorting: SortingModel<String>,
		public paging: PagingModel,
		public search: SearchModel,
		public filter: MasterAccountAccessApplicationsFilter,
		public route: RouteState,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false
	)
	{
	}
}
