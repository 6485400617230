<template>
	<div>
		<v-skeleton-loader height="16" max-width="200" type="image" class="mb-2 mt-4 rounded-xl"/>
	</div>
</template>

<script>
export default {
};
</script>

<style scoped>

</style>
