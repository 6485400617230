<template>
	<div>
		<v-subheader v-if="label" class="pa-0 mb-1 grey--text text-caption" style="height: fit-content">
			<span>{{ label }}</span>
			<span v-if="required">*</span>
		</v-subheader>
		<v-file-input outlined
					  :accept="isArray(accept) ? accept.join(', ') : accept"
					  :hint="hint"
					  :placeholder="placeholder"
					  :loading="meta.isLoading"
					  :clearable="clearable"
					  ref="input"
					  :background-color="colors.white.base"
					  @change="handleUpload"
					  :type="type"
					  :error-messages="[...internalErrors, ...errors]"
					  v-on="$listeners"
					  :rules="[...defaultRules, ...rules]"
					  dense
					  :persistent-hint="persistentHint"
					  v-bind="$attrs"
					  class="frp-text-field text-body-2"
					  :disabled="disabled"
					  :hide-details="hideDetails">
			<template #append-outer v-if="$slots['append-outer']">
				<slot name="append-outer"></slot>
			</template>
		</v-file-input>
	</div>
</template>

<script>
import FrpIcon from "Components/icon/FrpIcon";
import { isArray } from "lodash";
import colorsMixin from "Mixins/colorsMixin";
import FileMeta from "Store/shared/storage/types/fileMeta";
import { requiredRule } from "Utils/validation";

export default {
	inheritAttrs: false,
	mixins: [colorsMixin],
	model: {
		prop: "value",
		event: "update:value"
	},
	data() {
		return {
			isArray,
			internalErrors: []
		}
	},
	props: {
		persistentHint: {
			type: Boolean,
			default: false
		},
		hint: {
			type: String,
			default: ""
		},
		meta: {
			type: FileMeta,
			required: true
		},
		placeholder: String,
		namespace: String,
		persisted: {
			type: Boolean,
			default: false
		},
		errors: {
			type: [Array, String],
			default: ""
		},
		rules: {
			type: Array,
			default: () => []
		},
		value: {},
		type: String,
		label: String,
		disabled: Boolean,
		clearable: {
			type: Boolean,
			default: false
		},
		hideDetails: Boolean,
		required: Boolean,
		icon: String,
		appendIcon: String,
		accept: {
			type: Array,
			default: () => []
		}
	},
	computed: {
		defaultRules() {
			if(this.required)
				return [requiredRule()];
			else
				return [];
		}
	},
	methods: {
		handleUpload(value) {
			this.internalErrors = [];
			const allowedExtensions = this.accept.map(x => x.match(/(?<=\/)(\w)+$/)[0]);
			
			if(!value) {
				this.$emit("update:value", null);
			} else if(this.accept.length && !allowedExtensions.some(x => value.name.endsWith(x))) {
				this.internalErrors.push(this.$t("common.wrongFileType"));
			} else {
				const reader = new FileReader();
				
				reader.onload = e => {
					this.$emit("onload", e.target.result);
				};
				reader.readAsDataURL(value);
				
				this.$emit("update:value", value);
			}
		}
	},
	components: {
		FrpIcon
	}
};
</script>
