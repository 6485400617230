<template>
	<v-skeleton-loader type="heading" class="mb-1"/>
</template>

<script>
export default {
}
</script>

<style scoped>

</style>
