export default class CounterpartyEmployee {
	firstName: string;
	middleName: string;
	lastName: string;
	snils: string;
	isActive: boolean;
	isStatusSaving: boolean;

	constructor(firstName: string = "",
		middleName: string = "",
		lastName: string = "",
		snils: string = "",
		isActive: boolean = true,
		isStatusSaving: boolean = false
	)
	{
		this.firstName = firstName;
		this.middleName = middleName;
		this.lastName = lastName;
		this.snils = snils;
		this.isActive = isActive;
		this.isStatusSaving = isStatusSaving;
	}
}
