import ApiLegalEntity from "@/api/types/legalEntity/apiLegalEntity";

export default class ApiLegalEntityMasterAccountAccessApplicationCreate {
	email: string;
	legalEntity: ApiLegalEntity;
	documents: string[];

	constructor(email: string, legalEntity: ApiLegalEntity, documents: string[]) {
		this.email = email;
		this.legalEntity = legalEntity;
		this.documents = documents;
	}
}
