import {
	namespace,
	actionTypes,
	mutationTypes
} from "@/store/modules/counterparty/modules/physicalPersonCounterparty/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import FormMixinBuilder from "@/store/shared/form";
import SnapshotOptions from "@/store/shared/snapshot/snapshotOptions";
import stateSnapshotKeys from "@/store/shared/snapshot/keys";
import SnapshotMixinBuilder from "@/store/shared/snapshot";
import PhysicalPersonCounterpartyState
	from "@/store/modules/counterparty/modules/physicalPersonCounterparty/types/physicalPersonCounterpartyState";
import PhysicalPersonCounterparty from "@/store/modules/counterparty/modules/physicalPersonCounterparty/types/physicalPersonCounterparty";
import router from "@/router";
import ApiPhysicalPersonCounterparty from "@/api/types/counterparty/apiPhysicalPersonCounterparty";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import alertService, { AlertKeys } from "@/store/modules/alerts/services/alertService";
import ApiPhysicalPersonCounterpartyPersisted from "@/api/types/counterparty/apiPhysicalPersonCounterpartyPersisted";
import mapper from "@/store/modules/counterparty/modules/physicalPersonCounterparty/mapper";
import { validatePersonInn } from "@/utils/validator";
import { resolveAction, resolveMutation, resolveNestedState } from "@/utils/vuexModules";
import storeManager from "@/store/manager";
import { CounterpartyController } from "@/api/counterparty";
import { PhysicalPersonController } from "@/api/counterparty/physicalPerson";
import AbortService from "@/services/abortService";
import { RouteNames } from "@/router/routes";
import { Permissions } from "@/constants/permissions";
import { AuthorizationScopeType } from "@/types/authorization/authorizationScopeType";
import PermissionsService from "@/services/permissionsService";

const abortService = new AbortService();
const counterpartyController = new CounterpartyController(abortService);
const physicalPersonController = new PhysicalPersonController(abortService);
const permissionsService = new PermissionsService();

const formMixin = (new FormMixinBuilder()).build();
const baseMixin = (new BaseMixinBuilder(abortService)).build();
const snapshotMixin = (new SnapshotMixinBuilder({
	options: [
		new SnapshotOptions({
			key: stateSnapshotKeys.LAST_SAVED,
			fields: ["counterparty"]
		})
	]
})).build();

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new PhysicalPersonCounterpartyState(
			formMixin.state(),
			snapshotMixin.state()
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<PhysicalPersonCounterpartyState, any>>{
	...formMixin.getters,
	...snapshotMixin.getters
};

const actions = <ActionTree<PhysicalPersonCounterpartyState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...formMixin.actions,
	...snapshotMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit, state }, { id }: { id: string | null }) {
		await dispatch(actionTypes.initializeBase);

		if(id) {
			commit(mutationTypes.SET_ID, id);
			await dispatch(actionTypes.fetch);
		}

		commit(mutationTypes.SET_IS_INITIALIZED, true);
		commit(mutationTypes.SET_STATE_SNAPSHOT, stateSnapshotKeys.LAST_SAVED);
	},
	async [actionTypes.fetch]({ dispatch, commit, state }) {
		commit(mutationTypes.SET_IS_FORM_LOADING, true);

		try {
			const scope = await permissionsService.check([Permissions.GLOBAL_COUNTERPARTY_READ])
				? AuthorizationScopeType.GLOBAL
				: AuthorizationScopeType.OWN;

			let { counterparty } = await counterpartyController.getCounterparty<ApiPhysicalPersonCounterpartyPersisted>(state.id,
				ApiPhysicalPersonCounterpartyPersisted, scope);

			commit(mutationTypes.SET_COUNTERPARTY, mapper.map(counterparty, ApiPhysicalPersonCounterparty, PhysicalPersonCounterparty));
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
			commit(mutationTypes.SET_IS_FORM_DISABLED, true);
		} finally {
			commit(mutationTypes.SET_IS_FORM_LOADING, false);
		}
	},
	async [actionTypes.save]({ dispatch, commit, rootState, state }) {
		commit(mutationTypes.SET_IS_FORM_SAVING, true);

		const payload = mapper.map(state.counterparty, PhysicalPersonCounterparty, ApiPhysicalPersonCounterparty);
		try {
			if(state.id) {
				await counterpartyController.updatePhysicalPersonCounterparty(state.id, payload);

				commit(mutationTypes.SET_STATE_SNAPSHOT, stateSnapshotKeys.LAST_SAVED);
				alertService.addInfo(AlertKeys.SUCCESS_UPDATED_INFO);
			} else {
				let id = await counterpartyController.createPhysicalPersonCounterparty(payload);

				const hasGlobalPermissionCounterpartyRead = await permissionsService.check([Permissions.GLOBAL_COUNTERPARTY_READ]);

				if(!hasGlobalPermissionCounterpartyRead) {
					commit(mutationTypes.SET_IS_COUNTERPARTY_SUCCESS_CREATED_DIALOG_OPENED, true);
				} else {
					alertService.addInfo(AlertKeys.SUCCESS_CREATED_INFO);
					await router.push({ name: RouteNames.COUNTERPARTY, params: { id } });
				}
			}
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_FORM_SAVING, false);
		}
	},
	async [actionTypes.checkIsRecordUnique]({ commit, state }) {
		if(!validatePersonInn(state.counterparty.inn)) {
			commit(mutationTypes.SET_IS_RECORD_UNIQUE, true);
			return;
		}

		try {
			commit(mutationTypes.SET_IS_RECORD_UNIQUE_CHECK_IN_PROGRESS, true);

			const exists = await physicalPersonController.checkPhysicalPersonIsExists(state.counterparty.inn, state.counterparty.snils);

			commit(mutationTypes.SET_IS_RECORD_UNIQUE, !exists);
		} catch (error) {
			AlertHelper.handleGeneralRequestErrors(error);
			commit(mutationTypes.SET_IS_RECORD_UNIQUE, true);
		} finally {
			commit(mutationTypes.SET_IS_RECORD_UNIQUE_CHECK_IN_PROGRESS, false);
		}
	}
};

const mutations = <MutationTree<PhysicalPersonCounterpartyState>>{
	...stateManipulationMixin.mutations,
	...formMixin.mutations,
	...snapshotMixin.mutations,
	...baseMixin.mutations,
	[mutationTypes.SET_ID](state, value) {
		state.id = value;
	},
	[mutationTypes.SET_COUNTERPARTY](state, value) {
		state.counterparty = value;
	},
	[mutationTypes.SET_COUNTERPARTY_INN](state, value) {
		state.counterparty.inn = value;
	},
	[mutationTypes.SET_COUNTERPARTY_DESCRIPTION](state, value) {
		state.counterparty.description = value;
	},
	[mutationTypes.SET_COUNTERPARTY_SNILS](state, value) {
		state.counterparty.snils = value;
	},
	[mutationTypes.SET_COUNTERPARTY_FIRST_NAME](state, value) {
		state.counterparty.firstName = value;
	},
	[mutationTypes.SET_COUNTERPARTY_LAST_NAME](state, value) {
		state.counterparty.lastName = value;
	},
	[mutationTypes.SET_COUNTERPARTY_MIDDLE_NAME](state, value) {
		state.counterparty.middleName = value;
	},
	[mutationTypes.SET_COUNTERPARTY_IS_RFRP](state, value) {
		state.counterparty.isRfrp = value;
	},
	[mutationTypes.SET_COUNTERPARTY_IS_LEASING_COMPANY](state, value) {
		state.counterparty.isLeasingCompany = value;
	},
	[mutationTypes.SET_COUNTERPARTY_IS_PLEDGE_EXPERT_COMPANY](state, value) {
		state.counterparty.isPledgeExpertCompany = value;
	},
	[mutationTypes.SET_COUNTERPARTY_IS_VKM_EXPERT_COMPANY](state, value) {
		state.counterparty.isVkmExpertCompany = value;
	},
	[mutationTypes.SET_COUNTERPARTY_IS_FRP_ASSIGNEE_COMPANY](state, value) {
		state.counterparty.isFrpAssigneeCompany = value;
	},
	[mutationTypes.SET_IS_COUNTERPARTY_SUCCESS_CREATED_DIALOG_OPENED](state, value) {
		state.isCounterpartySuccessCreatedDialogOpened = value;
	}
};

const subscribe = (store: any) => {
	const { commit, dispatch } = store;
	store.subscribe(async ({ type, payload }: any, state: any) => {
		switch (type) {
			case resolveMutation(storeManager.counterparty.physicalPerson.namespace, mutationTypes.SET_COUNTERPARTY_INN):
			{
				let physicalPersonState = resolveNestedState<PhysicalPersonCounterpartyState>(state,
					storeManager.counterparty.physicalPerson.namespace);

				if(!physicalPersonState.id) {
					await dispatch(resolveAction(storeManager.counterparty.physicalPerson.namespace, actionTypes.checkIsRecordUnique));
				}

				break;
			}

			default:
				break;
		}
	});
};

export {
	namespace, state, getters, actions, mutations, subscribe
};

const physicalPersonCounterpartyModule = {
	namespace, state, getters, actions, mutations, subscribe, namespaced: true
};

export default physicalPersonCounterpartyModule;
