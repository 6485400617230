<template>
	<div>
		<v-subheader v-if="label" class="pa-0 mb-1 grey--text text-caption" style="height: fit-content">
			<span>{{ label }}</span>
			<span v-if="required">*</span>
		</v-subheader>
		<vue-tel-input-vuetify outlined
							   :disabled="disabled"
							   class="frp-phone-input"
							   @country-changed="setCountry"
							   :rules="[...rules, ...defaultRules]"
							   autocomplete="tel"
							   :hint="hint"
							   disabled-fetching-country
							   :preferred-countries="['RU']"
							   mode="international"
							   default-country="ru"
							   single-line
							   :placeholder="placeholder"
							   :label="placeholder"
							   @input="updateValue"
							   v-bind:value="phone">
		</vue-tel-input-vuetify>
	</div>
</template>

<script>
import { processPhone } from "Utils/phone";
import { phoneNumberRule, requiredRule } from "Utils/validation";
import { validatePhone } from "Utils/validator";

export default {
	props: {
		phone: String,
		rules: {
			type: Array,
			default: () => []
		},
		placeholder: String,
		required: Boolean,
		label: String,
		hint: String,
		disabled: Boolean
	},
	model: {
		prop: "phone",
		event: "update:phone"
	},
	data() {
		return {
			countryIso2: null
		};
	},
	computed: {
		defaultRules() {
			const rules = [phoneNumberRule({ getCountry: () => this.countryIso2 })];
			if(this.required)
				rules.push(requiredRule());
			return rules;
		}
	},
	methods: {
		setCountry({ iso2 }) {
			this.countryIso2 = iso2;
		},
		updateValue(value) {
			this.$emit("update:phone", validatePhone(value, this.countryIso2) ? processPhone(value) : value);
		}
	}
};
</script>

<style lang="scss">
.frp-phone-input {
	.v-input__slot {
		min-height: 40px !important;
		margin-bottom: 4px;

		input {
			font-size: 14px !important;
			color: var(--v-primary-darken1) !important;
		}
	}

	.v-select__selections input {
		padding: 0 !important;
	}

	.v-label {
		top: 10px !important;
		font-size: 14px !important;
		color: var(--v-grey-base) !important;
		opacity: 0.55;
	}

	.country-code {
		width: 44px;
	}
}
</style>
