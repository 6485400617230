<template>
	<v-responsive style="overflow: visible">
		<div class="d-flex align-center text-h6 mb-7">
			<v-btn icon x-small class="mr-2"
				   @click="handleBackStep"
				   v-if="!preventBackStep"
				   :disabled="isCreateMasterAccountRequestOperationExecuting">
				<v-icon color="primary">mdi-arrow-left</v-icon>
			</v-btn>
			<span data-cy="step-title">{{ title }}</span>
		</div>
		<slot></slot>
	</v-responsive>
</template>

<script>
import { getterTypes } from "Store/modules/masterAccountAccess/types";
import storeModuleBasedPage from "Mixins/storeModuleBasedPage";
import { mutationTypes, namespace } from "Store/modules/masterAccountAccess/types";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);

export default {
	mixins: [storeModuleBasedPage],
	props: {
		title: {
			required: true,
			type: String
		},
		preventBackStep: Boolean
	},
	data() {
		return {
			namespace
		};
	},
	computed: {
		...mapGetters({
			isSelectCounterpartyStep: getterTypes.isSelectCounterpartyStep
		}),
		...mapState({
			currentStepIndex: state => state.currentStepIndex,
			isCreateMasterAccountRequestOperationExecuting: state => state.isCreateMasterAccountRequestOperationExecuting
		})
	},
	methods: {
		...mapMutations({
			setCurrentStepIndex: mutationTypes.SET_CURRENT_STEP_INDEX
		}),
		async handleBackStep() {
			if(this.isSelectCounterpartyStep) {
				await this.destroyStore();
				this.$router.go(-1);
			} else {
				this.setCurrentStepIndex(this.currentStepIndex - 1);
			}
		}
	},
	async created() {
		await this.initializeStore();
	}
};
</script>

<style scoped>

</style>
