import BaseStepStateModel from "@/store/shared/baseStep/models/baseStepStateModel";
import DocumentsType from "@/api/types/documents/documentsType";
import { ApplicationStepOrders } from "@/store/modules/masterAccountAccess/types/ApplicationStepOrders";

export default class DocumentsStepStateModel extends BaseStepStateModel {
	constructor(
		public order: number = ApplicationStepOrders.DOCUMENTS_STEP_ORDER,
		public documents: DocumentsType = new DocumentsType(null, null),
		public isFormValid: boolean = false,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
	)
	{
		const component = "frp-documents-step";
		
		super(component, order, isInitialized, isDestroyed);
	}
}
