export default class Account {
	login: string;
	email: string;
	isActive: boolean;
	isEmailConfirmed: boolean;

	constructor(
		login: string = "",
		email: string = "",
		isActive: boolean = false,
		isEmailConfirmed: boolean = false
	)
	{
		this.login = login;
		this.email = email;
		this.isActive = isActive;
		this.isEmailConfirmed = isEmailConfirmed;
	}
}
