import { IsString, IsInt, Min, IsIn, Max, Length, IsArray } from "class-validator";
import { sortingOrderType } from "@/store/shared/sorting/models/types/sortingOrderType";
import { AVAILABLE_SORTS } from "@/store/modules/office/modules/officeEmployees/constants";

export default class OfficeEmployeesRouteQuery {
	@IsInt()
	@Min(0)
	@Max(1000)
	page: number;

	@IsString()
	@IsIn(AVAILABLE_SORTS)
	sort: String;

	@IsString()
	@IsIn([sortingOrderType.descending, sortingOrderType.ascending])
	sortDirection: String;

	@IsString()
	@Length(0, 100)
	query: String;

	@IsArray()
	filterRoles: String[];

	constructor(page: number, sort: String, sortDirection: String, query: String, filterRoles: String[]) {
		this.page = page;
		this.sort = sort;
		this.sortDirection = sortDirection;
		this.query = query;
		this.filterRoles = filterRoles;
	}
}
