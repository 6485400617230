import FormState from "@/store/shared/form/models/formState";
import ApiOfficeEmployeeProfile from "@/api/types/account/profile/apiOfficeEmployeeProfile";
import IPageState from "@/store/shared/base/types/pageState";

export default class OfficeEmployeeProfileState implements IPageState {
	constructor(
		public form: FormState,
		public snapshot: object,
		public profile: ApiOfficeEmployeeProfile = new ApiOfficeEmployeeProfile(),
		public id: string = "",
		public accountId: string = "",
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public isRequestPhoneConfirmationOperationExecuting: boolean = false,
		public isSignatureRequiredToggleLoading: boolean = false
	)
	{
	}
}
