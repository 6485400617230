import EmployeeJoinApplicationsState from "@/store/modules/applications/employeeJoin/list/types/employeeJoinApplicationsState";
import { createMap, createMapper, MappingProfile, forMember, mapFrom, addProfile, ignore } from "@automapper/core";
import { classes } from "@automapper/classes";
import ApiLegalEntityProfile from "@/api/types/account/profile/apiLegalEntityProfile";
import ApiForeignOrganizationProfile from "@/api/types/account/profile/apiForeignOrganizationProfile";
import ApiGetEmployeeJoinApplicationsParameters from "@/api/types/employeeJoinApplication/apiGetEmployeeJoinApplicationsParameters";
import { plainToInstance } from "class-transformer";
import ApiGetEmployeeJoinApplicationsCounterpartyIdentifiers
	from "@/api/types/employeeJoinApplication/apiGetEmployeeJoinApplicationsCounterpartyIdentifiers";

const mapper = createMapper({
	strategyInitializer: classes()
});

const listProfile: MappingProfile = (mapper) => {
	createMap(mapper, ApiLegalEntityProfile, ApiGetEmployeeJoinApplicationsCounterpartyIdentifiers,
		forMember(d => d.counterpartyInn, mapFrom(x => x.inn)),
		forMember(d => d.counterpartyOgrn, mapFrom(x => x.ogrn)),
		forMember(d => d.counterpartyKpp, mapFrom(x => x.kpp)),
		forMember(d => d.counterpartyLongName, ignore()),
		forMember(d => d.counterpartyCountryCode, ignore())
	);

	createMap(mapper, ApiForeignOrganizationProfile, ApiGetEmployeeJoinApplicationsCounterpartyIdentifiers,
		forMember(d => d.counterpartyInn, ignore()),
		forMember(d => d.counterpartyOgrn, ignore()),
		forMember(d => d.counterpartyKpp, ignore()),
		forMember(d => d.counterpartyLongName, mapFrom(x => x.longName)),
		forMember(d => d.counterpartyCountryCode, ignore())
	);
};

addProfile(mapper, listProfile);

export const mapApiGetEmployeeJoinApplicationsParameters = (state: EmployeeJoinApplicationsState) => {
	return plainToInstance(ApiGetEmployeeJoinApplicationsParameters, {
		query: state.search.query,
		status: state.filter.status,
		...state.counterpartyIdentifiers
	});
};

export default mapper;
