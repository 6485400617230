<template>
	<frp-list-layout>
		<template #title>
			{{ $t("titles.requestsForAccessToMasterAccount") }}
		</template>

		<template #search>
			<frp-text-field :ref="searchTextFieldRef"
							v-model="searchQuery"
							hide-details
							:rules="validation.searchQuery"
							autocomplete="off"
							icon="ico_search"
							:placeholder="$t('placeholders.search')">
			</frp-text-field>
		</template>

		<template #filter>
			<frp-filter v-model="isFilterOpened">
				<template #activator="{ on, attrs }">
					<frp-filter-btn :selected-filtering-parameters-count="selectedFilteringParametersCount"
									:activator="{ on, attrs }">
					</frp-filter-btn>
				</template>

				<template #content>
					<frp-checkbox-group :values="filter.status.value"
										@update:values="setInternalFilterStatus"
										:items="filter.status.items">
					</frp-checkbox-group>
				</template>

				<template #actions>
					<frp-btn outlined color="primary" @click="resetFilter" :disabled="isFilterEmpty && !isFilterChanged">
						{{ $t("buttons.clear") }}
					</frp-btn>
					<frp-btn @click="applyFilter" :disabled="!isFilterChanged">
						{{ $t("buttons.accept") }}
					</frp-btn>
				</template>
			</frp-filter>
		</template>

		<template #content>
			<v-data-table :headers="headers"
						  :items="items"
						  :loading-text="$t('tables.loading')"
						  hide-default-footer
						  :loading="isItemsLoading"
						  item-key="id"
						  :mobile-breakpoint="mobileBreakpoint"
						  :items-per-page="-1"
						  :custom-sort="sortItems"
						  must-sort
						  :options="options"
						  @click:row="openDetails"
						  :item-class="() => 'text-body-2 cy-table-row'"
						  class="frp-table pt-4 clickable-rows d-flex flex-column">
				<template #item.heads="{ item }">
					<div class="d-flex flex-column">
						<span v-for="{ fullName } in item.heads">{{ fullName }}</span>
					</div>
				</template>
				<template #item.masterAccountRequestStatus="{ item }">
					<frp-approve-status-label v-if="item.status" :value="item.status"></frp-approve-status-label>
				</template>
			</v-data-table>
		</template>

		<template #pagination>
			<frp-pagination v-if="!isItemsLoading"
							v-model="page"
							:length="Math.ceil(paging.total / paging.pageSize)">
			</frp-pagination>
		</template>
	</frp-list-layout>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn";
import FrpFilterBtn from "@/components/buttons/FrpFilterBtn";
import { RouteNames } from "@/router/routes";
import { ApproveStatusType } from "@/types/ApproveStatusType";
import authorizationMixin from "Mixins/authorizationMixin";
import { namespace } from "Store/modules/applications/masterAccountAccess/list";
import { actionTypes, getterTypes, mutationTypes } from "Store/modules/applications/masterAccountAccess/list/types";
import { createNamespacedHelpers } from "vuex";
import { listMixin } from "Mixins/listMixin";
import colorsMixin from "Mixins/colorsMixin";
import FrpApproveStatusLabel from "Components/common/FrpApproveStatusLabel";
import FrpListLayout from "Components/layouts/FrpListLayout";
import FrpTextField from "Components/fields/FrpTextField";
import FrpIcon from "Components/icon/FrpIcon";
import FrpCheckboxGroup from "Components/fields/FrpCheckboxGroup";
import FrpPagination from "Components/common/FrpPagination";
import FrpFilter from "Components/common/FrpFilter";
import storeModuleBasedPage from "Mixins/storeModuleBasedPage";

const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);

export default {
	mixins: [listMixin, colorsMixin, storeModuleBasedPage, authorizationMixin],
	data() {
		return {
			namespace,
			headers: [
				{
					text: this.$t("tables.title"),
					value: "title",
					class: "text-caption",
					sortable: false,
					width: "30%"
				},
				{
					text: this.$t("tables.inn"),
					value: "inn",
					class: "text-caption",
					width: "10%"
				},
				{
					text: this.$t("tables.heads"),
					value: "heads",
					class: "text-caption",
					sortable: false,
					width: "30%"
				},
				{
					text: this.$t("tables.createdAt"),
					value: "createdAt",
					class: "text-caption",
					width: "15%"
				},
				{
					text: this.$t("tables.status"),
					value: "masterAccountRequestStatus",
					class: "text-caption",
					width: "15%"
				}
			],
			internalFilterValues: {
				status: []
			}
		};
	},
	computed: {
		...mapGetters({
			items: getterTypes.formattedItems,
			isNoData: getterTypes.isListingEmpty
		}),
		filter() {
			return {
				status: {
					value: this.internalFilterValues.status,
					items: [
						{
							text: this.$t(`aliases.approveStatus.${ApproveStatusType.CREATED}`),
							value: ApproveStatusType.CREATED
						},
						{
							text: this.$t(`aliases.approveStatus.${ApproveStatusType.APPROVED}`),
							value: ApproveStatusType.APPROVED
						},
						{
							text: this.$t(`aliases.approveStatus.${ApproveStatusType.REJECTED}`),
							value: ApproveStatusType.REJECTED
						}
					]
				}
			};
		}
	},
	methods: {
		...mapMutations({
			setFilterStatus: mutationTypes.SET_FILTER_STATUS
		}),
		openDetails({ id }) {
			this.$router.push({ name: RouteNames.APPLICATIONS_MASTER_ACCOUNT_ACCESS_DETAILS, params: { id } });
		},
		setInternalFilterStatus(value) {
			this.internalFilterValues.status = value;
		},
		resetFilter() {
			this.setFilterStatus([]);
			this.isFilterOpened = false;
		},
		setInternalFilterValues() {
			this.internalFilterValues.status = this.filterValues.status && [...this.filterValues.status];
		},
		applyFilter() {
			this.setFilterStatus(this.internalFilterValues.status);
			this.isFilterOpened = false;
		}
	},
	created() {
		this.initializeStore();
	},
	components: {
		FrpBtn,
		FrpFilterBtn,
		FrpApproveStatusLabel,
		FrpListLayout,
		FrpTextField,
		FrpIcon,
		FrpCheckboxGroup,
		FrpPagination,
		FrpFilter
	}
};
</script>
