import { actionTypes, getterTypes, mutationTypes, namespace } from "@/store/modules/counterpartyEmployee/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import CounterpartyEmployeeState from "@/store/modules/counterpartyEmployee/types/counterpartyEmployeeState";
import counterpartyEmployeeGeneralTypes from "@/store/modules/counterpartyEmployee/modules/general/types";
import CounterpartyEmployeeGeneralModuleBuilder from "@/store/modules/counterpartyEmployee/modules/general";
import ApiCounterpartyPersistedBase from "@/api/types/counterparty/apiCounterpartyPersistedBase";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import counterpartyEmployeeTrustsModule from "@/store/modules/counterpartyEmployee/modules/trusts";
import counterpartyEmployeeTrustsTypes from "@/store/modules/counterpartyEmployee/modules/trusts/types";
import counterpartyEmployeeTrustModule from "@/store/modules/counterpartyEmployee/modules/trust";
import counterpartyEmployeeTrustTypes from "@/store/modules/counterpartyEmployee/modules/trust/types";
import mapper from "@/store/modules/counterpartyEmployee/mapper";
import CounterpartyEmployee from "@/store/modules/counterpartyEmployee/types/counterpartyEmployee";
import { resolveMutation } from "@/utils/vuexModules";
import storeManager from "@/store/manager";
import { cloneDeep } from "lodash";
import { CounterpartyController } from "@/api/counterparty";
import AbortService from "@/services/abortService";
import HttpNotFoundException from "@/exceptions/httpNotFoundException";
import counterpartyEmployeeProfileTypes from "@/store/modules/counterpartyEmployee/modules/profile/types";
import counterpartyEmployeeProfileModule from "@/store/modules/counterpartyEmployee/modules/profile";
import { formatCounterpartyTitle } from "@/utils/formatting";
import alertService, { AlertKeys } from "@/store/modules/alerts/services/alertService";
import { ApiCounterpartyEmployeeBase } from "@/api/types/counterparty/apiCounterpartyEmployee";
import rootTypes from "@/store/types";
import { PageModeType } from "@/store/types/pageModeType";
import AccessForbiddenException from "@/exceptions/accessForbiddenException";
import PermissionsService from "@/services/permissionsService";
import { Permissions } from "@/constants/permissions";
import { AuthorizationScopeType } from "@/types/authorization/authorizationScopeType";

const abortService = new AbortService();
const counterpartyController = new CounterpartyController(abortService);
const permissionsService = new PermissionsService();
const baseMixin = (new BaseMixinBuilder(abortService)).build();

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new CounterpartyEmployeeState();
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<CounterpartyEmployeeState, any>>{
	[getterTypes.counterpartyTitle]: state => {
		return formatCounterpartyTitle(state.counterparty);
	}
};

const actions = <ActionTree<CounterpartyEmployeeState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit, state, rootState }, payload: { id: string | null, counterpartyId: string })
	{
		await dispatch(actionTypes.initializeBase);

		commit(mutationTypes.SET_COUNTERPARTY_ID, payload.counterpartyId);

		try {
			if(payload.id) {
				commit(mutationTypes.SET_ID, payload.id);

				const scope = await permissionsService.check([Permissions.GLOBAL_COUNTERPARTY_EMPLOYEE_READ])
					? AuthorizationScopeType.GLOBAL
					: AuthorizationScopeType.OWN;

				let employeePersisted = await counterpartyController.getCounterpartyEmployee(state.id, state.counterpartyId, scope);

				commit(mutationTypes.SET_EMPLOYEE,
					mapper.map(employeePersisted.employee, ApiCounterpartyEmployeeBase, CounterpartyEmployee));
				commit(mutationTypes.SET_EMPLOYEE_IS_ACTIVE, employeePersisted.employee.isActive);

				const hasEmployeeReedPermission = await permissionsService.check([Permissions.GLOBAL_COUNTERPARTY_EMPLOYEE_PROFILE_READ]);
				const hasOwnEmployeeReedPermission = await permissionsService.check([Permissions.OWN_COUNTERPARTY_EMPLOYEE_PROFILE_READ]);

				if(hasEmployeeReedPermission || hasOwnEmployeeReedPermission) {
					const employeeProfileScope = hasEmployeeReedPermission
						? AuthorizationScopeType.GLOBAL
						: AuthorizationScopeType.OWN;

					let profile = await counterpartyController.getCounterpartyEmployeeProfile(state.counterpartyId,
						state.id,
						employeeProfileScope);

					commit(mutationTypes.SET_PROFILE, profile);
				}
			}

			const hasCounterpartyReadPermission = await permissionsService.check([Permissions.GLOBAL_COUNTERPARTY_READ]);
			const hasOwnCounterpartyReadPermission = await permissionsService.check([Permissions.OWN_COUNTERPARTY_READ]);

			if(hasCounterpartyReadPermission || hasOwnCounterpartyReadPermission) {
				let { counterparty } = await counterpartyController.getCounterparty<ApiCounterpartyPersistedBase>(state.counterpartyId,
					ApiCounterpartyPersistedBase,
					hasCounterpartyReadPermission ? AuthorizationScopeType.GLOBAL : AuthorizationScopeType.OWN);

				commit(mutationTypes.SET_COUNTERPARTY, counterparty);
			}
		} catch (error) {
			switch (error.constructor) {
				case HttpNotFoundException:
					commit(rootTypes.mutationTypes.SET_PAGE_MODE, PageModeType.PAGE_NOT_FOUND, { root: true });
					break;
				case AccessForbiddenException:
					commit(rootTypes.mutationTypes.SET_PAGE_MODE, PageModeType.ACCESS_DENIED, { root: true });
					break;
				default:
					AlertHelper.handleGeneralRequestErrors(error);
					break;
			}
		}

		commit(mutationTypes.SET_IS_INITIALIZED, true);
	},
	async [actionTypes.activateEmployee]({ commit, state }) {
		commit(mutationTypes.SET_IS_STATUS_SAVING, true);

		try {
			const scope = await permissionsService.check([Permissions.GLOBAL_COUNTERPARTY_EMPLOYEE_STATUS_UPDATE])
				? AuthorizationScopeType.GLOBAL
				: AuthorizationScopeType.OWN;

			await counterpartyController.activateCounterpartyEmployee(state.counterpartyId, state.id, scope);

			commit(mutationTypes.SET_EMPLOYEE_IS_ACTIVE, true);
			alertService.addInfo(AlertKeys.PROFILE_ACTIVATED);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_STATUS_SAVING, false);
		}
	},
	async [actionTypes.deactivateEmployee]({ commit, state }) {
		commit(mutationTypes.SET_IS_STATUS_SAVING, true);

		try {
			const scope = await permissionsService.check([Permissions.GLOBAL_COUNTERPARTY_EMPLOYEE_STATUS_UPDATE])
				? AuthorizationScopeType.GLOBAL
				: AuthorizationScopeType.OWN;

			await counterpartyController.deactivateCounterpartyEmployee(state.counterpartyId, state.id, scope);

			commit(mutationTypes.SET_EMPLOYEE_IS_ACTIVE, false);
			alertService.addInfo(AlertKeys.PROFILE_DEACTIVATED);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_STATUS_SAVING, false);
		}
	}
};

const mutations = <MutationTree<CounterpartyEmployeeState>>{
	...stateManipulationMixin.mutations,
	...baseMixin.mutations,
	[mutationTypes.SET_PROFILE](state, value) {
		state.profile = value;
	},
	[mutationTypes.SET_ID](state, value) {
		state.id = value;
	},
	[mutationTypes.SET_COUNTERPARTY_ID](state, value) {
		state.counterpartyId = value;
	},
	[mutationTypes.SET_COUNTERPARTY](state, value) {
		state.counterparty = value;
	},
	[mutationTypes.SET_EMPLOYEE](state, value) {
		state.employee = value;
	},
	[mutationTypes.SET_EMPLOYEE_IS_ACTIVE](state, value) {
		state.employee.isActive = value;
	},
	[mutationTypes.SET_IS_STATUS_SAVING](state, value) {
		state.employee.isStatusSaving = value;
	}
};

const modules = {
	[counterpartyEmployeeGeneralTypes.namespace]: ((new CounterpartyEmployeeGeneralModuleBuilder()).build()).module,
	[counterpartyEmployeeTrustTypes.namespace]: {
		...counterpartyEmployeeTrustModule
	},
	[counterpartyEmployeeTrustsTypes.namespace]: {
		...counterpartyEmployeeTrustsModule
	},
	[counterpartyEmployeeProfileTypes.namespace]: {
		...counterpartyEmployeeProfileModule
	}
};

const subscribe = (store: any) => {
	const { commit, dispatch } = store;

	store.subscribe(async ({ type, payload }: any, state: any) => {
		switch (type) {
			case resolveMutation(storeManager.counterpartyEmployee.general.namespace,
				counterpartyEmployeeGeneralTypes.mutationTypes.EMPLOYEE_UPDATED_EVENT):
			{
				commit(resolveMutation(storeManager.counterpartyEmployee.namespace, mutationTypes.SET_EMPLOYEE), cloneDeep(payload));
			}

			default:
				break;
		}
	});
};

export {
	namespace, state, getters, actions, mutations, modules, subscribe
};

const counterpartyEmployeeModule = {
	namespace, state, getters, actions, mutations, modules, subscribe, namespaced: true
};

export default counterpartyEmployeeModule;
