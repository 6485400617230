<template>
	<v-row v-if="isShown">
		<v-col class="pt-7 pa-6">
			<v-responsive style="min-height: 14px">
				<v-fade-transition>
					<v-breadcrumbs v-show="!isLoading" :items="items" class="pa-0 frp-breadcrumbs text-subtitle-2"></v-breadcrumbs>
				</v-fade-transition>
			</v-responsive>
		</v-col>
	</v-row>
</template>

<script>
import FrpBreadcrumbsLoader from "Components/loaders/common/FrpBreadcrumbsLoader";
import FrpTitleLoader from "Components/loaders/common/FrpTitleLoader";
import authorizationMixin from "Mixins/authorizationMixin";
import breadcrumbsTypes from "Store/modules/breadcrumbs/types";
import { mapInstanceActions, mapInstanceState } from "Utils/vuexMapInstanse";

export default {
	mixins: [authorizationMixin],
	props: {
		namespace: {
			type: String,
			required: true
		}
	},
	computed: {
		...mapInstanceState({
			isLoading: state => state.isLoading,
			items: state => state.items.map(x => ({
				...x,
				exact: true
			}))
		}),
		isShown() {
			return this.isLoading || this.items.length;
		}
	},
	methods: {
		...mapInstanceActions({
			processRoute: breadcrumbsTypes.actionTypes.processRoute
		})
	},
	components: { FrpBreadcrumbsLoader, FrpTitleLoader }
};
</script>

<style lang="scss">
.frp-breadcrumbs {
	font-size: 10px !important;

	.v-breadcrumbs__item {
		color: var(--v-secondary-base) !important;

		&--disabled {
			color: var(--v-grey) !important;
		}
	}

	.v-breadcrumbs__divider {
		color: var(--v-grey) !important;
		padding: 0 8px !important;
	}
}
</style>
