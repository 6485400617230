<template>
	<component ref="counterparty" :key="$route.name" v-bind:is="counterpartyComponent"></component>
</template>

<script>
import { RouteNames } from "@/router/routes";
import storeManager from "@/store/manager";
import colorsMixin from "Mixins/colorsMixin";
import { CounterpartyType } from "Types/CounterpartyType";
import FrpForeignOrganizationGeneral from "Views/counterparty/sections/FrpForeignOrganizationGeneral";
import FrpLegalEntityGeneral from "Views/counterparty/sections/FrpLegalEntityGeneral";
import FrpLegalPersonGeneral from "Views/counterparty/sections/FrpLegalPersonGeneral";
import FrpPhysicalPersonGeneral from "Views/counterparty/sections/FrpPhysicalPersonGeneral";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapMutations, mapActions } = createNamespacedHelpers(storeManager.counterparty.namespace);

export default {
	mixins: [colorsMixin],
	data() {
		return {
			RouteNames,
			CounterpartyType
		};
	},
	computed: {
		...mapState({
			counterparty: state => state.counterparty
		}),
		counterpartyType: {
			get() {
				return this.counterparty.type;
			}
		},
		counterpartyComponent() {
			switch (this.counterpartyType) {
				case CounterpartyType.LEGAL_ENTITY:
					return FrpLegalEntityGeneral.name;
				case CounterpartyType.PHYSICAL_PERSON:
					return FrpPhysicalPersonGeneral.name;
				case CounterpartyType.LEGAL_PERSON:
					return FrpLegalPersonGeneral.name;
				case CounterpartyType.FOREIGN_ORGANIZATION:
					return FrpForeignOrganizationGeneral.name;
			}
		}
	},
	beforeRouteLeave(to, from, next) {
		this.$refs.counterparty.beforeRouteLeave(to, from, next);
	},
	components: {
		FrpForeignOrganizationGeneral,
		FrpLegalEntityGeneral,
		FrpLegalPersonGeneral,
		FrpPhysicalPersonGeneral
	}
};
</script>

<style scoped>

</style>
