import { TrustStatusType } from "@/types/TrustStatusType";

export default class ApiCounterpartyEmployeeTrust {
	constructor(
		public counterpartyId: string,
		public trusteeEmployeeId: string,
		public trusteeSnils: string,
		public trusteeInn: string,
		public expireAt: string,
		public fileId: string,
		public isSigned: boolean,
		public acceptedAt: string,
		public acceptorOfficeEmployeeId: string,
		public declinedAt: string,
		public declinerOfficeEmployeeId: string,
		public status: TrustStatusType,
		public canceledAt: string,
		public cancellerOfficeEmployeeId: string,
		public cancellerCounterpartyEmployeeId: string,
		public declineReason: string,
		public isMCHD: boolean
	)
	{
	}
}
