<template>
	<frp-tile-card @click="setItemsMode" @click.middle="setItemsMode" :force-active="!isPreviewMode" group-mode>
		<span v-if="isPreviewMode" class="text-center">{{ title }}</span>
		<v-container v-else style="width: 100%; font-size: 14px; height: 100%" class="flex-column d-flex text-center pa-0">
			<v-row no-gutters class="d-flex">
				<v-col :class="{ 'bottom-border': items[2], 'right-border': items[1] }"
					   @click="replaceUrl($event, items[0].url)"
					   @click.middle="replaceUrl($event, items[0].url, true)"
					   class="frp-tile-group-item d-flex justify-center align-center px-1" v-if="items[0]">{{ items[0].name }}
				</v-col>
				<v-col :class="{ 'bottom-border': items[2] }"
					   @click="replaceUrl($event, items[1].url)"
					   @click.middle="replaceUrl($event, items[1].url, true)"
					   class="frp-tile-group-item d-flex justify-center align-center px-1" v-if="items[1]">{{ items[1].name }}
				</v-col>
			</v-row>
			<v-row no-gutters class="d-flex" v-if="items[2] || items[3]">
				<v-col :class="{ 'right-border': items[3] }" class="frp-tile-group-item d-flex justify-center align-center px-1"
					   @click="replaceUrl($event, items[2].url)"
					   @click.middle="replaceUrl($event, items[2].url, true)"
					   v-if="items[2]">
					{{ items[2].name }}
				</v-col>
				<v-col class="frp-tile-group-item d-flex justify-center align-center px-1"
					   @click="replaceUrl($event, items[3].url)"
					   @click.middle="replaceUrl($event, items[3].url, true)"
					   v-if="items[3]">{{ items[3].name }}</v-col>
			</v-row>
		</v-container>
	</frp-tile-card>
</template>

<script>
import FrpTileCard from "@/components/common/tile/FrpTileCard.vue";
import colorsMixin from "@/mixins/colorsMixin";

export default {
	mixins: [colorsMixin],
	data() {
		return {
			isPreviewMode: true
		};
	},
	props: {
		title: String,
		items: Array
	},
	methods: {
		setItemsMode() {
			this.isPreviewMode = false;
		},
		replaceUrl(event, url, blank = false) {
			(blank || event.ctrlKey) ? window.open(url, '_blank') : window.location.href = url
		}
	},
	components: {
		FrpTileCard
	}
};
</script>

<style scoped lang="scss">
.frp-tile-group-item {
	border: 0 solid white;
}

.frp-tile-group-item:hover {
	color: #FCE5EA;
}

.left-border {
	border-left-width: 1px;
}

.bottom-border {
	border-bottom-width: 1px;
}

.top-border {
	border-top-width: 1px;
}

.right-border {
	border-right-width: 1px;
}
</style>
