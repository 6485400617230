import { createMap, createMapper, MappingProfile, forMember, mapFrom, addProfile } from "@automapper/core";
import { classes } from "@automapper/classes";
import ApiLegalEntityIdentifiers from "@/api/types/legalEntity/apiLegalEntityIdentifiers";
import LegalEntityCounterparty from "@/store/modules/counterparty/modules/legalEntityCounterparty/types/legalEntityCounterparty";

const mapper = createMapper({
	strategyInitializer: classes()
});

const employeeJoinProfile: MappingProfile = (mapper) => {
	createMap(mapper, LegalEntityCounterparty, ApiLegalEntityIdentifiers,
		forMember(d => d.inn, mapFrom(x => x.inn)),
		forMember(d => d.ogrn, mapFrom(x => x.ogrn)),
		forMember(d => d.kpp, mapFrom(x => x.kpp))
	);
};

addProfile(mapper, employeeJoinProfile);

export default mapper;
