import ListingModel from "@/store/shared/listing/models/listingModel";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchModel from "@/store/shared/search/models/searchModel";
import IPageState from "@/store/shared/base/types/pageState";
import ApiCounterpartyEmployeeWithTrustInfo from "@/api/types/counterparty/ApiCounterpartyEmployeeWithTrustInfo";

export default class CounterpartyEmployeesState implements IPageState {
	constructor(
		public listing: ListingModel<ApiCounterpartyEmployeeWithTrustInfo>,
		public sorting: SortingModel<String>,
		public paging: PagingModel,
		public search: SearchModel,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false
	)
	{
	}
}
