import EmbeddedSnilsFormModuleBuilder from "@/store/shared/embeddedSnilsForm";
import { StorageController } from "@/api/storage";
import { ActionTree, Module } from "vuex";
import EmbeddedSnilsFormState from "@/store/shared/embeddedSnilsForm/types/embeddedSnilsFormState";
import { actionTypes, mutationTypes } from "@/store/shared/embeddedSnilsForm/types";
import { resolveNestedState } from "@/utils/vuexModules";
import storeManager from "@/store/manager";
import FileMeta from "@/store/shared/storage/types/fileMeta";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import { EmbeddedFormEditModeType } from "@/store/shared/embeddedForm/types/embeddedFormEditModeType";
import mapper from "@/store/shared/storage/mapper";
import { SnilsRecognitionController } from "@/api/snils/recognition";
import { CounterpartyController } from "@/api/counterparty";
import CounterpartyEmployeeGeneralState from "@/store/modules/counterpartyEmployee/modules/general/types/counterpartyEmployeeGeneralState";
import NotDefinedException from "@/exceptions/notDefinedException";
import ApiFileMeta from "@/api/types/storage/apiFileMeta";
import { Permissions } from "@/constants/permissions";
import { AuthorizationScopeType } from "@/types/authorization/authorizationScopeType";
import PermissionsService from "@/services/permissionsService";

export default class CounterpartyEmployeeEmbeddedSnilsFormModuleBuilder {

	constructor() {
	}

	build() {
		const { abortService, module: embeddedSnilsFormModule } = (new EmbeddedSnilsFormModuleBuilder()).build();

		const permissionsService = new PermissionsService();
		const counterpartyController = new CounterpartyController(abortService);
		const storageController = new StorageController(abortService);
		const snilsController = new SnilsRecognitionController(abortService);

		const actions = <ActionTree<EmbeddedSnilsFormState, any>>{
			...embeddedSnilsFormModule.actions,
			async [actionTypes.update]({ commit, state, rootState }) {
				let { counterpartyId } = resolveNestedState<CounterpartyEmployeeGeneralState>(rootState,
					storeManager.counterpartyEmployee.general.namespace);

				const scope = await permissionsService.check([Permissions.GLOBAL_COUNTERPARTY_EMPLOYEE_SNILS_FILE_UPDATE])
					? AuthorizationScopeType.GLOBAL
					: AuthorizationScopeType.OWN;

				await counterpartyController.updateCounterpartyEmployeeSnils(counterpartyId, state.ownerId, state.snilsFileMeta.id, scope);

				const employeeSnilsScope = await permissionsService.check([Permissions.GLOBAL_COUNTERPARTY_EMPLOYEE_SNILS_FILE_READ])
					? AuthorizationScopeType.GLOBAL
					: AuthorizationScopeType.OWN;

				let snilsPersisted = await counterpartyController.getCounterpartyEmployeeSnils(counterpartyId,
					state.ownerId,
					employeeSnilsScope);
				if(!snilsPersisted) throw new NotDefinedException("snilsPersisted");

				commit(mutationTypes.SET_SNILS_FILE_ID, snilsPersisted.snils.fileId);

				commit(mutationTypes.UPDATE_LAST_SAVED_META);
			},
			async [actionTypes.create]({ commit, state, rootState }) {
				let { counterpartyId } = resolveNestedState<CounterpartyEmployeeGeneralState>(rootState,
					storeManager.counterpartyEmployee.general.namespace);

				const scope = await permissionsService.check([Permissions.GLOBAL_COUNTERPARTY_EMPLOYEE_SNILS_FILE_CREATE])
					? AuthorizationScopeType.GLOBAL
					: AuthorizationScopeType.OWN;

				let { id } = await counterpartyController.createCounterpartyEmployeeSnils(counterpartyId,
					state.ownerId,
					state.snilsFileMeta.id, scope);

				const employeeSnilsScope = await permissionsService.check([Permissions.GLOBAL_COUNTERPARTY_EMPLOYEE_SNILS_FILE_READ])
					? AuthorizationScopeType.GLOBAL
					: AuthorizationScopeType.OWN;

				let snilsPersisted = await counterpartyController.getCounterpartyEmployeeSnils(counterpartyId,
					state.ownerId,
					employeeSnilsScope);
				if(!snilsPersisted) throw new NotDefinedException("snilsPersisted");

				commit(mutationTypes.SET_SNILS_FILE_ID, snilsPersisted.snils.fileId);

				commit(mutationTypes.SET_ID, id);

				commit(mutationTypes.UPDATE_LAST_SAVED_META);
			},
			async [actionTypes.fetch]({ state, commit, rootState }) {
				if(!state.ownerId) {
					commit(mutationTypes.SET_FORM_EDIT_MODE, EmbeddedFormEditModeType.CREATE);
					return;
				}

				if(!await permissionsService.check([Permissions.GLOBAL_COUNTERPARTY_EMPLOYEE_SNILS_FILE_READ,
					Permissions.OWN_COUNTERPARTY_EMPLOYEE_SNILS_FILE_READ]))
					return;

				try {
					let { counterpartyId } = resolveNestedState<CounterpartyEmployeeGeneralState>(rootState,
						storeManager.counterpartyEmployee.general.namespace);

					const employeeSnilsScope = await permissionsService.check([Permissions.GLOBAL_COUNTERPARTY_EMPLOYEE_SNILS_FILE_READ])
						? AuthorizationScopeType.GLOBAL
						: AuthorizationScopeType.OWN;

					let apiSnils = await counterpartyController.getCounterpartyEmployeeSnils(counterpartyId,
						state.ownerId,
						employeeSnilsScope);

					if(apiSnils) {
						let apiFileMeta = await storageController.getFileMeta(apiSnils.snils.fileId);

						commit(mutationTypes.SET_ID, apiSnils.id);

						commit(mutationTypes.SET_SNILS_FILE_META, mapper.map(apiFileMeta, ApiFileMeta, FileMeta));

						commit(mutationTypes.UPDATE_LAST_SAVED_META);
						commit(mutationTypes.SET_FORM_EDIT_MODE, EmbeddedFormEditModeType.UPDATE);
					} else {
						commit(mutationTypes.SET_FORM_EDIT_MODE, EmbeddedFormEditModeType.CREATE);
					}
				} catch (error) {
					console.error(error);
					AlertHelper.handleGeneralRequestErrors(error);
				}
			}
		};

		const counterpartyEmployeeSnilsModule: Module<EmbeddedSnilsFormState, any> = {
			...embeddedSnilsFormModule,
			actions
		};

		return {
			module: counterpartyEmployeeSnilsModule
		};

	}
}
