import { IsString, IsInt, Min, IsIn, Max, Length, IsArray } from "class-validator";
import { sortingOrderType } from "@/store/shared/sorting/models/types/sortingOrderType";
import { AVAILABLE_SORTS } from "@/store/modules/counterparties/constants";

export default class CounterpartiesRouteQuery {
	@IsInt()
	@Min(0)
	@Max(1000)
	page: number;

	@IsString()
	@IsIn(AVAILABLE_SORTS)
	sort: String;

	@IsString()
	@IsIn([sortingOrderType.descending, sortingOrderType.ascending])
	sortDirection: String;

	@IsString()
	@Length(0, 100)
	query: String;

	@IsArray()
	filterType: String[];

	constructor(page: number, sort: String, sortDirection: String, query: String, filterType: String[]) {
		this.page = page;
		this.sort = sort;
		this.sortDirection = sortDirection;
		this.query = query;
		this.filterType = filterType;
	}
}
