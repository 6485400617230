<template>
	<v-responsive>
		<template v-if="loading">
			<frp-details-group>
				<frp-details-subheader-loader></frp-details-subheader-loader>
				<frp-details-item-loader></frp-details-item-loader>
				<frp-details-item-loader></frp-details-item-loader>
				<frp-details-item-loader></frp-details-item-loader>
				<frp-details-item-loader></frp-details-item-loader>
				<frp-details-item-loader></frp-details-item-loader>
			</frp-details-group>
		</template>
		<template v-else>
			<frp-details-group>
				<frp-details-section-title>{{ title }}</frp-details-section-title>
				<frp-details-subheader>{{ $t("details.titles.general") }}</frp-details-subheader>
				<frp-details-item :title="$t('details.titles.fullPersonName')" :value="fullName"></frp-details-item>
				<frp-details-item :title="$t('details.titles.position')" :value="details.position"></frp-details-item>
				<frp-details-item :title="$t('details.titles.snils')" :value="details.snils"></frp-details-item>
				<frp-details-item :title="$t('details.titles.phone')" :value="details.phone"></frp-details-item>
				<frp-details-item :title="$t('details.titles.email')" :value="details.email"></frp-details-item>
			</frp-details-group>
		</template>
	</v-responsive>
</template>

<script>
import { ApiCounterpartyEmployeeBase } from "Api/types/counterparty/apiCounterpartyEmployee";
import FrpDetailsGroup from "Components/details/FrpDetailsGroup";
import FrpDetailsItem from "Components/details/FrpDetailsItem";
import FrpDetailsSectionTitle from "Components/details/FrpDetailsSectionTitle";
import FrpDetailsSubheader from "Components/details/FrpDetailsSubheader";
import FrpDetailsItemLoader from "Components/loaders/details/FrpDetailsItemLoader";
import FrpDetailsSubheaderLoader from "Components/loaders/details/FrpDetailsSubheaderLoader";
import { formatFullName } from "Utils/formatting";

export default {
	props: {
		details: ApiCounterpartyEmployeeBase,
		loading: Boolean,
		title: {
			type: String,
			default: ""
		}
	},
	computed: {
		fullName() {
			return formatFullName(this.details);
		}
	},
	components: {
		FrpDetailsSectionTitle,
		FrpDetailsItemLoader,
		FrpDetailsSubheaderLoader,
		FrpDetailsItem,
		FrpDetailsSubheader,
		FrpDetailsGroup
	}
};
</script>
