export default class LegalPersonCounterparty {
	inn: string;
	ogrnIp: string;
	fullName: string;
	isRfrp: boolean;
	isLeasingCompany: boolean;
	isPledgeExpertCompany: boolean;
	isVkmExpertCompany: boolean;
	isFrpAssigneeCompany: boolean;
	registrationDate: number;
	description: string;

	constructor(
		type: string = "",
		inn: string = "",
		ogrnIp: string = "",
		fullName: string = "",
		isRfrp: boolean = false,
		isLeasingCompany: boolean = false,
		isPledgeExpertCompany: boolean = false,
		isVkmExpertCompany: boolean = false,
		isFrpAssigneeCompany: boolean = false,
		registrationDate: number = 0,
		description: string = ""
	)
	{
		this.inn = inn;
		this.ogrnIp = ogrnIp;
		this.fullName = fullName;
		this.isRfrp = isRfrp;
		this.isLeasingCompany = isLeasingCompany;
		this.isPledgeExpertCompany = isPledgeExpertCompany;
		this.isVkmExpertCompany = isVkmExpertCompany;
		this.isFrpAssigneeCompany = isFrpAssigneeCompany;
		this.registrationDate = registrationDate;
		this.description = description;
	}
}
