import baseMixinTypes from "@/store/shared/base/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";

export const namespace = "profile-assignment";

export const getterTypes = {};

export const actionTypes = {
	...stateManipulationMixinTypes.actionTypes,
	...baseMixinTypes.actionTypes,
	assignProfile: "assignProfile"
};

export const mutationTypes = {
	...stateManipulationMixinTypes.mutationTypes,
	...baseMixinTypes.mutationTypes,
	SET_PROFILE: "SET_PROFILE",
	SET_COUNTERPARTY: "SET_COUNTERPARTY",
	SET_EMPLOYEE: "SET_EMPLOYEE",
	SET_OFFICE: "SET_OFFICE",
	SET_PROFILE_TYPE: "SET_PROFILE_TYPE",

	SET_DETAILS: "SET_DETAILS",
	SET_ID: "SET_ID",
	SET_IS_ASSIGNING: "SET_IS_ASSIGNING",
	SET_IS_ASSIGNED: "SET_IS_ASSIGNED",
	SET_IS_LOADING: "SET_IS_LOADING",
	SET_USER: "SET_USER"
};

const profileAssignmentTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default profileAssignmentTypes;
