import { createMap, createMapper, MappingProfile, forMember, mapFrom, addProfile, ignore, fromValue } from "@automapper/core";
import { classes } from "@automapper/classes";
import ApiCounterpartyEmployeeTrust from "@/api/types/counterparty/trust/apiCounterpartyEmployeeTrust";
import CounterpartyEmployeeTrust from "@/store/modules/counterpartyEmployee/modules/trust/types/counterpartyEmployeeTrust";
import { convertIsoToNumber, convertToZonedIso, convertToZonedTimestamp, timezoneNames } from "@/utils/dates";
import CounterpartyEmployeeTrustState from "@/store/modules/counterpartyEmployee/modules/trust/types/counterpartyEmployeeTrustState";
import ApiCounterpartyEmployeeTrustCreateRequest from "@/api/types/counterparty/trust/apiCounterpartyEmployeeTrustCreateRequest";
import CounterpartyEmployeeState from "@/store/modules/counterpartyEmployee/types/counterpartyEmployeeState";

const mapper = createMapper({
	strategyInitializer: classes()
});

const trustProfile: MappingProfile = (mapper) => {
	createMap(mapper, CounterpartyEmployeeState, ApiCounterpartyEmployeeTrustCreateRequest,
		forMember(d => d.counterpartyId, mapFrom(x => x.counterpartyId)),
		forMember(d => d.trusteeEmployeeId, mapFrom(x => x.id)),
		forMember(d => d.expireAt, ignore()),
		forMember(d => d.tempFileId, ignore()),
		forMember(d => d.trusteeSnils, mapFrom(x => x.employee.snils)),
		forMember(d => d.isMCHD, fromValue(true))
	);

	createMap(mapper, CounterpartyEmployeeTrustState, ApiCounterpartyEmployeeTrustCreateRequest,
		forMember(d => d.counterpartyId, ignore()),
		forMember(d => d.trusteeEmployeeId, ignore()),
		forMember(d => d.expireAt, mapFrom(x => convertToZonedIso(x.trust.expireAt))),
		forMember(d => d.tempFileId, mapFrom(x => x.trustFileMeta.id)),
		forMember(d => d.trusteeSnils, ignore()),
		forMember(d => d.isMCHD, fromValue(true))
	);

	createMap(mapper, ApiCounterpartyEmployeeTrust, CounterpartyEmployeeTrust,
		forMember(d => d.expireAt, mapFrom(x => convertToZonedTimestamp(x.expireAt, timezoneNames.GMT))),
		forMember(d => d.tempFileId, fromValue("")),
		forMember(d => d.fileId, mapFrom(x => x.fileId)),
		forMember(d => d.isSigned, mapFrom(x => x.isSigned)),
		forMember(d => d.acceptedAt, mapFrom(x => convertIsoToNumber(x.acceptedAt))),
		forMember(d => d.acceptorOfficeEmployeeId, mapFrom(x => x.acceptorOfficeEmployeeId)),
		forMember(d => d.declinedAt, mapFrom(x => convertIsoToNumber(x.declinedAt))),
		forMember(d => d.declinerOfficeEmployeeId, mapFrom(x => x.declinerOfficeEmployeeId)),
		forMember(d => d.canceledAt, mapFrom(x => convertIsoToNumber(x.canceledAt))),
		forMember(d => d.cancellerOfficeEmployeeId, mapFrom(x => x.cancellerOfficeEmployeeId)),
		forMember(d => d.status, mapFrom(x => x.status)),
		forMember(d => d.declineReason, mapFrom(x => x.declineReason)),
		forMember(d => d.isMCHD, mapFrom(x => x.isMCHD))
	);
};

addProfile(mapper, trustProfile);

export default mapper;
