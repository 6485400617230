<template>
	<frp-master-account-access-application-base-step :title="$t('titles.enterContactInformation')">
		<v-form v-model="isFormValid" ref="form" :readonly="isCreateMasterAccountRequestOperationExecuting">
			<v-container class="pa-0">
				<v-row no-gutters>
					<v-col cols="12">
						<p class="text-body-2">
							{{ $t("common.masterAccountAccessContactInformationStep.text") }}
						</p>
					</v-col>
					<v-col cols="12">
						<frp-text-field v-model="email"
										name="email"
										autocomplete="email"
										data-cy="email"
										:rules="validation.email"
										:label="$t('fields.simpleEmail.label')"
										type="email"
										:placeholder="$t('fields.simpleEmail.placeholder')">
						</frp-text-field>
					</v-col>
				</v-row>
			</v-container>
		</v-form>
	</frp-master-account-access-application-base-step>
</template>

<script>
import FrpMessageBox from "@/components/common/FrpMessageBox.vue";

import FrpTextField from "Components/fields/FrpTextField";
import { prepareEmailRule, requiredRule } from "Utils/validation";
import FrpMasterAccountAccessApplicationBaseStep from "Views/public/masterAccountAccess/steps/FrpMasterAccountAccessApplicationBaseStep";
import { createNamespacedHelpers } from "vuex";
import storeManager from "@/store/manager/index";
import contactInformationStepTypes from "Store/modules/masterAccountAccess/modules/contactInformationStep/types";
import FrpFormCol from "Components/form/FrpFormCol";
import storeModuleBasedPage from "Mixins/storeModuleBasedPage";

const {
	mapState,
	mapMutations,
	mapActions
} = createNamespacedHelpers(storeManager.masterAccountAccess.contactInformationStep.namespace);

const selectCounterpartyStepHelpers = createNamespacedHelpers(storeManager.masterAccountAccess.selectCounterpartyStep.namespace);

const masterAccountAccessHelpers = createNamespacedHelpers(storeManager.masterAccountAccess.namespace);

export default {
	mixins: [storeModuleBasedPage],
	props: {
		readonly: Boolean
	},
	data() {
		return {
			namespace: storeManager.masterAccountAccess.contactInformationStep.namespace
		};
	},
	computed: {
		...selectCounterpartyStepHelpers.mapState({
			isMasterAccountAlreadyExists: state => state.isMasterAccountAlreadyExists
		}),
		validation() {
			return {
				email: [requiredRule(), prepareEmailRule()]
			};
		},
		...masterAccountAccessHelpers.mapState({
			isCreateMasterAccountRequestOperationExecuting: state => state.isCreateMasterAccountRequestOperationExecuting
		}),
		...mapState({
			state: state => state
		}),
		email: {
			get() {
				return this.state.email;
			},
			set(value) {
				this.setEmail(value);
			}
		},
		isFormValid: {
			get() {
				return this.state.isFormValid;
			},
			set(value) {
				this.setIsFormValid(value);
			}
		}
	},
	methods: {
		...mapMutations({
			setIsFormValid: contactInformationStepTypes.mutationTypes.SET_IS_FORM_VALID,
			setEmail: contactInformationStepTypes.mutationTypes.SET_EMAIL
		})
	},
	async created() {
		await this.initializeStore();
	},
	watch: {
		email(value) {
			if(value)
				this.$refs.form.validate();
		}
	},
	components: { FrpMessageBox, FrpMasterAccountAccessApplicationBaseStep, FrpFormCol, FrpTextField }
};
</script>
