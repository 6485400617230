<template>
	<v-hover v-slot="{ hover }">
		<v-card class="frp-tile-link-btn rounded-lg d-flex justify-center align-center text-h6"
				ripple
				:ripple="!groupMode"
				@click="$emit('click')"
				@click.middle="$emit('click')"
				@mousedown="hideScroll"
				:class="[forceActive || hover ? 'secondary white--text' : 'white secondary--text', { 'frp-tile-link-btn--group-active active-shadow': groupMode && forceActive}, !forceActive ? 'frp-tile-link-btn--padding' : 'frp-tile-link-btn--transition-none']"
				:elevation="0"
				:height="$vuetify.breakpoint.xs ? '120' : '180'">
			<slot></slot>
		</v-card>
	</v-hover>
</template>

<script>
import colorsMixin from "@/mixins/colorsMixin";
import {mouseEvents} from "@/constants/events";

export default {
	mixins: [colorsMixin],
	props: {
		forceActive: Boolean,
		groupMode: Boolean
	},
	data() {
		return {
			mouseEvents
		}
	},
	methods: {
		hideScroll(event) {
			if (event.button === mouseEvents.middleButton)
				event.preventDefault()
		}
	},
	computed: {}
};
</script>

<style scoped lang="scss">
.frp-tile-link-btn {
	transition: all 0.2s cubic-bezier(0.4, 0, 0.6, 1);
	box-shadow: 3px 3px 12px rgba(169, 169, 191, 0.24) !important;
	font-weight: 300 !important;

	&:hover {
		box-shadow: 0px 32px 24px -16px rgba(193, 15, 15, 0.3) !important;
	}

	&:active {
		opacity: 0.62;
	}

	&--transition-none {
		transition: border 0s;
	}

	&--group-active:active {
		opacity: 1;
	}

	&--padding {
		padding: 10px;
	}
}

.active-shadow {
	box-shadow: 0px 32px 24px -16px rgba(193, 15, 15, 0.3) !important;
}
</style>
