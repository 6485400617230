import { IsString, Length } from "class-validator";

export default class EmployeeJoinApplicationsRouteQuery {
	@IsString()
	@Length(0, 100)
	query: String;

	@IsString()
	filterStatus: String;

	constructor(query: String, filterStatus: String) {
		this.query = query;
		this.filterStatus = filterStatus;
	}
}
