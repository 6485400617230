import { createMap, createMapper, MappingProfile, forMember, mapFrom, addProfile, fromValue } from "@automapper/core";
import { classes } from "@automapper/classes";
import CounterpartyEmployee from "@/store/modules/counterpartyEmployee/types/counterpartyEmployee";
import { ApiCounterpartyEmployeeBase } from "@/api/types/counterparty/apiCounterpartyEmployee";
import Counterparty from "@/store/modules/counterpartyEmployee/types/counterparty";
import ApiCounterpartyPersistedBase from "@/api/types/counterparty/apiCounterpartyPersistedBase";

const mapper = createMapper({
	strategyInitializer: classes()
});

const counterpartyEmployeeProfile: MappingProfile = (mapper) => {
	createMap(mapper, ApiCounterpartyPersistedBase, Counterparty,
		forMember(d => d.type, mapFrom(x => x.counterparty.type))
	);

	createMap(mapper, ApiCounterpartyEmployeeBase, CounterpartyEmployee,
		forMember(d => d.firstName, mapFrom(x => x.firstName)),
		forMember(d => d.lastName, mapFrom(x => x.lastName)),
		forMember(d => d.snils, mapFrom(x => x.snils)),
		forMember(d => d.middleName, mapFrom(x => x.middleName)),
		forMember(d => d.isStatusSaving, fromValue(false))
	);
};

addProfile(mapper, counterpartyEmployeeProfile);

export default mapper;
