<template>
	<v-container fluid class="pa-0">
		<v-row style="position: fixed; bottom: 0; left: 0; width: 100%"
			   class="ma-0 py-2 pr-3"
			   :class="backgroundColor">
			<v-col class="d-flex mr-5 justify-end">
				<slot></slot>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	props: {
		backgroundColor: {
			type: String,
			default: "white"
		}
	}
};
</script>
