import ApiCounterpartyEmployeeSnils from "@/api/types/counterparty/snils/apiCounterpartyEmployeeSnils";

export default class ApiCounterpartyEmployeeSnilsPersisted {
	id: string;
	snils: ApiCounterpartyEmployeeSnils;
	createdAt: string;
	updatedAt: string;

	constructor(id: string, snils: ApiCounterpartyEmployeeSnils, createdAt: string, updatedAt: string) {
		this.id = id;
		this.snils = snils;
		this.createdAt = createdAt;
		this.updatedAt = updatedAt;
	}
}
