import FrpRouterView from "@/components/common/FrpRouterView.vue";
import { stringifyQuery } from "@/utils/query";
import FrpAccount from "@/views/account/FrpAccount.vue";
import FrpAccountGeneral from "@/views/account/sections/FrpAccountGeneral.vue";
import FrpAccountProfiles from "@/views/account/sections/FrpAccountProfiles.vue";
import FrpAccounts from "@/views/accounts/FrpAccounts.vue";
import FrpCounterparties from "@/views/counterparties/FrpCounterparties.vue";
import FrpCounterparty from "@/views/counterparty/FrpCounterparty.vue";
import FrpCounterpartyEmployees from "@/views/counterparty/sections/employees/FrpCounterpartyEmployees.vue";
import FrpCounterpartyGeneral from "@/views/counterparty/sections/FrpCounterpartyGeneral.vue";
import FrpCounterpartyMasterAccount from "@/views/counterparty/sections/masterAccount/FrpCounterpartyMasterAccount.vue";
import FrpCounterpartyEmployee from "@/views/counterpartyEmployee/FrpCounterpartyEmployee.vue";
import FrpCounterpartyEmployeeGeneral from "@/views/counterpartyEmployee/FrpCounterpartyEmployeeGeneral.vue";
import FrpCounterpartyEmployeeProfile from "@/views/counterpartyEmployee/FrpCounterpartyEmployeeProfile.vue";
import FrpOfficeEmployeeProfile from "@/views/officeEmployee/FrpOfficeEmployeeProfile.vue";
import FrpCounterpartyEmployeeTrust from "@/views/counterpartyEmployee/FrpCounterpartyEmployeeTrust.vue";
import FrpCounterpartyEmployeeTrusts from "@/views/counterpartyEmployee/FrpCounterpartyEmployeeTrusts.vue";
import FrpInternalServerError from "@/views/errors/FrpInternalServerError.vue";
import FrpAccessDeniedError from "@/views/errors/FrpAccessDeniedError.vue";
import FrpPageNotFound from "@/views/errors/FrpPageNotFound.vue";
import FrpProfileAssignment from "@/views/profileAssignment/FrpProfileAssignment.vue";
import FrpSilentRenew from "@/views/FrpSilentRenew.vue";
import FrpOffice from "@/views/office/FrpOffice.vue";
import FrpOfficeEmployee from "@/views/officeEmployee/FrpOfficeEmployee.vue";
import FrpOfficeEmployeeGeneral from "@/views/officeEmployee/FrpOfficeEmployeeGeneral.vue";
import FrpOfficeGeneral from "@/views/office/sections/FrpOfficeGeneral.vue";
import FrpOfficeEmployees from "@/views/office/sections/FrpOfficeEmployees.vue";
import FrpMasterAccountAccess from "@/views/public/masterAccountAccess/FrpMasterAccountAccess.vue";
import FrpEmployeeJoin from "@/views/employeeJoin/FrpEmployeeJoin.vue";
import Vue from "vue";
import VueRouter from "vue-router";
import FrpOuterLayout from "@/components/layouts/FrpOuterLayout.vue";
import FrpLayout from "@/views/layouts/FrpLayout.vue";
import FrpEmptyLayout from "@/views/layouts/FrpEmptyLayout.vue";
import FrpMasterAccountAccessApplicationsList
	from "@/views/applications/masterAccountAccess/list/FrpMasterAccountAccessApplicationsList.vue";
import FrpMasterAccountApplicationDetails from "@/views/applications/masterAccountAccess/details/FrpMasterAccountApplicationDetails.vue";
import FrpCallback from "@/views/FrpCallback.vue";
import FrpConfirmEmail from "@/views/public/confirmEmail/FrpСonfirmEmail.vue";
import FrpConfirmPhone from "@/views/public/confirmPhone/FrpСonfirmPhone.vue";
import FrpEmployeeJoinApplicationsList from "@/views/applications/employeeJoin/list/FrpEmployeeJoinApplicationsList.vue";
import FrpEmployeeJoinApplicationDetails from "@/views/applications/employeeJoin/details/FrpEmployeeJoinApplicationDetails.vue";
import { RouteNames } from "@/router/routes";
import { Permissions } from "@/constants/permissions";
import { configureMiddleware } from "@/router/middleware";
import FrpApps from "@/views/apps/FrpApps.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: "/callback.html",
		component: FrpCallback,
		meta: { public: true },
		name: RouteNames.CALLBACK
	},
	{
		path: "/silent-renew.html",
		component: FrpSilentRenew,
		meta: { public: true },
		name: RouteNames.SILENT_RENEW
	},
	{
		path: "/",
		component: FrpLayout,
		name: RouteNames.ROOT,
		children: [
			{
				path: "/apps",
				component: FrpApps,
				name: RouteNames.APPS
			},
			{
				path: "applications",
				component: FrpRouterView,
				name: RouteNames.APPLICATIONS,
				redirect: { name: RouteNames.APPLICATIONS_MASTER_ACCOUNT_ACCESSES },
				children: [
					{
						path: "master-account-accesses",
						component: FrpRouterView,
						children: [
							{
								path: "",
								name: RouteNames.APPLICATIONS_MASTER_ACCOUNT_ACCESSES,
								component: FrpMasterAccountAccessApplicationsList
							},
							{
								path: ":id",
								name: RouteNames.APPLICATIONS_MASTER_ACCOUNT_ACCESS_DETAILS,
								component: FrpMasterAccountApplicationDetails
							}
						]
					},
					{
						path: "employee-join",
						component: FrpRouterView,
						children: [
							{
								path: "",
								name: RouteNames.APPLICATIONS_EMPLOYEE_JOIN_LIST,
								component: FrpEmployeeJoinApplicationsList
							},
							{
								path: ":id",
								name: RouteNames.APPLICATIONS_EMPLOYEE_JOIN_DETAILS,
								component: FrpEmployeeJoinApplicationDetails
							}
						]
					}
				]
			},
			{
				path: "office",
				component: FrpRouterView,
				children: [
					{
						path: "",
						name: RouteNames.OFFICE,
						redirect: { name: RouteNames.OFFICE_GENERAL },
						component: FrpOffice,
						children: [
							{
								path: "",
								meta: { key: "office-update" },
								name: RouteNames.OFFICE_GENERAL,
								component: FrpOfficeGeneral
							},
							{
								path: "employees",
								meta: { key: "office-update" },
								name: RouteNames.OFFICE_EMPLOYEES,
								component: FrpOfficeEmployees
							}
						]
					}
				]
			},
			{
				path: "office/employee",
				redirect: { name: RouteNames.OFFICE_EMPLOYEE_CREATE },
				component: FrpOfficeEmployee,
				children: [
					{
						path: "",
						name: RouteNames.OFFICE_EMPLOYEE_CREATE,
						component: FrpOfficeEmployeeGeneral
					}
				]
			},
			{
				path: "office/employee/:employeeId",
				name: RouteNames.OFFICE_EMPLOYEE,
				redirect: { name: RouteNames.OFFICE_EMPLOYEE_GENERAL },
				component: FrpOfficeEmployee,
				children: [
					{
						meta: { key: "office-employee-update" },
						path: "",
						name: RouteNames.OFFICE_EMPLOYEE_GENERAL,
						component: FrpOfficeEmployeeGeneral
					},
					{
						meta: { key: "office-employee-update" },
						path: "profile",
						name: RouteNames.OFFICE_EMPLOYEE_PROFILE,
						component: FrpOfficeEmployeeProfile
					}
				]
			},
			{
				path: "counterparties",
				name: RouteNames.COUNTERPARTIES,
				component: FrpCounterparties
			},
			{
				path: "counterparty",
				component: FrpCounterparty,
				redirect: { name: RouteNames.COUNTERPARTY_CREATE },
				children: [
					{
						meta: { key: "counterparty-create" },
						path: "",
						name: RouteNames.COUNTERPARTY_CREATE,
						component: FrpCounterpartyGeneral
					}
				]
			},
			{
				path: "counterparty",
				component: FrpRouterView,
				children: [
					{
						path: ":id",
						name: RouteNames.COUNTERPARTY,
						redirect: { name: RouteNames.COUNTERPARTY_GENERAL },
						component: FrpCounterparty,
						children: [
							{
								meta: { key: "counterparty-update" },
								path: "",
								name: RouteNames.COUNTERPARTY_GENERAL,
								component: FrpCounterpartyGeneral
							},
							{
								meta: { key: "counterparty-update" },
								path: "employees",
								name: RouteNames.COUNTERPARTY_EMPLOYEES,
								component: FrpCounterpartyEmployees
							},
							{
								meta: { key: "counterparty-update" },
								path: "master-account",
								name: RouteNames.COUNTERPARTY_MASTER_ACCOUNT,
								component: FrpCounterpartyMasterAccount
							}
						]
					}
				]
			},
			{
				path: "counterparty/:counterpartyId/employee",
				component: FrpCounterpartyEmployee,
				redirect: { name: RouteNames.COUNTERPARTY_EMPLOYEE_CREATE },
				children: [
					{
						path: "",
						name: RouteNames.COUNTERPARTY_EMPLOYEE_CREATE,
						component: FrpCounterpartyEmployeeGeneral
					}
				]
			},
			{
				path: "counterparty/:counterpartyId/employee/:employeeId",
				component: FrpCounterpartyEmployee,
				name: RouteNames.COUNTERPARTY_EMPLOYEE,
				redirect: { name: RouteNames.COUNTERPARTY_EMPLOYEE_GENERAL },
				children: [
					{
						meta: { key: "counterparty-employee-update" },
						path: "",
						name: RouteNames.COUNTERPARTY_EMPLOYEE_GENERAL,
						component: FrpCounterpartyEmployeeGeneral
					},
					{
						meta: { key: "counterparty-employee-update" },
						path: "trusts",
						name: RouteNames.COUNTERPARTY_EMPLOYEE_TRUSTS,
						component: FrpCounterpartyEmployeeTrusts
					},
					{
						meta: { key: "counterparty-employee-update" },
						path: "profile",
						name: RouteNames.COUNTERPARTY_EMPLOYEE_PROFILE,
						component: FrpCounterpartyEmployeeProfile
					},
					{
						meta: { key: "counterparty-employee-update" },
						path: "trust",
						redirect: { name: RouteNames.COUNTERPARTY_EMPLOYEE_TRUST },
						component: FrpRouterView,
						children: [
							{
								path: "",
								meta: {
									key: "counterparty-employee-update"
								},
								name: RouteNames.COUNTERPARTY_EMPLOYEE_TRUST_CREATE,
								component: FrpCounterpartyEmployeeTrust
							},
							{
								path: ":id",
								meta: {
									key: "counterparty-employee-update"
								},
								name: RouteNames.COUNTERPARTY_EMPLOYEE_TRUST,
								component: FrpCounterpartyEmployeeTrust
							}
						]
					}
				]
			},
			{
				path: "accounts",
				name: RouteNames.ACCOUNTS,
				component: FrpAccounts
			},
			{
				path: "account",
				component: FrpRouterView,
				children: [
					{
						path: ":id",
						name: RouteNames.ACCOUNT,
						redirect: { name: RouteNames.ACCOUNT_GENERAL },
						component: FrpAccount,
						children: [
							{
								path: "",
								meta: { key: "account-update" },
								name: RouteNames.ACCOUNT_GENERAL,
								component: FrpAccountGeneral
							},
							{
								path: "profiles",
								meta: { key: "account-update" },
								name: RouteNames.ACCOUNT_PROFILES,
								component: FrpAccountProfiles
							}
						]
					}
				]
			},
			{
				path: "profile/:id",
				name: RouteNames.PROFILE_ASSIGNMENT,
				component: FrpProfileAssignment
			},
			{
				path: "employee-join",
				name: RouteNames.EMPLOYEE_JOIN,
				component: FrpEmployeeJoin
			}
		]
	},
	{
		path: "/",
		component: FrpEmptyLayout,
		children: [
			{
				path: "",
				component: FrpOuterLayout,
				children: [
					{
						path: "master-account-access",
						name: RouteNames.MASTER_ACCOUNT_ACCESS,
						meta: { public: true },
						component: FrpMasterAccountAccess
					},
					{
						path: "confirm-email",
						component: FrpConfirmEmail,
						name: RouteNames.CONFIRM_EMAIL
					},
					{
						path: "confirm-phone",
						component: FrpConfirmPhone,
						name: RouteNames.CONFIRM_PHONE
					}
				]
			}
		]
	},
	{
		path: "/",
		component: FrpEmptyLayout,
		meta: { public: true },
		children: [
			{
				path: "internal-server-error",
				component: FrpInternalServerError,
				name: RouteNames.ERROR_INTERNAL_SERVER_ERROR
			},
			{
				path: "access-denied",
				component: FrpAccessDeniedError,
				name: RouteNames.ERROR_ACCESS_DENIED
			},
			{
				path: "*",
				component: FrpPageNotFound,
				name: RouteNames.ERROR_PAGE_NOT_FOUND
			}
		]
	}
];

const router = new VueRouter({
	routes,
	mode: "history",
	stringifyQuery: query => {
		// @ts-ignore
		let result = stringifyQuery(query);
		return result ? ("?" + result) : "";
	}
});

configureMiddleware(router);

export default router;
