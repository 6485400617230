import ListingModel from "@/store/shared/listing/models/listingModel";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchModel from "@/store/shared/search/models/searchModel";
import CounterpartiesFilter from "@/store/modules/counterparties/types/counterpartiesFilter";
import RouteState from "@/store/shared/route/types/routeState";
import ApiCounterpartyPersistedBase from "@/api/types/counterparty/apiCounterpartyPersistedBase";
import IPageState from "@/store/shared/base/types/pageState";
import SearchSuggestion from "@/services/searchSuggestion/searchSuggestion";

export default class MasterAccountAccessApplicationsState implements IPageState {
	constructor(
		public listing: ListingModel<ApiCounterpartyPersistedBase>,
		public sorting: SortingModel<String>,
		public paging: PagingModel,
		public search: SearchModel,
		public filter: CounterpartiesFilter,
		public route: RouteState,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public searchSuggestions: SearchSuggestion[] = []
	)
	{
	}
}
