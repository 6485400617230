import AppsState from "@/store/modules/apps/types/appsState";
import { namespace, actionTypes, mutationTypes, getterTypes } from "@/store/modules/apps/types";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import BaseMixinBuilder from "@/store/shared/base";
import AbortService from "@/services/abortService";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import { AuthorizationController } from "@/api/authorization";
import router from "@/router";
import { first } from "lodash";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { ApplicationModeType } from "@/types/ApplicationModeType";
import { LoanAuthorizationController } from "@/api/loan/authorization";
import { getApplicationMode } from "@/utils/configuration";
import ApiApp from "@/api/types/app/apiApp";
import { RouteNames } from "@/router/routes";
import { plainToInstance } from "class-transformer";
import AccruedInterest from "@/store/loan/modules/accruedInterest/types/accruedInterest";

const abortService = new AbortService();
const baseMixin = (new BaseMixinBuilder(abortService)).build();

const mode = getApplicationMode();

const authorizationController = mode === ApplicationModeType.OLK
	? new AuthorizationController(abortService)
	: new LoanAuthorizationController(abortService);

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new AppsState();
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();


const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<AppsState, any>>{};

const actions = <ActionTree<AppsState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit, state }) {
		await dispatch(actionTypes.initializeBase);
		await dispatch(actionTypes.fetch);

		commit(mutationTypes.SET_IS_INITIALIZED, true);
	},
	async [actionTypes.fetch]({ dispatch, commit, state }) {
		commit(mutationTypes.SET_IS_LOADING, true);

		try {
			//@ts-ignore
			const apps: ApiApp[] = await authorizationController.getApps();

			if(!apps.length) {
				await router.push({ name: RouteNames.ROOT }).catch(() => {});
			} else if(apps.length > 1) {
				commit(mutationTypes.SET_ITEMS, apps);
			} else {
				// @ts-ignore
				window.location.href = first(apps).url;
			}
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_LOADING, false);
		}
	}
};

const mutations = <MutationTree<AppsState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	[mutationTypes.SET_IS_INITIALIZED](state, value) {
		state.isInitialized = value;
	},
	[mutationTypes.SET_ITEMS](state, value) {
		state.items = value;
	},
	[mutationTypes.SET_IS_LOADING](state, value) {
		state.isLoading = value;
	}
};

export {
	namespace, state, getters, actions, mutations
};

const breadcrumbsModule = {
	namespace, state, getters, actions, mutations, namespaced: true
};

export default breadcrumbsModule;
