<template>
	<frp-nested-content-layout v-if="isInitialized">
		<template #title>
			<span class="text-h5 primary--text text--darken-1">
				{{ title }}
			</span>
		</template>
		<template #content>
			<template>
				<frp-tabs v-if="shouldShowTabs">
					<v-tab key="general"
						   v-if="can(Permissions.GLOBAL_ACCOUNT_READ)"
						   class="text-none"
						   :to="{ name: RouteNames.ACCOUNT_GENERAL, params: { id } }">
						{{ $t("subheaders.general") }}
					</v-tab>
					<v-tab key="profiles"
						   v-if="can(Permissions.GLOBAL_ACCOUNT_PROFILES_READ)"
						   class="text-none"
						   :to="{ name: RouteNames.ACCOUNT_PROFILES, params: { id } }">
						{{ $t("subheaders.profiles") }}
					</v-tab>
				</frp-tabs>
				<router-view :key="$route.meta.key || $route.name"/>
				<frp-bottom-space></frp-bottom-space>
			</template>
		</template>
	</frp-nested-content-layout>
	<frp-account-loader v-else></frp-account-loader>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn";
import FrpToggleActivityBtn from "@/components/buttons/FrpToggleActivityBtn";
import FrpTabs from "@/components/tabs/FrpTabs";
import authorizationMixin from "@/mixins/authorizationMixin";
import { RouteNames } from "@/router/routes";
import FrpNestedContentLayout from "Components/layouts/FrpNestedContentLayout";
import FrpPageNotFound from "Views/errors/FrpPageNotFound";
import FrpAccountLoader from "Views/account/FrpAccountLoader";
import FrpBottomSpace from "Components/layouts/FrpBottomSpace";
import storeManager from "Store/manager";
import storeModuleBasedPage from "Mixins/storeModuleBasedPage";
import colorsMixin from "Mixins/colorsMixin";
import { createNamespacedHelpers } from "vuex";
import { namespace } from "Store/modules/account/types";

const { mapState, mapMutations, mapActions } = createNamespacedHelpers(namespace);
const generalModuleHelpers = createNamespacedHelpers(storeManager.account.general.namespace);

export default {
	mixins: [storeModuleBasedPage, colorsMixin, authorizationMixin],
	data() {
		return {
			namespace,
			RouteNames
		};
	},
	computed: {
		...mapState({
			id: state => state.id,
			account: state => state.account,
			isLoading: state => state.isLoading,
			isInitialized: state => state.isInitialized
		}),
		isCreateMode() {
			return !this.$route.params.id;
		},
		shouldShowTabs() {
			return !this.isCreateMode;
		},
		id() {
			return this.$route.params.id;
		},
		title() {
			switch (this.$route.name) {
				case RouteNames.ACCOUNT_GENERAL:
				case RouteNames.ACCOUNT_PROFILES:
					return this.account.login;
			}
		}
	},
	created() {
		this.initializeStore({ id: this.id });
	},
	components: {
		FrpBtn,
		FrpToggleActivityBtn,
		FrpTabs,
		FrpNestedContentLayout,
		FrpAccountLoader,
		FrpBottomSpace,
		FrpPageNotFound
	}
};
</script>

<style scoped>

</style>
