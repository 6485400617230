import ApiFnsLegalPerson from "@/api/types/fns/apiFnsLegalPerson";

export default class ApiLegalPersonMasterAccountAccessFromEmployeeApplicationCreate {
	email: string;
	entrepreneur: ApiFnsLegalPerson;

	constructor(email: string, entrepreneur: ApiFnsLegalPerson) {
		this.email = email;
		this.entrepreneur = entrepreneur;
	}
}
