<template>
	<frp-nested-content-layout v-if="isInitialized">
		<template #title>
			<span class="text-h5 primary--text text--darken-1 d-flex flex-column">
				<span>{{ $t("titles.inviteToJoinAnOrganization") }}</span>
				<span>{{ title }}</span>
			</span>
		</template>
		<template #content>
			<v-card class="pa-8 pb-1 d-flex align-start flex-wrap"
					:style="{ 'column-gap': $vuetify.breakpoint.lgAndDown ? '56px' : '128px' }" elevation="0">
				<v-row>
					<frp-details-section-column>
						<frp-employee-details :title="$t('details.titles.employeeGeneral')"
											  :details="details.employee"></frp-employee-details>
					</frp-details-section-column>
				</v-row>
			</v-card>
			<frp-approving-actions @approve="handleApprove"
								   @reject="rejectApplication"
								   :is-approving="isApproving"
								   :is-rejecting="isRejecting"
								   :is-approve-disabled="details.status !== ApproveStatusType.CREATED"
								   :is-reject-disabled="details.status !== ApproveStatusType.CREATED">
			</frp-approving-actions>
		</template>
	</frp-nested-content-layout>
	<frp-employee-join-application-details-loader v-else/>
</template>

<script>
import storeModuleBasedPage from "@/mixins/storeModuleBasedPage";
import { RouteNames } from "@/router/routes";
import FrpEmployeeDetails from "Components/details/FrpEmployeeDetails";
import FrpNestedContentLayout from "Components/layouts/FrpNestedContentLayout";
import FrpEmployeeJoinApplicationDetailsLoader from "Views/applications/employeeJoin/details/FrpEmployeeJoinApplicationDetailsLoader";
import FrpDetailsSectionColumn from "Components/details/FrpDetailsSectionColumn";
import FrpIcon from "Components/icon/FrpIcon";
import FrpDetailsItem from "Components/details/FrpDetailsItem";
import FrpDetailsSubheader from "Components/details/FrpDetailsSubheader";
import FrpDetailsGroup from "Components/details/FrpDetailsGroup";
import FrpFile from "Components/common/FrpFile";
import FrpDetailsSectionTitle from "Components/details/FrpDetailsSectionTitle";
import FrpApprovingActions from "Components/buttons/FrpApprovingActions";
import FrpPageNotFound from "Views/errors/FrpPageNotFound";
import { ApproveStatusType } from "Types/ApproveStatusType";
import colorsMixin from "Mixins/colorsMixin";
import { actionTypes, getterTypes, namespace } from "Store/modules/applications/employeeJoin/details/types";
import { createNamespacedHelpers } from "vuex";
import { CounterpartyType } from "Types/CounterpartyType";

const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);

export default {
	mixins: [colorsMixin, storeModuleBasedPage],
	data() {
		return {
			namespace,
			CounterpartyType,
			ApproveStatusType,
			RouteNames
		};
	},
	computed: {
		...mapState({
			details: state => state.details,
			isLoading: state => state.isLoading,
			isApproving: state => state.isApproving,
			isRejecting: state => state.isRejecting,
			isInitialized: state => state.isInitialized
		}),
		...mapGetters({
			title: getterTypes.title
		}),
		id() {
			return this.$route.params.id;
		}
	},
	methods: {
		...mapActions({
			approveApplication: actionTypes.approveApplication,
			rejectApplication: actionTypes.rejectApplication
		}),
		async handleApprove() {
			await this.approveApplication();
		}
	},
	async created() {
		await this.initializeStore({ id: this.id });
	},
	components: {
		FrpEmployeeDetails,
		FrpNestedContentLayout,
		FrpIcon,
		FrpDetailsItem,
		FrpDetailsSubheader,
		FrpDetailsGroup,
		FrpFile,
		FrpDetailsSectionTitle,
		FrpEmployeeJoinApplicationDetailsLoader,
		FrpDetailsSectionColumn,
		FrpApprovingActions,
		FrpPageNotFound
	}
};
</script>
