<template>
	<frp-nested-content-layout v-if="isInitialized">
		<template #title>
			<span class="text-h5 primary--text text--darken-1">
				{{ $t("titles.inviteAnEmployee") }}
			</span>
		</template>
		<template #content>
			<v-card class="pa-8 pb-1 d-flex align-start flex-wrap"
					:style="{ 'column-gap': $vuetify.breakpoint.lgAndDown ? '56px' : '128px' }" elevation="0">
				<v-row v-if="profileType === ProfileType.OFFICE_EMPLOYEE">
					<frp-details-section-column>
						<frp-legal-entity-details :title="$t('details.titles.officeGeneral')"
												  :details="officePersisted.office"></frp-legal-entity-details>
					</frp-details-section-column>
					<frp-details-section-column>
						<frp-employee-details :title="$t('details.titles.employeeGeneral')"
											  :details="employeePersisted.employee"></frp-employee-details>
					</frp-details-section-column>
				</v-row>
				<v-row v-else>
					<frp-details-section-column v-if="profileType === ProfileType.COUNTERPARTY_LEGAL_ENTITY_EMPLOYEE">
						<frp-legal-entity-details :title="$t('details.titles.legalEntityGeneral')"
												  :details="counterpartyPersisted.counterparty"></frp-legal-entity-details>
					</frp-details-section-column>
					<frp-details-section-column v-else-if="profileType === ProfileType.COUNTERPARTY_ENTREPRENEUR_EMPLOYEE">
						<frp-legal-person-details :title="$t('details.titles.legalPersonGeneral')"
												  :details="counterpartyPersisted.counterparty"></frp-legal-person-details>
					</frp-details-section-column>
					<frp-details-section-column>
						<frp-employee-details :title="$t('details.titles.employeeGeneral')"
											  :details="employeePersisted.employee"></frp-employee-details>
					</frp-details-section-column>
				</v-row>
			</v-card>
			<frp-bottom-space></frp-bottom-space>

			<v-container fluid class="pa-0">
				<v-row style="position: fixed; bottom: 0; left: 0; width: 100%" class="white ma-0 py-2 pr-3">
					<v-col class="d-flex justify-end mr-5">
						<frp-btn
							   data-cy="approve-btn"
							   :disabled="isAssigned"
							   :loading="isAssigning"
							   @click="assignProfile">
							{{ $t("buttons.confirm") }}
						</frp-btn>
					</v-col>
				</v-row>
			</v-container>
		</template>
	</frp-nested-content-layout>
	<frp-profile-assignment-loader v-else></frp-profile-assignment-loader>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn";
import FrpLegalPersonDetails from "@/components/details/FrpLegalPersonDetails";
import FrpEmployeeDetails from "Components/details/FrpEmployeeDetails";
import FrpLegalEntityDetails from "Components/details/FrpLegalEntityDetails";
import FrpNestedContentLayout from "Components/layouts/FrpNestedContentLayout";
import FrpApprovingActions from "Components/buttons/FrpApprovingActions";
import FrpDetailsSectionColumn from "Components/details/FrpDetailsSectionColumn";
import FrpDetailsGroup from "Components/details/FrpDetailsGroup";
import FrpFile from "Components/common/FrpFile";
import FrpDetailsSectionTitle from "Components/details/FrpDetailsSectionTitle";
import FrpDetailsItem from "Components/details/FrpDetailsItem";
import FrpDetailsSectionTitleLoader from "Components/loaders/details/FrpDetailsSectionTitleLoader";
import FrpFileLoader from "Components/loaders/common/FrpFileLoader";
import FrpDetailsItemLoader from "Components/loaders/details/FrpDetailsItemLoader";
import FrpBottomSpace from "Components/layouts/FrpBottomSpace";
import FrpPageNotFound from "Views/errors/FrpPageNotFound";
import { ProfileType } from "Types/ProfileType";
import FrpProfileAssignmentLoader from "Views/profileAssignment/FrpProfileAssignmentLoader";
import SecurityService from "Services/auth/securityService";
import storeModuleBasedPage from "@/mixins/storeModuleBasedPage";
import { actionTypes, namespace } from "Store/modules/profileAssignment/types";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);
const securityService = new SecurityService();

export default {
	mixins: [storeModuleBasedPage],
	data() {
		return {
			namespace,
			ProfileType
		};
	},
	computed: {
		...mapState({
			isAssigning: state => state.isAssigning,
			isAssigned: state => state.isAssigned,
			isInitialized: state => state.isInitialized,
			counterpartyPersisted: state => state.counterparty,
			officePersisted: state => state.office,
			profileType: state => state.profileType,
			employeePersisted: state => state.employee
		})
	},
	methods: {
		...mapActions({
			assignProfile: actionTypes.assignProfile
		})
	},
	async created() {
		await this.initializeStore({ id: this.$route.params.id });
	},
	components: {
		FrpBtn,
		FrpLegalPersonDetails,
		FrpEmployeeDetails,
		FrpLegalEntityDetails,
		FrpNestedContentLayout,
		FrpApprovingActions,
		FrpDetailsSectionColumn,
		FrpDetailsGroup,
		FrpFile,
		FrpDetailsSectionTitle,
		FrpDetailsItem,
		FrpDetailsSectionTitleLoader,
		FrpFileLoader,
		FrpDetailsItemLoader,
		FrpProfileAssignmentLoader,
		FrpBottomSpace,
		FrpPageNotFound
	}
};
</script>
