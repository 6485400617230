import FormState from "@/store/shared/form/models/formState";
import Account from "@/store/modules/account/modules/general/types/account";
import IPageState from "@/store/shared/base/types/pageState";

export default class CounterpartyMasterAccountState implements IPageState {
	constructor(
		public form: FormState,
		public snapshot: object,
		public account: Account = new Account(),
		public counterpartyId: string = "",
		public accountId: string = "",
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public isStatusSaving: boolean = false
	)
	{
	}
}
