import ApiAccountProfilePersistedBase from "@/api/types/account/apiAccountProfilePersistedBase";
import ApiCounterpartyPersistedBase from "@/api/types/counterparty/apiCounterpartyPersistedBase";
import ApiCounterpartyEmployeePersistedBase from "@/api/types/counterparty/apiCounterpartyEmployeePersistedBase";
import { Type } from "class-transformer";
import ApiOfficePersistedBase from "@/api/types/office/apiOfficePersisted";
import ApiOfficeEmployeePersistedBase from "@/api/types/office/apiOfficeEmployeePersistedBase";

export default class ApiEmployeeInvitation {
	@Type(() => ApiAccountProfilePersistedBase)
	profile: ApiAccountProfilePersistedBase;

	@Type(() => ApiCounterpartyPersistedBase)
	employer: ApiCounterpartyPersistedBase;

	@Type(() => ApiCounterpartyEmployeePersistedBase)
	employee: ApiCounterpartyEmployeePersistedBase;

	@Type(() => ApiOfficePersistedBase)
	office: ApiOfficePersistedBase;

	@Type(() => ApiOfficeEmployeePersistedBase)
	officeEmployee: ApiOfficeEmployeePersistedBase;

	constructor(profile: ApiAccountProfilePersistedBase,
		employer: ApiCounterpartyPersistedBase,
		employee: ApiCounterpartyEmployeePersistedBase,
		office: ApiOfficePersistedBase,
		officeEmployee: ApiOfficeEmployeePersistedBase)
	{
		this.profile = profile;
		this.employer = employer;
		this.employee = employee;
		this.office = office;
		this.officeEmployee = officeEmployee;
	}
}
