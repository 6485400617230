import urls, { urlTemplateParts } from "@/api/config/urls";
import BaseController from "@/api/shared/baseController";
import AbortService from "@/services/abortService";
import SnilsRecognitionResult from "@/api/types/documents/snilsRecognitionResult";
import ApiSnilsPersisted from "@/api/types/snils/apiSnilsPersisted";
import { plainToInstance } from "class-transformer";

export class SnilsRecognitionController extends BaseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}

	getRecognizedSnils = async (fileId: string): Promise<string | null> => {
		const url = urls.snils.recognition.recognize.replace(urlTemplateParts.id, fileId);

		let { snilsCard } = await this.client.get<{ snilsCard: SnilsRecognitionResult | null }>(url);

		return snilsCard && snilsCard.snils;
	};
}
