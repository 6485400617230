import AccountsState from "@/store/modules/accounts/types/accountsState";
import ApiGetAccountsParameters from "@/api/types/account/apiGetAccountsParameters";

export const mapApiGetAccountsParameters = (state: AccountsState) => {
	return new ApiGetAccountsParameters(state.paging.pageSize,
		(state.paging.pageSize * (state.paging.page - 1)),
		state.search.query,
		state.sorting.type,
		state.sorting.order);
};
