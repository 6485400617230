import { Type } from "class-transformer";
import ApiLegalEntityFnsUpdate from "@/api/types/fns/apiLegalEntityFnsUpdate";

export default class ApiLegalEntityFnsUpdatePersisted {
	id: string;
	createdAt: string;
	updatedAt: string;
	@Type(() => ApiLegalEntityFnsUpdate)
	upToDateLegalEntity: ApiLegalEntityFnsUpdate;

	constructor(id = "", createdAt = "", updatedAt = "", upToDateLegalEntity = new ApiLegalEntityFnsUpdate()) {
		this.id = id;
		this.createdAt = createdAt;
		this.updatedAt = updatedAt;
		this.upToDateLegalEntity = upToDateLegalEntity;
	}
}
