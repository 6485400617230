<template>
	<div class="frp-form-item d-flex flex-column">
		<slot></slot>
	</div>
</template>

<script>
export default {};
</script>

<style scoped lang="less">
.frp-form-item {
	& + & {
		margin-top: 4px;
	}
}
</style>
