import ApiCounterpartyEmployeePersistedBase from "@/api/types/counterparty/apiCounterpartyEmployeePersistedBase";
import ApiCounterpartyEmployeeTrustInfo from "@/api/types/counterparty/ApiCounterpartyEmployeeTrustInfo";

export default class ApiCounterpartyEmployeeWithTrustInfo {
	employee: ApiCounterpartyEmployeePersistedBase;
	trust: ApiCounterpartyEmployeeTrustInfo;

	constructor(
		employee: ApiCounterpartyEmployeePersistedBase = new ApiCounterpartyEmployeePersistedBase(),
		trust: ApiCounterpartyEmployeeTrustInfo = new ApiCounterpartyEmployeeTrustInfo()
	)
	{
		this.employee = employee;
		this.trust = trust;
	}
}
