import {
	namespace,
	actionTypes,
	mutationTypes,
	getterTypes
} from "@/store/modules/counterpartyEmployee/modules/trusts/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import SortingMixinBuilder from "@/store/shared/sorting";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import ListingMixinBuilder from "@/store/shared/listing";
import ListingModel from "@/store/shared/listing/models/listingModel";
import PagingMixinBuilder from "@/store/shared/paging";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchMixinBuilder from "@/store/shared/search";
import { sortingOrderType } from "@/store/shared/sorting/models/types/sortingOrderType";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import TrustsState from "@/store/modules/counterpartyEmployee/modules/trusts/types/trustsState";
import Trust from "@/store/modules/counterpartyEmployee/modules/trusts/types/trust";
import { resolveNestedState } from "@/utils/vuexModules";
import storeManager from "@/store/manager";
import CounterpartyEmployeeState from "@/store/modules/counterpartyEmployee/types/counterpartyEmployeeState";
import mapper from "@/store/modules/counterpartyEmployee/modules/trusts/mapper";
import { TrustController } from "@/api/counterparty/trust";
import AbortService from "@/services/abortService";
import ApiCounterpartyEmployeeTrustPersisted from "@/api/types/counterparty/trust/apiCounterpartyEmployeeTrustPersisted";
import { Permissions } from "@/constants/permissions";
import { AuthorizationScopeType } from "@/types/authorization/authorizationScopeType";
import PermissionsService from "@/services/permissionsService";

const abortService = new AbortService();
const trustController = new TrustController(abortService);
const permissionsService = new PermissionsService();

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new TrustsState(
			new ListingModel<Trust>({
				items: [],
				isLoadingState: false
			}),
			new SortingModel<String>({
				type: "createdAt",
				order: sortingOrderType.descending
			}),
			new PagingModel({
				total: 0,
				page: 1,
				pageSize: 25
			})
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();
const baseMixin = (new BaseMixinBuilder(abortService)).build();
const listingMixin = (new ListingMixinBuilder()).build();
const pagingMixin = (new PagingMixinBuilder()).build();
const sortingMixin = (new SortingMixinBuilder()).build();
const searchMixin = (new SearchMixinBuilder()).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<TrustsState, any>>{
	...listingMixin.getters,
	[getterTypes.formattedItems]: state => {
		return state.listing.items;
	}
};

const actions = <ActionTree<TrustsState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...listingMixin.actions,
	...pagingMixin.actions,
	...sortingMixin.actions,
	...searchMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit }) {
		await dispatch(actionTypes.initializeBase);

		await dispatch(actionTypes.updateListingItems);

		commit(mutationTypes.SET_IS_INITIALIZED, true);
	},
	async [actionTypes.updateListingItems]({ state, rootState, commit, dispatch }) {
		commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, true);

		let { counterpartyId, id } = resolveNestedState<CounterpartyEmployeeState>(rootState, storeManager.counterpartyEmployee.namespace);

		try {
			const scope = await permissionsService.check([Permissions.GLOBAL_COUNTERPARTY_EMPLOYEE_TRUSTS_READ])
				? AuthorizationScopeType.GLOBAL
				: AuthorizationScopeType.OWN;

			let trusts = await trustController.getCounterpartyEmployeeTrusts(counterpartyId, id, scope);

			commit(mutationTypes.SET_LISTING_ITEMS, trusts.map(x => mapper.map(x, ApiCounterpartyEmployeeTrustPersisted, Trust)));
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, false);
		}
	}
};


const mutations = <MutationTree<TrustsState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	...listingMixin.mutations,
	...pagingMixin.mutations,
	...sortingMixin.mutations,
	...searchMixin.mutations
};

const subscribe = (store: any) => {

};

export {
	namespace, state, getters, actions, mutations, subscribe
};

const counterpartyEmployeeTrustsModule = {
	namespace, state, getters, actions, mutations, namespaced: true, subscribe
};

export default counterpartyEmployeeTrustsModule;
