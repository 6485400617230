import MasterAccountAccessApplicationsRouteQuery
	from "@/store/modules/applications/masterAccountAccess/list/types/masterAccountAccessApplicationsRouteQuery";
import MasterAccountAccessApplicationsState
	from "@/store/modules/applications/masterAccountAccess/list/types/masterAccountAccessApplicationsState";
import { difference } from "@/utils/difference";
import { Dictionary } from "vue-router/types/router";
import { plainToInstance } from "class-transformer";
import { validateOrReject } from "class-validator";
import { ApproveStatusType } from "@/types/ApproveStatusType";

export default class MasterAccountAccessApplicationsRouteQueryService {
	defaultRouteQuery: MasterAccountAccessApplicationsRouteQuery;

	constructor(defaultRouteQuery: MasterAccountAccessApplicationsRouteQuery) {
		this.defaultRouteQuery = defaultRouteQuery;
	}

	resolveRouteQueryDictionary(state: MasterAccountAccessApplicationsState) {
		let query = new MasterAccountAccessApplicationsRouteQuery(
			state.paging.page,
			state.sorting.type,
			state.sorting.order,
			state.search.query,
			state.filter.status ? state.filter.status.join(",") : ""
		);

		return difference(this.defaultRouteQuery, query);
	}

	async resolveRouteQuery(query: Dictionary<String>) {
		try {
			let result = plainToInstance(MasterAccountAccessApplicationsRouteQuery, {
				...this.defaultRouteQuery,
				...query
			}, { enableImplicitConversion: true });

			await validateOrReject(result);

			const filterStatus = result.filterStatus.length ? result.filterStatus.split(",") : [];

			return { ...result, filterStatus };
		} catch (e) {
			console.error(e);
			return this.defaultRouteQuery;
		}
	}
}
