import ApiFnsLegalPerson from "@/api/types/fns/apiFnsLegalPerson";

export default class ApiLegalPersonMasterAccountAccessApplicationCreate {
	email: string;
	entrepreneur: ApiFnsLegalPerson;
	documents: string[];

	constructor(email: string, entrepreneur: ApiFnsLegalPerson, documents: string[]) {
		this.email = email;
		this.entrepreneur = entrepreneur;
		this.documents = documents;
	}
}
