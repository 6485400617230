import ListingModel from "@/store/shared/listing/models/listingModel";
import SearchModel from "@/store/shared/search/models/searchModel";
import RouteState from "@/store/shared/route/types/routeState";
import IPageState from "@/store/shared/base/types/pageState";
import EmployeeJoinApplicationsFilter from "@/store/modules/applications/employeeJoin/list/types/employeeJoinApplicationsFilter";
import ApiEmployeeJoinApplicationPersisted from "@/api/types/employeeJoinApplication/apiEmployeeJoinApplicationPersisted";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import ApiGetEmployeeJoinApplicationsCounterpartyIdentifiers
	from "@/api/types/employeeJoinApplication/apiGetEmployeeJoinApplicationsCounterpartyIdentifiers";

export default class EmployeeJoinApplicationsState implements IPageState {
	constructor(
		public listing: ListingModel<ApiEmployeeJoinApplicationPersisted>,
		public sorting: SortingModel<String>,
		public paging: PagingModel,
		public search: SearchModel,
		public filter: EmployeeJoinApplicationsFilter,
		public route: RouteState,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public counterpartyIdentifiers: ApiGetEmployeeJoinApplicationsCounterpartyIdentifiers | null = null
	)
	{
	}
}
