import NotDefinedOrNullException from "@/exceptions/notDefinedOrNullException";
import { mapInstanceActions, mapInstanceMutations, mapInstanceState } from "@/utils/vuexMapInstanse";
import EmbeddedForm from "@/store/shared/embeddedForm/types/embeddedForm";
import { mutationTypes, actionTypes } from "@/store/shared/embeddedForm/types";
import Vue from "vue";

const embeddedFormMixin = Vue.extend({
	data() {
		return {
			refs: {
				form: "form"
			},
			formValid: true
		};
	},
	computed: {
		...mapInstanceState({
			isFormSaving: (state: { form: EmbeddedForm }) => state.form.isSaving,
			isFormReadonly: (state: { form: EmbeddedForm }) => {
				return state.form.isReadonly || state.form.isDisabled || state.form.isLoadingState || state.form.isSaving;
			},
			isFormDisabled: (state: { form: EmbeddedForm }) => state.form.isDisabled,
			isFormValid: (state: { form: EmbeddedForm }) => state.form.isValid,
			formMode: (state: { form: EmbeddedForm }) => state.form.mode,
			formEditMode: (state: { form: EmbeddedForm }) => state.form.editMode,
			isFormLoading: (state: { form: EmbeddedForm }) => state.form.isLoadingState
		})
	},
	methods: {
		...mapInstanceMutations({
			setIsFormValid: mutationTypes.SET_IS_FORM_VALID,
			setFormMode: mutationTypes.SET_FORM_MODE
		}),
		...mapInstanceActions({
			save: actionTypes.save
		}),
		validate() {
			return this.$refs[this.refs.form].validate();
		},
		async submit() {
			if(!this.validate())
				return;

			try {
				await this.save();
			} catch (e) {
				console.error(e);
				// Обработка производится на уровне store
			}
		}
	},
	watch: {
		formValid: {
			handler(value) {
				this.setIsFormValid(value);
			},
			immediate: true
		}
	},
	created() {
		if(!this.namespace)
			throw new NotDefinedOrNullException("namespace");
	}
});

export default embeddedFormMixin;
