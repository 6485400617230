<template>
	<v-container class="mt-6">
		<v-row class="justify-center">
			<v-col cols="8">
				<v-img aspect-ratio="1" contain width="100%" src="/img/images/sending-successful.png"></v-img>
			</v-col>
		</v-row>
		<v-row class="mt-6">
			<v-col class="text-center">
				<p v-if="accessReceived" class="text-body-1 primary--text text--darken-1">
					{{ $t("common.masterAccountAccessFinalStep.isAccessReceived") }}
				</p>
				<p v-else class="text-body-1 primary--text text--darken-1">
					{{ $t("common.masterAccountAccessFinalStep.default") }}
				</p>
			</v-col>
		</v-row>
		<v-row style="margin-top: 80px">
			<v-col class="d-flex justify-center">
				<frp-text-btn v-if="accessReceived"
							  @click="moveToHomePage"
							  color="black"
							  class="text-decoration-underline"
							  :text="$t('buttons.goToAuthenticationPage')"></frp-text-btn>
				<frp-message-box v-else icon="ico_info">{{ $t("alerts.info.youCanCloseCurrentBrowserPage") }}</frp-message-box>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import FrpLinkBtn from "@/components/buttons/FrpLinkBtn.vue";
import FrpTextBtn from "@/components/buttons/FrpTextBtn.vue";
import { MasterAccountApplicationMethodType } from "@/store/modules/masterAccountAccess/types/MasterAccountApplicationMethodType";
import { CounterpartyType } from "@/types/CounterpartyType";
import FrpIcon from "Components/icon/FrpIcon";
import FrpMessageBox from "Components/common/FrpMessageBox";
import storeModuleBasedPage from "Mixins/storeModuleBasedPage";
import storeManager from "@/store/manager/index";
import { createNamespacedHelpers } from "vuex";

const applicationHelper = createNamespacedHelpers(storeManager.masterAccountAccess.namespace);

export default {
	mixins: [storeModuleBasedPage],
	data() {
		return {
			CounterpartyType,
			namespace: storeManager.masterAccountAccess.finalStep.namespace

		};
	},
	computed: {
		...applicationHelper.mapState({
			applicationMethod: state => state.applicationMethod
		}),
		accessReceived() {
			return [MasterAccountApplicationMethodType.BY_EMPLOYEE,
				MasterAccountApplicationMethodType.BY_SIGNATURE].includes(this.applicationMethod);
		}
	},
	methods: {
		async moveToHomePage() {
			window.location = "/";
		}
	},
	components: {
		FrpTextBtn,
		FrpLinkBtn,
		FrpIcon,
		FrpMessageBox
	}
};
</script>
