import BaseStepStateModel from "@/store/shared/baseStep/models/baseStepStateModel";
import { ApplicationStepOrders } from "@/store/modules/masterAccountAccess/types/ApplicationStepOrders";

export default class SignApplicationStepState extends BaseStepStateModel {
	constructor(
		public order: number = ApplicationStepOrders.SIGN_APPLICATION_STEP_ORDER,
		public encodedApplicationString: string = "",
		public encodedDetachedSignatureString: string = "",
		public isFormValid: boolean = false,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
	)
	{
		const component = "frp-sign-application-step";
		
		super(component, order, isInitialized, isDestroyed);
	}
}
