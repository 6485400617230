<template>
	<div class="mb-6">
		<v-subheader v-if="label" :class="labelClasses" class="pa-0 mb-1 grey--text" style="min-height: 16px">{{ label }}</v-subheader>
		<vue-dropzone :name="type"
					  id="dropzone"
					  ref="dropzone"
					  v-if="!files.length"
					  :options="dropzoneOptions"
					  :useCustomSlot="true"
					  @vdropzone-file-added="handleFileAdded"
					  :class="`rounded-lg pa-2 d-flex align-center flex-wrap frp-dropzone ${background}`">
			<span class="primary--text">{{ multiple ? "Добавить документы" : "Добавить документ" }}</span>
			<div class="text-overline grey--text">
				<span class="mr-1" v-if="maxSize">Максимальный вес файла {{ maxSize }} Мб.</span>
				<span v-if="formats.length">Форматы {{ formats.join(", ") }}</span>
			</div>
		</vue-dropzone>
		<span v-if="!files.length" style="min-height: 20px" class="error--text px-3 pt-1">{{ errorMessage }}</span>
		<div>
			<v-row class="mt-1">
				<v-col class="d-flex align-start flex-column py-0">
					<div v-for="{ name } in files" :key="name" class="d-flex align-center">
						<frp-file deletable :name="name" @delete="onDelete" :uploading="isLoading"></frp-file>
					</div>
				</v-col>
			</v-row>
		</div>
	</div>
</template>

<script>
import { HrStorageController } from "@/api/hr/storage";
import { ApplicationModeType } from "@/types/ApplicationModeType";
import { getApplicationMode } from "@/utils/configuration";
import AbortService from "Services/abortService";
import AlertHelper from "Store/modules/alerts/helpers/alertHelper";
import VueDropzone from "vue2-dropzone";
import colorsMixin from "Mixins/colorsMixin";
import FrpIcon from "Components/icon/FrpIcon";
import FrpTextField from "Components/fields/FrpTextField";
import FrpFile from "Components/common/FrpFile";
import { StorageController } from "Api/storage";
import ApiFile from "Api/types/storage/apiFile";
import { FileTypes } from "@/constants/fileTypes";

const abortService = new AbortService();

const DEFAULT_URL = "http://localhost";

export default {
	mixins: [colorsMixin],
	props: {
		type: {
			type: String,
			required: true
		},
		label: String,
		labelClasses: String,
		maxSize: [Number, String],
		minSize: [Number, String],
		formats: Array,
		// TODO: добавление и удаление нескольких файлов
		multiple: Boolean,
		background: {
			type: String,
			default: "white darken-1"
		}
	},
	data() {
		return {
			dropzoneOptions: {
				autoProcessQueue: false,
				url: DEFAULT_URL,
				maxFilesize: this.maxSize,
				acceptedFiles: "",
				maxFiles: this.multiple ? 100 : 1
			},
			files: [],
			isLoading: false,
			errorMessage: "",
			storageController: null
		};
	},
	computed: {
		maxSizeInBytes() {
			return this.maxSize * 1000000;
		},
		minSizeInBytes() {
			return this.minSize * 1000000;
		}
	},
	methods: {
		handleError(file, message) {
			this.errorMessage = message;
			this.$refs.dropzone.removeFile(file);
		},
		async handleFileAdded(file) {
			this.errorMessage = "";
			
			if(file.size === 0)
				this.handleError(file, this.$t("common.fileMustNotBeEmpty"));
			if(file.size > this.maxSizeInBytes)
				this.handleError(file, this.$t("fields.dropzone.description.maxFileWeight", { maxSize: this.maxSize }));
			if(file.size < this.minSizeInBytes)
				this.handleError(file, this.$t("fields.dropzone.description.minFileWeight", { minSize: this.minSize }));
			
			if(this.errorMessage)
				return;
			
			this.isLoading = true;
			
			try {
				const preparedFile = new File([file], file.name, {
				  lastModified: file.lastModified,
				  type: file.type || FileTypes.DEFAULT
				});

				const { name, type } = preparedFile;

				this.files.push({ name });

				const res = await this.storageController.createTemperFile(new ApiFile(preparedFile, name, this.type, type, ""));

				this.$emit("file:add", res, type);
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			} finally {
				this.isLoading = false;
			}
		},
		onDelete(name) {
			this.files = this.files.filter(x => x.name !== name);
			this.$emit("file:delete", name);
		},
		setAcceptedFiles() {
			const res = [];
			this.formats.forEach(x => {
				switch (x) {
					case "png":
						res.push("image/png");
						break;
					case "jpg":
					case "jpeg":
						res.push("image/jpg", "image/jpeg");
						break;
					case "pdf":
						res.push("application/pdf");
				}
			});
			this.dropzoneOptions.acceptedFiles = res.join(",");
		},
		initializeController() {
			const mode = getApplicationMode();
			
			switch (mode) {
				case ApplicationModeType.OLK:
					this.storageController = new StorageController(abortService);
					break;
				case ApplicationModeType.HR:
					this.storageController = new HrStorageController(abortService);
					break;
				default:
					throw Error("FrpDropzone не работает в этом модуле");
			}
		},
		clear() {
			this.files = [];
		}
	},
	created() {
		this.initializeController();
		
		abortService.initialize();
	},
	watch: {
		formats: {
			handler(formats) {
				this.setAcceptedFiles(formats);
			},
			immediate: true
		}
	},
	components: {
		VueDropzone,
		FrpIcon,
		FrpTextField,
		FrpFile
	}
};
</script>
