<template>
	<v-skeleton-loader height="20" max-width="250" type="image" class="rounded-lg"/>
</template>

<script>
export default {
};
</script>

<style scoped>

</style>
