<template>
	<frp-nested-content-layout>
		<template #title>
			<frp-custom-title-loader/>
		</template>
		<template #content>
			<frp-tabs-loader></frp-tabs-loader>

			<template v-if="isGeneralPage">
				<frp-office-general-loader/>
			</template>

			<template v-else-if="isEmployeesPage">
				<frp-office-employees-loader/>
			</template>

		</template>
	</frp-nested-content-layout>
</template>

<script>
import FrpTabsLoader from "@/components/loaders/common/FrpTabsLoader";
import FrpNestedContentLayout from "Components/layouts/FrpNestedContentLayout";
import FrpCustomTitleLoader from "Components/loaders/common/FrpCustomTitleLoader";
import FrpFormCard from "Components/markup/FrpFormCard";
import FrpFormCardBlocks from "Components/markup/FrpFormCardBlocks";
import FrpFormCardBlock from "Components/markup/FrpFormCardBlock";
import FrpFormCardBlockRow from "Components/markup/FrpFormCardBlockRow";
import FrpFormCardBlockCol from "Components/markup/FrpFormCardBlockCol";
import FrpFormItem from "Components/markup/FrpFormItem";
import FrpSwitchLoader from "Components/loaders/form/FrpSwitchLoader";
import FrpTextareaLoader from "Components/loaders/form/FrpTextareaLoader";
import { RouteNames } from "Router/routes";
import FrpOfficeEmployeesLoader from "Views/office/sections/FrpOfficeEmployeesLoader";
import FrpOfficeGeneralLoader from "Views/office/sections/FrpOfficeGeneralLoader";

export default {
	data() {
		return {
			RouteNames
		};
	},
	computed: {
		isGeneralPage() {
			return this.$route.name === this.RouteNames.OFFICE_GENERAL;
		},
		isEmployeesPage() {
			return this.$route.name === this.RouteNames.OFFICE_EMPLOYEES;
		}
	},
	components: {
		FrpOfficeEmployeesLoader,
		FrpOfficeGeneralLoader,
		FrpCustomTitleLoader,
		FrpTabsLoader,
		FrpNestedContentLayout,
		FrpFormCardBlocks,
		FrpFormCard,
		FrpFormCardBlock,
		FrpFormCardBlockCol,
		FrpFormCardBlockRow,
		FrpFormItem,
		FrpSwitchLoader,
		FrpTextareaLoader
	}
};
</script>
