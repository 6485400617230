import ApiMasterAccountAccessApplication from "@/api/types/masterAccountAccessApplication/apiMasterAccountAccessApplication";
import IPageState from "@/store/shared/base/types/pageState";

export default class MasterAccountAccessApplicationDetailsState implements IPageState {
	constructor(
		public details = new ApiMasterAccountAccessApplication(),
		public isLoading: boolean = false,
		public isApproving: boolean = false,
		public isRejecting: boolean = false,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false
	)
	{
	}
}
