import urls, { urlTemplateParts } from "@/api/config/urls";
import { plainToInstance } from "class-transformer";
import { prepareUrl } from "@/utils/prepareUrlQuery";
import BaseController from "@/api/shared/baseController";
import AbortService from "@/services/abortService";
import ApiEmployeeInvitation from "@/api/types/employeeInvitation/apiEmployeeInvitation";

export class EmployeeInvitationController extends BaseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}

	getEmployeeInvitation = async (profileId: string): Promise<ApiEmployeeInvitation> => {
		const url = prepareUrl(urls.employeeInvitation.get, { profileId });

		let result = await this.client.get(url);

		return plainToInstance(ApiEmployeeInvitation, result);
	};
}
