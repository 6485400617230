import Account from "@/store/modules/account/modules/general/types/account";
import IPageState from "@/store/shared/base/types/pageState";

export default class AccountState implements IPageState {
	constructor(
		public id: string = "",
		public account: Account = new Account(),
		public isLoading: boolean = false,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false
	)
	{
	}
}
