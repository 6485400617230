import ApiCounterpartyPersistedBase from "@/api/types/counterparty/apiCounterpartyPersistedBase";
import ApiPhysicalPersonCounterparty from "@/api/types/counterparty/apiPhysicalPersonCounterparty";

export default class ApiPhysicalPersonCounterpartyPersisted extends ApiCounterpartyPersistedBase {
	counterparty: ApiPhysicalPersonCounterparty;

	constructor(id: string = "",
		createdAt: string = "",
		updatedAt: string = "",
		counterparty: ApiPhysicalPersonCounterparty = new ApiPhysicalPersonCounterparty())
	{
		super(id, createdAt, updatedAt, counterparty);

		this.counterparty = counterparty;
	}
}
