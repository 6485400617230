<template>
	<div :class="formattedCode" class="vti__flag"/>
</template>

<script>

// Стили тянутся из библиотеки vue-tel-input-vuetify. TODO вынести в код проекта
export default {
	props: {
		/**
		 * Country Codes Alpha-2, например, RU
		 */
		value: {
			type: String,
			required: true
		}
	},
	computed: {
		formattedCode() {
			return this.value.toLowerCase();
		}
	}
};
</script>
