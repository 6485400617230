import {
	namespace,
	actionTypes,
	mutationTypes
} from "@/store/modules/profileAssignment/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation/index";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import alertService, { AlertKeys } from "@/store/modules/alerts/services/alertService";
import ProfileAssignmentState from "@/store/modules/profileAssignment/types/profileAssignmentState";
import NotDefinedOrNullException from "@/exceptions/notDefinedOrNullException";
import SecurityService from "@/services/auth/securityService";
import { User } from "oidc-client-ts";
import InvalidArgumentException from "@/exceptions/invalidArgumentException";
import ApiLegalEntityEmployeeProfile from "@/api/types/account/profile/apiLegalEntityEmployeeProfile";
import ApiOfficeEmployeeProfile from "@/api/types/account/profile/apiOfficeEmployeeProfile";
import { ProfileType } from "@/types/ProfileType";
import router from "@/router";
import ApiEntrepreneurEmployeeProfile from "@/api/types/account/profile/apiEntrepreneurEmployeeProfile";
import { AccountController } from "@/api/account";
import AbortService from "@/services/abortService";
import HttpNotFoundException from "@/exceptions/httpNotFoundException";
import { RouteNames } from "@/router/routes";
import rootTypes from "@/store/types";
import { PageModeType } from "@/store/types/pageModeType";
import AccessForbiddenException from "@/exceptions/accessForbiddenException";
import { EmployeeInvitationController } from "@/api/employeeInvitation";
import { resolveNestedState } from "@/utils/vuexModules";
import UserState from "@/store/modules/user/types/userState";
import store from "@/store";
import userTypes from "@/store/modules/user/types";

const abortService = new AbortService();
const accountController = new AccountController(abortService);
const employeeInvitationController = new EmployeeInvitationController(abortService);

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new ProfileAssignmentState();
	}
}

const securityService = new SecurityService();

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();
const baseMixin = (new BaseMixinBuilder(abortService)).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<ProfileAssignmentState, any>>{};

const actions = <ActionTree<ProfileAssignmentState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit, rootState }, { id }) {
		if(!id) throw new InvalidArgumentException("id", id);

		await dispatch(actionTypes.initializeBase);

		commit(mutationTypes.SET_ID, id);

		let { user } = resolveNestedState<UserState>(store.state, userTypes.namespace);

		try {
			let {
				profile: profilePersisted,
				employee: employeePersisted,
				employer: employerPersisted,
				office: officePersisted,
				officeEmployee: officeEmployeePersisted
			} = await employeeInvitationController.getEmployeeInvitation(id);

			if(user.account.email !== profilePersisted.profile.email)
				throw new AccessForbiddenException("Email аккаунта не совпадает с email профиля");

			commit(mutationTypes.SET_PROFILE, profilePersisted);

			if(profilePersisted.profile instanceof ApiLegalEntityEmployeeProfile) {
				commit(mutationTypes.SET_PROFILE_TYPE, ProfileType.COUNTERPARTY_LEGAL_ENTITY_EMPLOYEE);
				commit(mutationTypes.SET_COUNTERPARTY, employerPersisted);
				commit(mutationTypes.SET_EMPLOYEE, employeePersisted);
			} else if(profilePersisted.profile instanceof ApiEntrepreneurEmployeeProfile) {
				commit(mutationTypes.SET_PROFILE_TYPE, ProfileType.COUNTERPARTY_ENTREPRENEUR_EMPLOYEE);
				commit(mutationTypes.SET_COUNTERPARTY, employerPersisted);
				commit(mutationTypes.SET_EMPLOYEE, employeePersisted);
			} else if(profilePersisted.profile instanceof ApiOfficeEmployeeProfile) {
				commit(mutationTypes.SET_PROFILE_TYPE, ProfileType.OFFICE_EMPLOYEE);
				commit(mutationTypes.SET_OFFICE, officePersisted);
				commit(mutationTypes.SET_EMPLOYEE, officeEmployeePersisted);
			}

			commit(mutationTypes.SET_IS_INITIALIZED, true);
		} catch (error) {
			console.error(error);

			switch (error.constructor) {
				case HttpNotFoundException:
					commit(rootTypes.mutationTypes.SET_PAGE_MODE, PageModeType.PAGE_NOT_FOUND, { root: true });
					break;
				case AccessForbiddenException:
					commit(rootTypes.mutationTypes.SET_PAGE_MODE, PageModeType.ACCESS_DENIED, { root: true });
					break;
				default:
					AlertHelper.handleGeneralRequestErrors(error);
					break;
			}
		}
	},
	async [actionTypes.assignProfile]({ state, commit }) {
		commit(mutationTypes.SET_IS_ASSIGNING, true);

		try {
			let user = await securityService.getUser();

			if(!user)
				throw new NotDefinedOrNullException(User.name);

			await accountController.assignProfile(state.profile.id);

			await securityService.signOut();

			commit(mutationTypes.SET_IS_ASSIGNED, true);
			alertService.addInfo(AlertKeys.PROFILE_ACTIVATED);
			await router.push({ name: RouteNames.ROOT });
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_ASSIGNING, false);
		}
	}
};

const mutations = <MutationTree<ProfileAssignmentState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	[mutationTypes.SET_PROFILE](state, value) {
		state.profile = value;
	},
	[mutationTypes.SET_EMPLOYEE](state, value) {
		state.employee = value;
	},
	[mutationTypes.SET_COUNTERPARTY](state, value) {
		state.counterparty = value;
	},
	[mutationTypes.SET_OFFICE](state, value) {
		state.office = value;
	},
	[mutationTypes.SET_PROFILE_TYPE](state, value) {
		state.profileType = value;
	},
	[mutationTypes.SET_IS_ASSIGNED](state, value) {
		state.isAssigned = value;
	},
	[mutationTypes.SET_DETAILS](state, value) {
		state.details = value;
	},
	[mutationTypes.SET_ID](state, value) {
		state.id = value;
	},
	[mutationTypes.SET_IS_LOADING](state, value) {
		state.isLoading = value;
	},
	[mutationTypes.SET_IS_ASSIGNING](state, value) {
		state.isAssigning = value;
	}
};

export {
	namespace, state, getters, actions, mutations
};

const profileAssignmentModule = {
	namespace, state, getters, actions, mutations, namespaced: true
};

export default profileAssignmentModule;
