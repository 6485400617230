import { ConfirmEmailModeType } from "@/store/modules/confirmEmail/types/confirmEmailModeType";
import FormState from "@/store/shared/form/models/formState";
import IPageState from "@/store/shared/base/types/pageState";

export default class ConfirmEmailState implements IPageState {
	constructor(
		public form: FormState,
		public email: string = "",
		public mode: ConfirmEmailModeType = ConfirmEmailModeType.INITIAL,
		public code: string = "",
		public codeError: string = "",
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false
	)
	{
	}
}
