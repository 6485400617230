import { actionTypes, mutationTypes, namespace } from "@/store/modules/breadcrumbs/types";

import { ActionTree, GetterTree, MutationTree } from "vuex";

import BreadcrumbsState from "@/store/modules/breadcrumbs/types/breadcrumbsState";
import Breadcrumb from "@/store/modules/breadcrumbs/types/breadcrumb";
import { resolveAction, resolveGetter, resolveMutation, resolveNestedState } from "@/utils/vuexModules";
import storeManager from "@/store/manager";
import MasterAccountAccessApplicationDetailsState
	from "@/store/modules/applications/masterAccountAccess/details/types/masterAccountAccessApplicationDetailsState";
import NotDefinedException from "@/exceptions/notDefinedException";
import baseMixinTypes from "@/store/shared/base/types";
import CounterpartyState from "@/store/modules/counterparty/types/counterpartyState";
import { isArray, isString } from "lodash";
import CounterpartyEmployeeState from "@/store/modules/counterpartyEmployee/types/counterpartyEmployeeState";
import { findRoute, RouteNames, routesThreeRoot } from "@/router/routes";
import { routeToStoreMap } from "@/router/routeToStoreMap";
import { formatCounterpartyTitle, formatFullName } from "@/utils/formatting";
import CounterpartyEmployeeTrustState from "@/store/modules/counterpartyEmployee/modules/trust/types/counterpartyEmployeeTrustState";
import { formatDate } from "@/utils/dates";
import { dateFormat } from "@/utils/formats";
import OfficeEmployeeState from "@/store/modules/officeEmployee/types/officeEmployeeState";
import AccountState from "@/store/modules/account/types/accountState";
import EmployeeJoinApplicationDetailsState
	from "@/store/modules/applications/employeeJoin/details/types/employeeJoinApplicationDetailsState";
import { i18n } from "@/plugins";
import PermissionsService from "@/services/permissionsService";
import routeToPermissionsMap from "@/router/routeToPermissionsMap";
import userTypes from "@/store/modules/user/types";

const permissionsService = new PermissionsService();

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new BreadcrumbsState();
	}
}

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<BreadcrumbsState, any>>{};

const prepareBreadcrumb = async (routeName: RouteNames,
	{ params, text }: { params?: any, text?: null | string } = { params: {}, text: null }) => {
	const permissions = routeToPermissionsMap.get(routeName);
	if(!permissions) throw new NotDefinedException("permissions");

	return new Breadcrumb(
		text ?? i18n.t(`navigation.breadcrumbs.${routeName}`).toString(),
		{ name: routeName, params },
		!(await permissionsService.check(permissions))
	);
};

const actions = <ActionTree<BreadcrumbsState, any>>{
	async [actionTypes.resolveBreadcrumb]({ rootState, rootGetters, commit }, { segment, route }) {

		const routeName = segment as RouteNames;
		const isMasterAccount = rootGetters[resolveGetter(storeManager.user.namespace,
			userTypes.getterTypes.isMasterAccount)];

		switch (routeName) {
			case RouteNames.ROOT:
			{
				if(isMasterAccount)
					return;

				return await prepareBreadcrumb(routeName);
			}
			case RouteNames.COUNTERPARTIES:
			{
				if(isMasterAccount)
					return;

				return await prepareBreadcrumb(routeName);
			}
			case RouteNames.COUNTERPARTY_CREATE:
			case RouteNames.COUNTERPARTY_MASTER_ACCOUNT:
			case RouteNames.COUNTERPARTY_EMPLOYEE_CREATE:
			case RouteNames.COUNTERPARTY_EMPLOYEE_TRUSTS:
			case RouteNames.COUNTERPARTY_EMPLOYEE_PROFILE:
			case RouteNames.COUNTERPARTY_EMPLOYEE_TRUST_CREATE:
			case RouteNames.OFFICE:
			case RouteNames.OFFICE_EMPLOYEES:
			case RouteNames.OFFICE_EMPLOYEE_CREATE:
			case RouteNames.OFFICE_EMPLOYEE_PROFILE:
			case RouteNames.APPLICATIONS:
			case RouteNames.APPLICATIONS_MASTER_ACCOUNT_ACCESSES:
			case RouteNames.APPLICATIONS_EMPLOYEE_JOIN_LIST:
			case RouteNames.PROFILE_ASSIGNMENT:
			case RouteNames.ACCOUNTS:
			case RouteNames.ACCOUNT_PROFILES:
				return await prepareBreadcrumb(routeName);
			case RouteNames.COUNTERPARTY_EMPLOYEE:
			{
				let { employee } = resolveNestedState<CounterpartyEmployeeState>(rootState,
					storeManager.counterpartyEmployee.namespace);

				return await prepareBreadcrumb(routeName, {
					text: formatFullName(employee)
				});
			}
			case RouteNames.COUNTERPARTY_EMPLOYEE_TRUST:
			{
				let { trust } = resolveNestedState<CounterpartyEmployeeTrustState>(rootState,
					storeManager.counterpartyEmployee.trust.namespace);
				return await prepareBreadcrumb(routeName, {
					text: `${i18n.t("content.expireAt")} ${formatDate(trust.expireAt, dateFormat)}`
				});
			}
			case RouteNames.OFFICE_EMPLOYEE:
			{
				let { employee } = resolveNestedState<OfficeEmployeeState>(rootState, storeManager.officeEmployee.namespace);
				return await prepareBreadcrumb(routeName, {
					text: formatFullName(employee)
				});
			}
			case RouteNames.APPLICATIONS_MASTER_ACCOUNT_ACCESS_DETAILS:
			{
				let { details } = resolveNestedState<MasterAccountAccessApplicationDetailsState>(rootState,
					storeManager.applications.masterAccountAccess.details.namespace);
				return await prepareBreadcrumb(routeName, {
					text: formatCounterpartyTitle(details.legalEntity || details.entrepreneur)
				});
			}
			case RouteNames.APPLICATIONS_EMPLOYEE_JOIN_DETAILS:
			{
				let { details } = resolveNestedState<EmployeeJoinApplicationDetailsState>(rootState,
					storeManager.applications.employeeJoin.details.namespace);
				return await prepareBreadcrumb(routeName, {
					text: formatFullName(details.employee)
				});
			}
			case RouteNames.ACCOUNT:
			{
				let { account } = resolveNestedState<AccountState>(rootState, storeManager.account.namespace);
				return await prepareBreadcrumb(routeName, {
					text: account.login
				});
			}
			case RouteNames.COUNTERPARTY:
			{
				if(findRoute(route, findRoute(RouteNames.COUNTERPARTY_EMPLOYEE)) ||
					findRoute(route, findRoute(RouteNames.COUNTERPARTY_EMPLOYEE_CREATE)))
				{
					let state = resolveNestedState<CounterpartyEmployeeState>(rootState, storeManager.counterpartyEmployee.namespace);

					return await prepareBreadcrumb(routeName, {
						params: { id: state.counterpartyId },
						text: formatCounterpartyTitle(state.counterparty)
					});
				} else {
					let { title, counterparty } = resolveNestedState<CounterpartyState>(rootState, storeManager.counterparty.namespace);

					return await prepareBreadcrumb(routeName, {
						params: { id: counterparty.id },
						text: title
					});
				}
			}
			case RouteNames.COUNTERPARTY_EMPLOYEES:
			{
				if(findRoute(route, findRoute(RouteNames.COUNTERPARTY_EMPLOYEE)) ||
					findRoute(route, findRoute(RouteNames.COUNTERPARTY_EMPLOYEE_CREATE)))
				{
					let state = resolveNestedState<CounterpartyEmployeeState>(rootState, storeManager.counterpartyEmployee.namespace);

					return await prepareBreadcrumb(routeName, {
						params: { id: state.counterpartyId }
					});
				} else {
					let { counterparty } = resolveNestedState<CounterpartyState>(rootState, storeManager.counterparty.namespace);

					return await prepareBreadcrumb(routeName, {
						params: { id: counterparty.id }
					});
				}
			}
		}
	},
	async [actionTypes.processRoute]({ rootState, commit, dispatch }, routeName: string) {
		const result = [];

		if(routeName) {
			let route = routesThreeRoot.first(x => x.model.name === routeName);

			if(!route) throw new NotDefinedException("route");

			for (const segment of route.getPath()) {
				let breadcrumb = await dispatch(actionTypes.resolveBreadcrumb, { segment: segment.model.name, route: routeName });
				if(breadcrumb && breadcrumb.text)
					result.push(breadcrumb);
			}
		}

		commit(mutationTypes.SET_ITEMS, result);
		commit(mutationTypes.SET_IS_LOADING, false);
	}
};

const mutations = <MutationTree<BreadcrumbsState>>{
	[mutationTypes.SET_ITEMS](state, value) {
		state.items = value;
	},
	[mutationTypes.SET_IS_LOADING](state, value) {
		state.isLoading = value;
	}
};

const subscribe = (store: any) => {
	const initializedMap = new Map<string, string | string[]>();
	const beforeInitializedMap = new Map<string, string | string[]>();

	for (const [route, namespace] of routeToStoreMap) {
		initializedMap.set(route, namespace.map(x => resolveMutation(x, baseMixinTypes.mutationTypes.SET_IS_INITIALIZED)));
		beforeInitializedMap.set(route, namespace.map(x => resolveMutation(x, baseMixinTypes.mutationTypes.BEFORE_INITIALIZED)));
	}

	store.subscribe(async ({ type, payload }: any, state: any) => {
		const beforeInitializedEventValue = beforeInitializedMap.get(state.route.name);
		if(beforeInitializedEventValue) {
			if(isString(beforeInitializedEventValue)) {
				if(type === beforeInitializedEventValue) {
					store.commit(resolveMutation(namespace, mutationTypes.SET_IS_LOADING), true);
				}
			} else if(isArray(beforeInitializedEventValue)) {
				let result = beforeInitializedEventValue.find(x => type === x);
				if(result) {
					store.commit(resolveMutation(namespace, mutationTypes.SET_IS_LOADING), true);
				}
			}
		}


		const initializedEventValue = initializedMap.get(state.route.name);
		if(initializedEventValue) {
			if(isString(initializedEventValue)) {
				if(type === initializedEventValue) {
					store.dispatch(resolveAction(namespace, actionTypes.processRoute), state.route.name);
				}
			} else if(isArray(initializedEventValue)) {
				let result = initializedEventValue.find(x => type === x);
				if(result) {
					store.dispatch(resolveAction(namespace, actionTypes.processRoute), state.route.name);
				}
			}
		}
	});
};

export {
	namespace, state, getters, actions, mutations, subscribe
};

const breadcrumbsModule = {
	namespace, state, getters, actions, mutations, namespaced: true, subscribe
};

export default breadcrumbsModule;
