<template>
	<frp-form-card-block-row>
		<frp-form-card-block-col cols="12">
			<v-fade-transition hide-on-leave>
				<template v-if="formMode === EmbeddedFormModeType.DETAILS">
					<template v-if="formEditMode === EmbeddedFormEditModeType.UPDATE">
						<frp-file :label="$t('fields.snilsFile.label')" v-if="id" :name="snilsFileMeta.name" :id="snilsFileMeta.id">
							<template #append-outer>
								<frp-text-btn :disabled="readonly"
											  v-if="can(updatePermissions)"
											  class="pr-0"
											  :text="$t('buttons.change')"
											  @click="switchToEditMode">
								</frp-text-btn>
							</template>
						</frp-file>

					</template>
					<frp-details-item v-else-if="formEditMode === EmbeddedFormEditModeType.CREATE"
									  :value="readonly ? readonlyText : $t('details.values.missing')"
									  :title="$t('details.titles.snilsFile')">
						<template #append>
							<frp-text-btn :disabled="readonly"
										  v-if="can(createPermissions)"
										  class="pr-0"
										  :text="$t('buttons.upload')"
										  @click="switchToEditMode">
							</frp-text-btn>
						</template>
					</frp-details-item>
				</template>
			</v-fade-transition>
			<v-fade-transition hide-on-leave>
				<v-card class="mt-4" v-if="formMode === EmbeddedFormModeType.EDIT || formMode === EmbeddedFormModeType.SUCCESS"
						outlined>
					<v-card-title class="text-subtitle-1" v-if="formMode === EmbeddedFormModeType.EDIT">
						{{ title }}
					</v-card-title>
					<v-card-text>
						<v-form v-if="EmbeddedFormEditModeType.UNKNOWN !== formEditMode"
								:readonly="isFormSaving || isFormReadonly"
								:disabled="isFormDisabled"
								:ref="refs.form"
								v-model="formValid">
							<v-container fluid class="pa-0">
								<frp-form-card-block-row>
									<frp-form-card-block-col v-if="EmbeddedFormModeType.SUCCESS === formMode"
															 class="d-flex flex-column align-center">
										<frp-icon src="ico_update-data-success" class="mb-5 mt-3"></frp-icon>
										<span class="primary--text text-body-2 text-center">{{ successText }}</span>
									</frp-form-card-block-col>
									<frp-form-card-block-col v-else-if="EmbeddedFormModeType.EDIT === formMode">
										<span class="text-body-2 primary--text d-inline-block mb-4">{{ editModeText }}</span>

										<frp-file-field :label="$t('fields.snils.label')"
														:accept="[FileTypes.JPG, FileTypes.PNG, FileTypes.PDF]"
														persistent-hint
														:rules="validation.snilsFormat"
														@update:value="handleSnilsUpload"
														:meta="internalSnilsFileMeta"
														:placeholder="$t('fields.snils.placeholderChoose')">
										</frp-file-field>
									</frp-form-card-block-col>
								</frp-form-card-block-row>
								<frp-form-card-block-row>
									<frp-form-card-block-col class="d-flex justify-end">
										<template v-if="EmbeddedFormModeType.EDIT === formMode">
											<frp-btn @click="switchToDetailsMode"
													 class="frp-btn flex-shrink-1"
													 color="primary"
													 compact
													 outlined>
												{{ $t("buttons.cancel") }}
											</frp-btn>

											<frp-btn :loading="isFormSaving"
													 :disabled="!formValid || isFormReadonly || !isMetaChanged || internalSnilsFileMeta.isLoading"
													 @click="submit"
													 compact
													 elevation="0"
													 class="flex-shrink-1">
												{{ $t("buttons.save") }}
											</frp-btn>
										</template>

										<frp-btn v-else-if="EmbeddedFormModeType.SUCCESS === formMode"
												 color="primary"
												 class="flex-shrink-1"
												 compact
												 @click="switchToDetailsMode"
												 outlined>
											{{ $t("buttons.close") }}
										</frp-btn>
									</frp-form-card-block-col>
								</frp-form-card-block-row>
							</v-container>
						</v-form>
					</v-card-text>
				</v-card>
			</v-fade-transition>
		</frp-form-card-block-col>
	</frp-form-card-block-row>
</template>

<script>
import { FileTypes } from "@/constants/fileTypes";
import authorizationMixin from "@/mixins/authorizationMixin";
import { checkSnilsFormatRule } from "@/utils/validation";
import FrpBtn from "Components/buttons/FrpBtn";
import FrpTextBtn from "Components/buttons/FrpTextBtn";
import FrpFile from "Components/common/FrpFile";
import FrpDetailsItem from "Components/details/FrpDetailsItem";
import FrpFileField from "Components/fields/FrpFileField";
import FrpIcon from "Components/icon/FrpIcon";
import FrpFormCardBlock from "Components/markup/FrpFormCardBlock";
import FrpFormCardBlockCol from "Components/markup/FrpFormCardBlockCol";
import FrpFormCardBlockRow from "Components/markup/FrpFormCardBlockRow";
import FrpFormCardBlocks from "Components/markup/FrpFormCardBlocks";
import embeddedFormMixin from "Mixins/embeddedFormMixin";
import { mutationTypes, actionTypes, getterTypes } from "Store/shared/embeddedSnilsForm/types";
import { EmbeddedFormEditModeType } from "Store/shared/embeddedForm/types/embeddedFormEditModeType";
import { EmbeddedFormModeType } from "Store/shared/embeddedForm/types/embeddedFormModeType";
import { mapInstanceActions, mapInstanceGetters, mapInstanceMutations, mapInstanceState } from "Utils/vuexMapInstanse";
import { i18n } from "@/plugins";

export default {
	mixins: [embeddedFormMixin, authorizationMixin],
	props: {
		title: {
			default: i18n.t("details.titles.snilsFile")
		},
		namespace: {
			required: true
		},
		successText: {
			default: i18n.t("alerts.info.onSuccessUploaded")
		},
		editModeText: String,
		readonlyText: String,
		readonly: Boolean,
		createPermissions: [Array, String],
		updatePermissions: [Array, String]
	},
	data() {
		return {
			EmbeddedFormEditModeType,
			EmbeddedFormModeType,
			FileTypes,
			validation: {
				snilsFormat: [checkSnilsFormatRule()]
			}
		};
	},
	computed: {
		...mapInstanceState({
			snilsFileMeta: state => state.snilsFileMeta,
			lastSavedMeta: state => state.lastSavedMeta,
			id: state => state.id,
			form: state => state.form
		}),
		...mapInstanceGetters({
			isMetaChanged: getterTypes.isMetaChanged
		}),
		internalSnilsFileMeta: {
			get() {
				return this.snilsFileMeta;
			},
			set(value) {
				this.setSnilsFileMeta(value);
			}
		}
	},
	methods: {
		...mapInstanceMutations({
			setSnilsFileMeta: mutationTypes.SET_SNILS_FILE_META,
			setSnilsFileMetaIsLoading: mutationTypes.SET_SNILS_FILE_META_IS_LOADING
		}),
		...mapInstanceActions({
			handleSnilsUpload: actionTypes.handleSnilsUpload,
			download: actionTypes.download
		}),
		switchToEditMode() {
			this.setFormMode(EmbeddedFormModeType.EDIT);
		},
		switchToDetailsMode() {
			this.setSnilsFileMeta(this.lastSavedMeta);
			this.setSnilsFileMetaIsLoading(false);
			this.setFormMode(EmbeddedFormModeType.DETAILS);
		}
	},
	components: {
		FrpFile,
		FrpFileField,
		FrpIcon,
		FrpBtn,
		FrpTextBtn,
		FrpDetailsItem,
		FrpFormCardBlockCol,
		FrpFormCardBlockRow,
		FrpFormCardBlock,
		FrpFormCardBlocks
	}
};
</script>
