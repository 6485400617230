import ListingModel from "@/store/shared/listing/models/listingModel";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchModel from "@/store/shared/search/models/searchModel";
import ApiAccountProfilePersistedBase from "@/api/types/account/apiAccountProfilePersistedBase";
import IPageState from "@/store/shared/base/types/pageState";

export default class AccountProfilesState implements IPageState {
	constructor(
		public listing: ListingModel<ApiAccountProfilePersistedBase>,
		public sorting: SortingModel<String>,
		public paging: PagingModel,
		public search: SearchModel,
		public isSelectItemHandling: boolean = false,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false
	)
	{
	}
}
