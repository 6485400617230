export default class CounterpartyEmployeeTrustActioners {
	acceptorFullname: string;
	cancellerFullname: string;
	declinerFullname: string;

	constructor(
		acceptorFullname: string = "",
		cancellerFullname: string = "",
		declinerFullname: string = ""
	)
	{
		this.acceptorFullname = acceptorFullname;
		this.cancellerFullname = cancellerFullname;
		this.declinerFullname = declinerFullname;
	}

}
