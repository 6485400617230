import { createMap, createMapper, MappingProfile, forMember, mapFrom, addProfile, fromValue, ignore } from "@automapper/core";
import { classes } from "@automapper/classes";
import ApiPhysicalPersonCounterparty from "@/api/types/counterparty/apiPhysicalPersonCounterparty";
import PhysicalPersonCounterparty from "@/store/modules/counterparty/modules/physicalPersonCounterparty/types/physicalPersonCounterparty";
import { CounterpartyType } from "@/types/CounterpartyType";

const mapper = createMapper({
	strategyInitializer: classes()
});

const physicalPersonProfile: MappingProfile = (mapper) => {
	createMap(mapper, ApiPhysicalPersonCounterparty, PhysicalPersonCounterparty,
		forMember(d => d.inn, mapFrom(x => x.inn)),
		forMember(d => d.firstName, mapFrom(x => x.firstName)),
		forMember(d => d.lastName, mapFrom(x => x.lastName)),
		forMember(d => d.middleName, mapFrom(x => x.middleName)),
		forMember(d => d.description, mapFrom(x => x.description)),
		forMember(d => d.snils, mapFrom(x => x.snils)),
		forMember(d => d.isRfrp, mapFrom(x => x.isRfrp)),
		forMember(d => d.isLeasingCompany, mapFrom(x => x.isLeasingCompany)),
		forMember(d => d.isPledgeExpertCompany, mapFrom(x => x.isPledgeExpertCompany)),
		forMember(d => d.isVkmExpertCompany, mapFrom(x => x.isVkmExpertCompany)),
		forMember(d => d.isFrpAssigneeCompany, mapFrom(x => x.isFrpAssigneeCompany))
	);

	createMap(mapper, PhysicalPersonCounterparty, ApiPhysicalPersonCounterparty,
		forMember(d => d.type, fromValue(CounterpartyType.PHYSICAL_PERSON)),
		forMember(d => d.inn, mapFrom(x => x.inn)),
		forMember(d => d.firstName, mapFrom(x => x.firstName)),
		forMember(d => d.lastName, mapFrom(x => x.lastName)),
		forMember(d => d.middleName, mapFrom(x => x.middleName)),
		forMember(d => d.description, mapFrom(x => x.description)),
		forMember(d => d.snils, mapFrom(x => x.snils ? x.snils : null)),
		forMember(d => d.isRfrp, mapFrom(x => x.isRfrp)),
		forMember(d => d.isLeasingCompany, mapFrom(x => x.isLeasingCompany)),
		forMember(d => d.isPledgeExpertCompany, mapFrom(x => x.isPledgeExpertCompany)),
		forMember(d => d.isVkmExpertCompany, mapFrom(x => x.isVkmExpertCompany)),
		forMember(d => d.isFrpAssigneeCompany, mapFrom(x => x.isFrpAssigneeCompany))
	);
};

addProfile(mapper, physicalPersonProfile);

export default mapper;
