import ApiRole from "@/api/types/roles/apiRole";
import { Type } from "class-transformer";

export class ApiOfficeEmployeeBase {
	position: string;
	snils: string;
	firstName: string;
	middleName: string;
	lastName: string;
	description: string;
	email: string;
	phone: string;
	extensionPhoneNumber: string;
	isActive: boolean;
	@Type(() => ApiRole)
	roles: ApiRole[];

	constructor(
		position: string,
		snils: string,
		firstName: string,
		middleName: string,
		lastName: string,
		description: string,
		email: string,
		phone: string,
		extensionPhoneNumber: string,
		isActive: boolean,
		roles: ApiRole[])
	{
		this.position = position;
		this.snils = snils;
		this.firstName = firstName;
		this.middleName = middleName;
		this.lastName = lastName;
		this.description = description;
		this.email = email;
		this.phone = phone;
		this.extensionPhoneNumber = extensionPhoneNumber;
		this.isActive = isActive;
		this.roles = roles;
	}
}
