export class ApiCounterpartyEmployeeBase {
	counterpartyId: string;
	email: string;
	phone: string;
	position: string;
	snils: string;
	firstName: string;
	middleName: string;
	lastName: string;
	isActive: boolean;
	hasLoanAgreementsAccess: boolean;

	constructor(counterpartyId: string = "",
		email: string = "",
		phone: string = "",
		position: string = "",
		snils: string = "",
		firstName: string = "",
		middleName: string = "",
		lastName: string = "",
		isActive: boolean = false,
		hasLoanAgreementsAccess: boolean = false
	)
	{
		this.counterpartyId = counterpartyId;
		this.email = email;
		this.phone = phone;
		this.position = position;
		this.snils = snils;
		this.firstName = firstName;
		this.middleName = middleName;
		this.lastName = lastName;
		this.isActive = isActive;
		this.hasLoanAgreementsAccess = hasLoanAgreementsAccess;
	}
}
