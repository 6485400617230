import BaseStepStateModel from "@/store/shared/baseStep/models/baseStepStateModel";
import { ApplicationStepOrders } from "@/store/modules/masterAccountAccess/types/ApplicationStepOrders";

export default class MasterAccountAccessFinalStepStateModel extends BaseStepStateModel {
	constructor(
		public order: number = ApplicationStepOrders.FINAL_STEP_ORDER,
		public isFormValid: boolean = false,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false
	)
	{
		const component = "frp-final-step";
		
		super(component, order, isInitialized, isDestroyed);
	}
}
