export default class ApiGetEmployeeJoinApplicationsParameters {
	query: String;
	statuses: String[];
	counterpartyInn: String;
	counterpartyOgrn: String;
	counterpartyKpp: String;
	counterpartyLongName: String;
	counterpartyCountryCode: String;

	constructor(
		query: String,
		statuses: String[],
		counterpartyInn: String = "",
		counterpartyOgrn: String = "",
		counterpartyKpp: String = "",
		counterpartyLongName: String = "",
		counterpartyCountryCode: String = ""
	)
	{
		this.query = query;
		this.statuses = statuses;
		this.counterpartyInn = counterpartyInn;
		this.counterpartyOgrn = counterpartyOgrn;
		this.counterpartyKpp = counterpartyKpp;
		this.counterpartyLongName = counterpartyLongName;
		this.counterpartyCountryCode = counterpartyCountryCode;
	}
}
