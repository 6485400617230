<template>
	<v-progress-circular :size="size"
						 :width="width"
						 :indeterminate="indeterminate"
						 :color="color"
						 :class="classes">
	</v-progress-circular>
</template>

<script>
export default {
	props: {
		size: {
			type: [String, Number],
			default: 70
		},
		width: {
			type: [String, Number],
			default: 7
		},
		indeterminate: {
			type: Boolean,
			default: true
		},
		color: {
			type: String,
			default: "secondary"
		},
		classes: {
			type: String,
			default: "d-block mx-auto my-4"
		}
	}
};
</script>

<style scoped>

</style>
