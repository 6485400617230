import { createMap, createMapper, MappingProfile, forMember, mapFrom, addProfile, ignore, fromValue } from "@automapper/core";
import { classes } from "@automapper/classes";
import ApiLegalEntityHeadIdentifiers from "@/api/types/legalEntity/apiLegalEntityHeadIdentifiers";
import ApiLegalEntityCounterpartyHead from "@/api/types/counterparty/apiLegalEntityCounterpartyHead";
import ApiLegalEntityCounterpartyHeadPersisted from "@/api/types/counterparty/apiLegalEntityCounterpartyHeadPersisted";
import ApiLegalEntityHead from "@/api/types/legalEntity/apiLegalEntityHead";
import LegalEntityCounterpartyHead
	from "@/store/modules/counterparty/modules/legalEntityCounterparty/modules/legalEntityCounterpartyHead/types/legalEntityCounterpartyHead";

const mapper = createMapper({
	strategyInitializer: classes()
});

const legalEntityHeadProfile: MappingProfile = (mapper) => {
	createMap(mapper, ApiLegalEntityCounterpartyHeadPersisted, LegalEntityCounterpartyHead,
		forMember(d => d.id, mapFrom(x => x.id)),
		forMember(d => d.inn, mapFrom(x => x.legalEntityHead.inn)),
		forMember(d => d.isSnilsRecognizing, fromValue(false)),
		forMember(d => d.snils, mapFrom(x => x.legalEntityHead.snils)),
		forMember(d => d.fullName, mapFrom(x => x.legalEntityHead.fullName)),
		forMember(d => d.position, mapFrom(x => x.legalEntityHead.position)),
		forMember(d => d.description, mapFrom(x => x.legalEntityHead.description))
	);

	createMap(mapper, ApiLegalEntityCounterpartyHead, LegalEntityCounterpartyHead,
		forMember(d => d.id, fromValue("")),
		forMember(d => d.isSnilsRecognizing, fromValue(false)),
		forMember(d => d.inn, mapFrom(x => x.inn)),
		forMember(d => d.snils, mapFrom(x => x.snils)),
		forMember(d => d.fullName, mapFrom(x => x.fullName)),
		forMember(d => d.position, mapFrom(x => x.position)),
		forMember(d => d.description, mapFrom(x => x.description || ""))
	);

	createMap(mapper, ApiLegalEntityHead, LegalEntityCounterpartyHead,
		forMember(d => d.id, fromValue("")),
		forMember(d => d.isSnilsRecognizing, fromValue(false)),
		forMember(d => d.inn, mapFrom(x => x.inn)),
		forMember(d => d.snils, mapFrom(x => x.snils)),
		forMember(d => d.fullName, mapFrom(x => x.fullName)),
		forMember(d => d.position, mapFrom(x => x.position)),
		forMember(d => d.description, fromValue(""))
	);

	createMap(mapper, LegalEntityCounterpartyHead, ApiLegalEntityHeadIdentifiers,
		forMember(d => d.inn, mapFrom(x => x.inn)),
		forMember(d => d.snils, mapFrom(x => x.snils))
	);
};

addProfile(mapper, legalEntityHeadProfile);

export default mapper;
