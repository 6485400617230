<template>
	<v-dialog v-if="$vuetify.breakpoint.xsOnly"
			  :value="value"
			  @input="$emit('update:value', $event)"
			  fullscreen>
		<template #activator="{ on, attrs }">
			<slot name="activator" :on="on" :attrs="attrs"></slot>
		</template>
		<v-card class="frp-mobile-dialog">
			<v-card-text class="pa-8">
				<slot name="content"></slot>
				<frp-bottom-space height="80"></frp-bottom-space>
			</v-card-text>
			<v-card-actions style="bottom: 0; width: 100%"
							:style="`background-color: ${colors.white.base}`"
							class="justify-end pt-3 pb-7 px-8">
				<slot name="actions"></slot>
			</v-card-actions>
		</v-card>
	</v-dialog>

	<v-menu v-else
			:value="value"
			@input="$emit('update:value', $event)"
			:close-on-content-click="false"
			offset-y
			:left="left"
			nudge-bottom="6">
		<template v-slot:activator="{ on, attrs }">
			<slot name="activator" :on="on" :attrs="attrs"></slot>
		</template>
		<v-responsive min-width="512">
			<v-card class="pa-6">
				<slot name="content"></slot>
				<v-card-actions class="align-center justify-end pa-0 mt-10">
					<slot name="actions"></slot>
				</v-card-actions>
			</v-card>
		</v-responsive>
	</v-menu>
</template>

<script>
import FrpBottomSpace from "Components/layouts/FrpBottomSpace";
import colorsMixin from "Mixins/colorsMixin";

export default {
	mixins: [colorsMixin],
	model: {
		prop: "value",
		event: "update:value"
	},
	props: {
		value: Boolean,
		left: {
			type: Boolean,
			default: false
		}
	},
	components: {
		FrpBottomSpace
	}
};
</script>

<style lang="scss">
.frp-mobile-dialog {
	.v-card__actions {
		position: fixed;
		right: 0;
		bottom: 28px;
	}
}

</style>
