import {
	namespace,
	actionTypes,
	mutationTypes,
	getterTypes
} from "@/store/modules/applications/employeeJoin/details/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import alertService, { AlertKeys } from "@/store/modules/alerts/services/alertService";
import { formatFullName } from "@/utils/formatting";
import AbortService from "@/services/abortService";
import HttpNotFoundException from "@/exceptions/httpNotFoundException";
import EmployeeJoinApplicationDetailsState
	from "@/store/modules/applications/employeeJoin/details/types/employeeJoinApplicationDetailsState";
import { EmployeeJoinApplicationController } from "@/api/employeeJoinApplication";
import EmployeeJoinApplicationStatus from "@/api/types/employeeJoinApplication/employeeJoinApplicationStatus";
import rootTypes from "@/store/types";
import { PageModeType } from "@/store/types/pageModeType";
import AccessForbiddenException from "@/exceptions/accessForbiddenException";
import { Permissions } from "@/constants/permissions";
import { AuthorizationScopeType } from "@/types/authorization/authorizationScopeType";
import PermissionsService from "@/services/permissionsService";

const abortService = new AbortService();
const employeeJoinApplicationController = new EmployeeJoinApplicationController(abortService);
const permissionsService = new PermissionsService();

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new EmployeeJoinApplicationDetailsState();
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();
const baseMixin = (new BaseMixinBuilder(abortService)).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<EmployeeJoinApplicationDetailsState, any>>{
	[getterTypes.title]: state => {
		return formatFullName(state.details.employee);
	}
};

const actions = <ActionTree<EmployeeJoinApplicationDetailsState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit }, { id }) {
		await dispatch(actionTypes.initializeBase);

		await dispatch(actionTypes.updateDetails, { id });

		commit(mutationTypes.SET_ID, id);
		commit(mutationTypes.SET_IS_INITIALIZED, true);
	},
	async [actionTypes.updateDetails]({ commit, dispatch }, { id }) {
		commit(mutationTypes.SET_IS_LOADING, true);

		try {
			const scope = await permissionsService.check([Permissions.OWN_EMPLOYEE_JOIN_APPLICATION_READ])
				? AuthorizationScopeType.OWN
				: AuthorizationScopeType.GLOBAL;

			const application = await employeeJoinApplicationController.getEmployeeJoinApplication(id, scope);

			commit(mutationTypes.SET_DETAILS, application);
		} catch (error) {
			switch (error.constructor) {
				case HttpNotFoundException:
					commit(rootTypes.mutationTypes.SET_PAGE_MODE, PageModeType.PAGE_NOT_FOUND, { root: true });
					break;
				case AccessForbiddenException:
					commit(rootTypes.mutationTypes.SET_PAGE_MODE, PageModeType.ACCESS_DENIED, { root: true });
					break;
				default:
					AlertHelper.handleGeneralRequestErrors(error);
					break;
			}
		} finally {
			commit(mutationTypes.SET_IS_LOADING, false);
		}
	},
	async [actionTypes.approveApplication]({ state, commit }) {
		commit(mutationTypes.SET_IS_APPROVING, true);

		try {
			const scope = await permissionsService.check([Permissions.OWN_EMPLOYEE_JOIN_APPLICATION_STATUS_UPDATE])
				? AuthorizationScopeType.OWN
				: AuthorizationScopeType.GLOBAL;

			await employeeJoinApplicationController.updateEmployeeJoinApplicationStatus(state.id,
				EmployeeJoinApplicationStatus.Approved, scope);

			commit(mutationTypes.SET_APPLICATION_STATUS, EmployeeJoinApplicationStatus.Approved);

			alertService.addInfo(AlertKeys.EMPLOYEE_JOIN_APPLICATION_APPROVED);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_APPROVING, false);
		}
	},
	async [actionTypes.rejectApplication]({ state, commit }) {
		commit(mutationTypes.SET_IS_REJECTING, true);

		try {
			const scope = await permissionsService.check([Permissions.OWN_EMPLOYEE_JOIN_APPLICATION_STATUS_UPDATE])
				? AuthorizationScopeType.OWN
				: AuthorizationScopeType.GLOBAL;

			await employeeJoinApplicationController.updateEmployeeJoinApplicationStatus(state.id,
				EmployeeJoinApplicationStatus.Rejected, scope);

			commit(mutationTypes.SET_APPLICATION_STATUS, EmployeeJoinApplicationStatus.Rejected);

			alertService.addInfo(AlertKeys.EMPLOYEE_JOIN_APPLICATION_REJECTED);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_REJECTING, false);
		}
	}
};

const mutations = <MutationTree<EmployeeJoinApplicationDetailsState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	[mutationTypes.SET_DETAILS](state, value) {
		state.details = value;
	},
	[mutationTypes.SET_IS_LOADING](state, value) {
		state.isLoading = value;
	},
	[mutationTypes.SET_IS_APPROVING](state, value) {
		state.isApproving = value;
	},
	[mutationTypes.SET_APPLICATION_STATUS](state, value) {
		state.details.status = value;
	},
	[mutationTypes.SET_IS_REJECTING](state, value) {
		state.isRejecting = value;
	},
	[mutationTypes.SET_ID](state, value) {
		state.id = value;
	}
};

export {
	namespace, state, getters, actions, mutations
};

const employeeJoinApplicationDetailsModule = {
	namespace, state, getters, actions, mutations, namespaced: true
};

export default employeeJoinApplicationDetailsModule;
