<template>
	<v-form :readonly="isFormSaving || isFormReadonly"
			:disabled="isFormDisabled"
			v-if="isInitialized"
			:ref="refs.form"
			v-model="formValid">
		<frp-form-card>
			<frp-form-card-blocks>
				<frp-form-card-block :title="$t('titles.general')">
					<frp-details-group>
						<frp-details-item
							:title="$t('details.titles.status')"
							:value="$t(`aliases.activeStatus.${account.isActive ? 'active' : 'inactive'}`)">
						</frp-details-item>
					</frp-details-group>
					<frp-embedded-email-form :edit-permissions="[Permissions.GLOBAL_ACCOUNT_EMAIL_UPDATE]"
											 :is-mode-selection-enabled="can(Permissions.GLOBAL_ACCOUNT_RESET)"
											 :namespace="emailModuleNamespace">
					</frp-embedded-email-form>
					<frp-embedded-password-form v-if="can(Permissions.GLOBAL_ACCOUNT_PASSWORD_UPDATE)"
												class="mb-4" :namespace="passwordModuleNamespace">
					</frp-embedded-password-form>

					<frp-form-card-block-row class="mt-0">
						<frp-form-card-block-col cols="12" md="6" lg="6">
							<div :class="{'d-flex flex-column': $vuetify.breakpoint.xsOnly}">
								<frp-toggle-activity-btn
									class="mr-sm-4"
									v-if="can(Permissions.GLOBAL_ACCOUNT_STATUS_UPDATE)"
									:is-active="account.isActive"
									:ml-auto="false"
									:loading="isStatusSaving"
									@click="updateStatus"/>

								<frp-dialog
									v-if="can(Permissions.GLOBAL_ACCOUNT_PASSWORD_RESET)"
									v-model="resetPasswordDialog"
									:title="$t('dialogs.resetPassword.title')"
									max-width="620">
									<template #activator="{ on, attrs }">
										<frp-btn
											v-if="can(Permissions.GLOBAL_ACCOUNT_PASSWORD_RESET)"
											v-on="on"
											class="mt-sm-0 mt-4"
											color="primary"
											v-bind="attrs">
											{{ $t("buttons.resetPassword") }}
										</frp-btn>
									</template>

									<template #content>
										<span class="text-body-1 primary--text">
											{{ $t("dialogs.resetPassword.description") }}
										</span>
									</template>

									<template #footer>
										<frp-btn @click="resetPasswordDialog = false" color="primary" outlined>
											{{ $t("buttons.back") }}
										</frp-btn>
										<frp-btn @click="handleResetPassword" :loading="isResetPasswordOperationExecuting"
												 color="secondary">
											{{ $t("buttons.ok") }}
										</frp-btn>
									</template>
								</frp-dialog>
							</div>
						</frp-form-card-block-col>
					</frp-form-card-block-row>
				</frp-form-card-block>
			</frp-form-card-blocks>
			<frp-bottom-space height="24"></frp-bottom-space>
		</frp-form-card>
	</v-form>
	<frp-account-general-loader v-else></frp-account-general-loader>
</template>

<script>
import FrpDetailsGroup from "@/components/details/FrpDetailsGroup";
import FrpDetailsItem from "@/components/details/FrpDetailsItem";
import authorizationMixin from "@/mixins/authorizationMixin";
import FrpToggleActivityBtn from "Components/buttons/FrpToggleActivityBtn";
import FrpEmbeddedEmailForm from "Components/embeddedForms/FrpEmbeddedEmailForm";
import FrpEmbeddedPasswordForm from "Components/embeddedForms/FrpEmbeddedPasswordForm";
import FrpTextField from "Components/fields/FrpTextField";
import FrpFormCardBlock from "Components/markup/FrpFormCardBlock";
import FrpFormCardBlockCol from "Components/markup/FrpFormCardBlockCol";
import FrpFormCardBlockRow from "Components/markup/FrpFormCardBlockRow";
import FrpFormCardBlocks from "Components/markup/FrpFormCardBlocks";
import FrpFormItem from "Components/markup/FrpFormItem";
import FrpFormCard from "Components/markup/FrpFormCard";
import FrpBottomSpace from "Components/layouts/FrpBottomSpace";
import FrpDialog from "Components/dialogs/FrpDialog";
import FrpBtn from "@/components/buttons/FrpBtn";
import { validateEmail } from "Utils/validator";
import FrpAccountGeneralLoader from "Views/account/sections/FrpAccountGeneralLoader";
import storeManager from "Store/manager/index";
import { actionTypes, mutationTypes } from "Store/modules/account/modules/general/types";
import storeModuleBasedPage from "Mixins/storeModuleBasedPage";
import formMixin from "Mixins/formMixin";
import {
	prepareEmailRule,
	prepareMaxLengthRule
} from "Utils/validation";
import { createNamespacedHelpers } from "vuex";
import { maxLength } from "Constants/validation";

const { mapState, mapMutations, mapActions } = createNamespacedHelpers(storeManager.account.general.namespace);

export default {
	mixins: [storeModuleBasedPage, formMixin, authorizationMixin],
	data() {
		return {
			validateEmail,
			namespace: storeManager.account.general.namespace,
			emailModuleNamespace: storeManager.account.general.email.namespace,
			passwordModuleNamespace: storeManager.account.general.password.namespace,
			validation: {
				login: [prepareMaxLengthRule({ maxLength: maxLength.short })],
				email: [prepareEmailRule()]
			},
			resetPasswordDialog: false
		};
	},
	computed: {
		...mapState({
			account: state => state.account,
			isResetPasswordOperationExecuting: state => state.isResetPasswordOperationExecuting,
			isInitialized: state => state.isInitialized,
			isStatusSaving: state => state.isStatusSaving,
			isEmailSaving: state => state.isEmailSaving
		}),
		login: {
			get() {
				return this.account.login;
			},
			set(value) {
				this.setLogin(value);
			}
		},
		email: {
			get() {
				return this.account.email;
			},
			set(value) {
				this.setEmail(value);
			}
		}
	},
	methods: {
		...mapActions({
			cancelChanges: actionTypes.cancelChanges,
			updateStatus: actionTypes.updateStatus,
			resetPassword: actionTypes.resetPassword
		}),
		...mapMutations({
			setLogin: mutationTypes.SET_LOGIN
		}),
		async handleResetPassword() {
			await this.resetPassword();
			this.resetPasswordDialog = false;
		}
	},
	created() {
		this.initializeStore({ id: this.$route.params.id });
	},
	components: {
		FrpDetailsGroup,
		FrpDetailsItem,
		FrpToggleActivityBtn,
		FrpEmbeddedEmailForm,
		FrpEmbeddedPasswordForm,
		FrpAccountGeneralLoader,
		FrpFormItem,
		FrpTextField,
		FrpFormCardBlockCol,
		FrpFormCardBlockRow,
		FrpFormCardBlock,
		FrpFormCardBlocks,
		FrpFormCard,
		FrpBottomSpace,
		FrpBtn,
		FrpDialog
	}
};
</script>

<style scoped>

</style>
