import AbortService from "@/services/abortService";
import ContactInformationStepStateModel
	from "@/store/modules/masterAccountAccess/modules/contactInformationStep/models/contactInformationStepStateModel";
import {
	namespace,
	actionTypes,
	mutationTypes,
	getterTypes
} from "@/store/modules/masterAccountAccess/modules/contactInformationStep/types";
import BaseMixinBuilder from "@/store/shared/base";
import BaseStepMixinBuilder from "@/store/shared/baseStep";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import baseMixinTypes from "@/store/shared/base/types";

const abortService = new AbortService();
const baseStepMixin = (new BaseStepMixinBuilder()).build();

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new ContactInformationStepStateModel();
	}
}


const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();
const baseMixin = (new BaseMixinBuilder(abortService)).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<ContactInformationStepStateModel, any>>{
	[getterTypes.isStepCompleted]: (state) => {
		return !!state.email && state.isFormValid;
	}
};

const actions = <ActionTree<ContactInformationStepStateModel, any>>{
	...stateManipulationMixin.actions,
	...baseStepMixin.actions,
	...baseMixin.actions,
	[actionTypes.initialize]({ dispatch, commit }) {
		dispatch(baseMixinTypes.actionTypes.initializeBase);

		commit(mutationTypes.SET_IS_INITIALIZED, true);
	}
};

const mutations = <MutationTree<ContactInformationStepStateModel>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	...baseStepMixin.mutations,
	[mutationTypes.SET_EMAIL](state, value) {
		state.email = value;
	},
	[mutationTypes.SET_IS_FORM_VALID](state, value) {
		state.isFormValid = value;
	}
};

export {
	namespace, state, getters, actions, mutations
};

const contactInformationStepModule = {
	namespace, state, getters, actions, mutations, namespaced: true
};

export default contactInformationStepModule;
