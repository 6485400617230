import { IsString, IsInt, Min, IsIn, Max, Length } from "class-validator";
import { AVAILABLE_SORTS } from "@/store/modules/applications/masterAccountAccess/list/constants";
import { sortingOrderType } from "@/store/shared/sorting/models/types/sortingOrderType";

export default class MasterAccountAccessApplicationsRouteQuery {
	@IsInt()
	@Min(0)
	@Max(1000)
	page: number;

	@IsString()
	@IsIn(AVAILABLE_SORTS)
	sort: String;

	@IsString()
	@IsIn([sortingOrderType.descending, sortingOrderType.ascending])
	sortDirection: String;

	@IsString()
	@Length(0, 100)
	query: String;

	@IsString()
	filterStatus: String;

	constructor(page: number, sort: String, sortDirection: String, query: String, filterStatus: String) {
		this.page = page;
		this.sort = sort;
		this.sortDirection = sortDirection;
		this.query = query;
		this.filterStatus = filterStatus;
	}
}
