import IPageState from "@/store/shared/base/types/pageState";
import FormState from "@/store/shared/form/models/formState";
import { ApiCounterpartyUnassignedEmployee } from "@/api/types/counterparty/apiCounterpartyUnassignedEmployee";
import LegalEntityCounterparty from "@/store/modules/counterparty/modules/legalEntityCounterparty/types/legalEntityCounterparty";

export default class EmployeeJoinState implements IPageState {
	constructor(
		public form: FormState,
		public snapshot: object,
		public employee: ApiCounterpartyUnassignedEmployee = new ApiCounterpartyUnassignedEmployee(),
		public counterparty: LegalEntityCounterparty = new LegalEntityCounterparty(),
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public isEgrulLegalEntityLoading: boolean = false
	)
	{
	}
}
