import {
	namespace,
	actionTypes,
	mutationTypes,
	getterTypes
} from "@/store/modules/counterparties/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import SortingMixinBuilder from "@/store/shared/sorting";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import ListingMixinBuilder from "@/store/shared/listing";
import ListingModel from "@/store/shared/listing/models/listingModel";
import PagingMixinBuilder from "@/store/shared/paging";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchMixinBuilder from "@/store/shared/search";
import SearchModel from "@/store/shared/search/models/searchModel";
import { sortingOrderType } from "@/store/shared/sorting/models/types/sortingOrderType";
import { resolveAction, resolveMutation, resolveNestedState } from "@/utils/vuexModules";
import CounterpartiesState from "@/store/modules/counterparties/types/counterpartiesState";
import { GetterTree, MutationTree, ActionTree, Store, MutationPayload } from "vuex";
import { i18n } from "@/plugins";
import ApiCounterpartyPersistedBase from "@/api/types/counterparty/apiCounterpartyPersistedBase";
import ApiForeignOrganizationCounterparty from "@/api/types/counterparty/apiForeignOrganizationCounterparty";
import ApiLegalPersonCounterparty from "@/api/types/counterparty/apiLegalPersonCounterparty";
import ApiLegalEntityCounterparty from "@/api/types/counterparty/apiLegalEntityCounterparty";
import ApiPhysicalPersonCounterparty from "@/api/types/counterparty/apiPhysicalPersonCounterparty";
import BatchService from "@/services/batchService";
import RouteMixinBuilder from "@/store/shared/route";
import CounterpartiesRouteQuery from "@/store/modules/counterparties/types/counterpartiesRouteQuery";
import CounterpartiesRouteQueryService from "@/store/modules/counterparties/services/counterpartiesRouteQueryService";
import CounterpartiesFilter from "@/store/modules/counterparties/types/counterpartiesFilter";
import SubscribersManager from "@/store/manager/subscribersManager";
import routeTypes from "@/store/shared/route/types";
import router from "@/router";
import { mapApiGetCounterpartiesParameters } from "@/store/modules/counterparties/mapper";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import { convertIsoToNumber, formatDate } from "@/utils/dates";
import { dateTimeFormat } from "@/utils/formats";
import { formatFullName } from "@/utils/formatting";
import { CounterpartyController } from "@/api/counterparty";
import AbortService from "@/services/abortService";
import { RouteNames } from "@/router/routes";
import * as ls from "local-storage";
import { LAST_VISITED_COUNTERPARTIES } from "@/constants/localStorage";

const abortService = new AbortService();
const counterpartyController = new CounterpartyController(abortService);

const defaultRouteQuery = new CounterpartiesRouteQuery(1, "createdAt", sortingOrderType.descending, "", []);

const routeQueryService = new CounterpartiesRouteQueryService(defaultRouteQuery);

const updateListingBatchService = new BatchService(({ interval: 100 }));

const routeMixin = (new RouteMixinBuilder<CounterpartiesState>()).build();

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new CounterpartiesState(
			new ListingModel<ApiCounterpartyPersistedBase>({
				items: [],
				isLoadingState: false
			}),
			new SortingModel<String>({
				type: "createdAt",
				order: sortingOrderType.descending
			}),
			new PagingModel({
				total: 0,
				page: 1,
				pageSize: 25
			}),
			new SearchModel({
				query: defaultRouteQuery.query
			}),
			new CounterpartiesFilter(
				[]
			),
			routeMixin.state()
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();
const baseMixin = (new BaseMixinBuilder(abortService)).build();
const listingMixin = (new ListingMixinBuilder()).build();
const pagingMixin = (new PagingMixinBuilder()).build();
const sortingMixin = (new SortingMixinBuilder()).build();
const searchMixin = (new SearchMixinBuilder()).build();

const state = (new DefaultStateBuilder()).build();

let subscribersManager: SubscribersManager<CounterpartiesState>;

const getters = <GetterTree<CounterpartiesState, any>>{
	...listingMixin.getters,
	[getterTypes.formattedItems]: state => {
		return state.listing.items.map(x => {
			let item = {
				id: x.id,
				createdAt: formatDate(convertIsoToNumber(x.createdAt), dateTimeFormat),
				inn: "",
				ogrn: "",
				title: "",
				counterpartyType: i18n.t(`aliases.counterpartyType.${x.counterparty.type}`)
			};

			if(x.counterparty instanceof ApiLegalEntityCounterparty) {
				item.inn = x.counterparty.inn;
				item.ogrn = x.counterparty.ogrn;
				item.title = x.counterparty.shortName;
			}
			if(x.counterparty instanceof ApiLegalPersonCounterparty) {
				item.inn = x.counterparty.inn;
				item.ogrn = x.counterparty.ogrnIp;
				item.title = x.counterparty.fullName;
			}
			if(x.counterparty instanceof ApiForeignOrganizationCounterparty) {
				item.title = x.counterparty.longName;
			}

			if(x.counterparty instanceof ApiPhysicalPersonCounterparty) {
				item.inn = x.counterparty.inn;
				item.title = formatFullName({ ...x.counterparty });
			}

			return item;
		});
	}
};

let unsubscribeCallback = () => {
};
let store: Store<{}>;

const initializeSubscribersManager = (value: Store<{}>) => {
	store = value;
	subscribersManager = new SubscribersManager<CounterpartiesState>(store);
};

const subscribe = async (mutation: MutationPayload, rootState: any) => {
	let state = resolveNestedState<CounterpartiesState>(rootState, namespace);
	switch (mutation.type) {
		case resolveMutation(routeTypes.namespace, routeTypes.mutationTypes.ROUTE_CHANGED):
			if((mutation.payload.from.name === mutation.payload.to.name) && !state.route.isPushing)
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.processRouteQuery));
			break;
		case resolveMutation(namespace, mutationTypes.SET_SORTING_TYPE):
		case resolveMutation(namespace, mutationTypes.SET_SORTING_ORDER):
		case resolveMutation(namespace, mutationTypes.SET_SEARCH_QUERY):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_TYPE):
			if(!state.route.isProcessing) {
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.resetPagingPage));
			}
			break;
		case resolveMutation(namespace, mutationTypes.SET_PAGING_PAGE):
		{
			if(!state.route.isProcessing)
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.pushRoute));

			updateListingBatchService.push(async () => {
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.updateListingItems));
			});

			break;
		}
	}
};

const actions = <ActionTree<CounterpartiesState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...listingMixin.actions,
	...pagingMixin.actions,
	...sortingMixin.actions,
	...searchMixin.actions,
	...routeMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit }) {
		await dispatch(actionTypes.initializeBase);

		await dispatch(actionTypes.processRouteQuery);
		await dispatch(actionTypes.reconstituteRoute);
		await dispatch(actionTypes.updateListingItems);

		const searchSuggestions = ls.get(LAST_VISITED_COUNTERPARTIES) || [];
		commit(mutationTypes.SET_LAST_VISITED_COUNTERPARTIES, searchSuggestions);

		unsubscribeCallback = subscribersManager.subscribe(subscribe);
		commit(mutationTypes.SET_IS_INITIALIZED, true);
	},
	async [actionTypes.updateListingItems]({ state, commit, dispatch }) {
		commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, true);

		try {
			let { counterparties, totalCount } = await counterpartyController.getCounterparties(mapApiGetCounterpartiesParameters(state));

			commit(mutationTypes.SET_LISTING_ITEMS, counterparties);
			commit(mutationTypes.SET_PAGING_TOTAL, totalCount);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, false);
		}
	},
	async [actionTypes.processRouteQuery]({ rootState, commit }) {
		commit(mutationTypes.SET_IS_ROUTE_PROCESSING, true);

		let routeQuery = await routeQueryService.resolveRouteQuery(rootState.route.query);

		commit(mutationTypes.SET_PAGING_PAGE, routeQuery.page);
		commit(mutationTypes.SET_SORTING_ORDER, routeQuery.sortDirection);
		commit(mutationTypes.SET_SORTING_TYPE, routeQuery.sort);
		commit(mutationTypes.SET_SEARCH_QUERY, routeQuery.query);
		commit(mutationTypes.SET_FILTER_TYPE, routeQuery.filterType);

		commit(mutationTypes.SET_IS_ROUTE_PROCESSING, false);
	},
	async [actionTypes.pushRoute]({ state, commit }) {
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, true);

		await router.push({
			name: RouteNames.COUNTERPARTIES,
			query: routeQueryService.resolveRouteQueryDictionary(state)
		})
					.catch(() => {
					});

		commit(mutationTypes.SET_IS_ROUTE_PUSHING, false);
	},
	async [actionTypes.reconstituteRoute]({ state, commit }) {
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, true);

		await router.replace({
			name: RouteNames.COUNTERPARTIES,
			query: routeQueryService.resolveRouteQueryDictionary(state)
		})
					.catch(() => {
					});

		commit(mutationTypes.SET_IS_ROUTE_PUSHING, false);
	},
	async [actionTypes.destroy]({ dispatch }) {
		unsubscribeCallback();
		await dispatch(actionTypes.destroyBase);
	}
};


const mutations = <MutationTree<CounterpartiesState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	...listingMixin.mutations,
	...pagingMixin.mutations,
	...sortingMixin.mutations,
	...searchMixin.mutations,
	...routeMixin.mutations,
	[mutationTypes.SET_FILTER_TYPE](state, value) {
		state.filter.type = value;
	},
	[mutationTypes.SET_LAST_VISITED_COUNTERPARTIES](state, value) {
		state.searchSuggestions = value;
	}
};

export {
	namespace, state, getters, actions, mutations, initializeSubscribersManager
};

const counterpartiesModule = {
	namespace, state, getters, actions, mutations, namespaced: true, initializeSubscribersManager
};

export default counterpartiesModule;
