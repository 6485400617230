import SubscribersManager from "@/store/manager/subscribersManager";
import MasterAccountAccessApplicationsState
	from "@/store/modules/applications/masterAccountAccess/list/types/masterAccountAccessApplicationsState";
import {
	namespace,
	actionTypes,
	mutationTypes,
	getterTypes
} from "@/store/modules/applications/masterAccountAccess/list/types";
import MasterAccountAccessApplicationsRouteQuery
	from "@/store/modules/applications/masterAccountAccess/list/types/masterAccountAccessApplicationsRouteQuery";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import SortingMixinBuilder from "@/store/shared/sorting";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import ListingMixinBuilder from "@/store/shared/listing";
import ListingModel from "@/store/shared/listing/models/listingModel";
import PagingMixinBuilder from "@/store/shared/paging";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchMixinBuilder from "@/store/shared/search";
import SearchModel from "@/store/shared/search/models/searchModel";
import { sortingOrderType } from "@/store/shared/sorting/models/types/sortingOrderType";
import { resolveAction, resolveMutation, resolveNestedState } from "@/utils/vuexModules";
import { ActionTree, GetterTree, MutationPayload, MutationTree } from "vuex";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";
import MasterAccountAccessApplicationsRouteQueryService
	from "@/store/modules/applications/masterAccountAccess/list/services/masterAccountAccessApplicationsRouteQueryService";
import BatchService from "@/services/batchService";
import RouteMixinBuilder from "@/store/shared/route";
import MasterAccountAccessApplicationsFilter
	from "@/store/modules/applications/masterAccountAccess/list/types/masterAccountAccessApplicationsFilter";
import routeTypes from "@/store/shared/route/types";
import router from "@/router";
import { mapApiGetMasterAccountAccessApplicationsParameters } from "@/store/modules/applications/masterAccountAccess/list/mapper";
import { Store } from "vuex";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import { formatDate } from "@/utils/dates";
import { dateTimeFormat } from "@/utils/formats";
import { formatFullName } from "@/utils/formatting";
import { CounterpartyType } from "@/types/CounterpartyType";
import ApiLegalEntityCounterpartyHead from "@/api/types/counterparty/apiLegalEntityCounterpartyHead";
import { MasterAccountAccessApplicationController } from "@/api/masterAccountAccessApplication";
import AbortService from "@/services/abortService";
import { RouteNames } from "@/router/routes";
import { ApproveStatusType } from "@/types/ApproveStatusType";

const abortService = new AbortService();
const masterAccountAccessApplicationController = new MasterAccountAccessApplicationController(abortService);

const defaultRouteQuery = new MasterAccountAccessApplicationsRouteQuery(1, "createdAt", sortingOrderType.descending, "", ApproveStatusType.CREATED);

const routeQueryService = new MasterAccountAccessApplicationsRouteQueryService(defaultRouteQuery);

const updateListingBatchService = new BatchService(({ interval: 100 }));

const routeMixin = (new RouteMixinBuilder<MasterAccountAccessApplicationsState>()).build();

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new MasterAccountAccessApplicationsState(
			new ListingModel({
				items: [],
				isLoadingState: false
			}),
			new SortingModel<String>({
				type: defaultRouteQuery.sort,
				order: defaultRouteQuery.sortDirection
			}),
			new PagingModel({
				total: 0,
				page: defaultRouteQuery.page,
				pageSize: 25
			}),
			new SearchModel({
				query: defaultRouteQuery.query
			}),
			new MasterAccountAccessApplicationsFilter(
				[]
			),
			routeMixin.state()
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();
const baseMixin = (new BaseMixinBuilder(abortService)).build();
const listingMixin = (new ListingMixinBuilder()).build();
const pagingMixin = (new PagingMixinBuilder()).build();
const sortingMixin = (new SortingMixinBuilder()).build();
const searchMixin = (new SearchMixinBuilder()).build();

const state = (new DefaultStateBuilder()).build();

let subscribersManager: SubscribersManager<MasterAccountAccessApplicationsState>;

const getters = <GetterTree<MasterAccountAccessApplicationsState, any>>{
	...listingMixin.getters,
	[getterTypes.formattedItems]: state => {
		return state.listing.items.map(x => {
			let item = {
				...x,
				masterAccountRequestStatus: x.status,
				heads: [] as ApiLegalEntityCounterpartyHead[],
				inn: "",
				title: "",
				createdAt: formatDate(new Date(x.createdAt), dateTimeFormat)
			};

			if(x.type === CounterpartyType.LEGAL_ENTITY) {
				item.heads = x.legalEntity.heads;
				item.inn = x.legalEntity.inn;
				item.title = x.legalEntity.shortName;
			}
			if(x.type === CounterpartyType.LEGAL_PERSON) {
				item.inn = x.entrepreneur.inn;
				item.title = x.entrepreneur.fullName;
			}
			if(x.type === CounterpartyType.FOREIGN_ORGANIZATION) {
				item.title = x.foreignOrganization.longName;
			}

			if(x.type === CounterpartyType.PHYSICAL_PERSON) {
				item.inn = x.individual.inn;
				item.title = formatFullName({ ...x.individual });
			}

			return item;
		});
	}
};

let unsubscribeCallback = () => {
};
let store: Store<{}>;

const initializeSubscribersManager = (value: Store<{}>) => {
	store = value;
	subscribersManager = new SubscribersManager<MasterAccountAccessApplicationsState>(store);
};

const subscribe = async (mutation: MutationPayload, rootState: any) => {
	let state = resolveNestedState<MasterAccountAccessApplicationsState>(rootState, namespace);
	switch (mutation.type) {
		case resolveMutation(routeTypes.namespace, routeTypes.mutationTypes.ROUTE_CHANGED):
			if((mutation.payload.from.name === mutation.payload.to.name) && !state.route.isPushing)
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.processRouteQuery));
			break;
		case resolveMutation(namespace, mutationTypes.SET_SORTING_TYPE):
		case resolveMutation(namespace, mutationTypes.SET_SORTING_ORDER):
		case resolveMutation(namespace, mutationTypes.SET_SEARCH_QUERY):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_STATUS):
			await subscribersManager.dispatch(resolveAction(namespace, actionTypes.resetPagingPage));
		case resolveMutation(namespace, mutationTypes.SET_PAGING_PAGE):
		{
			if(!state.route.isProcessing)
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.pushRoute));

			updateListingBatchService.push(async () => {
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.updateListingItems));
			});

			break;
		}
	}
};

const actions = <ActionTree<MasterAccountAccessApplicationsState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...listingMixin.actions,
	...pagingMixin.actions,
	...sortingMixin.actions,
	...searchMixin.actions,
	...routeMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit }) {
		await dispatch(actionTypes.initializeBase);

		await dispatch(actionTypes.processRouteQuery);
		await dispatch(actionTypes.reconstituteRoute);
		await dispatch(actionTypes.updateListingItems);

		unsubscribeCallback = subscribersManager.subscribe(subscribe);

		commit(mutationTypes.SET_IS_INITIALIZED, true);
	},
	async [actionTypes.updateListingItems]({ commit, state, dispatch }) {
		commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, true);

		try {
			let { requests, totalCount } = await masterAccountAccessApplicationController.getMasterAccountAccessApplications(
				mapApiGetMasterAccountAccessApplicationsParameters(state));

			commit(mutationTypes.SET_LISTING_ITEMS, requests);
			commit(mutationTypes.SET_PAGING_TOTAL, totalCount);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, false);
		}
	},
	async [actionTypes.processRouteQuery]({ rootState, commit }) {
		commit(mutationTypes.SET_IS_ROUTE_PROCESSING, true);

		let routeQuery = await routeQueryService.resolveRouteQuery(rootState.route.query);

		commit(mutationTypes.SET_PAGING_PAGE, routeQuery.page);
		commit(mutationTypes.SET_SORTING_ORDER, routeQuery.sortDirection);
		commit(mutationTypes.SET_SORTING_TYPE, routeQuery.sort);
		commit(mutationTypes.SET_SEARCH_QUERY, routeQuery.query);
		commit(mutationTypes.SET_FILTER_STATUS, routeQuery.filterStatus);

		commit(mutationTypes.SET_IS_ROUTE_PROCESSING, false);
	},
	async [actionTypes.pushRoute]({ state, commit }) {
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, true);

		await router.push({
			name: RouteNames.APPLICATIONS_MASTER_ACCOUNT_ACCESSES,
			query: routeQueryService.resolveRouteQueryDictionary(state)
		}).catch(() => {});

		commit(mutationTypes.SET_IS_ROUTE_PUSHING, false);
	},
	async [actionTypes.reconstituteRoute]({ state, commit }) {
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, true);

		await router.replace({
			name: RouteNames.APPLICATIONS_MASTER_ACCOUNT_ACCESSES,
			query: routeQueryService.resolveRouteQueryDictionary(state)
		}).catch(() => {});

		commit(mutationTypes.SET_IS_ROUTE_PUSHING, false);
	},
	async [actionTypes.destroy]({ dispatch }) {
		unsubscribeCallback();
		await dispatch(actionTypes.destroyBase);
	}
};

const mutations = <MutationTree<MasterAccountAccessApplicationsState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	...listingMixin.mutations,
	...pagingMixin.mutations,
	...sortingMixin.mutations,
	...searchMixin.mutations,
	...routeMixin.mutations,
	[mutationTypes.SET_FILTER_STATUS](state, value) {
		state.filter.status = value;
	}
};

export {
	namespace, state, getters, actions, mutations, initializeSubscribersManager
};

const masterAccountAccessApplicationsModule = {
	namespace, state, getters, actions, mutations, initializeSubscribersManager, namespaced: true
};

export default masterAccountAccessApplicationsModule;
