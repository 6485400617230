import BaseStepStateModel from "@/store/shared/baseStep/models/baseStepStateModel";
import { CounterpartyType } from "@/types/CounterpartyType";
import ApiLegalEntity from "@/api/types/legalEntity/apiLegalEntity";
import { ApplicationStepOrders } from "@/store/modules/masterAccountAccess/types/ApplicationStepOrders";
import MasterAccountAccessErrorType from "@/store/modules/masterAccountAccess/types/masterAccountAccessErrorType";
import ApiFnsLegalPerson from "@/api/types/fns/apiFnsLegalPerson";

export default class SelectCounterpartyStepState extends BaseStepStateModel {
	constructor(
		public order: number = ApplicationStepOrders.SELECT_COUNTERPARTY_STEP_ORDER,
		public inn: string = "",
		public counterparty: ApiLegalEntity | ApiFnsLegalPerson | null = null,
		public counterpartyType: CounterpartyType = CounterpartyType.UNKNOWN,
		public isCounterpartyLoading: boolean = false,
		public isCounterpartyAlreadyExists: boolean = false,
		public isMasterAccountAlreadyExists: boolean = false,
		public errors: { type: MasterAccountAccessErrorType }[] = [],
		public isFormValid: boolean = false,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false
	)
	{
		const component = "frp-select-legal-entity-step";
		
		super(component, order, isInitialized, isDestroyed);
	}
}
