<template>
	<div>
		<v-icon size="12"
				:color="color"
				class="mr-2">
			mdi-circle
		</v-icon>
		<span :style="{ color }">{{ $t(`aliases.activeStatus.${status}`) }}</span>
	</div>
</template>

<script>
import colorsMixin from "Mixins/colorsMixin";

export default {
	mixins: [colorsMixin],
	props: {
		value: {
			type: Boolean,
			required: true
		}
	},
	computed: {
		status() {
			return this.value ? "active" : "inactive";
		},
		color() {
			return this.value ? this.colors.green.base : this.colors.grey.base;
		}
	}
};
</script>
