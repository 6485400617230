import { actionTypes, mutationTypes, getterTypes } from "@/store/shared/embeddedSnilsForm/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import EmbeddedFormMixinBuilder from "@/store/shared/embeddedForm";
import EmbeddedSnilsFormState from "@/store/shared/embeddedSnilsForm/types/embeddedSnilsFormState";
import { EmbeddedFormEditModeType } from "@/store/shared/embeddedForm/types/embeddedFormEditModeType";
import { EmbeddedFormModeType } from "@/store/shared/embeddedForm/types/embeddedFormModeType";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import AbortService from "@/services/abortService";
import { cloneDeep, isEqual } from "lodash";
import { StorageController } from "@/api/storage";
import { saveAs } from "file-saver";
import FileMeta from "@/store/shared/storage/types/fileMeta";
import ApiFile from "@/api/types/storage/apiFile";
import { STORAGE_SNILS_NAMESPACE } from "@/constants/storage";
import { plainToInstance } from "class-transformer";

class DefaultStateBuilder {
	embeddedFormMixin: any;

	constructor(embeddedFormMixin: any) {
		this.embeddedFormMixin = embeddedFormMixin;
	}

	build() {
		const embeddedFormMixin = this.embeddedFormMixin;
		return new EmbeddedSnilsFormState(
			"",
			embeddedFormMixin.state()
		);
	}
}

export default class EmbeddedSnilsFormModuleBuilder {
	constructor() {
	}

	build() {
		const abortService = new AbortService();
		abortService.initialize();

		const embeddedFormMixin = (new EmbeddedFormMixinBuilder()).build();
		const baseMixin = (new BaseMixinBuilder(abortService)).build();

		const storageController = new StorageController(abortService);

		const stateManipulationMixin = (new StateManipulationMixinBuilder({
			defaultStateBuilder: new DefaultStateBuilder(embeddedFormMixin)
		})).build();

		const state = (new DefaultStateBuilder(embeddedFormMixin)).build();

		const getters = <GetterTree<EmbeddedSnilsFormState, any>>{
			...embeddedFormMixin.getters,
			[getterTypes.isMetaChanged]: state => {
				return !isEqual(state.snilsFileMeta, state.lastSavedMeta);
			}
		};

		const actions = <ActionTree<EmbeddedSnilsFormState, any>>{
			...baseMixin.actions,
			...stateManipulationMixin.actions,
			...embeddedFormMixin.actions,
			async [actionTypes.initialize]({ dispatch, commit }, { id }) {
				await dispatch(actionTypes.initializeBase);

				if(id)
					commit(mutationTypes.SET_OWNER_ID, id);

				await dispatch(actionTypes.fetch);

				commit(mutationTypes.SET_FORM_MODE, EmbeddedFormModeType.DETAILS);
				commit(mutationTypes.SET_IS_INITIALIZED, true);
			},
			async [actionTypes.handleSnilsUpload]({ state, commit, dispatch }, file) {
				if(!file) {
					commit(mutationTypes.SET_SNILS_FILE_META, new FileMeta());
					return;
				}

				try {
					commit(mutationTypes.SET_SNILS_FILE_META_IS_LOADING, true);

					const { name, type } = file;
					let meta = await storageController.createTemperFile(new ApiFile(file, name, STORAGE_SNILS_NAMESPACE, type, ""));

					commit(mutationTypes.SET_SNILS_FILE_META, plainToInstance(FileMeta, meta));
				} catch (error) {
					console.error(error);
					AlertHelper.handleGeneralRequestErrors(error);
				} finally {
					commit(mutationTypes.SET_SNILS_FILE_META_IS_LOADING, false);
				}
			},
			async [actionTypes.save]({ dispatch, commit, state }) {
				commit(mutationTypes.SET_IS_FORM_SAVING, true);

				try {
					if(state.form.editMode === EmbeddedFormEditModeType.CREATE) {
						await dispatch(actionTypes.create);
						commit(mutationTypes.SET_FORM_EDIT_MODE, EmbeddedFormEditModeType.UPDATE);
					} else if(state.form.editMode === EmbeddedFormEditModeType.UPDATE) {
						await dispatch(actionTypes.update);
					}

					commit(mutationTypes.SET_FORM_MODE, EmbeddedFormModeType.SUCCESS);
				} catch (error) {
					console.error(error);
					AlertHelper.handleGeneralRequestErrors(error);
				} finally {
					commit(mutationTypes.SET_IS_FORM_SAVING, false);
				}
			},
			async [actionTypes.download]({ state }) {
				try {
					const file = await storageController.getFile(state.snilsFileMeta.id);
					await saveAs(file, "СНИЛС");
				} catch (error) {
					console.error(error);
					AlertHelper.handleGeneralRequestErrors(error);
				}
			}
		};

		const mutations = <MutationTree<EmbeddedSnilsFormState>>{
			...stateManipulationMixin.mutations,
			...embeddedFormMixin.mutations,
			...baseMixin.mutations,
			[mutationTypes.SET_SNILS_FILE_ID](state, value) {
				state.snilsFileMeta.id = value;
			},
			[mutationTypes.SET_ID](state, value) {
				state.id = value;
			},
			[mutationTypes.UPDATE_LAST_SAVED_META](state) {
				state.lastSavedMeta = cloneDeep(state.snilsFileMeta);
			},
			[mutationTypes.SET_SNILS_FILE_META](state, value) {
				state.snilsFileMeta = value;
			},
			[mutationTypes.SET_OWNER_ID](state, value) {
				state.ownerId = value;
			},
			[mutationTypes.SET_SNILS_FILE_META_IS_LOADING](state, value) {
				state.snilsFileMeta.isLoading = value;
			}
		};

		const embeddedSnilsModule: Module<EmbeddedSnilsFormState, any> = {
			state, getters, actions, mutations, namespaced: true
		};

		return {
			module: embeddedSnilsModule,
			abortService
		};
	}
}
