<template>
	<v-skeleton-loader height="14" max-width="180" type="image" class="mb-6 rounded-lg"/>
</template>

<script>
export default {
};
</script>

<style scoped>

</style>
