import ApiOfficeEmployeeRoles from "@/api/types/roles/apiOfficeEmployeeRoles";
import OfficeEmployee from "@/store/modules/officeEmployee/types/officeEmployee";
import ApiAccountProfilePersistedBase from "@/api/types/account/apiAccountProfilePersistedBase";
import ApiOffice from "@/api/types/office/apiOffice";
import IPageState from "@/store/shared/base/types/pageState";

export default class OfficeEmployeeState implements IPageState {
	constructor(
		public employee: OfficeEmployee = new OfficeEmployee(),
		public office = new ApiOffice(),
		public officeId: string = "",
		public id: string = "",
		public roles: ApiOfficeEmployeeRoles[] = [],
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public profile: ApiAccountProfilePersistedBase | null = null
	)
	{
	}
}
