<template>
	<frp-master-account-access-application-base-step :title="title">
		<template v-if="counterpartyType === CounterpartyType.LEGAL_ENTITY">
			<frp-legal-entity-details v-if="details" :details="details"></frp-legal-entity-details>
		</template>
		<template v-else>
			<frp-legal-person-details v-if="details" :details="details"></frp-legal-person-details>
		</template>

		<v-container class="pa-0" v-if="counterpartyType === CounterpartyType.LEGAL_ENTITY">
			<v-row no-gutters>
				<v-col cols="12">
					<frp-digital-signature-dialog @signed="handleSign"
												  :payload="signingPayload"
												  v-model="dialog">
						<template v-slot:activator="{ on, attrs }">
							<frp-btn v-on="on" v-bind="attrs"
									 :disabled="isSigned">
								{{ isSigned ? $t("buttons.digitalSignature.isSigned") : $t("buttons.digitalSignature.default") }}
							</frp-btn>
						</template>
					</frp-digital-signature-dialog>
				</v-col>
				<v-col cols="12" class="mt-6">
					<frp-link-btn icon="ico_doc-info" :color="colors.secondary.base" :href="helpWorkWithDigitalSignatureLink" target="_blank">
						{{ $t("common.helpWorkWithDigitalSignature") }}
					</frp-link-btn>
				</v-col>
				<v-col cols="12" class="mt-6" v-if="!isSigned">
					<frp-link-btn @click="moveToDocumentsStep">
						{{ $t("common.signatureIsMissing") }}
					</frp-link-btn>
				</v-col>
			</v-row>
		</v-container>
	</frp-master-account-access-application-base-step>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import FrpLinkBtn from "@/components/buttons/FrpLinkBtn.vue";
import FrpLegalPersonDetails from "@/components/details/FrpLegalPersonDetails.vue";
import colorsMixin from "@/mixins/colorsMixin";
import masterAccountAccessTypes from "@/store/modules/masterAccountAccess/types";
import { CounterpartyType } from "@/types/CounterpartyType";
import FrpDetailsGroup from "Components/details/FrpDetailsGroup";
import FrpDetailsItem from "Components/details/FrpDetailsItem";
import FrpDetailsSubheader from "Components/details/FrpDetailsSubheader";
import FrpLegalEntityDetails from "Components/details/FrpLegalEntityDetails";
import { cloneDeep } from "lodash";
import storeModuleBasedPage from "Mixins/storeModuleBasedPage";
import storeManager from "Store/manager";
import signApplicationStepTypes from "Store/modules/masterAccountAccess/modules/signApplicationStep/types";
import { processApiPayload } from "Utils/api";
import FrpMasterAccountAccessApplicationBaseStep from "Views/public/masterAccountAccess/steps/FrpMasterAccountAccessApplicationBaseStep";
import { createNamespacedHelpers } from "vuex";
import FrpDigitalSignatureDialog, { DigitalSignatureDialogModeType } from "Components/digitalSignature/FrpDigitalSignatureDialog";

const applicationHelpers = createNamespacedHelpers(storeManager.masterAccountAccess.namespace);
const selectCounterpartyStep = createNamespacedHelpers(storeManager.masterAccountAccess.selectCounterpartyStep.namespace);
const contactInformationStepHelpers = createNamespacedHelpers(storeManager.masterAccountAccess.contactInformationStep.namespace);
const signApplicationStepHelpers = createNamespacedHelpers(storeManager.masterAccountAccess.signApplicationStep.namespace);

export default {
	mixins: [storeModuleBasedPage, colorsMixin],
	data() {
		return {
			DigitalSignatureDialogModeType,
			CounterpartyType,
			dialog: false,
			namespace: storeManager.masterAccountAccess.signApplicationStep.namespace,
			helpWorkWithDigitalSignatureLink: "https://loan.bitrix24.site/ecp/"
		};
	},
	computed: {
		...selectCounterpartyStep.mapState({
			details: state => state.counterparty,
			counterpartyType: state => state.counterpartyType
		}),
		...contactInformationStepHelpers.mapState({
			email: state => state.email
		}),
		...signApplicationStepHelpers.mapGetters({
			isSigned: signApplicationStepTypes.getterTypes.isSigned
		}),
		title() {
			switch (this.counterpartyType) {
				case CounterpartyType.LEGAL_ENTITY:
					return this.$t("titles.masterAccountAccessApplication.legalEntityCounterpartyType");
				case CounterpartyType.LEGAL_PERSON:
					return this.$t("titles.masterAccountAccessApplication.legalPersonCounterpartyType");
			}
		},
		signingPayload() {
			let legalEntity = cloneDeep(this.details);

			processApiPayload(legalEntity);

			return JSON.stringify({
				legalEntity,
				requestorEmail: this.email
			});
		}
	},
	methods: {
		...applicationHelpers.mapActions({
			moveToDocumentsStep: masterAccountAccessTypes.actionTypes.moveToDocumentsStep
		}),
		...signApplicationStepHelpers.mapMutations({
			setEncodedApplicationString: signApplicationStepTypes.mutationTypes.SET_ENCODED_APPLICATION_STRING,
			setEncodedDetachedSignatureString: signApplicationStepTypes.mutationTypes.SET_ENCODED_DETACHED_SIGNATURE_STRING
		}),
		async handleSign({ data, signature }) {
			try {
				this.setEncodedApplicationString(data);
				this.setEncodedDetachedSignatureString(signature);
			} catch (e) {
				console.error(e);
			}
		}
	},
	async created() {
		await this.initializeStore();
	},
	components: {
		FrpLegalPersonDetails,
		FrpLinkBtn,
		FrpDigitalSignatureDialog,
		FrpBtn,
		FrpDetailsItem,
		FrpDetailsSubheader,
		FrpDetailsGroup,
		FrpLegalEntityDetails,
		FrpMasterAccountAccessApplicationBaseStep
	}
};
</script>
