<template>
	<v-radio-group hide-details class="pa-0 ma-0 frp-radio-card-group my-4 mt-md-0 mb-md-6" :value="value" dense
				   @change="$emit('update:value', $event)">
		<v-container class="pa-0 mx-0" fluid>
			<v-row>
				<v-col cols="12" sm="6" md="auto" v-for="({label, value}, i) of items" :key="i">
					<v-card class="pa-5" elevation="0" width="290">
						<v-radio :value="value" color="secondary">
							<template #label>
								<span class="text-subtitle-1 font-weight-bold">
									{{ label }}
								</span>
							</template>
						</v-radio>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</v-radio-group>
</template>

<script>
export default {
	model: {
		prop: "value",
		event: "update:value"
	},
	props: {
		value: {
			required: true
		},
		items: {
			required: true,
			type: Array
		}
	}
};
</script>

<style scoped lang="scss">
@import "~vuetify/src/styles/styles.sass";

.frp-radio-card-group {
	@media #{map-get($display-breakpoints, 'md-and-up')} {
		.col {
			flex-grow: unset;
		}
	}

	@media #{map-get($display-breakpoints, 'sm-and-down')} {
		.row > div {
			padding-top: 8px !important;
			padding-bottom: 8px !important;
		}
		.v-card {
			width: 100% !important;
			height: 100%;
			display: flex;
			padding-top: 10px !important;
			padding-bottom: 10px !important;
		}
	}

	@media #{map-get($display-breakpoints, 'xs-only')} {
		.row > div {
			padding-top: 4px !important;
			padding-bottom: 4px !important;
		}
	}
}
</style>
