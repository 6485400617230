<template>
	<v-container fluid class="fill-height" style="background-color: var(--v-white-darken2)">
		<v-row align="center" justify="center">
			<v-col cols="11" sm="11" md="9" lg="8" xl="6">
				<v-card class="py-6"
						rounded="lg"
						style="filter: drop-shadow(0px 6px 16px rgba(0, 123, 255, 0.08))"
						:class="$vuetify.breakpoint.smAndDown ? 'px-3' : 'px-6'"
						elevation="0">
					<v-card-title class="mb-4 align-center flex-column">
						<router-link :to="{ name: RouteNames.ROOT }">
							<frp-icon src="ico_logo" :color="colors.secondary.base"></frp-icon>
						</router-link>
						<span class="text-body-2 primary--text text--darken-1 mt-5">{{ title }}</span>
					</v-card-title>
					<v-card-text>
						<v-form :readonly="isFormSaving || isFormReadonly" :disabled="isFormDisabled" @submit.prevent="submit"
								:ref="refs.form" v-model="formValid" v-if="mode === EmailConfirmedModeType.INITIAL">
							<frp-icon class="justify-center mt-4 mb-8" src="ico_email-open" :color="colors.grey.base"></frp-icon>

							<div class="text-body-1 primary--text text--darken-1 text-center mx-auto">
								На адрес электронной почты
								отправлено письмо с кодом подтверждения
							</div>

							<frp-otp-input v-model="code"
										   name="one-time-code"
										   autocomplete="one-time-code"
										   label="Код подтверждения"
										   required
										   class="mt-5 mb-2"
										   :rules="validation.code"
										   :error="codeError">
							</frp-otp-input>

							<v-btn class="frp-btn" block color="secondary" :loading="isFormSaving" :disabled="!formValid"
								   @click="confirmEmail">
								Подтвердить код
							</v-btn>

							<v-btn class="frp-btn mt-8" block text :disabled="!!timer" @click="getCode" color="secondary">
								Выслать код еще раз
							</v-btn>

							<div class="text-caption grey--text mx-auto mt-4"
								 style="width: fit-content"
								 :style="!!timer ? '' : 'visibility: hidden'">
								Выслать код еще раз через:
								<span class="secondary--text ml-1">{{ timer }}с</span>
							</div>
						</v-form>

						<div v-else-if="mode === EmailConfirmedModeType.SUCCESS" class="d-flex flex-column">
							<frp-icon class="justify-center mt-4 mb-8" src="ico_sign-up-success" :color="colors.grey.base"></frp-icon>

							<div class="text-body-1 primary--text text--darken-1 text-center mx-auto">
								Адрес электронной почты
								подтвержден, вы можете перейти в личный кабинет
							</div>

							<v-btn class="frp-btn mt-10 mb-2" block color="secondary" :to="{name: RouteNames.ROOT}">
								На главную
							</v-btn>
						</div>
					</v-card-text>
				</v-card>

				<v-col class="d-flex justify-center mt-9">
					<v-btn color="secondary"
						   @click="signOut"
						   small
						   class="frp-btn"
						   plain
						   text>
						Сменить аккаунт
					</v-btn>
				</v-col>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import authorizationMixin from "@/mixins/authorizationMixin";
import { RouteNames } from "@/router/routes";
import FrpIcon from "Components/icon/FrpIcon";
import FrpMessageBox from "Components/common/FrpMessageBox";
import FrpTextField from "Components/fields/FrpTextField";
import FrpOtpInput from "Components/fields/FrpOtpInput";
import formMixin from "Mixins/formMixin";
import storeModuleBasedPage from "Mixins/storeModuleBasedPage";
import colorsMixin from "Mixins/colorsMixin";
import { ConfirmEmailModeType } from "Store/modules/confirmEmail/types/confirmEmailModeType";
import { actionTypes, mutationTypes, namespace } from "Store/modules/confirmEmail/types";
import { requiredRule } from "Utils/validation";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);

export default {
	mixins: [colorsMixin, storeModuleBasedPage, formMixin, authorizationMixin],
	data() {
		return {
			EmailConfirmedModeType: ConfirmEmailModeType,
			RouteNames,
			namespace,
			validation: {
				code: []
			},
			timer: 0
		};
	},
	computed: {
		...mapState({
			email: state => state.email,
			mode: state => state.mode,
			registrationCode: state => state.code,
			codeError: state => state.codeError
		}),
		title() {
			switch (this.mode) {
				case ConfirmEmailModeType.INITIAL:
					return "Подтверждение почты";
				case ConfirmEmailModeType.SUCCESS:
					return "Адрес успешно подтвержден!";
			}
		},
		code: {
			get() {
				return this.registrationCode;
			},
			set(value) {
				this.setCode(value);
			}
		}
	},
	async created() {
		await this.initializeStore();
		if(this.$route.query.code) {
			this.validate();
		}
		this.startTimer();
	},
	methods: {
		...mapMutations({
			setCode: mutationTypes.SET_CODE,
			setCodeError: mutationTypes.SET_CODE_ERROR
		}),
		...mapActions({
			confirmEmail: actionTypes.confirmEmail,
			getNewCode: actionTypes.getNewCode
		}),
		startTimer() {
			if(this.timer > 0) return;
			this.timer = 60;
			const interval = setInterval(() => {
				this.timer--;
				if(this.timer <= 0) clearInterval(interval);
			}, 1000);
		},
		async getCode() {
			await this.getNewCode();
			this.startTimer();
		}
	},
	watch: {
		code() {
			this.setCodeError("");
		}
	},
	components: {
		FrpIcon,
		FrpMessageBox,
		FrpTextField,
		FrpOtpInput
	}
};
</script>
