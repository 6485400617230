import MasterAccountAccessApplicationStatus from "@/api/types/masterAccountAccessApplication/masterAccountAccessApplicationStatus";
import { MasterAccountAccessApplicationController } from "@/api/masterAccountAccessApplication";
import {
	namespace,
	actionTypes,
	mutationTypes,
	getterTypes
} from "@/store/modules/applications/masterAccountAccess/details/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import MasterAccountAccessApplicationDetailsState
	from "@/store/modules/applications/masterAccountAccess/details/types/masterAccountAccessApplicationDetailsState";
import { plainToInstance } from "class-transformer";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import alertService, { AlertKeys } from "@/store/modules/alerts/services/alertService";
import { CounterpartyType } from "@/types/CounterpartyType";
import { formatFullName } from "@/utils/formatting";
import ApiMasterAccountAccessApplication from "@/api/types/masterAccountAccessApplication/apiMasterAccountAccessApplication";
import AbortService from "@/services/abortService";
import HttpNotFoundException from "@/exceptions/httpNotFoundException";
import rootTypes from "@/store/types";
import { PageModeType } from "@/store/types/pageModeType";
import AccessForbiddenException from "@/exceptions/accessForbiddenException";

const abortService = new AbortService();
const masterAccountAccessApplicationController = new MasterAccountAccessApplicationController(abortService);

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new MasterAccountAccessApplicationDetailsState();
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();
const baseMixin = (new BaseMixinBuilder(abortService)).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<MasterAccountAccessApplicationDetailsState, any>>{
	[getterTypes.details]: state => {
		let details = {
			...state.details,
			title: ""
		};

		if(state.details.type === CounterpartyType.LEGAL_ENTITY) {
			Object.assign(details, state.details.legalEntity);
			details.title = state.details.legalEntity.longName;
		}
		if(state.details.type === CounterpartyType.LEGAL_PERSON) {
			Object.assign(details, state.details.entrepreneur);
			details.title = state.details.entrepreneur.fullName;
		}
		if(state.details.type === CounterpartyType.FOREIGN_ORGANIZATION) {
			Object.assign(details, state.details.foreignOrganization);
			details.title = state.details.foreignOrganization.longName;
		}
		if(state.details.type === CounterpartyType.PHYSICAL_PERSON) {
			Object.assign(details, state.details.individual);
			details.title = formatFullName({ ...state.details.individual });
		}

		return details;
	}
};

const actions = <ActionTree<MasterAccountAccessApplicationDetailsState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit }, { id }) {
		await dispatch(actionTypes.initializeBase);

		await dispatch(actionTypes.updateDetails, { id });

		commit(mutationTypes.SET_IS_INITIALIZED, true);
	},
	async [actionTypes.updateDetails]({ commit, dispatch }, { id }) {
		commit(mutationTypes.SET_IS_LOADING, true);

		try {
			const data = await masterAccountAccessApplicationController.getMasterAccountAccessApplication(id);

			const details = plainToInstance(ApiMasterAccountAccessApplication, data);

			commit(mutationTypes.SET_DETAILS, details);
		} catch (error) {
			switch (error.constructor) {
				case HttpNotFoundException:
					commit(rootTypes.mutationTypes.SET_PAGE_MODE, PageModeType.PAGE_NOT_FOUND, { root: true });
					break;
				case AccessForbiddenException:
					commit(rootTypes.mutationTypes.SET_PAGE_MODE, PageModeType.ACCESS_DENIED, { root: true });
					break;
				default:
					AlertHelper.handleGeneralRequestErrors(error);
					break;
			}
		} finally {
			commit(mutationTypes.SET_IS_LOADING, false);
		}
	},
	async [actionTypes.approveApplication]({ state, commit }) {
		commit(mutationTypes.SET_IS_APPROVING, true);

		try {
			await masterAccountAccessApplicationController.updateMasterAccountAccessApplicationStatus(state.details.id,
				MasterAccountAccessApplicationStatus.Approved);
			commit(mutationTypes.SET_APPLICATION_STATUS, MasterAccountAccessApplicationStatus.Approved);
			alertService.addInfo(AlertKeys.MASTER_ACCOUNT_ACCESS_APPLICATION_APPROVED);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_APPROVING, false);
		}
	},
	async [actionTypes.rejectApplication]({ state, commit }) {
		commit(mutationTypes.SET_IS_REJECTING, true);

		try {
			await masterAccountAccessApplicationController.updateMasterAccountAccessApplicationStatus(state.details.id,
				MasterAccountAccessApplicationStatus.Rejected);
			commit(mutationTypes.SET_APPLICATION_STATUS, MasterAccountAccessApplicationStatus.Rejected);
			alertService.addInfo(AlertKeys.MASTER_ACCOUNT_ACCESS_APPLICATION_REJECTED);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_REJECTING, false);
		}
	}
};

const mutations = <MutationTree<MasterAccountAccessApplicationDetailsState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	[mutationTypes.SET_DETAILS](state, value) {
		state.details = value;
	},
	[mutationTypes.SET_IS_LOADING](state, value) {
		state.isLoading = value;
	},
	[mutationTypes.SET_IS_APPROVING](state, value) {
		state.isApproving = value;
	},
	[mutationTypes.SET_APPLICATION_STATUS](state, value) {
		state.details.status = value;
	},
	[mutationTypes.SET_IS_REJECTING](state, value) {
		state.isRejecting = value;
	}
};

export {
	namespace, state, getters, actions, mutations
};

const masterAccountAccessApplicationDetailsModule = {
	namespace, state, getters, actions, mutations, namespaced: true
};

export default masterAccountAccessApplicationDetailsModule;
