import BaseStepStateModel from "@/store/shared/baseStep/models/baseStepStateModel";
import { ApplicationStepOrders } from "@/store/modules/masterAccountAccess/types/ApplicationStepOrders";

export default class ContactInformationStepStateModel extends BaseStepStateModel {
	constructor(
		public order: number = ApplicationStepOrders.CONTACT_INFORMATION_STEP_ORDER,
		public email: string = "",
		public isFormValid: boolean = false,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
	)
	{
		const component = "frp-contact-information-step";

		super(component, order, isInitialized, isDestroyed);
	}
}
