<template>
	<frp-internal-list-layout>
		<template #title>
			<frp-title-loader></frp-title-loader>
		</template>

		<template #side-btn>
			<frp-btn-loader></frp-btn-loader>
		</template>

		<template #content>

		</template>
	</frp-internal-list-layout>
</template>

<script>
import FrpDetailsGroup from "Components/details/FrpDetailsGroup";
import FrpDetailsSectionColumn from "Components/details/FrpDetailsSectionColumn";
import FrpInternalListLayout from "Components/layouts/FrpInternalListLayout";
import FrpTitleLoader from "Components/loaders/common/FrpTitleLoader";
import FrpBtnLoader from "Components/loaders/form/FrpBtnLoader";
import FrpFormCard from "Components/markup/FrpFormCard";
import FrpFormCardBlocks from "Components/markup/FrpFormCardBlocks";
import FrpFormCardBlock from "Components/markup/FrpFormCardBlock";
import FrpFormCardBlockRow from "Components/markup/FrpFormCardBlockRow";
import FrpFormCardBlockCol from "Components/markup/FrpFormCardBlockCol";
import FrpFormItem from "Components/markup/FrpFormItem";
import FrpNestedContentLayout from "Components/layouts/FrpNestedContentLayout";
import FrpFormBlockTitleLoader from "Components/loaders/form/FrpFormBlockTitleLoader";

export default {
	components: {
		FrpBtnLoader,
		FrpTitleLoader,
		FrpInternalListLayout,
		FrpNestedContentLayout,
		FrpDetailsGroup,
		FrpDetailsSectionColumn,
		FrpFormCardBlocks,
		FrpFormCard,
		FrpFormCardBlock,
		FrpFormCardBlockCol,
		FrpFormCardBlockRow,
		FrpFormItem,
		FrpFormBlockTitleLoader
	}
};
</script>
