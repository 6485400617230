<template>
	<frp-master-account-access-application-base-step :title="$t('titles.enterInnYourOrganization')">
		<p class="text-body-2">{{ $t("common.masterAccountAccessSelectCounterpartyStep.text") }}</p>

		<frp-text-field :label="$t('fields.inn.label')"
						autocomplete="inn"
						name="inn"
						:mask="masks.inn"
						:loading="isCounterpartyLoading"
						data-cy="inn"
						:rules="validation.inn"
						placeholder="7710172832"
						v-model="inn">
		</frp-text-field>

		<frp-message-box v-for="({ type, message }, i) of errors" type="error" :key="i">
			<template v-if="type === MasterAccountAccessErrorType.APPLICATION_EXISTS">
				{{ $t("alerts.errors.masterAccountAccessApplicationExists") }}
			</template>
			<template v-else>
				{{ message }}
			</template>
		</frp-message-box>
	</frp-master-account-access-application-base-step>
</template>

<script>
import FrpMessageBox from "Components/common/FrpMessageBox";
import { masks } from "Constants/masks";
import colorsMixin from "Mixins/colorsMixin";
import FrpTextField from "Components/fields/FrpTextField";
import FrpIcon from "Components/icon/FrpIcon";
import FrpCombobox from "Components/fields/FrpCombobox";
import { prepareInnRule, requiredRule } from "Utils/validation";
import FrpMasterAccountAccessApplicationBaseStep from "Views/public/masterAccountAccess/steps/FrpMasterAccountAccessApplicationBaseStep";
import { createNamespacedHelpers } from "vuex";
import { actionTypes, mutationTypes } from "Store/modules/masterAccountAccess/modules/selectCounterpartyStep/types";
import contactInformationTypes from "Store/modules/masterAccountAccess/modules/contactInformationStep/types";
import storeManager from "Store/manager/index";
import storeModuleBasedPage from "Mixins/storeModuleBasedPage";
import MasterAccountAccessErrorType from "@/store/modules/masterAccountAccess/types/masterAccountAccessErrorType";

const {
	mapState,
	mapActions,
	mapMutations
} = createNamespacedHelpers(storeManager.masterAccountAccess.selectCounterpartyStep.namespace);

const contactInformationStepHelpers = createNamespacedHelpers(storeManager.masterAccountAccess.contactInformationStep.namespace);

export default {
	mixins: [colorsMixin, storeModuleBasedPage],
	data() {
		return {
			masks,
			validation: {
				inn: [requiredRule(), prepareInnRule()]
			},
			MasterAccountAccessErrorType,
			namespace: storeManager.masterAccountAccess.selectCounterpartyStep.namespace
		};
	},
	computed: {
		...mapState({
			state: state => state,
			errors: state => state.errors,
			isCounterpartyLoading: state => state.isCounterpartyLoading
		}),
		applicationFillingMode: {
			get() {
				return this.state.applicationFillingMode;
			},
			set(value) {
				this.setApplicationFillingMode(value);
			}
		},
		inn: {
			get() {
				return this.state.inn;
			},
			set(value) {
				this.setInn(value);
			}
		}
	},
	methods: {
		...mapActions({
			fetchCounterparty: actionTypes.fetchCounterparty
		}),
		...contactInformationStepHelpers.mapMutations({
			setEmail: contactInformationTypes.mutationTypes.SET_EMAIL
		}),
		...mapMutations({
			setInn: mutationTypes.SET_INN,
			setThumbprint: mutationTypes.SET_THUMBPRINT,
			setApplicationFillingMode: mutationTypes.SET_APPLICATION_FILLING_MODE,
			removeCounterparty: mutationTypes.REMOVE_COUNTERPARTY
		})
	},
	watch: {
		inn: {
			handler(value) {
				if(value && this.validation.inn.every(v => v(value) === true)) {
					this.fetchCounterparty();
				} else if(this.state.counterparty) {
					this.removeCounterparty();
				}
			}
		}
	},
	async created() {
		await this.initializeStore();
	},
	components: {
		FrpMasterAccountAccessApplicationBaseStep,
		FrpMessageBox,
		FrpTextField,
		FrpIcon,
		FrpCombobox
	}
};
</script>
