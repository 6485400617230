<template>
	<a v-if="src" :href="src" class="text-decoration-none">
		<frp-tile-card>
			<span class="text-center">{{ title }}</span>
		</frp-tile-card>
	</a>
	<span v-else></span>
</template>

<script>
import FrpTileCard from "@/components/common/tile/FrpTileCard.vue";
import colorsMixin from "@/mixins/colorsMixin";

export default {
	mixins: [colorsMixin],
	props: {
		title: String,
		src: String
	},
	components: {
		FrpTileCard
	}
};
</script>
