import {
	namespace,
	actionTypes,
	mutationTypes,
	getterTypes
} from "@/store/modules/officeEmployee/modules/general/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import FormMixinBuilder from "@/store/shared/form";
import SnapshotOptions from "@/store/shared/snapshot/snapshotOptions";
import stateSnapshotKeys from "@/store/shared/snapshot/keys";
import SnapshotMixinBuilder from "@/store/shared/snapshot";
import OfficeEmployeeGeneralState from "@/store/modules/officeEmployee/modules/general/types/officeEmployeeGeneralState";
import router from "@/router";
import { ApiOfficeEmployeeBase } from "@/api/types/office/apiOfficeEmployee";
import { OfficeController } from "@/api/office";
import OfficeEmployee from "@/store/modules/officeEmployee/modules/general/types/officeEmployee";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import alertService, { AlertKeys } from "@/store/modules/alerts/services/alertService";
import mapper from "@/store/modules/officeEmployee/modules/general/mapper";
import AbortService from "@/services/abortService";
import HttpNotFoundException from "@/exceptions/httpNotFoundException";
import { resolveNestedState } from "@/utils/vuexModules";
import storeManager from "@/store/manager";
import OfficeEmployeeState from "@/store/modules/officeEmployee/types/officeEmployeeState";
import { JoiningEmployeeToOfficeMethodType } from "@/store/modules/officeEmployee/modules/general/types/joiningEmployeeToOfficeMethodType";
import { RouteNames } from "@/router/routes";
import rootTypes from "@/store/types";
import { PageModeType } from "@/store/types/pageModeType";
import AccessForbiddenException from "@/exceptions/accessForbiddenException";

const abortService = new AbortService();
const officeController = new OfficeController(abortService);

const formMixin = (new FormMixinBuilder()).build();
const baseMixin = (new BaseMixinBuilder(abortService)).build();
const snapshotMixin = (new SnapshotMixinBuilder({
	options: [
		new SnapshotOptions({
			key: stateSnapshotKeys.LAST_SAVED,
			fields: ["employee"]
		})
	]
})).build();

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new OfficeEmployeeGeneralState(
			formMixin.state(),
			snapshotMixin.state()
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<OfficeEmployeeGeneralState, any>>{
	...formMixin.getters,
	...snapshotMixin.getters
};

const actions = <ActionTree<OfficeEmployeeGeneralState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...formMixin.actions,
	...snapshotMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit, state }, { id }: { id: string | null }) {
		await dispatch(actionTypes.initializeBase);

		if(id)
			commit(mutationTypes.SET_ID, id);

		await dispatch(actionTypes.fetch);

		commit(mutationTypes.SET_IS_INITIALIZED, true);
		commit(mutationTypes.SET_STATE_SNAPSHOT, stateSnapshotKeys.LAST_SAVED);
	},
	async [actionTypes.save]({ dispatch, commit, rootState, state }) {
		commit(mutationTypes.SET_IS_FORM_SAVING, true);

		const { officeId } = resolveNestedState<OfficeEmployeeState>(rootState, storeManager.officeEmployee.namespace);

		try {
			let apiEmployee = mapper.map(state.employee, OfficeEmployee, ApiOfficeEmployeeBase);
			await officeController.updateOfficeEmployee(state.id, officeId, apiEmployee);

			alertService.addInfo(AlertKeys.SUCCESS_UPDATED_INFO);
			commit(mutationTypes.SET_STATE_SNAPSHOT, stateSnapshotKeys.LAST_SAVED);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_FORM_SAVING, false);
		}
	},
	async [actionTypes.create]({ dispatch, commit, rootState, state }, { joiningEmployeeToOfficeMethod }) {
		commit(mutationTypes.SET_IS_FORM_SAVING, true);

		const { officeId } = resolveNestedState<OfficeEmployeeState>(rootState, storeManager.officeEmployee.namespace);

		try {
			let apiEmployee = mapper.map(state.employee, OfficeEmployee, ApiOfficeEmployeeBase);
			let assignToAccount = joiningEmployeeToOfficeMethod === JoiningEmployeeToOfficeMethodType.REGISTER;

			await officeController.createOfficeEmployee(officeId, apiEmployee, assignToAccount);
			alertService.addInfo(AlertKeys.SUCCESS_CREATED_INFO);
			await router.push({ name: RouteNames.OFFICE_EMPLOYEES });
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_FORM_SAVING, false);
		}
	},
	async [actionTypes.fetch]({ commit, state, rootState }) {
		commit(mutationTypes.SET_IS_FORM_LOADING, true);

		const { officeId } = resolveNestedState<OfficeEmployeeState>(rootState, storeManager.officeEmployee.namespace);

		try {
			let roles = await officeController.getOfficeEmployeeRoles();

			commit(mutationTypes.SET_ROLES, roles);

			if(state.id) {
				let { employee } = await officeController.getOfficeEmployee(state.id, officeId);
				commit(mutationTypes.SET_EMPLOYEE, mapper.map(employee, ApiOfficeEmployeeBase, OfficeEmployee));

				let profile = await officeController.getOfficeEmployeeProfile(officeId, state.id);
				commit(mutationTypes.SET_PROFILE, profile);
			}
		} catch (error) {
			switch (error.constructor) {
				case HttpNotFoundException:
					commit(rootTypes.mutationTypes.SET_PAGE_MODE, PageModeType.PAGE_NOT_FOUND, { root: true });
					break;
				case AccessForbiddenException:
					commit(rootTypes.mutationTypes.SET_PAGE_MODE, PageModeType.ACCESS_DENIED, { root: true });
					break;
				default:
					AlertHelper.handleGeneralRequestErrors(error);
					break;
			}
		} finally {
			commit(mutationTypes.SET_IS_FORM_LOADING, false);
		}
	}

};

const mutations = <MutationTree<OfficeEmployeeGeneralState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	...formMixin.mutations,
	...snapshotMixin.mutations,
	[mutationTypes.SET_ID](state, value) {
		state.id = value;
	},
	[mutationTypes.SET_EMPLOYEE](state, value) {
		state.employee = value;
	},
	[mutationTypes.SET_EMPLOYEE_FIRST_NAME](state, value) {
		state.employee.firstName = value;
	},
	[mutationTypes.SET_EMPLOYEE_MIDDLE_NAME](state, value) {
		state.employee.middleName = value;
	},
	[mutationTypes.SET_EMPLOYEE_LAST_NAME](state, value) {
		state.employee.lastName = value;
	},
	[mutationTypes.SET_EMPLOYEE_SNILS](state, value) {
		state.employee.snils = value;
	},
	[mutationTypes.SET_EMPLOYEE_POSITION](state, value) {
		state.employee.position = value;
	},
	[mutationTypes.SET_EMPLOYEE_DESCRIPTION](state, value) {
		state.employee.description = value;
	},
	[mutationTypes.SET_EMPLOYEE_IS_ACTIVE](state, value) {
		state.employee.isActive = value;
	},
	[mutationTypes.SET_EMPLOYEE_PHONE](state, value) {
		state.employee.phone = value;
	},
	[mutationTypes.SET_EMPLOYEE_EXTENSION_PHONE_NUMBER](state, value) {
		state.employee.extensionPhoneNumber = value;
	},
	[mutationTypes.SET_ROLES](state, value) {
		state.roles = value;
	},
	[mutationTypes.SET_EMPLOYEE_EMAIL](state, value) {
		state.employee.email = value;
	},
	[mutationTypes.SET_EMPLOYEE_ROLES](state, value) {
		state.employee.roles = value;
	},
	[mutationTypes.SET_PROFILE](state, value) {
		state.profile = value;
	}
};

export {
	namespace, state, getters, actions, mutations
};

const officeEmployeeGeneralModule = {
	namespace, state, getters, actions, mutations, namespaced: true
};

export default officeEmployeeGeneralModule;
