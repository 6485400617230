import urls from "@/api/config/urls";
import { prepareUrl } from "@/utils/prepareUrlQuery";
import HttpNotFoundException from "@/exceptions/httpNotFoundException";
import ApiPhysicalPersonCounterpartyPersisted from "@/api/types/counterparty/apiPhysicalPersonCounterpartyPersisted";
import BaseController from "@/api/shared/baseController";
import AbortService from "@/services/abortService";

export class PhysicalPersonController extends BaseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}

	getPhysicalPersonByIdentifiers = async (inn: string): Promise<ApiPhysicalPersonCounterpartyPersisted | null> => {
		try {
			return await this.client.get(prepareUrl(urls.counterparty.physicalPerson.search, { inn }));
		} catch (e) {
			if(e instanceof HttpNotFoundException)
				return null;
			else
				throw e;
		}
	};

	checkPhysicalPersonIsExists = async (inn: string, snils: string): Promise<boolean> => {
			const url = prepareUrl(urls.counterparty.physicalPerson.checkIsExists, { inn, snils });

			const { exists } = await this.client.get<{ exists: boolean }>(url);

			return exists
	};
}
