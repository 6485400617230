import { ApiCounterpartyEmployeeBase } from "@/api/types/counterparty/apiCounterpartyEmployee";
import { Expose, Type } from "class-transformer";
import ApiLegalEntityHead from "@/api/types/legalEntity/apiLegalEntityHead";
import "reflect-metadata";

export default class ApiCounterpartyEmployeePersistedBase {
	id: string;
	createdAt: string;
	updatedAt: string;
	number: number;

	@Expose({ name: "$counterpartyType" })
	counterpartyType: string;

	@Expose({ name: "employee" })
	@Type(() => ApiCounterpartyEmployeeBase)
	employee: ApiCounterpartyEmployeeBase;

	constructor(
		id = "",
		createdAt = "",
		updatedAt = "",
		counterpartyType = "",
		employee = new ApiCounterpartyEmployeeBase(),
		number = NaN
	)
	{
		this.id = id;
		this.createdAt = createdAt;
		this.updatedAt = updatedAt;
		this.counterpartyType = counterpartyType;
		this.employee = employee;
		this.number = number;
	}
}
