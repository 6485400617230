<template>
	<v-form :readonly="isFormSaving || isFormReadonly"
			:disabled="isFormDisabled"
			v-if="isInitialized"
			:ref="refs.form"
			v-model="formValid">
		<frp-form-card>
			<frp-form-card-blocks>
				<frp-form-card-block :title="$t('titles.general')">
					<frp-form-card-block-row>
						<frp-form-card-block-col cols="12" sm="6" md="4" :class="{ 'pb-0': $vuetify.breakpoint.xsOnly }">
							<frp-details-group>
								<frp-details-item :title="$t('details.titles.status')"
												  :value="$t(`aliases.activeStatus.${isActive ? 'active' : 'inactive'}`)">
								</frp-details-item>
								<frp-details-item :title="$t('details.titles.name')"
												  :value="profile.title">
								</frp-details-item>
								<frp-details-item :title="$t('details.titles.email')"
												  class="mb-0"
												  :value="profile.email">
								</frp-details-item>
								<frp-details-item :title="$t('details.phone')"
												  class="mb-0"
												  :value="profile.phone">
								</frp-details-item>
								<frp-details-item v-if="profile.phone"
												  :title="$t('details.phoneConfirmationStatus')"
												  class="mb-0"
												  :value="profile.isPhoneConfirmed ? $t('aliases.confirmation.confirmed') : $t('aliases.confirmation.notConfirmed')">
								</frp-details-item>
								<frp-details-item :title="$t('details.isSignatureRequired')"
												  class="mb-0">
									<frp-switch :loading="isSignatureRequiredToggleLoading"
												class="mt-0"
												:label="profile.isSignatureRequired ? $t('aliases.affirmation.yes') : $t('aliases.affirmation.no')"
												v-model="isSignatureRequiredEnabled">
									</frp-switch>
								</frp-details-item>
							</frp-details-group>
							<frp-btn v-if="profile.phone && can(Permissions.GLOBAL_OFFICE_EMPLOYEE_UPDATE)"
									 :disabled="!profile.isPhoneConfirmed"
									 :loading="isRequestPhoneConfirmationOperationExecuting"
									 @click="requestPhoneConfirmation"
									 color="secondary">
								{{ $t("buttons.requestPhoneConfirmation") }}
							</frp-btn>
						</frp-form-card-block-col>
					</frp-form-card-block-row>

				</frp-form-card-block>
			</frp-form-card-blocks>
			<frp-bottom-space height="24"></frp-bottom-space>
		</frp-form-card>
	</v-form>
	<frp-office-employee-profile-loader v-else></frp-office-employee-profile-loader>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn";
import FrpSwitch from "@/components/fields/FrpSwitch.vue";
import authorizationMixin from "@/mixins/authorizationMixin";
import { actionTypes } from "@/store/modules/officeEmployee/modules/profile/types";
import FrpToggleActivityBtn from "Components/buttons/FrpToggleActivityBtn";
import FrpDetailsGroup from "Components/details/FrpDetailsGroup";
import FrpDetailsItem from "Components/details/FrpDetailsItem";
import FrpFormCardBlock from "Components/markup/FrpFormCardBlock";
import FrpFormCardBlockCol from "Components/markup/FrpFormCardBlockCol";
import FrpFormCardBlockRow from "Components/markup/FrpFormCardBlockRow";
import FrpFormCardBlocks from "Components/markup/FrpFormCardBlocks";
import FrpFormItem from "Components/markup/FrpFormItem";
import FrpFormCard from "Components/markup/FrpFormCard";
import FrpBottomSpace from "Components/layouts/FrpBottomSpace";
import FrpOfficeEmployeeProfileLoader from "Views/officeEmployee/FrpOfficeEmployeeProfileLoader";
import storeManager from "@/store/manager/index";
import storeModuleBasedPage from "Mixins/storeModuleBasedPage";
import formMixin from "Mixins/formMixin";
import { createNamespacedHelpers } from "vuex";

const namespace = storeManager.officeEmployee.profile.namespace;
const { mapState, mapMutations, mapActions } = createNamespacedHelpers(namespace);
const confirmPhoneModuleHelpers = createNamespacedHelpers(storeManager.confirmPhone.namespace);
const officeEmployeeModuleHelpers = createNamespacedHelpers(storeManager.officeEmployee.namespace);

export default {
	mixins: [storeModuleBasedPage, formMixin, authorizationMixin],
	data() {
		return {
			namespace
		};
	},
	computed: {
		...mapState({
			profile: state => state.profile,
			isRequestPhoneConfirmationOperationExecuting: state => state.isRequestPhoneConfirmationOperationExecuting,
			isSignatureRequiredToggleLoading: state => state.isSignatureRequiredToggleLoading,
			isInitialized: state => state.isInitialized
		}),
		...confirmPhoneModuleHelpers.mapState({
			isProfilePhoneConfirmationRequiredOperationExecuting: state => state.isProfilePhoneConfirmationRequiredOperationExecuting
		}),
		...officeEmployeeModuleHelpers.mapState({
			isActive: state => state.employee.isActive
		}),
		isSignatureRequiredEnabled: {
			get() {
				return this.profile.isSignatureRequired;
			},
			set(value) {
				this.toggleSignatureRequired(value);
			}
		}
	},
	methods: {
		...mapActions({
			requestPhoneConfirmation: actionTypes.requestPhoneConfirmation,
			toggleSignatureRequired: actionTypes.toggleSignatureRequired
		})
	},
	created() {
		this.initializeStore();
	},
	components: {
		FrpSwitch,
		FrpBtn,
		FrpDetailsItem,
		FrpDetailsGroup,
		FrpToggleActivityBtn,
		FrpOfficeEmployeeProfileLoader,
		FrpFormItem,
		FrpFormCardBlockCol,
		FrpFormCardBlockRow,
		FrpFormCardBlock,
		FrpFormCardBlocks,
		FrpFormCard,
		FrpBottomSpace
	}
};
</script>

<style scoped>

</style>
