import { prepareUrl } from "@/utils/prepareUrlQuery";
import urls, { urlTemplateParts } from "@/api/config/urls";
import { plainToInstance } from "class-transformer";
import ApiOfficeEmployeeRoles from "@/api/types/roles/apiOfficeEmployeeRoles";
import ApiOfficeEmployeeActiveRoles from "@/api/types/roles/apiOfficeEmployeeActiveRoles";
import BaseController from "@/api/shared/baseController";
import AbortService from "@/services/abortService";

export class RoleController extends BaseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}


	getOfficeEmployeeRoles = async (): Promise<ApiOfficeEmployeeRoles[]> => {
		let roles = await this.client.get<object[]>(prepareUrl(urls.roles.officeEmployee.getAllRoles));

		return plainToInstance(ApiOfficeEmployeeRoles, roles);
	};
}
