<template>
	<v-badge :color="color" dot left offset-y="12" offset-x="-7" class="frp-approve-status-label">
		<span :style="{ color }">
			{{ $t(`aliases.approveStatus.${value}`) }}
		</span>
	</v-badge>
</template>

<script>
import colorsMixin from "Mixins/colorsMixin";
import { ApproveStatusType } from "Types/ApproveStatusType";

export default {
	mixins: [colorsMixin],
	props: {
		value: {
			type: [String, Number],
			required: true
		}
	},
	computed: {
		color() {
			switch (this.value) {
				case ApproveStatusType.APPROVED:
					return this.colors.green.base;
				case ApproveStatusType.CREATED:
					return this.colors.warning.base;
				case ApproveStatusType.REJECTED:
					return this.colors.secondary.base;
				case ApproveStatusType.UNDEFINED:
					return this.colors.grey.base;
			}
		}
	}
};
</script>
