<template>
	<div>
		<v-card elevation="0" class="mt-9">
			<v-container fluid class="pa-0">
				<v-row class="align-center px-5 pt-2">
					<v-col>
					<span class="text-h5 blue--text text--darken-4">
						<slot name="title"></slot>
					</span>
					</v-col>
				</v-row>

				<v-row class="mt-1 px-5">
					<v-col cols="12" sm="4">
						<slot name="search"></slot>
					</v-col>

					<v-col cols="12" sm="4" class="px-sm-0 pt-0 pt-sm-3">
						<slot name="filter"></slot>
					</v-col>

					<v-col v-if="$vuetify.breakpoint.smAndUp"
						   cols="12"
						   sm="4"
						   class="d-flex justify-end">
						<slot name="side-btn"></slot>
					</v-col>

					<v-col v-else
						   cols="12"
						   sm="8"
						   style="height: 52px"
						   class="pt-0">
						<slot name="side-btn"></slot>
					</v-col>
				</v-row>

				<v-row class="pt-0">
					<v-col class="py-0">
						<slot name="content"></slot>
					</v-col>
				</v-row>
			</v-container>
		</v-card>

		<v-container fluid class="pa-0">
			<v-row class="mt-1">
				<v-col class="d-flex justify-end">
					<slot name="pagination"></slot>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>

export default {
};
</script>
