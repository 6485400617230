import {
	actionTypes,
	mutationTypes
} from "@/store/modules/counterparty/modules/legalEntityCounterparty/modules/legalEntityCounterpartyHead/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import FormMixinBuilder from "@/store/shared/form";
import { resolveAction, resolveNestedNamespace, resolveNestedState } from "@/utils/vuexModules";
// @ts-ignore
import { plainToInstance } from "class-transformer";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import mapper from "@/store/modules/counterparty/modules/legalEntityCounterparty/modules/legalEntityCounterpartyHead/mapper";
import { SnilsRecognitionController } from "@/api/snils/recognition";
import AbortService from "@/services/abortService";
import LegalEntityCounterpartyHeadState
	from "@/store/modules/counterparty/modules/legalEntityCounterparty/modules/legalEntityCounterpartyHead/types/legalEntityCounterpartyHeadState";
import ApiLegalEntityCounterpartyHeadPersisted from "@/api/types/counterparty/apiLegalEntityCounterpartyHeadPersisted";
import LegalEntityCounterpartyHead
	from "@/store/modules/counterparty/modules/legalEntityCounterparty/modules/legalEntityCounterpartyHead/types/legalEntityCounterpartyHead";
import legalEntityCounterpartyHeadEmbeddedSnilsFormModuleTypes
	from "@/store/modules/counterparty/modules/legalEntityCounterparty/modules/legalEntityCounterpartyHead/modules/snils/types";
import LegalEntityCounterpartyHeadEmbeddedSnilsFormModuleBuilder
	from "@/store/modules/counterparty/modules/legalEntityCounterparty/modules/legalEntityCounterpartyHead/modules/snils";
import EmbeddedSnilsFormState from "@/store/shared/embeddedSnilsForm/types/embeddedSnilsFormState";
import ApiLegalEntityCounterpartyHead from "@/api/types/counterparty/apiLegalEntityCounterpartyHead";
import alertService, { AlertKeys } from "@/store/modules/alerts/services/alertService";

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new LegalEntityCounterpartyHeadState();
	}
}

export default class LegalEntityCounterpartyHeadModuleBuilder {
	namespace: string;

	constructor(namespace: string) {
		this.namespace = namespace;
	}

	build() {
		const namespace = this.namespace;
		const snilsNamespace = resolveNestedNamespace(namespace, legalEntityCounterpartyHeadEmbeddedSnilsFormModuleTypes.namespace);
		const abortService = new AbortService();
		const snilsRecognitionController = new SnilsRecognitionController(abortService);

		const formMixin = (new FormMixinBuilder()).build();
		const baseMixin = (new BaseMixinBuilder(abortService)).build();

		const { module: legalEntityCounterpartyHeadEmbeddedSnilsFormModule } = (new LegalEntityCounterpartyHeadEmbeddedSnilsFormModuleBuilder()).build();

		const stateManipulationMixin = (new StateManipulationMixinBuilder({
			defaultStateBuilder: new DefaultStateBuilder()
		})).build();

		const state = (new DefaultStateBuilder()).build;

		const getters = <GetterTree<LegalEntityCounterpartyHeadState, any>>{
			...formMixin.getters
		};

		const actions = <ActionTree<LegalEntityCounterpartyHeadState, any>>{
			...baseMixin.actions,
			...stateManipulationMixin.actions,
			...formMixin.actions,
			async [actionTypes.initialize]({ dispatch, commit, state }, apiHead: ApiLegalEntityCounterpartyHeadPersisted | null) {
				await dispatch(actionTypes.initializeBase);

				await dispatch(resolveAction(snilsNamespace,
					legalEntityCounterpartyHeadEmbeddedSnilsFormModuleTypes.actionTypes.initialize), { id: apiHead?.id }, { root: true });

				if(apiHead) {
					commit(mutationTypes.SET_ID, apiHead.id);
					commit(mutationTypes.SET_HEAD,
						mapper.map(apiHead.legalEntityHead, ApiLegalEntityCounterpartyHead, LegalEntityCounterpartyHead));
				} else {

				}

				commit(mutationTypes.SET_IS_INITIALIZED, true);
			},
			async [actionTypes.recognizeSnils]({ commit, state, rootState }, { index }) {
				commit(mutationTypes.SET_IS_HEAD_SNILS_RECOGNIZING, true);

				try {
					let { snilsFileMeta } = resolveNestedState<EmbeddedSnilsFormState>(rootState, snilsNamespace);

					if(snilsFileMeta.id) {
						let snils = await snilsRecognitionController.getRecognizedSnils(snilsFileMeta.id);

						if(snils) {
							alertService.addInfo(AlertKeys.SNILS_RECOGNIZE_SUCCESS);
						} else {
							alertService.addError(AlertKeys.SNILS_RECOGNIZE_ERROR);
						}

						commit(mutationTypes.SET_HEAD_SNILS, snils || "");
					}
				} catch (error) {
					console.error(error);
					AlertHelper.handleGeneralRequestErrors(error);
					throw error;
				} finally {
					commit(mutationTypes.SET_IS_HEAD_SNILS_RECOGNIZING, false);
				}
			}
		};

		const mutations = <MutationTree<LegalEntityCounterpartyHeadState>>{
			...stateManipulationMixin.mutations,
			...formMixin.mutations,
			...baseMixin.mutations,
			[mutationTypes.SET_HEAD](state, value) {
				state.head = value;
			},
			[mutationTypes.SET_ID](state, value) {
				state.id = value;
			},
			[mutationTypes.SET_HEAD_POSITION](state, value) {
				state.head.position = value;
			},
			[mutationTypes.SET_HEAD_DESCRIPTION](state, value) {
				state.head.description = value;
			},
			[mutationTypes.SET_HEAD_FULL_NAME](state, value) {
				state.head.fullName = value;
			},
			[mutationTypes.SET_HEAD_INN](state, value) {
				state.head.inn = value;
			},
			[mutationTypes.SET_HEAD_SNILS](state, value) {
				state.head.snils = value;
			},
			[mutationTypes.SET_IS_HEAD_SNILS_RECOGNIZING](state, value) {
				state.head.isSnilsRecognizing = value;
			}
		};

		const modules = {
			[legalEntityCounterpartyHeadEmbeddedSnilsFormModuleTypes.namespace]: {
				...legalEntityCounterpartyHeadEmbeddedSnilsFormModule
			}
		};

		const legalEntityCounterpartyHeadModule: Module<LegalEntityCounterpartyHeadState, any> = {
			state, getters, actions, mutations, namespaced: true, modules
		};

		return legalEntityCounterpartyHeadModule;
	}
}
