import ApiGetMasterAccountAccessApplicationsParameters from "@/api/types/masterAccountAccessApplication/apiGetMasterAccountAccessApplicationsParameters";
import MasterAccountAccessApplicationsState
	from "@/store/modules/applications/masterAccountAccess/list/types/masterAccountAccessApplicationsState";

export const mapApiGetMasterAccountAccessApplicationsParameters = (state: MasterAccountAccessApplicationsState) => {
	return new ApiGetMasterAccountAccessApplicationsParameters(state.paging.pageSize,
		(state.paging.pageSize * (state.paging.page - 1)),
		state.search.query,
		state.sorting.type,
		state.sorting.order,
		state.filter.status);
};
