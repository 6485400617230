<template>
	<frp-nested-content-layout>
		<template #title>
			<frp-custom-title-loader :height="20" class="mb-1"/>
		</template>
		<template #content>
			<frp-tabs-loader></frp-tabs-loader>

			<template v-if="isGeneralPage">
				<frp-legal-entity-general-loader/>
			</template>

			<template v-else-if="isEmployeesPage">
				<frp-counterparty-employees-loader/>
			</template>

			<template v-else-if="isMasterAccountPage">
				<frp-counterparty-master-account-loader/>
			</template>

		</template>
	</frp-nested-content-layout>
</template>

<script>
import FrpNestedContentLayout from "Components/layouts/FrpNestedContentLayout";
import FrpCustomTitleLoader from "Components/loaders/common/FrpCustomTitleLoader";
import FrpTabsLoader from "Components/loaders/common/FrpTabsLoader";
import FrpFormCard from "Components/markup/FrpFormCard";
import FrpFormCardBlocks from "Components/markup/FrpFormCardBlocks";
import FrpFormCardBlock from "Components/markup/FrpFormCardBlock";
import FrpFormCardBlockRow from "Components/markup/FrpFormCardBlockRow";
import FrpFormCardBlockCol from "Components/markup/FrpFormCardBlockCol";
import FrpFormItem from "Components/markup/FrpFormItem";
import FrpCheckboxLoader from "Components/loaders/common/FrpCheckboxLoader";
import FrpInputLoader from "Components/loaders/form/FrpInputLoader";
import FrpFormBlockTitleLoader from "Components/loaders/form/FrpFormBlockTitleLoader";
import FrpSwitchLoader from "Components/loaders/form/FrpSwitchLoader";
import FrpTextareaLoader from "Components/loaders/form/FrpTextareaLoader";
import { RouteNames } from "Router/routes";
import FrpCounterpartyEmployeesLoader from "Views/counterparty/sections/employees/FrpCounterpartyEmployeesLoader";
import FrpCounterpartyMasterAccountLoader from "Views/counterparty/sections/employees/FrpCounterpartyMasterAccountLoader";
import FrpLegalEntityGeneral from "Views/counterparty/sections/FrpLegalEntityGeneral";
import FrpLegalEntityGeneralLoader from "Views/counterparty/sections/FrpLegalEntityGeneralLoader";
import FrpLegalPersonGeneralLoader from "Views/counterparty/sections/FrpLegalPersonGeneralLoader";


export default {
	data() {
		return {
			RouteNames
		};
	},
	computed: {
		isGeneralPage() {
			return this.$route.name === this.RouteNames.COUNTERPARTY_GENERAL;
		},
		isEmployeesPage() {
			return this.$route.name === this.RouteNames.COUNTERPARTY_EMPLOYEES;
		},
		isMasterAccountPage() {
			return this.$route.name === this.RouteNames.COUNTERPARTY_MASTER_ACCOUNT;
		}
	},
	components: {
		FrpTabsLoader,
		FrpLegalEntityGeneral,
		FrpCounterpartyEmployeesLoader,
		FrpCounterpartyMasterAccountLoader,
		FrpLegalPersonGeneralLoader,
		FrpCustomTitleLoader,
		FrpNestedContentLayout,
		FrpFormCardBlocks,
		FrpFormCard,
		FrpFormCardBlock,
		FrpFormCardBlockCol,
		FrpFormCardBlockRow,
		FrpFormItem,
		FrpInputLoader,
		FrpFormBlockTitleLoader,
		FrpCheckboxLoader,
		FrpSwitchLoader,
		FrpTextareaLoader,
		FrpLegalEntityGeneralLoader
	}
};
</script>
