<template>
	<v-col class="py-0 mb-3" :cols="12">
		<slot></slot>
	</v-col>
</template>

<script>
export default {
	props: {}
};
</script>
