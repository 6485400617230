import AbortService from "@/services/abortService";
import {
	namespace,
	getterTypes
} from "@/store/modules/masterAccountAccess/modules/finalStep/types";
import MasterAccountAccessFinalStepStateModel
	from "@/store/modules/masterAccountAccess/modules/finalStep/models/masterAccountAccessFinalStepStateModel";
import { ApplicationStepOrders } from "@/store/modules/masterAccountAccess/types/ApplicationStepOrders";
import BaseMixinBuilder from "@/store/shared/base";
import BaseStepMixinBuilder from "@/store/shared/baseStep";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import { actionTypes, mutationTypes } from "@/store/modules/masterAccountAccess/modules/contactInformationStep/types";
import baseMixinTypes from "@/store/shared/base/types";

const abortService = new AbortService();

const baseStepMixin = (new BaseStepMixinBuilder()).build();

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new MasterAccountAccessFinalStepStateModel();
	}
}


const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();
const baseMixin = (new BaseMixinBuilder(abortService)).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<MasterAccountAccessFinalStepStateModel, any>>{
	[getterTypes.isStepCompleted]: (state) => {
		return true;
	}
};

const actions = <ActionTree<MasterAccountAccessFinalStepStateModel, any>>{
	...stateManipulationMixin.actions,
	...baseStepMixin.actions,
	...baseMixin.actions,
	[actionTypes.initialize]({ dispatch, commit }) {
		dispatch(baseMixinTypes.actionTypes.initializeBase);

		commit(mutationTypes.SET_IS_INITIALIZED, true);
	}
};

const mutations = <MutationTree<MasterAccountAccessFinalStepStateModel>>{
	...stateManipulationMixin.mutations,
	...baseStepMixin.mutations,
	...baseMixin.mutations
};

export {
	namespace, state, getters, actions, mutations
};

const masterAccountAccessFinalStepModule = {
	namespace, state, getters, actions, mutations, namespaced: true
};

export default masterAccountAccessFinalStepModule;
