import LegalEntityCounterpartyHead
	from "@/store/modules/counterparty/modules/legalEntityCounterparty/modules/legalEntityCounterpartyHead/types/legalEntityCounterpartyHead";

export default class LegalEntityCounterpartyHeadState {
	constructor(
		public head = new LegalEntityCounterpartyHead(),
		public id = "",
		public isInitialized = false,
		public isDestroyed: boolean = false
	)
	{
	}
}
