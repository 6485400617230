import { Type } from "class-transformer";
import ApiCounterpartyEmployeeTrust from "@/api/types/counterparty/trust/apiCounterpartyEmployeeTrust";

export default class ApiCounterpartyEmployeeTrustPersisted {
	id: string;
	createdAt: string;
	updatedAt: string;
	@Type(() => ApiCounterpartyEmployeeTrust)
	trust: ApiCounterpartyEmployeeTrust;

	constructor(id: string, createdAt: string, updatedAt: string, trust: ApiCounterpartyEmployeeTrust) {
		this.id = id;
		this.createdAt = createdAt;
		this.updatedAt = updatedAt;
		this.trust = trust;
	}
}
