import ApiForeignOrganizationCounterparty from "@/api/types/counterparty/apiForeignOrganizationCounterparty";
import ApiLegalPersonCounterparty from "@/api/types/counterparty/apiLegalPersonCounterparty";
import ApiPhysicalPersonCounterparty from "@/api/types/counterparty/apiPhysicalPersonCounterparty";
import ApiLegalEntityCounterparty from "@/api/types/counterparty/apiLegalEntityCounterparty";
import { IsOptional } from "class-validator";
import { Type } from "class-transformer";
import { ApiCounterpartyEmployeeBase } from "@/api/types/counterparty/apiCounterpartyEmployee";

export default class ApiMasterAccountAccessApplication {
	id: string;
	createdAt: string;
	updatedAt: string;
	requestorEmail: string;
	documentIds: string[];
	status: boolean;
	type: string;

	@IsOptional()
	@Type(() => ApiLegalPersonCounterparty)
	entrepreneur: ApiLegalPersonCounterparty;
	@IsOptional()
	@Type(() => ApiLegalEntityCounterparty)
	legalEntity: ApiLegalEntityCounterparty;
	@IsOptional()
	@Type(() => ApiPhysicalPersonCounterparty)
	individual: ApiPhysicalPersonCounterparty;
	@IsOptional()
	@Type(() => ApiForeignOrganizationCounterparty)
	foreignOrganization: ApiForeignOrganizationCounterparty;

	constructor(
		id: string = "",
		createdAt: string = "",
		updatedAt: string = "",
		requestorEmail: string = "",
		documentIds: string[] = [],
		status: boolean = false,
		type: string = "",
		entrepreneur: ApiLegalPersonCounterparty = new ApiLegalPersonCounterparty(),
		legalEntity: ApiLegalEntityCounterparty = new ApiLegalEntityCounterparty(),
		individual: ApiPhysicalPersonCounterparty = new ApiPhysicalPersonCounterparty(),
		foreignOrganization: ApiForeignOrganizationCounterparty = new ApiForeignOrganizationCounterparty()
	)
	{
		this.id = id;
		this.createdAt = createdAt;
		this.updatedAt = updatedAt;
		this.requestorEmail = requestorEmail;
		this.documentIds = documentIds;
		this.status = status;
		this.type = type;
		this.entrepreneur = entrepreneur;
		this.legalEntity = legalEntity;
		this.individual = individual;
		this.foreignOrganization = foreignOrganization;
	}
}
