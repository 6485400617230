import ApiCounterpartyPersistedBase from "@/api/types/counterparty/apiCounterpartyPersistedBase";
import ApiForeignOrganizationCounterparty from "@/api/types/counterparty/apiForeignOrganizationCounterparty";

export default class ApiForeignOrganizationCounterpartyPersisted extends ApiCounterpartyPersistedBase {
	counterparty: ApiForeignOrganizationCounterparty;

	constructor(id: string = "",
		createdAt: string = "",
		updatedAt: string = "",
		counterparty: ApiForeignOrganizationCounterparty = new ApiForeignOrganizationCounterparty())
	{
		super(id, createdAt, updatedAt, counterparty);

		this.counterparty = counterparty;
	}
}
