<template>
	<v-menu absolute offset-x offset-y v-if="$vuetify.breakpoint.mdAndUp">
		<template v-slot:activator="{ attrs, on }">
			<v-icon v-bind="attrs" v-on="on">
				mdi-help-circle-outline
			</v-icon>
		</template>
		<slot name="desktop"></slot>
	</v-menu>
	<v-dialog v-else
			  v-model="dialog"
			  fullscreen
			  transition="dialog-bottom-transition">
		<template v-slot:activator="{ attrs, on }">
			<v-icon v-bind="attrs" v-on="on">
				mdi-help-circle-outline
			</v-icon>
		</template>
		<v-card>
			<v-toolbar
				dark
				color="primary">
				<v-spacer></v-spacer>
				<v-btn
					icon
					dark
					@click="dialog = false">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-toolbar>
			<slot name="mobile"></slot>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	data() {
		return {
			dialog: false
		};
	}
};
</script>

<style scoped>

</style>
