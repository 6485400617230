<template>
	<frp-internal-list-layout>
		<template #title>
			{{ $t("titles.profiles") }}
		</template>

		<template #content>
			<v-data-table :headers="headers"
						  :items="items"
						  :loading-text="$t('tables.loading')"
						  hide-default-footer
						  :loading="isItemsLoading || isSelectItemHandling"
						  item-key="id"
						  :mobile-breakpoint="mobileBreakpoint"
						  must-sort
						  :options="options"
						  @click:row="handleSelectProfile"
						  :item-class="() => 'text-body-2'"
						  class="frp-table clickable-rows elevation-0 mt-2 d-flex flex-column">
				<template #item.isActive="{item}">
					<frp-status-label :value="item.isActive"></frp-status-label>
				</template>
			</v-data-table>
		</template>
	</frp-internal-list-layout>
</template>

<script>
import storeModuleBasedPage from "@/mixins/storeModuleBasedPage";
import storeManager from "@/store/manager/index";
import { mutationTypes, actionTypes, getterTypes } from "Store/modules/account/modules/profiles/types";
import { createNamespacedHelpers } from "vuex";
import { listMixin } from "Mixins/listMixin";
import colorsMixin from "Mixins/colorsMixin";
import FrpInternalListLayout from "Components/layouts/FrpInternalListLayout";
import FrpStatusLabel from "Components/common/FrpStatusLabel";

const namespace = storeManager.account.profiles.namespace;
const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);

export default {
	mixins: [storeModuleBasedPage, listMixin, colorsMixin],
	data() {
		return {
			namespace,
			headers: [
				{
					text: this.$t("tables.title"),
					value: "title",
					class: "text-caption",
					width: "25%"
				},
				{
					text: this.$t("tables.email"),
					value: "email",
					class: "text-caption",
					width: "25%"
				},
				{
					text: this.$t("tables.phone"),
					value: "phone",
					class: "text-caption",
					width: "20%"
				},
				{
					text: this.$t("tables.status"),
					value: "isActive",
					class: "text-caption",
					width: "15%"
				},
				{
					text: this.$t("tables.createdAt"),
					value: "createdAt",
					class: "text-caption",
					width: "15%"
				}
			]
		};
	},
	computed: {
		...mapState({
			isSelectItemHandling: state => state.isSelectItemHandling
		}),
		...mapGetters({
			items: getterTypes.formattedItems,
			isNoData: getterTypes.isListingEmpty
		})
	},
	methods: {
		...mapActions({
			handleSelectProfile: actionTypes.handleSelectProfile
		})
	},
	created() {
		this.initializeStore();
	},
	components: {
		FrpInternalListLayout,
		FrpStatusLabel
	}
};
</script>
