import storeManager from "@/store/manager";
import { RouteNames } from "@/router/routes";

export const routeToStoreMap = new Map<string, string[]>([
		[RouteNames.COUNTERPARTIES, [storeManager.counterparties.namespace]],

		[RouteNames.MASTER_ACCOUNT_ACCESS, []],
		[RouteNames.APPLICATIONS, []],
		[RouteNames.APPLICATIONS_MASTER_ACCOUNT_ACCESSES, [storeManager.applications.masterAccountAccess.list.namespace]],
		[RouteNames.APPLICATIONS_MASTER_ACCOUNT_ACCESS_DETAILS, [storeManager.applications.masterAccountAccess.details.namespace]],
		[RouteNames.APPLICATIONS_EMPLOYEE_JOIN_LIST, [storeManager.applications.employeeJoin.list.namespace]],
		[RouteNames.APPLICATIONS_EMPLOYEE_JOIN_DETAILS, [storeManager.applications.employeeJoin.details.namespace]],

		[RouteNames.COUNTERPARTY, [storeManager.counterparty.namespace]],
		[RouteNames.COUNTERPARTY_CREATE, [storeManager.counterparty.namespace]],
		[RouteNames.COUNTERPARTY_GENERAL, [
			storeManager.counterparty.legalEntity.namespace,
			storeManager.counterparty.legalPerson.namespace,
			storeManager.counterparty.foreignOrganization.namespace,
			storeManager.counterparty.physicalPerson.namespace
		]],
		[RouteNames.COUNTERPARTY_EMPLOYEES, [storeManager.counterparty.employees.namespace]],
		[RouteNames.COUNTERPARTY_MASTER_ACCOUNT, [storeManager.counterparty.masterAccount.namespace]],

		[RouteNames.PROFILE_ASSIGNMENT, []],
		[RouteNames.ACCOUNTS, [storeManager.accounts.namespace]],
		[RouteNames.ACCOUNT, [storeManager.account.namespace]],
		[RouteNames.ACCOUNT_GENERAL, [storeManager.account.general.namespace]],
		[RouteNames.ACCOUNT_PROFILES, [storeManager.account.profiles.namespace]],
		[RouteNames.CONFIRM_EMAIL, []],
		[RouteNames.CONFIRM_PHONE, []],

		[RouteNames.COUNTERPARTY_EMPLOYEE, [storeManager.counterpartyEmployee.namespace]],
		[RouteNames.COUNTERPARTY_EMPLOYEE_CREATE, [storeManager.counterpartyEmployee.namespace]],
		[RouteNames.COUNTERPARTY_EMPLOYEE_GENERAL, [storeManager.counterpartyEmployee.general.namespace]],
		[RouteNames.COUNTERPARTY_EMPLOYEE_TRUSTS, [storeManager.counterpartyEmployee.trusts.namespace]],
		[RouteNames.COUNTERPARTY_EMPLOYEE_PROFILE, [storeManager.counterpartyEmployee.profile.namespace]],
		[RouteNames.COUNTERPARTY_EMPLOYEE_TRUST, [storeManager.counterpartyEmployee.trust.namespace]],
		[RouteNames.COUNTERPARTY_EMPLOYEE_TRUST_CREATE, [storeManager.counterpartyEmployee.trust.namespace]],

		[RouteNames.OFFICE, [storeManager.office.namespace]],
		[RouteNames.OFFICE_GENERAL, [storeManager.office.general.namespace]],
		[RouteNames.OFFICE_EMPLOYEES, [storeManager.office.employees.namespace]],
		[RouteNames.OFFICE_EMPLOYEE, [storeManager.officeEmployee.namespace]],
		[RouteNames.OFFICE_EMPLOYEE_CREATE, [storeManager.officeEmployee.namespace]],
		[RouteNames.OFFICE_EMPLOYEE_GENERAL, [storeManager.officeEmployee.general.namespace]],
		[RouteNames.OFFICE_EMPLOYEE_PROFILE, [storeManager.officeEmployee.profile.namespace]]
	]
);
