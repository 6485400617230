import { createMap, createMapper, MappingProfile, forMember, mapFrom, addProfile, ignore, fromValue } from "@automapper/core";
import { classes } from "@automapper/classes";
import { convertIsoToNumber, convertToZonedTimestamp, timezoneNames } from "@/utils/dates";
import ApiCounterpartyEmployeeTrustPersisted from "@/api/types/counterparty/trust/apiCounterpartyEmployeeTrustPersisted";
import Trust from "@/store/modules/counterpartyEmployee/modules/trusts/types/trust";

const mapper = createMapper({
	strategyInitializer: classes()
});

const trustsProfile: MappingProfile = (mapper) => {
	createMap(mapper, ApiCounterpartyEmployeeTrustPersisted, Trust,
		forMember(d => d.id, mapFrom(x => x.id)),
		forMember(d => d.status, mapFrom(x => x.trust.status)),
		forMember(d => d.expireAt, mapFrom(x => convertToZonedTimestamp(x.trust.expireAt, timezoneNames.GMT))),
		forMember(d => d.createdAt, mapFrom(x => convertIsoToNumber(x.createdAt))),
		forMember(d => d.isSigned, mapFrom(x => x.trust.isSigned)),
		forMember(d => d.acceptedAt, mapFrom(x => convertIsoToNumber(x.trust.acceptedAt))),
		forMember(d => d.declinedAt, mapFrom(x => convertIsoToNumber(x.trust.declinedAt))),
		forMember(d => d.isMCHD, mapFrom(x => x.trust.isMCHD))
	);
};

addProfile(mapper, trustsProfile);

export default mapper;
