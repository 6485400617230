export default class ForeignOrganizationCounterparty {
	id: string;
	longName: string;
	shortName: string;
	countryCode: string;
	legalAddress: string;
	isRfrp: boolean;
	isLeasingCompany: boolean;
	isPledgeExpertCompany: boolean;
	isVkmExpertCompany: boolean;
	isFrpAssigneeCompany: boolean;

	constructor(
		id: string,
		longName: string,
		shortName: string,
		countryCode: string,
		legalAddress: string,
		isRfrp: boolean = false,
		isLeasingCompany: boolean = false,
		isPledgeExpertCompany: boolean = false,
		isVkmExpertCompany: boolean = false,
		isFrpAssigneeCompany: boolean = false
	)
	{
		this.id = id;
		this.longName = longName;
		this.shortName = shortName;
		this.countryCode = countryCode;
		this.legalAddress = legalAddress;
		this.isRfrp = isRfrp;
		this.isLeasingCompany = isLeasingCompany;
		this.isPledgeExpertCompany = isPledgeExpertCompany;
		this.isVkmExpertCompany = isVkmExpertCompany;
		this.isFrpAssigneeCompany = isFrpAssigneeCompany;
	}
}
