<template>
	<frp-form-card-block-row>
		<frp-form-card-block-col cols="12" sm="9" md="7" lg="5">
			<v-fade-transition hide-on-leave>
				<template v-if="formMode === EmbeddedFormModeType.DETAILS">
					<frp-details-item v-if="formEditMode === EmbeddedFormEditModeType.UPDATE"
									  :title="$t('fields.email.label')"
									  :value="confirmedEmail"
									  style="width: 350px; min-width: fit-content">
						<template #append>
							<frp-text-btn class="ml-6" v-if="can(editPermissions)" :text="$t('buttons.change')" @click="switchToEditMode"></frp-text-btn>
						</template>
					</frp-details-item>
					<frp-details-item v-else-if="formEditMode === EmbeddedFormEditModeType.CREATE"
									  :value="$t('details.values.missing')"
									  :title="$t('fields.email.label')"
									  style="width: 300px; min-width: fit-content">
						<template #append>
							<frp-text-btn v-if="can(editPermissions)" :text="$t('buttons.indicate')" @click="switchToEditMode"></frp-text-btn>
						</template>
					</frp-details-item>
				</template>
			</v-fade-transition>
			<v-fade-transition hide-on-leave>
				<v-card v-if="formMode === EmbeddedFormModeType.EDIT || formMode === EmbeddedFormModeType.SUCCESS"
						outlined
						class="mb-8">
					<v-card-title class="text-subtitle-1" v-if="formMode === EmbeddedFormModeType.EDIT">
						{{ $t("subheaders.changeEmail") }}
					</v-card-title>
					<v-card-text>
						<v-form v-if="EmbeddedFormEditModeType.UNKNOWN !== formEditMode"
								:readonly="isFormSaving || isFormReadonly"
								:disabled="isFormDisabled"
								:ref="refs.form"
								v-model="formValid">
							<v-container fluid class="pa-0">
								<frp-form-card-block-row>
									<frp-form-card-block-col v-if="EmbeddedFormModeType.SUCCESS === formMode"
															 class="d-flex flex-column align-center">
										<frp-icon src="ico_update-data-success" class="mb-5 mt-3"></frp-icon>
										<span class="primary--text text-body-2 text-center">
											<template v-if="isModeSelectionEnabled">
												{{
													$t(`common.accountEmailFormText.${isChangeCredentialsMode ?
														"changeCredentialsSuccessText" :
														"changeEmailSuccessText"}`)
												}}
											</template>
											<template v-else>
												{{ successText }}
											</template>
										</span>
									</frp-form-card-block-col>
									<frp-form-card-block-col v-else-if="EmbeddedFormModeType.EDIT === formMode">
										<span class="text-body-2 primary--text d-inline-block mb-4">{{ editModeText }}</span>
										<frp-text-field :placeholder="$t('fields.email.placeholder')"
														:label="$t('fields.email.label')"
														autocomplete="email"
														name="email"
														:errors="errors"
														:loading="isEmailUniqueCheckInProgress"
														required
														:rules="validation.email"
														v-model.trim="internalEmail">
										</frp-text-field>
										<frp-checkbox v-if="isModeSelectionEnabled"
													  v-model="isChangeCredentialsMode"
													  class="mt-0"
													  color="secondary"
													  :text="$t('buttons.updateAndSendCredentials')">
										</frp-checkbox>
									</frp-form-card-block-col>
								</frp-form-card-block-row>
								<frp-form-card-block-row>
									<frp-form-card-block-col class="d-flex justify-end">
										<template v-if="EmbeddedFormModeType.EDIT === formMode">
											<frp-btn @click="switchToDetailsMode"
													 class="frp-btn"
													 color="primary"
													 outlined>
												{{ $t("buttons.cancel") }}
											</frp-btn>

											<frp-btn :disabled="!formValid || isFormReadonly"
													 :loading="isFormSaving"
													 @click="submit"
													 elevation="0">
												{{ $t("buttons.send") }}
											</frp-btn>
										</template>

										<frp-btn v-else-if="EmbeddedFormModeType.SUCCESS === formMode"
												 color="primary"
												 @click="switchToDetailsMode"
												 outlined>
											{{ $t("buttons.close") }}
										</frp-btn>
									</frp-form-card-block-col>
								</frp-form-card-block-row>
							</v-container>
						</v-form>
					</v-card-text>
				</v-card>
			</v-fade-transition>
		</frp-form-card-block-col>
	</frp-form-card-block-row>
</template>

<script>
import FrpBtn from "Components/buttons/FrpBtn";
import FrpTextBtn from "Components/buttons/FrpTextBtn";
import FrpDetailsItem from "Components/details/FrpDetailsItem";
import FrpCheckbox from "Components/fields/FrpCheckbox";
import FrpTextField from "Components/fields/FrpTextField";
import FrpIcon from "Components/icon/FrpIcon";
import FrpFormCardBlock from "Components/markup/FrpFormCardBlock";
import FrpFormCardBlockCol from "Components/markup/FrpFormCardBlockCol";
import FrpFormCardBlockRow from "Components/markup/FrpFormCardBlockRow";
import FrpFormCardBlocks from "Components/markup/FrpFormCardBlocks";
import authorizationMixin from "Mixins/authorizationMixin";
import embeddedFormMixin from "Mixins/embeddedFormMixin";
import { mutationTypes, actionTypes } from "Store/shared/embeddedEmailForm/types";
import { EmbeddedEmailChangeModeType } from "Store/shared/embeddedEmailForm/types/embeddedEmailChangeModeType";
import { EmbeddedFormEditModeType } from "Store/shared/embeddedForm/types/embeddedFormEditModeType";
import { EmbeddedFormModeType } from "Store/shared/embeddedForm/types/embeddedFormModeType";
import { prepareEmailRule, prepareUniqRule } from "Utils/validation";
import { mapInstanceActions, mapInstanceMutations, mapInstanceState } from "Utils/vuexMapInstanse";
import { i18n } from "@/plugins/index";

export default {
	mixins: [embeddedFormMixin, authorizationMixin],
	props: {
		namespace: {
			required: true
		},
		successText: {
			type: String
		},
		editModeText: {
			default: i18n.t("common.accountEmailFormText.editModeText")
		},
		isModeSelectionEnabled: {
			default: false,
			type: Boolean
		},
		editPermissions: [Array, String]
	},
	data() {
		return {
			EmbeddedFormEditModeType,
			EmbeddedFormModeType,
			validation: {
				email: [prepareEmailRule(),
					prepareUniqRule({ getValue: () => this.confirmedEmail, message: this.$t("validation.emailAlreadyUsedInAccount") })]
			}
		};
	},
	computed: {
		...mapInstanceState({
			email: state => state.email,
			confirmedEmail: state => state.confirmedEmail,
			form: state => state.form,
			isEmailUnique: state => state.isEmailUnique,
			isEmailUniqueCheckInProgress: state => state.isEmailUniqueCheckInProgress,
			changeMode: state => state.changeMode
		}),
		errors() {
			return this.isEmailUnique ? [] : [`${this.$t("validation.accountWithThisEmailIsExist")}`];
		},
		internalEmail: {
			get() {
				return this.email;
			},
			set(value) {
				this.setEmail(value);
			}
		},
		isChangeCredentialsMode: {
			get() {
				return this.changeMode === EmbeddedEmailChangeModeType.CHANGE_CREDENTIALS;
			},
			set(value) {
				if(value) {
					this.setChangeMode(EmbeddedEmailChangeModeType.CHANGE_CREDENTIALS);
				} else {
					this.setChangeMode(EmbeddedEmailChangeModeType.CHANGE_EMAIL);
				}
			}
		}
	},
	methods: {
		...mapInstanceActions({
			setChangeMode: actionTypes.setChangeMode
		}),
		...mapInstanceMutations({
			setEmail: mutationTypes.SET_EMAIL
		}),
		switchToEditMode() {
			this.setFormMode(EmbeddedFormModeType.EDIT);
		},
		switchToDetailsMode() {
			this.setFormMode(EmbeddedFormModeType.DETAILS);
		}
	},
	components: {
		FrpCheckbox,
		FrpIcon,
		FrpBtn,
		FrpTextBtn,
		FrpDetailsItem,
		FrpFormCardBlockCol,
		FrpFormCardBlockRow,
		FrpFormCardBlock,
		FrpFormCardBlocks,
		FrpTextField
	}
};
</script>
