<template>
	<router-link :to="{name, params }" class="text-decoration-none">
		<v-icon size="12"
				:color="color"
				class="mr-2">
			mdi-circle
		</v-icon>
		<span class="text-decoration-underline" :style="{ color }">{{ text }}</span>
	</router-link>
</template>

<script>
import colorsMixin from "Mixins/colorsMixin";

export default {
	mixins: [colorsMixin],
	props: {
		color: String,
		text: String,
		name: String,
		params: Object
	}
};
</script>
