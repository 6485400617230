import { difference } from "@/utils/difference";
import { Dictionary } from "vue-router/types/router";
import { plainToInstance } from "class-transformer";
import { validateOrReject } from "class-validator";
import AccountsRouteQuery from "@/store/modules/accounts/types/accountsRouteQuery";
import AccountsState from "@/store/modules/accounts/types/accountsState";

export default class AccountsRouteQueryService {
	defaultRouteQuery: AccountsRouteQuery;

	constructor(defaultRouteQuery: AccountsRouteQuery) {
		this.defaultRouteQuery = defaultRouteQuery;
	}

	resolveRouteQueryDictionary(state: AccountsState) {
		let query = new AccountsRouteQuery(state.paging.page, state.sorting.type, state.sorting.order, state.search.query);

		return difference(this.defaultRouteQuery, query);
	}

	async resolveRouteQuery(query: Dictionary<String>) {
		try {
			let result = plainToInstance(AccountsRouteQuery, {
				...this.defaultRouteQuery,
				...query
			}, { enableImplicitConversion: true });

			await validateOrReject(result);
			return result;
		} catch (e) {
			console.error(e);
			return this.defaultRouteQuery;
		}
	}
}
