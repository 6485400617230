import ApiForeignOrganizationCounterparty from "@/api/types/counterparty/apiForeignOrganizationCounterparty";
import urls, { urlTemplateParts } from "@/api/config/urls";
import { prepareUrl } from "@/utils/prepareUrlQuery";
import HttpNotFoundException from "@/exceptions/httpNotFoundException";
import { ApiCounterpartyEmployeeBase } from "@/api/types/counterparty/apiCounterpartyEmployee";
import BaseController from "@/api/shared/baseController";
import AbortService from "@/services/abortService";

export class ForeignOrganizationController extends BaseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}

	createForeignOrganizationCounterparty = async (counterparty: ApiForeignOrganizationCounterparty): Promise<string> => {
		return await this.client.post(urls.counterparty.foreignOrganization.create, counterparty);
	};

	updateForeignOrganizationCounterpartyEmployee =
		async (id: string, payload: ApiCounterpartyEmployeeBase): Promise<void> => {
			return await this.client.put<void>(urls.counterparty.foreignOrganization.employee.update.replace(urlTemplateParts.id, id),
				payload);
		};

	createForeignOrganizationCounterpartyEmployee =
		async (counterpartyId: string, payload: ApiCounterpartyEmployeeBase, assignToAccount: boolean = true): Promise<string> => {
			const url = prepareUrl(urls.counterparty.foreignOrganization.employee.create.replace(urlTemplateParts.id, counterpartyId),
				{ assignToAccount });
			return await this.client.post<string>(url, payload);
		};

	getForeignOrganizationByLongName = async (longName: string): Promise<ApiForeignOrganizationCounterparty | null> => {
		try {
			return await this.client.get(prepareUrl(urls.counterparty.foreignOrganization.search, {
				longName
			}));
		} catch (e) {
			if(e instanceof HttpNotFoundException)
				return null;
			else
				throw e;
		}
	};

	checkForeignOrganizationIsExists = async (longName: string): Promise<boolean> => {
			const url = prepareUrl(urls.counterparty.foreignOrganization.checkIsExists, { longName });

			const { exists } = await this.client.get<{ exists: boolean }>(url);

			return exists
	};
}
