import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import alertService from "@/store/modules/alerts/services/alertService";
import {
	actionTypes,
	getterTypes,
	mutationTypes,
	namespace
} from "@/store/modules/masterAccountAccess/modules/selectCounterpartyStep/types";
import SelectCounterpartyStepState
	from "@/store/modules/masterAccountAccess/modules/selectCounterpartyStep/types/selectCounterpartyStepState";
import BaseMixinBuilder from "@/store/shared/base";
import BaseStepMixinBuilder from "@/store/shared/baseStep";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import { CounterpartyType } from "@/types/CounterpartyType";
import { LegalEntityController } from "@/api/counterparty/legalEntity";
import AbortService from "@/services/abortService";
import { LegalPersonController } from "@/api/counterparty/legalPerson";
import MasterAccountAccessApplicationStatus from "@/api/types/masterAccountAccessApplication/masterAccountAccessApplicationStatus";
import MasterAccountAccessErrorType from "@/store/modules/masterAccountAccess/types/masterAccountAccessErrorType";
import { MasterAccountAccessApplicationController } from "@/api/masterAccountAccessApplication";
import baseMixinTypes from "@/store/shared/base/types";

const abortService = new AbortService();
const legalEntityController = new LegalEntityController(abortService);
const legalPersonController = new LegalPersonController(abortService);
const masterAccountAccessApplicationController = new MasterAccountAccessApplicationController(abortService);

const baseStepMixin = (new BaseStepMixinBuilder()).build();

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new SelectCounterpartyStepState();
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();
const baseMixin = (new BaseMixinBuilder(abortService)).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<SelectCounterpartyStepState, any>>{
	[getterTypes.isStepCompleted]: (state) => {
		if(state.isCounterpartyLoading || state.errors.length > 0)
			return false;

		return !!state.counterparty;
	}
};

const actions = <ActionTree<SelectCounterpartyStepState, any>>{
	...stateManipulationMixin.actions,
	...baseStepMixin.actions,
	...baseMixin.actions,
	[actionTypes.initialize]({ dispatch, commit }) {
		dispatch(baseMixinTypes.actionTypes.initializeBase);

		commit(mutationTypes.SET_IS_INITIALIZED, true);
	},
	[actionTypes.resetFetchedInfo]({ commit }) {
		const builder = new DefaultStateBuilder();
		const { counterparty, counterpartyType, isCounterpartyAlreadyExists, isMasterAccountAlreadyExists } = builder.build();

		commit(mutationTypes.SET_COUNTERPARTY, counterparty);
		commit(mutationTypes.SET_IS_COUNTERPARTY_ALREADY_EXISTS, isCounterpartyAlreadyExists);
		commit(mutationTypes.SET_IS_MASTER_ACCOUNT_ALREADY_EXISTS, isMasterAccountAlreadyExists);
		commit(mutationTypes.SET_COUNTERPARTY_TYPE, counterpartyType);
		commit(mutationTypes.CLEAR_ERRORS);
	},
	async [actionTypes.fetchCounterparty]({ state, commit, dispatch }) {
		await dispatch(actionTypes.resetFetchedInfo);

		commit(mutationTypes.SET_IS_COUNTERPARTY_LOADING, true);

		switch (state.inn.length) {
			case 10:
				commit(mutationTypes.SET_COUNTERPARTY_TYPE, CounterpartyType.LEGAL_ENTITY);
				break;
			case 12:
				commit(mutationTypes.SET_COUNTERPARTY_TYPE, CounterpartyType.LEGAL_PERSON);
				break;
		}

		try {
			if(state.counterpartyType === CounterpartyType.LEGAL_ENTITY) {
				const suggestion = await legalEntityController.getLegalEntitySuggestion(state.inn);

				if(!suggestion) {
					alertService.addCustomError(`Организация с ИНН ${state.inn} не найдена в ЕГРЮЛ`);
					return;
				}

				commit(mutationTypes.SET_COUNTERPARTY, suggestion);

				const { inn, ogrn, kpp } = suggestion;

				// Существует ли контрагент в системе
				const counterpartyExists = await legalEntityController.checkLegalEntityCounterpartyIsExists(inn, ogrn, kpp);
				if(counterpartyExists) {
					commit(mutationTypes.SET_IS_COUNTERPARTY_ALREADY_EXISTS, true);

					// Есть ли в системе мастер-аккаунт у этого контрагента
					const masterAccountExists = await legalEntityController.checkLegalEntityProfile(inn, ogrn, kpp);

					commit(mutationTypes.SET_IS_MASTER_ACCOUNT_ALREADY_EXISTS, masterAccountExists);
				}

				// Проверяем наличие заявки в системе
				const applicationExists = await masterAccountAccessApplicationController.getLegalEntityAccountApplicationId(inn, ogrn, kpp,
					MasterAccountAccessApplicationStatus.Created);

				// Если заявка уже есть, то новая подана быть не может
				if(applicationExists) {
					commit(mutationTypes.ADD_ERROR, { type: MasterAccountAccessErrorType.APPLICATION_EXISTS });
				}
			} else if(state.counterpartyType === CounterpartyType.LEGAL_PERSON) {
				const suggestion = await legalPersonController.getSuggestion(state.inn);

				if(!suggestion) {
					alertService.addCustomError(`Индивидуальный предприниматель с ИНН ${state.inn} не найден в ЕГРИП`);
					return;
				}

				commit(mutationTypes.SET_COUNTERPARTY, suggestion);

				const { inn, ogrn } = suggestion;

				// Существует ли контрагент в системе
				const counterpartyExists = await legalPersonController.checkLegalPersonIsExists(inn, ogrn);
				if(counterpartyExists) {
					commit(mutationTypes.SET_IS_COUNTERPARTY_ALREADY_EXISTS, true);

					// Есть ли в системе мастер-аккаунт у этого контрагента
					const masterAccountExists = await legalPersonController.checkMasterAccount(inn, ogrn);

					commit(mutationTypes.SET_IS_MASTER_ACCOUNT_ALREADY_EXISTS, masterAccountExists);
				}

				// Проверяем наличие заявки в системе
				const applicationExists = await masterAccountAccessApplicationController.getLegalPersonAccountApplicationId(inn, ogrn,
					MasterAccountAccessApplicationStatus.Created);

				// Если заявка уже есть, то новая подана быть не может
				if(applicationExists) {
					commit(mutationTypes.ADD_ERROR, { type: MasterAccountAccessErrorType.APPLICATION_EXISTS });
				}
			}
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_COUNTERPARTY_LOADING, false);
		}
	}
};

const mutations = <MutationTree<SelectCounterpartyStepState>>{
	...stateManipulationMixin.mutations,
	...baseStepMixin.mutations,
	...baseMixin.mutations,
	[mutationTypes.SET_INN](state, value) {
		state.inn = value;
	},
	[mutationTypes.SET_IS_COUNTERPARTY_LOADING](state, value) {
		state.isCounterpartyLoading = value;
	},
	[mutationTypes.SET_COUNTERPARTY](state, value) {
		state.counterparty = value;
	},
	[mutationTypes.REMOVE_COUNTERPARTY](state) {
		state.counterparty = null;
	},
	[mutationTypes.SET_COUNTERPARTY_TYPE](state, value) {
		state.counterpartyType = value;
	},
	[mutationTypes.SET_IS_COUNTERPARTY_ALREADY_EXISTS](state, value) {
		state.isCounterpartyAlreadyExists = value;
	},
	[mutationTypes.SET_IS_MASTER_ACCOUNT_ALREADY_EXISTS](state, value) {
		state.isMasterAccountAlreadyExists = value;
	},
	[mutationTypes.CLEAR_ERRORS](state) {
		state.errors = [];
	},
	[mutationTypes.ADD_ERROR](state, { type }) {
		state.errors.push({ type });
	}
};

export {
	namespace, state, getters, actions, mutations
};

const selectCounterpartyStepModule = {
	namespace, state, getters, actions, mutations, namespaced: true
};

export default selectCounterpartyStepModule;
