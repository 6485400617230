import urls, { urlTemplateParts } from "@/api/config/urls";
import HttpNotFoundException from "@/exceptions/httpNotFoundException";
import { prepareUrl } from "@/utils/prepareUrlQuery";
import { plainToInstance } from "class-transformer";
import ApiLegalEntityMasterAccountAccessApplicationCreate
	from "@/api/types/masterAccountAccessApplication/apiLegalEntityMasterAccountAccessApplicationCreate";
import MasterAccountAccessApplicationStatus from "@/api/types/masterAccountAccessApplication/masterAccountAccessApplicationStatus";
import ApiGetMasterAccountAccessApplicationsParameters
	from "@/api/types/masterAccountAccessApplication/apiGetMasterAccountAccessApplicationsParameters";
import ApiMasterAccountAccessApplications from "@/api/types/masterAccountAccessApplication/apiMasterAccountAccessApplications";
import ApiMasterAccountAccessApplication from "@/api/types/masterAccountAccessApplication/apiMasterAccountAccessApplication";
import ApiSignedMasterAccountAccessApplication from "@/api/types/masterAccountAccessApplication/apiSignedMasterAccountAccessApplication";
import ApiLegalPersonMasterAccountAccessApplicationCreate
	from "@/api/types/masterAccountAccessApplication/apiLegalPersonMasterAccountAccessApplicationCreate";
import BaseController from "@/api/shared/baseController";
import AbortService from "@/services/abortService";
import ApiLegalEntityMasterAccountAccessFromEmployeeApplicationCreate
	from "@/api/types/masterAccountAccessApplication/apiLegalEntityMasterAccountAccessFromEmployeeApplicationCreate";
import ApiLegalPersonMasterAccountAccessFromEmployeeApplicationCreate
	from "@/api/types/masterAccountAccessApplication/apiLegalPersonMasterAccountAccessFromEmployeeApplicationCreate";
import { cloneDeep } from "lodash";

export class MasterAccountAccessApplicationController extends BaseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}

	getMasterAccountAccessApplications = async (parameters: ApiGetMasterAccountAccessApplicationsParameters): Promise<ApiMasterAccountAccessApplications> => {
		const url = prepareUrl(urls.applications.masterAccountAccess.getMasterAccountAccessApplications, parameters);
		let data = await this.client.get<{ requests: object[], totalCount: number }>(url, {});

		return new ApiMasterAccountAccessApplications(plainToInstance(ApiMasterAccountAccessApplication, data.requests), data.totalCount);
	};

	updateMasterAccountAccessApplicationStatus = async (id: string, status: MasterAccountAccessApplicationStatus) => {
		return await this.client.put(urls.applications.masterAccountAccess.updateStatus.replace(urlTemplateParts.id, id), {
			status
		}, {});
	};

	submitLegalEntityMasterAccountAccessApplication = async (payload: ApiLegalEntityMasterAccountAccessApplicationCreate) => {
		return await this.client.post(urls.applications.masterAccountAccess.createLegalEntityAccountApplication, payload);
	};

	submitLegalPersonMasterAccountAccessApplication = async (payload: ApiLegalPersonMasterAccountAccessApplicationCreate) => {
		return await this.client.post(urls.applications.masterAccountAccess.createLegalPersonAccountApplication, payload);
	};

	submitSignedLegalEntityAccessApplication = async (payload: ApiSignedMasterAccountAccessApplication) => {
		return await this.client.post(urls.applications.masterAccountAccess.createSignedLegalEntityAccountApplication, payload);
	};

	submitLegalEntityAccessFromEmployeeApplication = async (payload: ApiLegalEntityMasterAccountAccessFromEmployeeApplicationCreate) => {
		return await this.client.post(urls.applications.masterAccountAccess.createLegalEntityAccountFromEmployeeApplication, payload);
	};

	submitLegalPersonAccessFromEmployeeApplication = async (payload: ApiLegalPersonMasterAccountAccessFromEmployeeApplicationCreate) => {
		return await this.client.post(urls.applications.masterAccountAccess.createLegalPersonAccountFromEmployeeApplication, payload);
	};

	getLegalEntityAccountApplicationId = async (inn: string, ogrn: string, kpp: string,
		status: MasterAccountAccessApplicationStatus): Promise<boolean> => {
		let { exists } = await this.client.get<{ exists: boolean }>(prepareUrl(urls.applications.masterAccountAccess.getLegalEntityAccountApplicationId,
			{ inn, ogrn, kpp, status }));
		return exists;
	};

	getLegalPersonAccountApplicationId = async (inn: string, ogrnIp: string,
		status: MasterAccountAccessApplicationStatus): Promise<boolean> => {
		let { exists } = await this.client.get<{ exists: boolean }>(prepareUrl(urls.applications.masterAccountAccess.getLegalPersonAccountApplicationId,
			{ inn, ogrnIp, status }));
		return exists;
	};

	getMasterAccountAccessApplication = async (id: string): Promise<ApiMasterAccountAccessApplication> => {
		const url = urls.applications.masterAccountAccess.getApplicationDetails.replace(urlTemplateParts.id, id);

		let data = await this.client.get<object>(url);

		return plainToInstance(ApiMasterAccountAccessApplication, data);
	};
}
