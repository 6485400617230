import urls, { urlTemplateParts } from "@/api/config/urls";
import { plainToInstance } from "class-transformer";
import ApiCounterpartyEmployeeTrustCreateRequest from "@/api/types/counterparty/trust/apiCounterpartyEmployeeTrustCreateRequest";
import ApiCounterpartyEmployeeTrustPersisted from "@/api/types/counterparty/trust/apiCounterpartyEmployeeTrustPersisted";
import BaseController from "@/api/shared/baseController";
import AbortService from "@/services/abortService";
import HttpNotFoundException from "@/exceptions/httpNotFoundException";
import { AuthorizationScopeType } from "@/types/authorization/authorizationScopeType";
import { prepareUrl } from "@/utils/prepareUrlQuery";
import { i18n } from "@/plugins";

export class TrustController extends BaseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}

	getCounterpartyEmployeeTrust =
		async (id: string,
			employeeId: string,
			counterpartyId: string,
			scope = AuthorizationScopeType.GLOBAL): Promise<ApiCounterpartyEmployeeTrustPersisted> => {
			const url = scope === AuthorizationScopeType.GLOBAL
				? urls.counterparty.employee.trust.get
					  .replace(urlTemplateParts.id, counterpartyId)
					  .replace(urlTemplateParts.subId, employeeId)
					  .replace(urlTemplateParts.thirdId, id)
				: urls.counterparty.employee.trust.getOwn
					  .replace(urlTemplateParts.subId, employeeId)
					  .replace(urlTemplateParts.thirdId, id);

			let { trust } = await this.client.get<{ trust: object }>(url);

			if(trust === null)
				throw new HttpNotFoundException(i18n.t("alerts.errors.trustNotFound") as string);

			return plainToInstance(ApiCounterpartyEmployeeTrustPersisted, trust);
		};

	getCounterpartyEmployeeTrusts = async (counterpartyId: string,
		employeeId: string,
		scope = AuthorizationScopeType.GLOBAL): Promise<ApiCounterpartyEmployeeTrustPersisted[]> =>
	{
		const url = scope === AuthorizationScopeType.GLOBAL
			? urls.counterparty.employee.trust.getItems
				  .replace(urlTemplateParts.id, counterpartyId)
				  .replace(urlTemplateParts.subId, employeeId)
			: urls.counterparty.employee.trust.getOwnItems
				  .replace(urlTemplateParts.id, counterpartyId)
				  .replace(urlTemplateParts.subId, employeeId);

		let { trusts } = await this.client.get<{ trusts: object[] }>(url);

		return plainToInstance(ApiCounterpartyEmployeeTrustPersisted, trusts);
	};

	createCounterpartyEmployeeTrust = async (counterpartyId: string, employeeId: string,
		payload: ApiCounterpartyEmployeeTrustCreateRequest, scope = AuthorizationScopeType.GLOBAL): Promise<string> =>
	{
		const url = scope === AuthorizationScopeType.GLOBAL
			? urls.counterparty.employee.trust.getItems
				  .replace(urlTemplateParts.id, counterpartyId)
				  .replace(urlTemplateParts.subId, employeeId)
			: urls.counterparty.employee.trust.getOwnItems
				  .replace(urlTemplateParts.subId, employeeId);


		return await this.client.post<string>(url, payload);
	};

	acceptCounterpartyEmployeeTrust = async (counterpartyId: string,
		employeeId: string,
		trustId: string): Promise<ApiCounterpartyEmployeeTrustPersisted> =>
	{
		const url = urls.counterparty.employee.trust.accept
						.replace(urlTemplateParts.id, counterpartyId)
						.replace(urlTemplateParts.subId, employeeId)
						.replace(urlTemplateParts.thirdId, trustId);


		let { trust } = await this.client.post<{ trust: object }>(url, {}, {});

		return plainToInstance(ApiCounterpartyEmployeeTrustPersisted, trust);
	};

	declineCounterpartyEmployeeTrust = async (
		counterpartyId: string,
		employeeId: string,
		trustId: string,
		declineReason: string
	): Promise<ApiCounterpartyEmployeeTrustPersisted> => {
		const url = urls.counterparty.employee.trust.decline
						.replace(urlTemplateParts.id, counterpartyId)
						.replace(urlTemplateParts.subId, employeeId)
						.replace(urlTemplateParts.thirdId, trustId);


		let { trust } = await this.client.post<{ trust: object }>(url, {declineReason}, {});

		return plainToInstance(ApiCounterpartyEmployeeTrustPersisted, trust);
	};

	cancelCounterpartyEmployeeTrust = async (counterpartyId: string,
		employeeId: string,
		trustId: string): Promise<ApiCounterpartyEmployeeTrustPersisted> => {
		const url = urls.counterparty.employee.trust.cancel
						.replace(urlTemplateParts.id, counterpartyId)
						.replace(urlTemplateParts.subId, employeeId)
						.replace(urlTemplateParts.thirdId, trustId);


		let { trust } = await this.client.post<{ trust: object }>(url, {}, {});

		return plainToInstance(ApiCounterpartyEmployeeTrustPersisted, trust);
	};

	getCounterpartyTrustActioner = async (
		trustId: string,
		employeeId: string,
		counterpartyId: string,
		actionerKind: string,
		scope = AuthorizationScopeType.GLOBAL
	): Promise<string> => {
			const url = scope === AuthorizationScopeType.GLOBAL
				? prepareUrl(urls.counterparty.employee.trust.getActioner
						.replace(urlTemplateParts.id, trustId), {actionerKind})
				: prepareUrl(urls.counterparty.employee.trust.getOwnActioner
						.replace(urlTemplateParts.id, employeeId)
					    .replace(urlTemplateParts.subId, trustId), {actionerKind});

			let { fullname } = await this.client.get<{ fullname: string }>(url);

			return fullname;
		};
}
