<template>
	<frp-nested-content-layout v-if="isInitialized"
							   :back-route="{ name: RouteNames.APPLICATIONS_MASTER_ACCOUNT_ACCESSES }">
		<template #title>
			<span class="text-h5 primary--text text--darken-1 d-flex flex-column">
				<span>{{ $t("titles.requestForAccessToMasterAccount") }}</span>
				<span>{{ details.title }}</span>
			</span>
		</template>
		<template #content>
			<v-card class="pa-8 pb-1 d-flex align-start flex-wrap"
					:style="{ 'column-gap': $vuetify.breakpoint.lgAndDown ? '56px' : '128px' }" elevation="0">
				<v-row>
					<frp-details-section-column>
						<frp-details-group>
							<frp-details-section-title>{{ $t("details.titles.commonData") }}</frp-details-section-title>
							<frp-details-item :title="$t('details.titles.name')" :value="details.title"></frp-details-item>
							<frp-details-item :title="$t('details.titles.ogrn')" :value="details.ogrn || details.ogrnIp"></frp-details-item>
							<frp-details-item :title="$t('details.titles.inn')" :value="details.inn"></frp-details-item>
							<frp-details-item :title="$t('details.titles.legalAddress')" :value="details.legalAddress"></frp-details-item>
							<frp-details-item :title="$t('details.titles.requestorEmail')"
											  :value="details.requestorEmail"></frp-details-item>
						</frp-details-group>
					</frp-details-section-column>
					<frp-details-section-column v-if="details.heads">
						<frp-details-group v-for="head in details.heads" :key="head.inn">
							<frp-details-section-title>{{ head.position }}</frp-details-section-title>
							<frp-details-item :title="$t('details.titles.fullName')" :value="head.fullName"></frp-details-item>
							<frp-details-item :title="$t('details.titles.inn')" :value="head.inn"></frp-details-item>
							<frp-details-item :title="$t('details.titles.snils')" :value="head.snils"></frp-details-item>
						</frp-details-group>
					</frp-details-section-column>
					<frp-details-section-column v-if="details.documentIds.length">
						<frp-details-group>
							<frp-details-section-title>{{ $t("details.titles.documentsList") }}</frp-details-section-title>
							<frp-file v-for="id in details.documentIds" :id="id" :key="id"></frp-file>
						</frp-details-group>
					</frp-details-section-column>
				</v-row>
			</v-card>
			<frp-approving-actions @approve="handleApprove"
								   :accept-permissions="Permissions.GLOBAL_MASTER_ACCOUNT_APPLICATION_STATUS_UPDATE"
								   :decline-permissions="Permissions.GLOBAL_MASTER_ACCOUNT_APPLICATION_STATUS_UPDATE"
								   @reject="rejectApplication"
								   :is-approving="isApproving"
								   :is-rejecting="isRejecting"
								   :is-approve-disabled="details.status !== ApproveStatusType.CREATED"
								   :is-reject-disabled="details.status !== ApproveStatusType.CREATED">
			</frp-approving-actions>
		</template>
	</frp-nested-content-layout>
	<frp-master-account-access-application-details-loader v-else/>
</template>

<script>
import authorizationMixin from "@/mixins/authorizationMixin";
import storeModuleBasedPage from "@/mixins/storeModuleBasedPage";
import { RouteNames } from "@/router/routes";
import FrpNestedContentLayout from "Components/layouts/FrpNestedContentLayout";
import FrpMasterAccountAccessApplicationDetailsLoader
	from "Views/applications/masterAccountAccess/details/FrpMasterAccountAccessApplicationDetailsLoader";
import FrpDetailsSectionColumn from "Components/details/FrpDetailsSectionColumn";
import FrpIcon from "Components/icon/FrpIcon";
import FrpDetailsItem from "Components/details/FrpDetailsItem";
import FrpDetailsSubheader from "Components/details/FrpDetailsSubheader";
import FrpDetailsGroup from "Components/details/FrpDetailsGroup";
import FrpFile from "Components/common/FrpFile";
import FrpDetailsSectionTitle from "Components/details/FrpDetailsSectionTitle";
import FrpApprovingActions from "Components/buttons/FrpApprovingActions";
import FrpPageNotFound from "Views/errors/FrpPageNotFound";
import { ApproveStatusType } from "Types/ApproveStatusType";
import colorsMixin from "Mixins/colorsMixin";
import { actionTypes, getterTypes, namespace } from "Store/modules/applications/masterAccountAccess/details/types";
import { createNamespacedHelpers } from "vuex";
import { CounterpartyType } from "Types/CounterpartyType";

const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);

export default {
	mixins: [colorsMixin, storeModuleBasedPage, authorizationMixin],
	data() {
		return {
			namespace,
			CounterpartyType,
			ApproveStatusType,
			RouteNames
		};
	},
	computed: {
		...mapState({
			isLoading: state => state.isLoading,
			isApproving: state => state.isApproving,
			isRejecting: state => state.isRejecting,
			isInitialized: state => state.isInitialized
		}),
		...mapGetters({
			details: getterTypes.details
		}),
		id() {
			return this.$route.params.id;
		}
	},
	methods: {
		...mapActions({
			approveApplication: actionTypes.approveApplication,
			rejectApplication: actionTypes.rejectApplication
		}),
		async handleApprove() {
			await this.approveApplication();
		}
	},
	async created() {
		await this.initializeStore({ id: this.id });
	},
	components: {
		FrpNestedContentLayout,
		FrpIcon,
		FrpDetailsItem,
		FrpDetailsSubheader,
		FrpDetailsGroup,
		FrpFile,
		FrpDetailsSectionTitle,
		FrpMasterAccountAccessApplicationDetailsLoader,
		FrpDetailsSectionColumn,
		FrpApprovingActions,
		FrpPageNotFound
	}
};
</script>
