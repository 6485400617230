export default class BaseStepModel {
	order: number;
	title: string;
	isEnabled: boolean;
	component: string;

	constructor({ order, title, isEnabled, component }:
		{
			order: number,
			title: string,
			isEnabled: boolean,
			component: string
		})
	{
		this.order = order;
		this.title = title;
		this.isEnabled = isEnabled;
		this.component = component;
	}
}
