export default class CertificateInfo {
	owner: string;
	thumbprint: string;
	expireDate: string;

	constructor(
		owner: string = "",
		thumbprint: string = "",
		expireDate: string = ""
	)
	{
		this.owner = owner;
		this.thumbprint = thumbprint;
		this.expireDate = expireDate;
	}
}
