import ApiOffice from "@/api/types/office/apiOffice";
import {
	namespace,
	actionTypes,
	mutationTypes
} from "@/store/modules/office/modules/general/types";
// @ts-ignore
import { checkINN } from "ru-validation-codes";
import OfficeGeneralState from "@/store/modules/office/modules/general/types/officeGeneralState";
import BaseMixinBuilder from "@/store/shared/base";
import FormMixinBuilder from "@/store/shared/form";
import SnapshotMixinBuilder from "@/store/shared/snapshot";
import stateSnapshotKeys from "@/store/shared/snapshot/keys";
import SnapshotOptions from "@/store/shared/snapshot/snapshotOptions";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { resolveAction, resolveMutation } from "@/utils/vuexModules";
import router from "@/router";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import Office from "@/store/modules/office/modules/general/types/office";
import storeManager from "@/store/manager";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import alertService, { AlertKeys } from "@/store/modules/alerts/services/alertService";
import mapper from "@/store/modules/office/modules/general/mapper";
import { OfficeController } from "@/api/office";
import { LegalEntityController } from "@/api/counterparty/legalEntity";
import AbortService from "@/services/abortService";
import HttpNotFoundException from "@/exceptions/httpNotFoundException";
import { RouteNames } from "@/router/routes";
import ApiLegalEntity from "@/api/types/legalEntity/apiLegalEntity";
import rootTypes from "@/store/types";
import { PageModeType } from "@/store/types/pageModeType";
import AccessForbiddenException from "@/exceptions/accessForbiddenException";

const abortService = new AbortService();
const legalEntityController = new LegalEntityController(abortService);
const officeController = new OfficeController(abortService);

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new OfficeGeneralState(
			formMixin.state(),
			snapshotMixin.state()
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();
const baseMixin = (new BaseMixinBuilder(abortService)).build();
const formMixin = (new FormMixinBuilder()).build();

const snapshotMixin = (new SnapshotMixinBuilder({
	options: [
		new SnapshotOptions({
			key: stateSnapshotKeys.LAST_SAVED,
			fields: ["office"]
		})
	]
})).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<OfficeGeneralState, any>>{
	...snapshotMixin.getters
};

const actions = <ActionTree<OfficeGeneralState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...formMixin.actions,
	...snapshotMixin.actions,
	async [actionTypes.initialize]({ commit, dispatch }, { id }) {
		await dispatch(actionTypes.initializeBase);

		commit(mutationTypes.SET_ID, process.env.VUE_APP_HEAD_OFFICE_ID);
		await dispatch(actionTypes.fetch);

		commit(mutationTypes.SET_IS_INITIALIZED, true);
		commit(mutationTypes.SET_STATE_SNAPSHOT, stateSnapshotKeys.LAST_SAVED);
	},
	async [actionTypes.fetch]({ dispatch, commit, rootState, state }) {
		commit(mutationTypes.SET_IS_FORM_LOADING, true);

		try {
			let apiOffice = await officeController.getOffice(state.id);

			commit(mutationTypes.SET_OFFICE, mapper.map(apiOffice.office, ApiOffice, Office));
		} catch (error) {
			switch (error.constructor) {
				case HttpNotFoundException:
					commit(rootTypes.mutationTypes.SET_PAGE_MODE, PageModeType.PAGE_NOT_FOUND, { root: true });
					break;
				case AccessForbiddenException:
					commit(rootTypes.mutationTypes.SET_PAGE_MODE, PageModeType.ACCESS_DENIED, { root: true });
					break;
				default:
					AlertHelper.handleGeneralRequestErrors(error);
					break;
			}
		} finally {
			commit(mutationTypes.SET_IS_FORM_LOADING, false);
		}
	},
	async [actionTypes.save]({ dispatch, commit, rootState, state }) {
		commit(mutationTypes.SET_IS_FORM_SAVING, true);

		try {
			let payload = mapper.map(state.office, Office, ApiOffice);

			if(state.id) {
				await officeController.updateOffice(state.id, payload);

				commit(mutationTypes.SET_STATE_SNAPSHOT, stateSnapshotKeys.LAST_SAVED);
				alertService.addInfo(AlertKeys.SUCCESS_UPDATED_INFO);
			} else {
				const id = await officeController.createOffice(payload);

				alertService.addInfo(AlertKeys.SUCCESS_CREATED_INFO);

				await router.push({ name: RouteNames.OFFICE, params: { id } });
			}
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_FORM_SAVING, false);
		}
	},
	async [actionTypes.handleInnInput]({ state, commit, dispatch }) {
		try {
			commit(mutationTypes.SET_IS_FORM_READONLY, true);
			commit(mutationTypes.SET_IS_EGRUL_LEGAL_ENTITY_LOADING, true);

			let suggestion = await legalEntityController.getLegalEntitySuggestion(state.office.inn, "");

			if(suggestion) {
				commit(mutationTypes.SET_OFFICE, mapper.map(suggestion, ApiLegalEntity, Office));
			} else {
				alertService.addCustomError(`Не удалось найти организацию с ИНН ${state.office.inn} в ЕГРЮЛ`);
			}
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_FORM_READONLY, false);
			commit(mutationTypes.SET_IS_EGRUL_LEGAL_ENTITY_LOADING, false);
		}
	}
};

const mutations = <MutationTree<OfficeGeneralState>>{
	...stateManipulationMixin.mutations,
	...formMixin.mutations,
	...snapshotMixin.mutations,
	...baseMixin.mutations,
	[mutationTypes.SET_ID](state, value) {
		state.id = value;
	},
	[mutationTypes.SET_OFFICE](state, value) {
		state.office = value;
	},
	[mutationTypes.SET_INN](state, value) {
		state.office.inn = value;
	},
	[mutationTypes.SET_OGRN](state, value) {
		state.office.ogrn = value;
	},
	[mutationTypes.SET_KPP](state, value) {
		state.office.kpp = value;
	},
	[mutationTypes.SET_SHORT_NAME](state, value) {
		state.office.shortName = value;
	},
	[mutationTypes.SET_LONG_NAME](state, value) {
		state.office.longName = value;
	},
	[mutationTypes.SET_DESCRIPTION](state, value) {
		state.office.description = value;
	},
	[mutationTypes.SET_IS_EGRUL_LEGAL_ENTITY_LOADING](state, value) {
		state.isEgrulLegalEntityLoading = value;
	}
};

const subscribe = (store: any) => {
	const { commit, dispatch } = store;
	store.subscribe(({ type, payload }: any, state: any) => {
		switch (type) {
			case  resolveMutation(storeManager.office.general.namespace, mutationTypes.SET_INN):
			{
				const inn = payload || "";

				if(checkINN(inn)) {
					dispatch(resolveAction(storeManager.office.general.namespace, actionTypes.handleInnInput));
				}

				break;
			}

			default:
				break;
		}
	});
};

export {
	namespace, state, getters, actions, mutations, subscribe
};

const officeGeneralModule = {
	namespace, state, getters, actions, mutations, subscribe, namespaced: true
};

export default officeGeneralModule;
