import Account from "@/store/modules/account/modules/general/types/account";
import FormState from "@/store/shared/form/models/formState";

export default class AccountGeneralState {
	constructor(
		public snapshot: any,
		public form: FormState,
		public account: Account = new Account(),
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public isStatusSaving: boolean = false,
		public isEmailSaving: boolean = false,
		public id: string = "",
		public isResetPasswordOperationExecuting: boolean = false
	)
	{
	}
}
