export class ApiCounterpartyUnassignedEmployee {
	email: string;
	phone: string;
	inn: string;
	position: string;
	snils: string;
	firstName: string;
	middleName: string;
	lastName: string;

	constructor(
		email: string = "",
		phone: string = "",
		inn: string = "",
		position: string = "",
		snils: string = "",
		firstName: string = "",
		middleName: string = "",
		lastName: string = ""
	)
	{
		this.email = email;
		this.phone = phone;
		this.inn = inn;
		this.position = position;
		this.snils = snils;
		this.firstName = firstName;
		this.middleName = middleName;
		this.lastName = lastName;
	}
}
