<template>
	<frp-internal-list-extended-layout>
		<template #title>
			{{ $t("titles.employees") }}
		</template>

		<template #search>
			<frp-text-field :ref="searchTextFieldRef"
							v-model="searchQuery"
							hide-details
							:rules="validation.searchQuery"
							autocomplete="off"
							icon="ico_search"
							:placeholder="$t('placeholders.search')">
			</frp-text-field>
		</template>

		<template #filter>
			<frp-filter v-model="isFilterOpened">
				<template #activator="{ on, attrs }">
					<frp-filter-btn :selected-filtering-parameters-count="selectedFilteringParametersCount"
									:activator="{ on, attrs }">
					</frp-filter-btn>
				</template>

				<template #content>
					<frp-checkbox-group :values="filter.roles.value"
										@update:values="setInternalFilterRoles"
										:columns="$vuetify.breakpoint.smOnly ? 2 : ($vuetify.breakpoint.lgAndUp ? 4 : 3)"
										:gap="$vuetify.breakpoint.xsOnly ? 24 : 32"
										:loading="rolesIsLoading"
										:items="filter.roles.items">
					</frp-checkbox-group>
				</template>

				<template #actions>
					<frp-btn outlined color="primary" @click="resetFilter" :disabled="isFilterEmpty && !isFilterChanged">
						{{ $t("buttons.clear") }}
					</frp-btn>
					<frp-btn @click="applyFilter" :disabled="!isFilterChanged">
						{{ $t("buttons.accept") }}
					</frp-btn>
				</template>
			</frp-filter>
		</template>

		<template #side-btn>
			<frp-btn class="frp-xs-full-width" @click="handleCreateItem"  v-if="can(Permissions.GLOBAL_OFFICE_EMPLOYEE_CREATE)">
				{{ $t("buttons.addEmployee") }}
			</frp-btn>
		</template>

		<template #content>
			<v-data-table :headers="headers"
						  :items="items"
						  :loading-text="$t('tables.loading')"
						  hide-default-footer
						  :loading="isItemsLoading"
						  item-key="id"
						  :mobile-breakpoint="mobileBreakpoint"
						  :items-per-page="-1"
						  :custom-sort="sortItems"
						  must-sort
						  :options="options"
						  @click:row="handleItemSelect"
						  :item-class="() => 'text-body-2'"
						  class="frp-table clickable-rows elevation-0 mt-2 d-flex flex-column">
				<template #item.isActive="{item}">
					<frp-status-label :value="item.isActive"></frp-status-label>
				</template>
			</v-data-table>
		</template>

		<template #pagination>
			<frp-pagination v-if="!isItemsLoading"
							v-model="page"
							:length="Math.ceil(paging.total / paging.pageSize)">
			</frp-pagination>
		</template>
	</frp-internal-list-extended-layout>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn";
import FrpFilterBtn from "@/components/buttons/FrpFilterBtn";
import authorizationMixin from "@/mixins/authorizationMixin";
import { RouteNames } from "@/router/routes";
import FrpInternalListExtendedLayout from "Components/layouts/FrpInternalListExtendedLayout";
import FrpStatusLabel from "Components/common/FrpStatusLabel";
import FrpTextField from "Components/fields/FrpTextField";
import FrpFilter from "Components/common/FrpFilter";
import FrpCheckboxGroup from "Components/fields/FrpCheckboxGroup";
import FrpIcon from "Components/icon/FrpIcon";
import FrpPagination from "Components/common/FrpPagination";
import storeModuleBasedPage from "Mixins/storeModuleBasedPage";
import storeManager from "@/store/manager/index";
import { mutationTypes, actionTypes, getterTypes } from "Store/modules/office/modules/officeEmployees/types";
import { createNamespacedHelpers } from "vuex";
import { listMixin } from "Mixins/listMixin";
import colorsMixin from "Mixins/colorsMixin";

const namespace = storeManager.office.employees.namespace;
const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);

export default {
	mixins: [listMixin, colorsMixin, storeModuleBasedPage, authorizationMixin],
	data() {
		return {
			namespace,
			headers: [
				{
					text: this.$t("tables.number"),
					value: "number",
					class: "text-caption",
					width: "14%"
				},
				{
					text: this.$t("tables.fullName"),
					value: "fio",
					class: "text-caption",
					width: "14%"
				},
				{
					text: this.$t("tables.position"),
					value: "position",
					class: "text-caption",
					width: "14%"
				},
				{
					text: this.$t("tables.description"),
					value: "description",
					class: "text-caption",
					width: "14%"
				},
				{
					text: this.$t("tables.roles"),
					value: "roles",
					class: "text-caption",
					width: "14%",
					sortable: false
				},
				{
					text: this.$t("tables.status"),
					value: "isActive",
					class: "text-caption",
					width: "14%"
				},
				{
					text: this.$t("tables.createdAt"),
					value: "createdAt",
					class: "text-caption",
					width: "14%"
				}
			],
			internalFilterValues: {
				roles: []
			}
		};
	},
	computed: {
		...mapState({
			rolesIsLoading: state => state.rolesIsLoading
		}),
		...mapGetters({
			items: getterTypes.formattedItems,
			isNoData: getterTypes.isListingEmpty,
			filterRolesItems: getterTypes.filterRolesItems
		}),
		filter() {
			return {
				roles: {
					value: this.internalFilterValues.roles,
					items: this.filterRolesItems
				}
			};
		}
	},
	methods: {
		...mapMutations({
			setFilterRoles: mutationTypes.SET_FILTER_ROLES
		}),
		handleItemSelect({ id }) {
			this.$router.push({ name: RouteNames.OFFICE_EMPLOYEE, params: { employeeId: id, officeId: this.$route.params.id } });
		},
		handleCreateItem() {
			this.$router.push({ name: RouteNames.OFFICE_EMPLOYEE_CREATE, params: { officeId: this.$route.params.id } });
		},
		setInternalFilterRoles(value) {
			this.internalFilterValues.roles = value;
		},
		resetFilter() {
			this.setFilterRoles([]);
			this.isFilterOpened = false;
		},
		setInternalFilterValues() {
			this.internalFilterValues.roles = this.filterValues.roles && [...this.filterValues.roles];
		},
		applyFilter() {
			this.setFilterRoles(this.internalFilterValues.roles);
			this.isFilterOpened = false;
		}
	},
	created() {
		this.initializeStore();
	},
	components: {
		FrpBtn,
		FrpFilterBtn,
		FrpInternalListExtendedLayout,
		FrpStatusLabel,
		FrpTextField,
		FrpFilter,
		FrpIcon,
		FrpCheckboxGroup,
		FrpPagination
	}
};
</script>
