<template>
	<v-container fill-height fluid>
		<v-row align="center"
			   justify="center">
			<v-col>
				<frp-spinner-loader/>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import FrpSpinnerLoader from "@/components/loaders/common/FrpSpinnerLoader.vue";

export default {
	components: { FrpSpinnerLoader }
};
</script>
