import FormState from "@/store/shared/form/models/formState";
import ForeignOrganization
	from "@/store/modules/counterparty/modules/foreignOrganizationCounterparty/types/foreignOrganizationCounterparty";
import IPageState from "@/store/shared/base/types/pageState";

export default class ForeignOrganizationCounterpartyState implements IPageState {
	constructor(
		public form: FormState,
		public counterparty: ForeignOrganization,
		public snapshot: object,
		public isInitialized: boolean,
		public isDestroyed: boolean = false,
		public isCounterpartySuccessCreatedDialogOpened: boolean = false
	)
	{
	}
}


