import {
	namespace,
	actionTypes,
	mutationTypes,
	getterTypes
} from "@/store/modules/counterparty/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import SnapshotOptions from "@/store/shared/snapshot/snapshotOptions";
import stateSnapshotKeys from "@/store/shared/snapshot/keys";
import SnapshotMixinBuilder from "@/store/shared/snapshot";
import CounterpartyState from "@/store/modules/counterparty/types/counterpartyState";
import Counterparty from "@/store/modules/counterparty/types/counterparty";
import foreignOrganizationCounterpartyModule from "@/store/modules/counterparty/modules/foreignOrganizationCounterparty";
import legalEntityCounterpartyModule from "@/store/modules/counterparty/modules/legalEntityCounterparty";
import legalPersonCounterpartyModule from "@/store/modules/counterparty/modules/legalPersonCounterparty";
import physicalPersonCounterpartyModule from "@/store/modules/counterparty/modules/physicalPersonCounterparty";
import counterpartyEmployeesModule from "@/store/modules/counterparty/modules/counterpartyEmployees";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import ApiCounterpartyPersistedBase from "@/api/types/counterparty/apiCounterpartyPersistedBase";
import ApiLegalEntityCounterparty from "@/api/types/counterparty/apiLegalEntityCounterparty";
import ApiLegalPersonCounterparty from "@/api/types/counterparty/apiLegalPersonCounterparty";
import ApiForeignOrganizationCounterparty from "@/api/types/counterparty/apiForeignOrganizationCounterparty";
import ApiPhysicalPersonCounterparty from "@/api/types/counterparty/apiPhysicalPersonCounterparty";
import { formatFullName } from "@/utils/formatting";
import counterpartyMasterAccountModule from "@/store/modules/counterparty/modules/counterpartyMasterAccount";
import { CounterpartyController } from "@/api/counterparty";
import AbortService from "@/services/abortService";
import HttpNotFoundException from "@/exceptions/httpNotFoundException";
import { CounterpartyType } from "@/types/CounterpartyType";
import { i18n } from "@/plugins";
import SearchSuggestionsService from "@/services/searchSuggestion/searchSuggestionsService";
import SearchSuggestion from "@/services/searchSuggestion/searchSuggestion";
import { LAST_VISITED_COUNTERPARTIES } from "@/constants/localStorage";
import OfflineException from "@/exceptions/offlineException";
import alertService, { AlertKeys } from "@/store/modules/alerts/services/alertService";
import ServerUnavailableException from "@/exceptions/serverUnavailableException";
import RequestCancelledException from "@/exceptions/requestCancelledException";
import AccessForbiddenException from "@/exceptions/accessForbiddenException";
import rootTypes from "@/store/types";
import { PageModeType } from "@/store/types/pageModeType";
import { Permissions } from "@/constants/permissions";
import { AuthorizationScopeType } from "@/types/authorization/authorizationScopeType";
import PermissionsService from "@/services/permissionsService";

const abortService = new AbortService();
const counterpartyController = new CounterpartyController(abortService);
const permissionsService = new PermissionsService();
const searchSuggestionService = new SearchSuggestionsService();

const baseMixin = (new BaseMixinBuilder(abortService)).build();
const snapshotMixin = (new SnapshotMixinBuilder({
	options: [
		new SnapshotOptions({
			key: stateSnapshotKeys.LAST_SAVED,
			fields: ["counterparty"]
		})
	]
})).build();

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new CounterpartyState(new Counterparty("", CounterpartyType.LEGAL_ENTITY));
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<CounterpartyState, any>>{};

const actions = <ActionTree<CounterpartyState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit, state }, { id }) {
		await dispatch(actionTypes.initializeBase);

		if(id) {
			await dispatch(actionTypes.fetch, { id });

			const searchSuggestion = new SearchSuggestion(id, state.title);
			searchSuggestionService.add(LAST_VISITED_COUNTERPARTIES, searchSuggestion);
		} else {
			commit(mutationTypes.SET_TITLE, "Добавление контрагента");
		}

		commit(mutationTypes.SET_IS_INITIALIZED, true);
	},
	async [actionTypes.fetch]({ dispatch, commit, state }, { id }) {
		commit(mutationTypes.SET_IS_COUNTERPARTY_LOADING, true);

		try {
			const scope = await permissionsService.check([Permissions.GLOBAL_COUNTERPARTY_READ])
				? AuthorizationScopeType.GLOBAL
				: AuthorizationScopeType.OWN;

			let { counterparty } = await counterpartyController.getCounterparty<ApiCounterpartyPersistedBase>(id,
				ApiCounterpartyPersistedBase,
				scope);

			if(counterparty instanceof ApiLegalEntityCounterparty) {
				commit(mutationTypes.SET_TITLE, counterparty.shortName);
			} else if(counterparty instanceof ApiLegalPersonCounterparty) {
				commit(mutationTypes.SET_TITLE, `${i18n.t("common.legalPerson")} "${counterparty.fullName}"`);
			} else if(counterparty instanceof ApiForeignOrganizationCounterparty) {
				commit(mutationTypes.SET_TITLE, counterparty.longName);
			} else if(counterparty instanceof ApiPhysicalPersonCounterparty) {
				commit(mutationTypes.SET_TITLE, formatFullName({ ...counterparty }));
			}

			commit(mutationTypes.SET_COUNTERPARTY, new Counterparty(id, counterparty.type));
		} catch (error) {
			console.error(error);

			switch (error.constructor) {
				case HttpNotFoundException:
					commit(rootTypes.mutationTypes.SET_PAGE_MODE, PageModeType.PAGE_NOT_FOUND, { root: true });
					break;
				case AccessForbiddenException:
					commit(rootTypes.mutationTypes.SET_PAGE_MODE, PageModeType.ACCESS_DENIED, { root: true });
					break;
				default:
					AlertHelper.handleGeneralRequestErrors(error);
					break;
			}
		} finally {
			commit(mutationTypes.SET_IS_COUNTERPARTY_LOADING, false);
		}
	}
};

const mutations = <MutationTree<CounterpartyState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	...snapshotMixin.mutations,
	[mutationTypes.SET_COUNTERPARTY](state, value) {
		state.counterparty = value;
	},
	[mutationTypes.SET_ID](state, value) {
		state.counterparty.id = value;
	},
	[mutationTypes.SET_TITLE](state, value) {
		state.title = value;
	},
	[mutationTypes.SET_COUNTERPARTY_TYPE](state, value) {
		state.counterparty.type = value;
	},
	[mutationTypes.SET_IS_COUNTERPARTY_LOADING](state, value) {
		state.isCounterpartyLoading = value;
	},
	[mutationTypes.SET_IS_COUNTERPARTY_LOADING](state, value) {
		state.isCounterpartyLoading = value;
	}
};


const modules = {
	[legalPersonCounterpartyModule.namespace]: {
		...legalPersonCounterpartyModule
	},
	[physicalPersonCounterpartyModule.namespace]: {
		...physicalPersonCounterpartyModule
	},
	[foreignOrganizationCounterpartyModule.namespace]: {
		...foreignOrganizationCounterpartyModule
	},
	[legalEntityCounterpartyModule.namespace]: {
		...legalEntityCounterpartyModule
	},
	[counterpartyEmployeesModule.namespace]: {
		...counterpartyEmployeesModule
	},
	[counterpartyMasterAccountModule.namespace]: {
		...counterpartyMasterAccountModule
	}
};

const subscribe = (store: any) => {
	legalEntityCounterpartyModule.subscribe(store);
	legalPersonCounterpartyModule.subscribe(store);
	physicalPersonCounterpartyModule.subscribe(store);
	counterpartyMasterAccountModule.subscribe(store);
};

export {
	namespace, state, getters, actions, mutations, modules, subscribe
};

const counterpartyModule = {
	namespace, state, getters, actions, mutations, modules, subscribe, namespaced: true
};

export default counterpartyModule;
