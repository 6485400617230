<template>
	<frp-form-card v-if="isInitialized">
		<v-form :ref="refs.form" v-model="formValid">
			<frp-form-card-blocks>
				<frp-form-card-block :title="$t('titles.general')">
					<frp-form-card-block-row>
						<frp-form-card-block-col cols="12" sm="6" md="4" :class="{ 'pb-0': $vuetify.breakpoint.xsOnly }">
							<frp-form-item>
								<frp-text-field :label="$t('fields.lastName.label')"
												required
												name="lname"
												autocomplete="family-name"
												:rules="validation.lastName"
												v-model="lastName"
												:disabled="!hasEditPermissions"
												:placeholder="$t('fields.lastName.placeholder')">
								</frp-text-field>
							</frp-form-item>
							<frp-form-item>
								<frp-text-field :label="$t('fields.firstName.label')"
												required
												name="fname"
												autocomplete="given-name"
												:rules="validation.firstName"
												v-model="firstName"
												:disabled="!hasEditPermissions"
												:placeholder="$t('fields.firstName.placeholder')">
								</frp-text-field>
							</frp-form-item>
							<frp-form-item>
								<frp-text-field :label="$t('fields.middleName.label')"
												:rules="validation.middleName"
												v-model="middleName"
												name="mname"
												autocomplete="additional-name"
												:disabled="!hasEditPermissions"
												:placeholder="$t('fields.middleName.placeholder')">
								</frp-text-field>
							</frp-form-item>
							<frp-form-item>
								<frp-text-field :label="$t('fields.email.label')"
												required
												name="email"
												autocomplete="email"
												:rules="validation.email"
												v-model="email"
												:disabled="!hasEditPermissions || !isCreationMode"
												:placeholder="$t('fields.email.placeholder')">
								</frp-text-field>
							</frp-form-item>
							<frp-form-item>
								<frp-phone-field :label="$t('fields.phone.label')"
												 required
												 name="phone"
												 autocomplete="mobile phone"
												 :rules="validation.phone"
												 v-model.trim="phone"
												 :disabled="!hasEditPermissions"
												 :placeholder="$t('fields.phone.placeholder')">
								</frp-phone-field>
							</frp-form-item>
						</frp-form-card-block-col>

						<frp-form-card-block-col offset-md="2" cols="12" sm="6" md="4" :class="{ 'pt-0': $vuetify.breakpoint.xsOnly }">
							<template v-if="isSnilsUploadAvailable">
								<frp-form-item v-if="can(Permissions.GLOBAL_COUNTERPARTY_EMPLOYEE_SNILS_RECOGNIZE)">
									<frp-text-field :tooltip-text="$t('common.tooltipTextSnils')"
													:label="$t('fields.employeeSnils.label')"
													autocomplete="off"
													:mask="SNILS_FORMAT"
													:rules="validation.headSnils"
													v-model="snils"
													:placeholder="$t('fields.employeeSnils.placeholderInput')">
									</frp-text-field>
								</frp-form-item>
								<frp-form-item>
									<frp-embedded-snils-form :readonly="!employeeId"
															 v-if="can([Permissions.GLOBAL_COUNTERPARTY_EMPLOYEE_SNILS_FILE_READ, Permissions.OWN_COUNTERPARTY_EMPLOYEE_SNILS_FILE_READ])"
															 :create-permissions="[Permissions.GLOBAL_COUNTERPARTY_EMPLOYEE_SNILS_FILE_CREATE, Permissions.OWN_COUNTERPARTY_EMPLOYEE_SNILS_FILE_CREATE]"
															 :update-permissions="[Permissions.GLOBAL_COUNTERPARTY_EMPLOYEE_SNILS_FILE_UPDATE, Permissions.OWN_COUNTERPARTY_EMPLOYEE_SNILS_FILE_UPDATE]"
															 :edit-mode-text="$t('content.uploadScanOfSnilsDocumentAnEmployeeOfOrganization')"
															 :readonly-text="$t('content.availableAfterSaving')"
															 class="mb-1"
															 :namespace="snilsNamespace">
									</frp-embedded-snils-form>
								</frp-form-item>
							</template>

							<frp-text-field v-else
											:tooltip-text="$t('common.tooltipTextSnils')"
											:label="$t('fields.snils.label')"
											:mask="SNILS_FORMAT"
											name="snils"
											autocomplete="snils"
											:rules="validation.snils"
											v-model="snils"
											:disabled="!hasEditPermissions"
											:placeholder="$t('fields.snils.placeholderInput')">
							</frp-text-field>

							<frp-form-item>
								<frp-text-field :label="$t('fields.position.label')"
												required
												name="organization-title"
												autocomplete="organization-title"
												:rules="validation.position"
												v-model="position"
												:disabled="!hasEditPermissions"
												:placeholder="$t('fields.position.placeholder')">
								</frp-text-field>
							</frp-form-item>
						</frp-form-card-block-col>
					</frp-form-card-block-row>
				</frp-form-card-block>
			</frp-form-card-blocks>

			<frp-form-card-blocks>
				<frp-form-card-block :title="$t('titles.roles')">
					<frp-form-card-block-row class="pb-8">
						<frp-form-card-block-col cols="12">
							<frp-checkbox-group :items="[{ value: 0, text: $t('content.hasAccessToLoanAgreements') }]"
												:disabled="!hasEditPermissions"
												v-model="roles"
												columns="5"
												gap="16">
							</frp-checkbox-group>
						</frp-form-card-block-col>
					</frp-form-card-block-row>
				</frp-form-card-block>
			</frp-form-card-blocks>

			<template v-if="isCreationMode">
				<frp-form-card-blocks>
					<frp-form-card-block-row class="pb-8">
						<frp-form-card-block-col cols="12">
							<frp-text-caption>
								{{ $t("aliases.userAgreement.pushingButton") }} "{{ $t("buttons.add") }}",
								{{ $t("aliases.userAgreement.iGiveMyConsentToProcessingMyPersonalData") }}
							</frp-text-caption>
							<frp-text-caption>
								{{ $t("aliases.userAgreement.onPersonalData") }}
								<a :href="consentToProcessingPersonalDataLink" target="_blank" class="frp-link">
									{{ $t("aliases.userAgreement.consentToProcessingMyPersonalData") }}
								</a>
							</frp-text-caption>
						</frp-form-card-block-col>
					</frp-form-card-block-row>
				</frp-form-card-blocks>
			</template>
		</v-form>

		<frp-form-actions v-if="hasEditPermissions"
						  :is-save-disabled="!stateContainsUnsavedChanges || !isFormValid"
						  :is-cancel-disabled="!stateContainsUnsavedChanges"
						  :is-loading-state="isFormSaving || isFormLoadingState"
						  @cancel="onFormCancel"
						  @submit="submit">

			<template v-if="isCreationMode" #submit-action>
				<frp-btn @click="handleCreationFormSubmit"
						 color="secondary"
						 :loading="isFormSaving || isFormLoadingState"
						 :disabled="!stateContainsUnsavedChanges || !isFormValid">
					{{ $t("buttons.add") }}
				</frp-btn>
			</template>
		</frp-form-actions>

		<frp-dialog v-model="isMasterAccountExitDialogOpened" :title="$t('dialogs.masterAccountExit.title')" title-center>
			<template #content>
				<v-row>
					<v-col>
						<span class="primary--text text-body-2">{{ $t("dialogs.masterAccountExit.textPart1") }}</span>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<span class="primary--text text-body-2">{{ $t("dialogs.masterAccountExit.textPart2") }}</span>
					</v-col>
				</v-row>
			</template>

			<template #footer>
				<frp-btn outlined
						 @click="goToCounterpartyEmployeesPage"
						 color="primary">
					{{ $t("buttons.addMoreUsers") }}
				</frp-btn>
				<frp-btn elevation="0"
						 :loading="isFormSaving"
						 @click="signOut()">
					{{ $t("buttons.goToPersonalAccount") }}
				</frp-btn>
			</template>
		</frp-dialog>
	</frp-form-card>
	<frp-counterparty-employee-general-loader v-else></frp-counterparty-employee-general-loader>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import FrpDropdownBtn from "@/components/buttons/FrpDropdownBtn";
import FrpDialog from "@/components/dialogs/FrpDialog.vue";
import FrpEmbeddedSnilsForm from "@/components/embeddedForms/FrpEmbeddedSnilsForm";
import { Permissions } from "@/constants/permissions";
import { i18n } from "@/plugins";
import { RouteNames } from "@/router/routes";
import {
	JoiningEmployeeToCounterpartyMethodType
} from "@/store/modules/counterpartyEmployee/modules/general/types/JoiningEmployeeToCounterpartyMethodType";
import FrpTextCaption from "Components/typography/FrpTextCaption";
import FrpCountryAutocomplete from "Components/fields/FrpCountryAutocomplete";
import FrpRadioCardGroup from "Components/fields/FrpRadioCardGroup";
import FrpTextField from "Components/fields/FrpTextField";
import FrpFormActions from "Components/form/FrpFormActions";
import FrpNestedContentLayout from "Components/layouts/FrpNestedContentLayout";
import FrpBottomSpace from "Components/layouts/FrpBottomSpace";
import FrpFormCard from "Components/markup/FrpFormCard";
import FrpFormCardBlock from "Components/markup/FrpFormCardBlock";
import FrpFormCardBlockCol from "Components/markup/FrpFormCardBlockCol";
import FrpFormCardBlockRow from "Components/markup/FrpFormCardBlockRow";
import FrpFormCardBlocks from "Components/markup/FrpFormCardBlocks";
import FrpFormItem from "Components/markup/FrpFormItem";
import FrpTextarea from "Components/fields/FrpTextarea";
import FrpCheckboxGroup from "Components/fields/FrpCheckboxGroup";
import FrpFileField from "Components/fields/FrpFileField";
import authorizationMixin from "Mixins/authorizationMixin";
import storeManager from "Store/manager";
import FrpCounterpartyLoader from "Views/counterparty/FrpCounterpartyLoader";
import FrpPhoneField from "Components/fields/FrpPhoneField";
import FrpSwitch from "@/components/fields/FrpSwitch";
import colorsMixin from "Mixins/colorsMixin";
import formMixin from "Mixins/formMixin";
import storeModuleBasedPage from "Mixins/storeModuleBasedPage";
import { actionTypes, mutationTypes } from "Store/modules/counterpartyEmployee/modules/general/types";
import { SNILS_FORMAT } from "Utils/formats";
import { prepareMaxLengthRule, prepareEmailRule, prepareSnilsRule } from "Utils/validation";
import FrpCounterpartyEmployeeGeneralLoader from "Views/counterpartyEmployee/FrpCounterpartyEmployeeGeneralLoader";
import { createNamespacedHelpers } from "vuex";
import { masks } from "Constants/masks";
import urls, { urlTemplateParts } from "@/api/config/urls";

const namespace = storeManager.counterpartyEmployee.general.namespace;
const { mapState, mapMutations, mapActions } = createNamespacedHelpers(namespace);
const snilsNamespace = storeManager.counterpartyEmployee.general.snils.namespace;
const snilsHelpers = createNamespacedHelpers(snilsNamespace);

export default {
	mixins: [storeModuleBasedPage, colorsMixin, formMixin, authorizationMixin],
	data() {
		return {
			snilsNamespace,
			joiningEmployeeToCounterpartyMethodValue: JoiningEmployeeToCounterpartyMethodType.REGISTER,
			SNILS_FORMAT,
			namespace,
			RouteNames,
			masks,
			validation: {
				firstName: [prepareMaxLengthRule({ maxLength: 100 })],
				lastName: [prepareMaxLengthRule({ maxLength: 100 })],
				middleName: [prepareMaxLengthRule({ maxLength: 100 })],
				email: [prepareEmailRule()],
				phone: [],
				snils: [prepareSnilsRule()],
				position: [prepareMaxLengthRule({ maxLength: 100 })]
			},
			userAgreementFileId: process.env.VUE_APP_USER_AGREEMENT_FILE_ID,
			consentToProcessingPersonalDataLink: process.env.VUE_APP_OLK_CONSENT_TO_PROCESSING_PERSONAL_DATA_LINK,
			isResetValidationDisabled: true
		};
	},
	computed: {
		...mapState({
			counterparty: state => state.counterparty,
			employeeId: state => state.id,
			employee: state => state.employee,
			isSnilsRecognizing: state => state.isSnilsRecognizing,
			isInitialized: state => state.isInitialized,
			isSnilsUploadAvailable: state => state.isSnilsUploadAvailable,
			lastSavedSnilsFileId: state => state.lastSavedSnilsFileId,
			isMasterAccountExitDialogOpened: state => state.isMasterAccountExitDialogOpened
		}),
		hasEditPermissions() {
			return this.isCreationMode ?
				this.can([Permissions.GLOBAL_COUNTERPARTY_EMPLOYEE_CREATE, Permissions.OWN_COUNTERPARTY_EMPLOYEE_CREATE]) :
				this.can([Permissions.GLOBAL_COUNTERPARTY_EMPLOYEE_UPDATE, Permissions.OWN_COUNTERPARTY_EMPLOYEE_UPDATE]);
		},
		...snilsHelpers.mapState({
			snilsFileMeta: state => state.snilsFileMeta
		}),
		isCreateMode() {
			return !this.$route.params.employeeId;
		},
		snils: {
			get() {
				return this.employee.snils;
			},
			set(value) {
				this.setSnils(value);
			}
		},
		position: {
			get() {
				return this.employee.position;
			},
			set(value) {
				this.setPosition(value);
			}
		},
		firstName: {
			get() {
				return this.employee.firstName;
			},
			set(value) {
				this.setFirstName(value);
			}
		},
		lastName: {
			get() {
				return this.employee.lastName;
			},
			set(value) {
				this.setLastName(value);
			}
		},
		middleName: {
			get() {
				return this.employee.middleName;
			},
			set(value) {
				this.setMiddleName(value);
			}
		},
		email: {
			get() {
				return this.employee.email;
			},
			set(value) {
				this.setEmail(value);
			}
		},
		phone: {
			get() {
				return this.employee.phone;
			},
			set(value) {
				this.setPhone(value);
			}
		},
		roles: {
			// временно, пока роли не станут массивом
			get() {
				return this.employee.hasLoanAgreementsAccess ? [0] : [];
			},
			set(selectedRoleIds) {
				this.setHasLoanAgreementsAccess(!!selectedRoleIds.length);
			}
		},
		isCreationMode() {
			return this.$route.name === RouteNames.COUNTERPARTY_EMPLOYEE_CREATE;
		}
	},
	methods: {
		...mapActions({
			save: actionTypes.save,
			createEmployee: actionTypes.create
		}),
		...mapMutations({
			setSnils: mutationTypes.SET_EMPLOYEE_SNILS,
			setFirstName: mutationTypes.SET_EMPLOYEE_FIRST_NAME,
			setLastName: mutationTypes.SET_EMPLOYEE_LAST_NAME,
			setMiddleName: mutationTypes.SET_EMPLOYEE_MIDDLE_NAME,
			setPosition: mutationTypes.SET_EMPLOYEE_POSITION,
			setEmail: mutationTypes.SET_EMPLOYEE_EMAIL,
			setPhone: mutationTypes.SET_EMPLOYEE_PHONE,
			setHasLoanAgreementsAccess: mutationTypes.SET_EMPLOYEE_HAS_LOAN_AGREEMENTS_ACCESS,
			setIsMasterAccountExitDialogOpened: mutationTypes.SET_IS_MASTER_ACCOUNT_EXIT_DIALOG_OPENED
		}),
		async handleCreationFormSubmit() {
			await this.createEmployee({
				joiningEmployeeToCounterpartyMethod: this.joiningEmployeeToCounterpartyMethodValue
			});
		},
		async goToCounterpartyEmployeesPage() {
			await this.$router.push({ name: RouteNames.COUNTERPARTY_EMPLOYEES, params: { id: this.$route.params.counterpartyId } }).catch(() => {});
		}
	},
	created() {
		this.initializeStore({ id: this.$route.params.employeeId, counterpartyId: this.$route.params.counterpartyId });
	},
	components: {
		FrpDialog,
		FrpBtn,
		FrpEmbeddedSnilsForm,
		FrpDropdownBtn,
		FrpTextCaption,
		FrpFormActions,
		FrpCountryAutocomplete,
		FrpRadioCardGroup,
		FrpTextField,
		FrpFormItem,
		FrpFormCardBlockCol,
		FrpFormCardBlockRow,
		FrpFormCardBlock,
		FrpFormCardBlocks,
		FrpFormCard,
		FrpNestedContentLayout,
		FrpCounterpartyLoader,
		FrpBottomSpace,
		FrpTextarea,
		FrpPhoneField,
		FrpCounterpartyEmployeeGeneralLoader,
		FrpCheckboxGroup,
		FrpSwitch,
		FrpFileField
	}
};
</script>

<style scoped>
.frp-link {
	text-decoration: none !important;
	cursor: pointer !important;
	color: #FB104A !important;
}
</style>
