export default class ApiLegalEntityHeadSnilsCreateRequest {
	legalEntityId: string;
	legalEntityHeadId: string;
	temporaryFileId: string;

	constructor(legalEntityId: string,
		legalEntityHeadId: string,
		temporaryFileId: string)
	{
		this.legalEntityId = legalEntityId;
		this.legalEntityHeadId = legalEntityHeadId;
		this.temporaryFileId = temporaryFileId;
	}
}
