<template>
	<frp-nested-content-layout>
		<template #title>
			<span class="text-h5 primary--text text--darken-1">
				<frp-details-title-loader></frp-details-title-loader>
			</span>
		</template>
		<template #content>
			<v-card class="pa-8 pb-1 d-flex align-start flex-wrap"
					:style="{ 'column-gap': $vuetify.breakpoint.lgAndDown ? '56px' : '128px' }" elevation="0">
				<v-row class="d-flex flex-wrap">
					<frp-details-section-column>
						<frp-details-group>
							<frp-details-section-title-loader></frp-details-section-title-loader>
							<frp-details-item-loader v-for="i in 4" :key="i"/>
						</frp-details-group>
					</frp-details-section-column>
					<frp-details-section-column>
						<frp-details-group>
							<frp-details-section-title-loader></frp-details-section-title-loader>
							<frp-details-item-loader v-for="i in 6" :key="i"/>
						</frp-details-group>
					</frp-details-section-column>
					<frp-details-section-column>
						<frp-details-group>
							<frp-details-section-title-loader></frp-details-section-title-loader>
							<frp-details-item-loader v-for="i in 2" :key="i"/>
						</frp-details-group>
					</frp-details-section-column>
				</v-row>
			</v-card>
		</template>
		<template #actions>

		</template>
	</frp-nested-content-layout>
</template>

<script>
import FrpDetailsItemLoader from "Components/loaders/details/FrpDetailsItemLoader";
import FrpDetailsGroup from "Components/details/FrpDetailsGroup";
import FrpDetailsSectionTitleLoader from "Components/loaders/details/FrpDetailsSectionTitleLoader";
import FrpFileLoader from "Components/loaders/common/FrpFileLoader";
import FrpDetailsSectionColumn from "Components/details/FrpDetailsSectionColumn";
import FrpDetailsTitleLoader from "Components/loaders/details/FrpDetailsTitleLoader";
import FrpNestedContentLayout from "Components/layouts/FrpNestedContentLayout";

export default {
	components: {
		FrpDetailsTitleLoader,
		FrpNestedContentLayout,
		FrpDetailsItemLoader,
		FrpDetailsGroup,
		FrpDetailsSectionTitleLoader,
		FrpFileLoader,
		FrpDetailsSectionColumn
	}
};
</script>
