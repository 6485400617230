import { render, staticRenderFns } from "./FrpLegalPersonDetails.vue?vue&type=template&id=26e1a554"
import script from "./FrpLegalPersonDetails.vue?vue&type=script&lang=js"
export * from "./FrpLegalPersonDetails.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports