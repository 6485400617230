import {
	namespace,
	actionTypes,
	mutationTypes,
	getterTypes
} from "@/store/modules/counterparty/modules/counterpartyEmployees/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import SortingMixinBuilder from "@/store/shared/sorting";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import ListingMixinBuilder from "@/store/shared/listing";
import ListingModel from "@/store/shared/listing/models/listingModel";
import PagingMixinBuilder from "@/store/shared/paging";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchMixinBuilder from "@/store/shared/search";
import SearchModel from "@/store/shared/search/models/searchModel";
import { sortingOrderType } from "@/store/shared/sorting/models/types/sortingOrderType";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import CounterpartyEmployeesState from "@/store/modules/counterparty/modules/counterpartyEmployees/types/counterpartyEmployeesState";
import ApiCounterpartyEmployeePersistedBase from "@/api/types/counterparty/apiCounterpartyEmployeePersistedBase";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import { convertIsoToNumber, formatDate } from "@/utils/dates";
import { dateTimeFormat } from "@/utils/formats";
import { formatFullName } from "@/utils/formatting";
import { CounterpartyController } from "@/api/counterparty";
import AbortService from "@/services/abortService";
import { Permissions } from "@/constants/permissions";
import { AuthorizationScopeType } from "@/types/authorization/authorizationScopeType";
import PermissionsService from "@/services/permissionsService";
import ApiCounterpartyEmployeeWithTrustInfo from "@/api/types/counterparty/ApiCounterpartyEmployeeWithTrustInfo";

const abortService = new AbortService();
const counterpartyController = new CounterpartyController(abortService);
const permissionsService = new PermissionsService();

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new CounterpartyEmployeesState(
			new ListingModel<ApiCounterpartyEmployeeWithTrustInfo>({
				items: [],
				isLoadingState: false
			}),
			new SortingModel<String>({
				type: "number",
				order: sortingOrderType.descending
			}),
			new PagingModel({
				total: 0,
				page: 1,
				pageSize: 25
			}),
			new SearchModel({
				query: ""
			})
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();
const baseMixin = (new BaseMixinBuilder(abortService)).build();
const listingMixin = (new ListingMixinBuilder()).build();
const pagingMixin = (new PagingMixinBuilder()).build();
const sortingMixin = (new SortingMixinBuilder()).build();
const searchMixin = (new SearchMixinBuilder()).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<CounterpartyEmployeesState, any>>{
	...listingMixin.getters,
	[getterTypes.formattedItems]: state => {
		return state.listing.items.map(({ employee, trust }) => {
			return {
				id: employee.id,
				fullName: formatFullName({ ...employee.employee }),
				email: employee.employee.email,
				position: employee.employee.position,
				isActive: employee.employee.isActive,
				createdAt: formatDate(convertIsoToNumber(employee.createdAt), dateTimeFormat),
				number: employee.number,
				trust: trust.status
			};
		});
	}
};

const actions = <ActionTree<CounterpartyEmployeesState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...listingMixin.actions,
	...pagingMixin.actions,
	...sortingMixin.actions,
	...searchMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit }, { id }) {
		await dispatch(actionTypes.initializeBase);

		await dispatch(actionTypes.updateListingItems, { id });

		commit(mutationTypes.SET_IS_INITIALIZED, true);
	},
	async [actionTypes.updateListingItems]({ state, rootState, commit, dispatch }, { id }) {
		commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, true);

		try {
			const scope = await permissionsService.check([Permissions.GLOBAL_COUNTERPARTY_EMPLOYEES_READ])
				? AuthorizationScopeType.GLOBAL
				: AuthorizationScopeType.OWN;

			let employees: ApiCounterpartyEmployeeWithTrustInfo[] = await counterpartyController.getCounterpartyEmployees(id, scope);
			commit(mutationTypes.SET_LISTING_ITEMS, employees);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, false);
		}
	}
};


const mutations = <MutationTree<CounterpartyEmployeesState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	...listingMixin.mutations,
	...pagingMixin.mutations,
	...sortingMixin.mutations,
	...searchMixin.mutations
};

const subscribe = (store: any) => {

};

export {
	namespace, state, getters, actions, mutations, subscribe
};

const counterpartyEmployeesModule = {
	namespace, state, getters, actions, mutations, namespaced: true, subscribe
};

export default counterpartyEmployeesModule;
