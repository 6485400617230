import ApiApp from "@/api/types/app/apiApp";
import IPageState from "@/store/shared/base/types/pageState";

export default class AppsState implements IPageState {
	constructor(
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public items: ApiApp[] = [],
		public isLoading: boolean = false
	)
	{
	}
}
