<template>
	<frp-form-card-block-row>
		<frp-form-card-block-col cols="12" sm="6" md="5">
			<v-fade-transition hide-on-leave>
				<template v-if="formMode === EmbeddedFormModeType.DETAILS">
					<frp-details-item v-if="formEditMode === EmbeddedFormEditModeType.UPDATE"
									  :title="$t('fields.password.label')"
									  value="* * * * * * * * * * *"
									  style="width: 350px; min-width: fit-content">
						<template #append>
							<frp-text-btn text="Изменить" @click="switchToEditMode"></frp-text-btn>
						</template>
					</frp-details-item>
				</template>
			</v-fade-transition>
			<v-fade-transition hide-on-leave>
				<v-card v-if="formMode === EmbeddedFormModeType.EDIT || formMode === EmbeddedFormModeType.SUCCESS"
						outlined
						class="mb-8">
					<v-card-title class="text-subtitle-1" v-if="formMode === EmbeddedFormModeType.EDIT">
						Изменение пароля
					</v-card-title>
					<v-card-text>
						<v-form v-if="EmbeddedFormEditModeType.UNKNOWN !== formEditMode"
								:readonly="isFormSaving || isFormReadonly"
								:disabled="isFormDisabled"
								:ref="refs.form"
								v-model="formValid">
							<v-container fluid class="pa-0">
								<frp-form-card-block-row>
									<frp-form-card-block-col v-if="EmbeddedFormModeType.SUCCESS === formMode"
															 class="d-flex flex-column align-center">
										<frp-icon src="ico_update-data-success" class="mb-5 mt-3"></frp-icon>
										<span class="primary--text text-body-2 text-center">{{ successText }}</span>
									</frp-form-card-block-col>
									<frp-form-card-block-col v-else-if="EmbeddedFormModeType.EDIT === formMode">
										<span class="text-body-2 primary--text d-inline-block mb-4">{{ editModeText }}</span>

										<frp-text-field :placeholder="$t('fields.newPassword.placeholder')"
														:label="$t('fields.newPassword.label')"
														autocomplete="new-password"
														name="password"
														required
														:type="isPasswordHidden ? 'password' : 'text'"
														:append-icon="isPasswordHidden ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
														@click:append="isPasswordHidden = !isPasswordHidden"
														:rules="validation.password"
														v-model.trim="internalPassword">
										</frp-text-field>

										<frp-text-field :placeholder="$t('fields.confirmedPassword.placeholder')"
														:label="$t('fields.confirmedPassword.label')"
														autocomplete="new-password"
														name="password"
														required
														:type="isConfirmedPasswordHidden ? 'password' : 'text'"
														:append-icon="isConfirmedPasswordHidden ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
														@click:append="isConfirmedPasswordHidden = !isConfirmedPasswordHidden"
														:rules="validation.confirmedPassword"
														v-model.trim="internalConfirmedPassword">
										</frp-text-field>
									</frp-form-card-block-col>
								</frp-form-card-block-row>
								<frp-form-card-block-row>
									<frp-form-card-block-col class="d-flex justify-end">
										<template v-if="EmbeddedFormModeType.EDIT === formMode">
											<frp-btn @click="switchToDetailsMode"
													 color="primary"
													 outlined>
												{{ $t("buttons.cancel") }}
											</frp-btn>

											<frp-btn :disabled="!formValid || isFormReadonly"
													 :loading="isFormSaving"
													 @click="submit"
													 elevation="0">
												{{ $t("buttons.send") }}
											</frp-btn>
										</template>

										<frp-btn v-else-if="EmbeddedFormModeType.SUCCESS === formMode"
												 @click="switchToDetailsMode"
												 elevation="0">
											{{ $t("buttons.close") }}
										</frp-btn>
									</frp-form-card-block-col>
								</frp-form-card-block-row>
							</v-container>
						</v-form>
					</v-card-text>
				</v-card>
			</v-fade-transition>
		</frp-form-card-block-col>
	</frp-form-card-block-row>
</template>

<script>
import FrpBtn from "Components/buttons/FrpBtn";
import FrpTextBtn from "Components/buttons/FrpTextBtn";
import FrpDetailsItem from "Components/details/FrpDetailsItem";
import FrpTextField from "Components/fields/FrpTextField";
import FrpIcon from "Components/icon/FrpIcon";
import FrpFormCardBlock from "Components/markup/FrpFormCardBlock";
import FrpFormCardBlockCol from "Components/markup/FrpFormCardBlockCol";
import FrpFormCardBlockRow from "Components/markup/FrpFormCardBlockRow";
import FrpFormCardBlocks from "Components/markup/FrpFormCardBlocks";
import embeddedFormMixin from "Mixins/embeddedFormMixin";
import { mutationTypes, actionTypes } from "Store/shared/embeddedPasswordForm/types";
import { EmbeddedFormEditModeType } from "Store/shared/embeddedForm/types/embeddedFormEditModeType";
import { EmbeddedFormModeType } from "Store/shared/embeddedForm/types/embeddedFormModeType";
import { prepareConfirmedPasswordRule, preparePasswordRule } from "Utils/validation";
import { mapInstanceMutations, mapInstanceState } from "Utils/vuexMapInstanse";

export default {
	mixins: [embeddedFormMixin],
	props: {
		namespace: {
			required: true
		},
		successText: {
			default: "Пароль успешно изменён. На указанный адрес электронной почты было направлено письмо с новым паролем."
		},
		editModeText: {
			default: "Укажите новый пароль. После изменения на ваш адрес электронной почты будет направлено письмо с новым паролем."
		}
	},
	data() {
		return {
			EmbeddedFormEditModeType,
			EmbeddedFormModeType,
			validation: {
				password: [preparePasswordRule()],
				confirmedPassword: [prepareConfirmedPasswordRule(() => this.password)]
			},
			isPasswordHidden: true,
			isConfirmedPasswordHidden: true
		};
	},
	computed: {
		...mapInstanceState({
			password: state => state.password,
			confirmedPassword: state => state.confirmedPassword,
			form: state => state.form
		}),
		internalPassword: {
			get() {
				return this.password;
			},
			set(value) {
				this.setPassword(value);
			}
		},
		internalConfirmedPassword: {
			get() {
				return this.confirmedPassword;
			},
			set(value) {
				this.setConfirmedPassword(value);
			}
		}
	},
	methods: {
		...mapInstanceMutations({
			setPassword: mutationTypes.SET_PASSWORD,
			setConfirmedPassword: mutationTypes.SET_CONFIRMED_PASSWORD
		}),
		switchToEditMode() {
			this.setFormMode(EmbeddedFormModeType.EDIT);
		},
		switchToDetailsMode() {
			this.setFormMode(EmbeddedFormModeType.DETAILS);
		}
	},
	components: {
		FrpIcon,
		FrpBtn,
		FrpTextBtn,
		FrpDetailsItem,
		FrpFormCardBlockCol,
		FrpFormCardBlockRow,
		FrpFormCardBlock,
		FrpFormCardBlocks,
		FrpTextField
	}
};
</script>
