import ApiCounterpartyPersistedBase from "@/api/types/counterparty/apiCounterpartyPersistedBase";
import ApiLegalPersonCounterparty from "@/api/types/counterparty/apiLegalPersonCounterparty";

export default class ApiLegalPersonCounterpartyPersisted extends ApiCounterpartyPersistedBase {
	counterparty: ApiLegalPersonCounterparty;

	constructor(id: string = "",
		createdAt: string = "",
		updatedAt: string = "",
		counterparty: ApiLegalPersonCounterparty = new ApiLegalPersonCounterparty())
	{
		super(id, createdAt, updatedAt, counterparty);

		this.counterparty = counterparty;
	}
}
