import ApiCounterparty from "@/api/types/counterparty/apiCounterparty";
import {plainToInstance} from "class-transformer";
import Counterparty from "@/store/modules/counterparties/types/counterparty";
import CounterpartiesState from "@/store/modules/counterparties/types/counterpartiesState";
import ApiGetCounterpartiesParameters from "@/api/types/counterparty/apiGetCounterpartiesParameters";

export const mapCounterparty = (counterparty: ApiCounterparty) => {
	return plainToInstance(Counterparty, counterparty);
}

export const mapApiGetCounterpartiesParameters = (state: CounterpartiesState) => {
	return new ApiGetCounterpartiesParameters(state.paging.pageSize,
		(state.paging.pageSize * (state.paging.page - 1)),
		state.search.query,
		state.sorting.type,
		state.sorting.order,
		state.filter.type);
};
