import Counterparty from "@/store/modules/counterparty/types/counterparty";
import { CounterpartyType } from "@/types/CounterpartyType";
import IPageState from "@/store/shared/base/types/pageState";

export default class CounterpartyState implements IPageState {
	constructor(
		public counterparty: Counterparty = new Counterparty("", CounterpartyType.UNKNOWN),
		public isCounterpartyLoading: boolean = false,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public title: string = "",
		public shortTitle: string = ""
	)
	{
	}
}
