import urls, { urlTemplateParts } from "@/api/config/urls";
import { plainToInstance } from "class-transformer";
import { prepareUrl } from "@/utils/prepareUrlQuery";
import ApiOffice from "@/api/types/office/apiOffice";
import ApiOfficeEmployeePersistedBase from "@/api/types/office/apiOfficeEmployeePersistedBase";
import ApiOfficePersistedBase from "@/api/types/office/apiOfficePersisted";
import { ApiOfficeEmployeeBase } from "@/api/types/office/apiOfficeEmployee";
import ApiGetOfficesParameters from "@/api/types/office/apiGetOfficesParameters";
import ApiOffices from "@/api/types/office/apiOffices";
import ApiOfficeEmployeeRoles from "@/api/types/roles/apiOfficeEmployeeRoles";
import ApiGetOfficeEmployeesParameters from "@/api/types/office/apiGetOfficeEmployeesParameters";
import ApiOfficeEmployees from "@/api/types/office/apiOfficeEmployees";
import BaseController from "@/api/shared/baseController";
import AbortService from "@/services/abortService";
import HttpNotFoundException from "@/exceptions/httpNotFoundException";
import ApiAccountProfilePersistedBase from "@/api/types/account/apiAccountProfilePersistedBase";
import { i18n } from "@/plugins";

export class OfficeController extends BaseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}

	getOffices = async (parameters: ApiGetOfficesParameters): Promise<ApiOffices> => {
		let data = await this.client.get<{ offices: object[], totalCount: number }>(prepareUrl(urls.office.getOffices, parameters));
		return new ApiOffices(plainToInstance(ApiOfficePersistedBase, data.offices), data.totalCount);
	};

	getOffice = async (id: string): Promise<ApiOfficePersistedBase> => {
		let { officePersisted } =
			await this.client.get<{ officePersisted: object }>(urls.office.getOffice.replace(urlTemplateParts.id, id));
		return plainToInstance(ApiOfficePersistedBase, officePersisted);
	};

	updateOffice = async (id: string, office: ApiOffice): Promise<ApiOffice> => {
		return await this.client.put(urls.office.updateOffice.replace(urlTemplateParts.id, id), office);
	};

	createOffice = async (office: ApiOffice): Promise<string> => {
		return await this.client.post(urls.office.createOffice, office);
	};

	getOfficeEmployees = async (id: string, parameters: ApiGetOfficeEmployeesParameters): Promise<ApiOfficeEmployees> => {
		let data = await this.client.get<{ employees: object[], totalCount: number }>(prepareUrl(urls.office.employee.getItems.replace(
				urlTemplateParts.id,
				id),
			parameters));
		return new ApiOfficeEmployees(plainToInstance(ApiOfficeEmployeePersistedBase, data.employees), data.totalCount);
	};

	getOfficeEmployee = async (id: string, officeId: string): Promise<ApiOfficeEmployeePersistedBase> => {
		const url = urls.office.employee.get
						.replace(urlTemplateParts.id, officeId)
						.replace(urlTemplateParts.subId, id);

		let { employeePersisted } = await this.client.get<{ employeePersisted: object }>(url);

		if(employeePersisted === null)
			throw new HttpNotFoundException(i18n.t("alerts.errors.officeEmployeeNotFound") as string);

		return plainToInstance(ApiOfficeEmployeePersistedBase, employeePersisted);
	};

	getOfficeEmployeeById = async (id: string): Promise<ApiOfficeEmployeePersistedBase> => {
		const url = urls.office.employee.getById.replace(urlTemplateParts.id, id);

		let { employeePersisted } = await this.client.get<{ employeePersisted: object }>(url, {});

		return plainToInstance(ApiOfficeEmployeePersistedBase, employeePersisted);
	};

	updateOfficeEmployee = async (id: string, officeId: string, payload: ApiOfficeEmployeeBase): Promise<void> => {
		return await this.client.put<void>(urls.office.employee.update.replace(urlTemplateParts.id, officeId)
											   .replace(urlTemplateParts.subId, id), payload);
	};

	createOfficeEmployee = async (officeId: string, payload: ApiOfficeEmployeeBase, assignToAccount: boolean = true): Promise<string> => {
		const url = prepareUrl(urls.office.employee.create.replace(urlTemplateParts.id, officeId),
			{ assignToAccount });
		return await this.client.post<string>(url, payload);
	};

	getOfficeEmployeeRoles = async (): Promise<ApiOfficeEmployeeRoles[]> => {
		let { roles } = await this.client.get<{ roles: object[] }>(prepareUrl(urls.office.getRoles));

		return plainToInstance(ApiOfficeEmployeeRoles, roles);
	};

	getOfficeEmployeeProfile = async (officeId: string, id: string): Promise<ApiAccountProfilePersistedBase> => {
		const url = urls.office.employee.getEmployeeProfile
						.replace(urlTemplateParts.id, officeId)
						.replace(urlTemplateParts.subId, id);

		let profilePersisted = await this.client.get<{ profilePersisted: object }>(url);

		return plainToInstance(ApiAccountProfilePersistedBase, profilePersisted);
	};

	activateOfficeEmployee = async (accountId: string, profileId: string) => {
		return await this.client.post(urls.office.employee.activate
										  .replace(urlTemplateParts.id, accountId)
										  .replace(urlTemplateParts.subId, profileId), {});
	};

	deactivateOfficeEmployee = async (accountId: string, profileId: string) => {
		return await this.client.post(urls.office.employee.deactivate
										  .replace(urlTemplateParts.id, accountId)
										  .replace(urlTemplateParts.subId, profileId), {});
	};

}
