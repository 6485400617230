import FormState from "@/store/shared/form/models/formState";
import PhysicalPersonCounterparty from "@/store/modules/counterparty/modules/physicalPersonCounterparty/types/physicalPersonCounterparty";
import IPageState from "@/store/shared/base/types/pageState";

export default class PhysicalPersonCounterpartyState implements IPageState {
	constructor(
		public form: FormState,
		public snapshot: object,
		public counterparty: PhysicalPersonCounterparty = new PhysicalPersonCounterparty(),
		public id: string = "",
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public isCounterpartySuccessCreatedDialogOpened: boolean = false
	)
	{
	}
}
