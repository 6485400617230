<template>
	<v-alert :color="backgroundColor"
			 class="rounded-lg text-body-2 white--text frp-alert py-3 px-2"
			 v-model="internalValue">
		<slot></slot>
		<v-btn v-if="closable" class="ml-4" small icon :style="{color: colors.white.base}" @click="internalValue = false">
			<v-icon>mdi-close</v-icon>
		</v-btn>
		<template #prepend v-if="icon">
			<frp-icon :src="icon" :color="colors.white.base" class="mr-2"></frp-icon>
		</template>
	</v-alert>
</template>

<script>
import FrpIcon from "Components/icon/FrpIcon";
import alertMixin from "Mixins/alertMixin";
import colorsMixin from "Mixins/colorsMixin";

export default {
	mixins: [colorsMixin, alertMixin],
	model: {
		prop: "value",
		event: "update:value"
	},
	props: {
		type: {
			type: String,
			default: "info"
		},
		value: {
			type: Boolean,
			default: true
		},
		closable: Boolean,
		icon: String
	},
	computed: {
		internalValue: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit("update:value", value);
			}
		}
	},
	components: {
		FrpIcon
	}
};
</script>

<style scoped>
.frp-alert {
	width: fit-content;
}
</style>
