export default class ApiLegalEntityFnsUpdate {
	externalId: string;
	isEnabled: boolean;
	lastChecked: string;
	lastUpdated: string;
	inn: string;
	ogrn: string;
	kpp: string;
	type: string;

	constructor(
		externalId: string = "",
		isEnabled: boolean = false,
		lastChecked: string = "",
		lastUpdated: string = "",
		inn: string = "",
		ogrn: string = "",
		kpp: string = "",
		type: string = ""
	)
	{
		this.externalId = externalId;
		this.isEnabled = isEnabled;
		this.lastChecked = lastChecked;
		this.lastUpdated = lastUpdated;
		this.inn = inn;
		this.ogrn = ogrn;
		this.kpp = kpp;
		this.type = type;
	}
}
