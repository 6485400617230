import {
	namespace,
	actionTypes,
	mutationTypes,
	getterTypes
} from "@/store/modules/counterparty/modules/foreignOrganizationCounterparty/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import ForeignOrganizationCounterpartyState
	from "@/store/modules/counterparty/modules/foreignOrganizationCounterparty/types/foreignOrganizationCounterpartyState";
import FormMixinBuilder from "@/store/shared/form";
import SnapshotOptions from "@/store/shared/snapshot/snapshotOptions";
import stateSnapshotKeys from "@/store/shared/snapshot/keys";
import SnapshotMixinBuilder from "@/store/shared/snapshot";
import { plainToInstance } from "class-transformer";
import ForeignOrganizationCounterparty
	from "@/store/modules/counterparty/modules/foreignOrganizationCounterparty/types/foreignOrganizationCounterparty";
import router from "@/router";
import ApiForeignOrganizationCounterparty from "@/api/types/counterparty/apiForeignOrganizationCounterparty";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import alertService, { AlertKeys } from "@/store/modules/alerts/services/alertService";
import ApiForeignOrganizationCounterpartyPersisted from "@/api/types/counterparty/apiForeignOrganizationCounterpartyPersisted";
import { CounterpartyController } from "@/api/counterparty";
import { ForeignOrganizationController } from "@/api/counterparty/foreignOrganization";
import AbortService from "@/services/abortService";
import { RouteNames } from "@/router/routes";
import { Permissions } from "@/constants/permissions";
import { AuthorizationScopeType } from "@/types/authorization/authorizationScopeType";
import PermissionsService from "@/services/permissionsService";

const abortService = new AbortService();
const foreignOrganizationController = new ForeignOrganizationController(abortService);
const counterpartyController = new CounterpartyController(abortService);
const permissionsService = new PermissionsService();

const formMixin = (new FormMixinBuilder()).build();
const baseMixin = (new BaseMixinBuilder(abortService)).build();
const snapshotMixin = (new SnapshotMixinBuilder({
	options: [
		new SnapshotOptions({
			key: stateSnapshotKeys.LAST_SAVED,
			fields: ["counterparty"]
		})
	]
})).build();

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new ForeignOrganizationCounterpartyState(
			formMixin.state(),
			new ForeignOrganizationCounterparty("", "", "", "", ""),
			snapshotMixin.state(),
			false
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<ForeignOrganizationCounterpartyState, any>>{
	...formMixin.getters,
	...snapshotMixin.getters
};

const actions = <ActionTree<ForeignOrganizationCounterpartyState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...formMixin.actions,
	...snapshotMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit, state }, { id }: { id: string | null }) {
		await dispatch(actionTypes.initializeBase);

		if(id)
			await dispatch(actionTypes.fetch, { id });

		commit(mutationTypes.SET_IS_INITIALIZED, true);
		commit(mutationTypes.SET_STATE_SNAPSHOT, stateSnapshotKeys.LAST_SAVED);
	},
	async [actionTypes.fetch]({ dispatch, commit, state }, { id }) {
		commit(mutationTypes.SET_IS_FORM_LOADING, true);

		try {
			const scope = await permissionsService.check([Permissions.GLOBAL_COUNTERPARTY_READ])
				? AuthorizationScopeType.GLOBAL
				: AuthorizationScopeType.OWN;

			let counterpartyPersisted = await counterpartyController.getCounterparty<ApiForeignOrganizationCounterpartyPersisted>(id,
				ApiForeignOrganizationCounterpartyPersisted, scope);

			let counterparty = plainToInstance(ForeignOrganizationCounterparty, counterpartyPersisted.counterparty);

			commit(mutationTypes.SET_COUNTERPARTY, counterparty);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
			commit(mutationTypes.SET_IS_FORM_DISABLED, true);
		} finally {
			commit(mutationTypes.SET_IS_FORM_LOADING, false);
		}
	},
	async [actionTypes.checkIsRecordUnique]({ dispatch, commit, rootState, state }) {
		if(!state.counterparty.longName) {
			commit(mutationTypes.SET_IS_RECORD_UNIQUE, true);
			return;
		}

		try {
			commit(mutationTypes.SET_IS_RECORD_UNIQUE_CHECK_IN_PROGRESS, true);

			let exists = await foreignOrganizationController.checkForeignOrganizationIsExists(state.counterparty.longName);

			commit(mutationTypes.SET_IS_RECORD_UNIQUE, !exists);

		} catch (error) {
			AlertHelper.handleGeneralRequestErrors(error);
			commit(mutationTypes.SET_IS_RECORD_UNIQUE, true);
		} finally {
			commit(mutationTypes.SET_IS_RECORD_UNIQUE_CHECK_IN_PROGRESS, false);
		}
	},
	async [actionTypes.save]({ dispatch, commit, rootState, state }, { id }) {
		commit(mutationTypes.SET_IS_FORM_SAVING, true);

		try {
			if(id) {
				await counterpartyController.updateForeignOrganizationCounterparty(
					id,
					plainToInstance(ApiForeignOrganizationCounterparty, state.counterparty)
				);

				commit(mutationTypes.SET_STATE_SNAPSHOT, stateSnapshotKeys.LAST_SAVED);
				alertService.addInfo(AlertKeys.SUCCESS_UPDATED_INFO);
			} else {
				id = await foreignOrganizationController.createForeignOrganizationCounterparty(
					plainToInstance(ApiForeignOrganizationCounterparty, state.counterparty)
				);

				const hasGlobalPermissionCounterpartyRead = await permissionsService.check([Permissions.GLOBAL_COUNTERPARTY_READ]);

				if(!hasGlobalPermissionCounterpartyRead) {
					commit(mutationTypes.SET_IS_COUNTERPARTY_SUCCESS_CREATED_DIALOG_OPENED, true);
				} else {
					alertService.addInfo(AlertKeys.SUCCESS_CREATED_INFO);
					await router.push({ name: RouteNames.COUNTERPARTY, params: { id } });
				}
			}
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_FORM_SAVING, false);
		}
	}
};

const mutations = <MutationTree<ForeignOrganizationCounterpartyState>>{
	...stateManipulationMixin.mutations,
	...formMixin.mutations,
	...snapshotMixin.mutations,
	...baseMixin.mutations,
	[mutationTypes.SET_COUNTERPARTY](state, value) {
		state.counterparty = value;
	},
	[mutationTypes.SET_COUNTERPARTY_LEGAL_ADDRESS](state, value) {
		state.counterparty.legalAddress = value;
	},
	[mutationTypes.SET_COUNTERPARTY_COUNTRY_CODE](state, value) {
		state.counterparty.countryCode = value;
	},
	[mutationTypes.SET_COUNTERPARTY_SHORT_NAME](state, value) {
		state.counterparty.shortName = value;
	},
	[mutationTypes.SET_COUNTERPARTY_LONG_NAME](state, value) {
		state.counterparty.longName = value;
	},
	[mutationTypes.SET_COUNTERPARTY_IS_RFRP](state, value) {
		state.counterparty.isRfrp = value;
	},
	[mutationTypes.SET_COUNTERPARTY_IS_LEASING_COMPANY](state, value) {
		state.counterparty.isLeasingCompany = value;
	},
	[mutationTypes.SET_COUNTERPARTY_IS_PLEDGE_EXPERT_COMPANY](state, value) {
		state.counterparty.isPledgeExpertCompany = value;
	},
	[mutationTypes.SET_COUNTERPARTY_IS_VKM_EXPERT_COMPANY](state, value) {
		state.counterparty.isVkmExpertCompany = value;
	},
	[mutationTypes.SET_COUNTERPARTY_IS_FRP_ASSIGNEE_COMPANY](state, value) {
		state.counterparty.isFrpAssigneeCompany = value;
	},
	[mutationTypes.SET_IS_COUNTERPARTY_SUCCESS_CREATED_DIALOG_OPENED](state, value) {
		state.isCounterpartySuccessCreatedDialogOpened = value;
	}
};

export {
	namespace, state, getters, actions, mutations
};

const foreignOrganizationCounterpartyModule = {
	namespace, state, getters, actions, mutations, namespaced: true
};

export default foreignOrganizationCounterpartyModule;
