import { IsIn, IsArray } from "class-validator";
import { AVAILABLE_FILTER_STATUS } from "@/store/modules/applications/employeeJoin/list/constants";

export default class EmployeeJoinApplicationsFilter {
	@IsArray()
	@IsIn(AVAILABLE_FILTER_STATUS, {
		each: true
	})
	status: String[];

	constructor(status: String[]) {
		this.status = status;
	}
}
