import { actionTypes, mutationTypes, namespace } from "@/store/modules/counterpartyEmployee/modules/general/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import FormMixinBuilder from "@/store/shared/form";
import SnapshotOptions from "@/store/shared/snapshot/snapshotOptions";
import stateSnapshotKeys from "@/store/shared/snapshot/keys";
import SnapshotMixinBuilder from "@/store/shared/snapshot";
import { plainToInstance } from "class-transformer";
import { resolveAction, resolveGetter, resolveNestedState } from "@/utils/vuexModules";
import storeManager from "@/store/manager/index";
import router from "@/router";
import { ApiCounterpartyEmployeeBase } from "@/api/types/counterparty/apiCounterpartyEmployee";
import { CounterpartyType } from "@/types/CounterpartyType";
import alertService, { AlertKeys } from "@/store/modules/alerts/services/alertService";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import CounterpartyEmployee from "@/store/modules/counterpartyEmployee/modules/general/types/counterpartyEmployee";
import CounterpartyEmployeeGeneralState from "@/store/modules/counterpartyEmployee/modules/general/types/counterpartyEmployeeGeneralState";
import CounterpartyEmployeeState from "@/store/modules/counterpartyEmployee/types/counterpartyEmployeeState";
import mapper from "@/store/modules/counterpartyEmployee/modules/general/mapper";
import { CounterpartyController } from "@/api/counterparty";
import { ForeignOrganizationController } from "@/api/counterparty/foreignOrganization";
import { LegalEntityController } from "@/api/counterparty/legalEntity";
import { LegalPersonController } from "@/api/counterparty/legalPerson";
import AbortService from "@/services/abortService";
import FileMeta from "@/store/shared/storage/types/fileMeta";
import ApiFile from "@/api/types/storage/apiFile";
import { STORAGE_SNILS_NAMESPACE } from "@/constants/storage";
import { StorageController } from "@/api/storage";
import { isEqual } from "lodash";
import { saveAs } from "file-saver";
import { RouteNames } from "@/router/routes";
import { JoiningEmployeeToCounterpartyMethodType } from "@/store/modules/counterpartyEmployee/modules/general/types/JoiningEmployeeToCounterpartyMethodType";
import counterpartyEmployeeEmbeddedSnilsFormModuleTypes from "@/store/modules/counterpartyEmployee/modules/general/modules/snils/types";
import CounterpartyEmployeeEmbeddedSnilsFormModuleBuilder from "@/store/modules/counterpartyEmployee/modules/general/modules/snils";
import EmbeddedSnilsFormState from "@/store/shared/embeddedSnilsForm/types/embeddedSnilsFormState";
import { SnilsRecognitionController } from "@/api/snils/recognition";
import { Permissions } from "@/constants/permissions";
import { AuthorizationScopeType } from "@/types/authorization/authorizationScopeType";
import PermissionsService from "@/services/permissionsService";
import userTypes from "@/store/modules/user/types";


class DefaultStateBuilder {
	formMixin: any;
	snapshotMixin: any;

	constructor(formMixin: any, snapshotMixin: any) {
		this.formMixin = formMixin;
		this.snapshotMixin = snapshotMixin;
	}

	build() {
		return new CounterpartyEmployeeGeneralState(
			this.formMixin.state(),
			this.snapshotMixin.state()
		);
	}
}

export default class CounterpartyEmployeeGeneralModuleBuilder {
	constructor() {
	}

	build() {
		const abortService = new AbortService();
		const legalPersonController = new LegalPersonController(abortService);
		const legalEntityController = new LegalEntityController(abortService);
		const counterpartyController = new CounterpartyController(abortService);
		const foreignOrganizationController = new ForeignOrganizationController(abortService);
		const storageController = new StorageController(abortService);
		const snilsRecognitionController = new SnilsRecognitionController(abortService);
		const permissionsService = new PermissionsService();

		const formMixin = (new FormMixinBuilder()).build();
		const baseMixin = (new BaseMixinBuilder(abortService)).build();
		const snapshotMixin = (new SnapshotMixinBuilder({
			options: [
				new SnapshotOptions({
					key: stateSnapshotKeys.LAST_SAVED,
					fields: ["employee", "snilsFileMeta"]
				})
			]
		})).build();


		const stateManipulationMixin = (new StateManipulationMixinBuilder({
			defaultStateBuilder: new DefaultStateBuilder(formMixin, snapshotMixin)
		})).build();

		const state = (new DefaultStateBuilder(formMixin, snapshotMixin)).build();

		const getters = <GetterTree<CounterpartyEmployeeGeneralState, any>>{
			...formMixin.getters,
			...snapshotMixin.getters
		};

		const actions = <ActionTree<CounterpartyEmployeeGeneralState, any>>{
			...baseMixin.actions,
			...stateManipulationMixin.actions,
			...formMixin.actions,
			...snapshotMixin.actions,
			async [actionTypes.destroy]({ dispatch }) {
				dispatch(actionTypes.destroyBase);
				await dispatch(resolveAction(storeManager.counterpartyEmployee.general.snils.namespace,
					counterpartyEmployeeEmbeddedSnilsFormModuleTypes.actionTypes.destroy), null, { root: true });
			},
			async [actionTypes.initialize]({ dispatch, commit, state, rootState },
				{ id, counterpartyId }: { id: string | null, counterpartyId: string })
			{
				await dispatch(actionTypes.initializeBase);

				const { counterparty: { isRfrp, isLeasingCompany, isPledgeExpertCompany } } = resolveNestedState(rootState,
					storeManager.counterpartyEmployee.namespace);
				commit(mutationTypes.SET_IS_SNILS_UPLOAD_AVAILABLE, isRfrp || isLeasingCompany || isPledgeExpertCompany);

				commit(mutationTypes.SET_COUNTERPARTY_ID, counterpartyId);

				if(id) {
					commit(mutationTypes.SET_ID, id);
					await dispatch(actionTypes.fetch);
				}

				if(state.isSnilsUploadAvailable) {
					await dispatch(resolveAction(storeManager.counterpartyEmployee.general.snils.namespace,
						counterpartyEmployeeEmbeddedSnilsFormModuleTypes.actionTypes.initialize), { id }, { root: true });
				}

				commit(mutationTypes.SET_IS_INITIALIZED, true);

				commit(mutationTypes.SET_STATE_SNAPSHOT, stateSnapshotKeys.LAST_SAVED);
			},
			async [actionTypes.recognizeSnils]({ commit, state, rootState }, { index }) {
				commit(mutationTypes.SET_IS_SNILS_RECOGNIZING, true);

				try {
					let { snilsFileMeta } = resolveNestedState<EmbeddedSnilsFormState>(rootState,
						storeManager.counterpartyEmployee.general.snils.namespace);

					if(snilsFileMeta.id) {
						let snils = await snilsRecognitionController.getRecognizedSnils(snilsFileMeta.id);

						if(snils) {
							alertService.addInfo(AlertKeys.SNILS_RECOGNIZE_SUCCESS);
						} else {
							alertService.addError(AlertKeys.SNILS_RECOGNIZE_ERROR);
						}

						commit(mutationTypes.SET_EMPLOYEE_SNILS, snils || "");
					}
				} catch (error) {
					console.error(error);
					AlertHelper.handleGeneralRequestErrors(error);
					throw error;
				} finally {
					commit(mutationTypes.SET_IS_SNILS_RECOGNIZING, false);
				}
			},
			async [actionTypes.save]({ dispatch, commit, rootState, state }) {
				commit(mutationTypes.SET_IS_FORM_SAVING, true);

				try {
					let { counterparty, counterpartyId } =
						resolveNestedState<CounterpartyEmployeeState>(rootState, storeManager.counterpartyEmployee.namespace);

					let apiEmployee = mapper.map(state.employee, CounterpartyEmployee, ApiCounterpartyEmployeeBase);
					apiEmployee.counterpartyId = counterpartyId;

					const scope = await permissionsService.check([Permissions.GLOBAL_COUNTERPARTY_EMPLOYEE_UPDATE])
						? AuthorizationScopeType.GLOBAL
						: AuthorizationScopeType.OWN;

					switch (counterparty.type) {
						case CounterpartyType.LEGAL_ENTITY:
							await legalEntityController.updateLegalEntityCounterpartyEmployee(state.id, apiEmployee, scope);
							break;
						case CounterpartyType.LEGAL_PERSON:
							await legalPersonController.updateLegalPersonCounterpartyEmployee(counterpartyId, state.id, apiEmployee, scope);
							break;
						case CounterpartyType.FOREIGN_ORGANIZATION:
							await foreignOrganizationController.updateForeignOrganizationCounterpartyEmployee(state.id, apiEmployee);
							break;
					}

					commit(mutationTypes.EMPLOYEE_UPDATED_EVENT, state.employee);
					commit(mutationTypes.SET_STATE_SNAPSHOT, stateSnapshotKeys.LAST_SAVED);
					alertService.addInfo(AlertKeys.SUCCESS_UPDATED_INFO);
				} catch (error) {
					console.error(error);
					AlertHelper.handleGeneralRequestErrors(error);
				} finally {
					commit(mutationTypes.SET_IS_FORM_SAVING, false);
				}
			},
			async [actionTypes.create]({ dispatch, commit, rootState, rootGetters, state }, { joiningEmployeeToCounterpartyMethod }) {
				commit(mutationTypes.SET_IS_FORM_SAVING, true);

				try {
					let { counterparty, counterpartyId } =
						resolveNestedState<CounterpartyEmployeeState>(rootState, storeManager.counterpartyEmployee.namespace);

					let apiEmployee = mapper.map(state.employee, CounterpartyEmployee, ApiCounterpartyEmployeeBase);
					apiEmployee.counterpartyId = counterpartyId;

					let assignToAccount = joiningEmployeeToCounterpartyMethod === JoiningEmployeeToCounterpartyMethodType.REGISTER;

					const scope = await permissionsService.check([Permissions.GLOBAL_COUNTERPARTY_EMPLOYEE_CREATE])
						? AuthorizationScopeType.GLOBAL
						: AuthorizationScopeType.OWN;

					let employeeId;

					switch (counterparty.type) {
						case CounterpartyType.LEGAL_ENTITY:
							employeeId = await legalEntityController.createLegalEntityCounterpartyEmployee(counterpartyId,
								apiEmployee,
								assignToAccount,
								scope);
							break;
						case CounterpartyType.LEGAL_PERSON:
							employeeId = await legalPersonController.createLegalPersonCounterpartyEmployee(counterpartyId,
								apiEmployee,
								assignToAccount,
								scope);
							break;
						case CounterpartyType.FOREIGN_ORGANIZATION:
							employeeId = await foreignOrganizationController.createForeignOrganizationCounterpartyEmployee(counterpartyId,
								apiEmployee,
								assignToAccount);
							break;
					}

					alertService.addInfo(assignToAccount ? AlertKeys.EMPLOYEE_SUCCESS_CREATED : AlertKeys.EMPLOYEE_SUCCESS_INVITED);

					const isMasterAccount = rootGetters[resolveGetter(storeManager.user.namespace,
						userTypes.getterTypes.isMasterAccount)];

					if (isMasterAccount && employeeId) {
						await router.push({ name: RouteNames.COUNTERPARTY_EMPLOYEE_GENERAL, params: { counterpartyId, employeeId } });

						commit(mutationTypes.SET_IS_MASTER_ACCOUNT_EXIT_DIALOG_OPENED, true);
					} else {
						await router.push({ name: RouteNames.COUNTERPARTY_EMPLOYEES, params: { id: counterpartyId } });
					}

				} catch (error) {
					console.error(error);
					AlertHelper.handleGeneralRequestErrors(error);
				} finally {
					commit(mutationTypes.SET_IS_FORM_SAVING, false);
				}
			},
			async [actionTypes.fetch]({ commit, state, dispatch }) {
				commit(mutationTypes.SET_IS_FORM_LOADING, true);

				try {
					const scope = await permissionsService.check([Permissions.GLOBAL_COUNTERPARTY_EMPLOYEE_READ])
						? AuthorizationScopeType.GLOBAL
						: AuthorizationScopeType.OWN;

					let employeePersisted = await counterpartyController.getCounterpartyEmployee(state.id, state.counterpartyId, scope);

					let employee = plainToInstance(CounterpartyEmployee, employeePersisted.employee);
					commit(mutationTypes.SET_EMPLOYEE, employee);
				} catch (error) {
					console.error(error);
					AlertHelper.handleGeneralRequestErrors(error);
					commit(mutationTypes.SET_IS_FORM_DISABLED, true);
				} finally {
					commit(mutationTypes.SET_IS_FORM_LOADING, false);
				}
			}
		};

		const mutations = <MutationTree<CounterpartyEmployeeGeneralState>>{
			...stateManipulationMixin.mutations,
			...formMixin.mutations,
			...snapshotMixin.mutations,
			...baseMixin.mutations,
			[mutationTypes.SET_EMPLOYEE](state, value) {
				state.employee = value;
			},
			[mutationTypes.SET_EMPLOYEE_FIRST_NAME](state, value) {
				state.employee.firstName = value;
			},
			[mutationTypes.SET_EMPLOYEE_MIDDLE_NAME](state, value) {
				state.employee.middleName = value;
			},
			[mutationTypes.SET_EMPLOYEE_LAST_NAME](state, value) {
				state.employee.lastName = value;
			},
			[mutationTypes.SET_EMPLOYEE_IS_ACTIVE](state, value) {
				state.employee.isActive = value;
			},
			[mutationTypes.SET_EMPLOYEE_SNILS](state, value) {
				state.employee.snils = value;
			},
			[mutationTypes.SET_IS_SNILS_RECOGNIZING](state, value) {
				state.isSnilsRecognizing = value;
			},
			[mutationTypes.SET_EMPLOYEE_EMAIL](state, value) {
				state.employee.email = value;
			},
			[mutationTypes.SET_EMPLOYEE_PHONE](state, value) {
				state.employee.phone = value;
			},
			[mutationTypes.SET_ID](state, value) {
				state.id = value;
			},
			[mutationTypes.SET_COUNTERPARTY_ID](state, value) {
				state.counterpartyId = value;
			},
			[mutationTypes.SET_EMPLOYEE_POSITION](state, value) {
				state.employee.position = value;
			},
			[mutationTypes.SET_EMPLOYEE_HAS_LOAN_AGREEMENTS_ACCESS](state, value) {
				state.employee.hasLoanAgreementsAccess = value;
			},
			[mutationTypes.SET_EMPLOYEE_SNILS_FILE_META](state, value) {
				state.snilsFileMeta = value;
			},
			[mutationTypes.SET_EMPLOYEE_SNILS_FILE_META_IS_LOADING](state, value) {
				state.snilsFileMeta.isLoading = value;
			},
			[mutationTypes.SET_IS_SNILS_UPLOAD_AVAILABLE](state, value) {
				state.isSnilsUploadAvailable = value;
			},
			[mutationTypes.SET_LAST_SAVED_SNILS_FILE_ID](state, value) {
				state.lastSavedSnilsFileId = value;
			},
			[mutationTypes.SET_IS_MASTER_ACCOUNT_EXIT_DIALOG_OPENED](state, value) {
				state.isMasterAccountExitDialogOpened = value;
			},
			[mutationTypes.EMPLOYEE_UPDATED_EVENT]() {
			}
		};

		const { module: snilsModule } = (new CounterpartyEmployeeEmbeddedSnilsFormModuleBuilder()).build();

		const modules = {
			[counterpartyEmployeeEmbeddedSnilsFormModuleTypes.namespace]: snilsModule
		};


		const counterpartyEmployeeGeneralModule: Module<CounterpartyEmployeeGeneralState, any> = {
			state, getters, actions, mutations, namespaced: true, modules
		};

		return {
			module: counterpartyEmployeeGeneralModule
		};
	}
}

