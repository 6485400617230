<template>
	<frp-counterparty-master-account-loader v-if="!isInitialized"></frp-counterparty-master-account-loader>
	<frp-form-card v-else>
		<v-form :readonly="isFormSaving || isFormReadonly" :disabled="isFormDisabled" :ref="refs.form" v-model="formValid">
			<frp-form-card-blocks>
				<frp-form-card-block :title="$t('titles.general')">
					<frp-embedded-email-form
						:edit-permissions="[Permissions.GLOBAL_COUNTERPARTY_MASTER_ACCOUNT_UPDATE, Permissions.GLOBAL_COUNTERPARTY_MASTER_ACCOUNT_CREATE]"
						class="mb-4"
						:namespace="emailModuleNamespace"
						:success-text="$t('common.masterAccountEmailFormText.successText')"
						:edit-mode-text="$t('common.masterAccountEmailFormText.editModeText')">
					</frp-embedded-email-form>

					<frp-form-card-block-row class="mt-0" v-if="hasEditPermissions && accountId">
						<frp-form-card-block-col cols="12" sm="6" md="4">
							<frp-toggle-activity-btn :is-active="account.isActive"
													 :loading="isStatusSaving"
													 @click="updateStatus">
							</frp-toggle-activity-btn>
						</frp-form-card-block-col>
					</frp-form-card-block-row>
				</frp-form-card-block>
			</frp-form-card-blocks>
			<frp-bottom-space height="24"></frp-bottom-space>
		</v-form>
	</frp-form-card>
</template>

<script>
import authorizationMixin from "@/mixins/authorizationMixin";
import FrpToggleActivityBtn from "Components/buttons/FrpToggleActivityBtn";
import FrpEmbeddedEmailForm from "Components/embeddedForms/FrpEmbeddedEmailForm";
import FrpCounterpartyMasterAccountLoader from "Views/counterparty/sections/employees/FrpCounterpartyMasterAccountLoader";
import FrpBottomSpace from "Components/layouts/FrpBottomSpace";
import FrpFormCard from "Components/markup/FrpFormCard";
import FrpFormCardBlock from "Components/markup/FrpFormCardBlock";
import FrpFormCardBlockCol from "Components/markup/FrpFormCardBlockCol";
import FrpFormCardBlockRow from "Components/markup/FrpFormCardBlockRow";
import FrpFormCardBlocks from "Components/markup/FrpFormCardBlocks";
import FrpFormItem from "Components/markup/FrpFormItem";
import { validateEmail } from "Utils/validator";
import formMixin from "Mixins/formMixin";
import storeModuleBasedPage from "Mixins/storeModuleBasedPage";
import storeManager from "Store/manager/index";
import { actionTypes } from "Store/modules/counterparty/modules/counterpartyMasterAccount/types";
import { createNamespacedHelpers } from "vuex";
import { prepareEmailRule } from "Utils/validation";
import { Permissions } from "Constants/permissions";

const namespace = storeManager.counterparty.masterAccount.namespace;
const { mapState, mapMutations, mapActions } = createNamespacedHelpers(namespace);

export default {
	mixins: [storeModuleBasedPage, formMixin, authorizationMixin],
	data() {
		return {
			namespace,
			emailModuleNamespace: storeManager.counterparty.masterAccount.email.namespace,
			validateEmail,
			validation: {
				email: [prepareEmailRule()]
			}
		};
	},
	computed: {
		...mapState({
			account: state => state.account,
			accountId: state => state.accountId,
			isInitialized: state => state.isInitialized,
			isStatusSaving: state => state.isStatusSaving,
			isEmailSaving: state => state.isEmailSaving,
		}),
		hasEditPermissions() {
			return this.can([Permissions.GLOBAL_COUNTERPARTY_MASTER_ACCOUNT_UPDATE, Permissions.GLOBAL_COUNTERPARTY_MASTER_ACCOUNT_CREATE]);
		},
	},
	methods: {
		...mapActions({
			updateStatus: actionTypes.updateStatus
		})
	},
	created() {
		this.initializeStore();
	},
	components: {
		FrpToggleActivityBtn,
		FrpEmbeddedEmailForm,
		FrpFormItem,
		FrpFormCardBlockCol,
		FrpFormCardBlockRow,
		FrpFormCardBlock,
		FrpFormCardBlocks,
		FrpFormCard,
		FrpBottomSpace,
		FrpCounterpartyMasterAccountLoader
	}
};
</script>

<style scoped>

</style>
