import { mutationTypes, actionTypes, getterTypes, namespace } from "@/store/modules/confirmPhone/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import { ConfirmPhoneModeType } from "@/store/modules/confirmPhone/types/confirmPhoneModeType";
import ConfirmPhoneState from "@/store/modules/confirmPhone/types/confirmPhoneState";
import FormMixinBuilder from "@/store/shared/form";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import SecurityService from "@/services/auth/securityService";
import { AuthorizationController } from "@/api/authorization";
import AbortService from "@/services/abortService";
import alertService, { AlertKeys } from "@/store/modules/alerts/services/alertService";

const abortService = new AbortService();
const authorizationController = new AuthorizationController(abortService);

const securityService = new SecurityService();
const formMixin = (new FormMixinBuilder()).build();
const baseMixin = (new BaseMixinBuilder(abortService)).build();

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new ConfirmPhoneState(
			formMixin.state(),
			"",
			ConfirmPhoneModeType.INITIAL,
			"",
			"",
			false
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<ConfirmPhoneState, any>>{
	...formMixin.getters
};


const actions = <ActionTree<ConfirmPhoneState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...formMixin.actions,
	async [actionTypes.initialize]({ commit, dispatch }) {
		await dispatch(actionTypes.initializeBase);

		let user = await securityService.getUser();

		if(user.profile.phone && !user.profile.isPhoneConfirmed) {
			await dispatch(actionTypes.getNewCode);
		} else {
			throw new Error("Телефон отсутствует или уже подтвержден");
		}
	},
	async [actionTypes.confirmPhone]({ commit, state }) {
		commit(mutationTypes.SET_IS_FORM_SAVING, true);

		try {
			await authorizationController.confirmPhone(state.code);

			commit(mutationTypes.SET_MODE, ConfirmPhoneModeType.SUCCESS);

			await securityService.signinSilent();
		} catch (error) {
			console.error(error);
			commit(mutationTypes.SET_CODE_ERROR, "Код неверный");
		} finally {
			commit(mutationTypes.SET_IS_FORM_SAVING, false);
		}
	},
	async [actionTypes.getNewCode]({ state }) {
		try {
			await authorizationController.getPhoneConfirmationCode();
		} catch (error) {
			AlertHelper.handleGeneralRequestErrors(error);
		}
	}
};

const mutations = <MutationTree<ConfirmPhoneState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	...formMixin.mutations,
	[mutationTypes.SET_PHONE](state, value) {
		state.phone = value;
	},
	[mutationTypes.SET_MODE](state, value) {
		state.mode = value;
	},
	[mutationTypes.SET_CODE](state, value) {
		state.code = value;
	},
	[mutationTypes.SET_CODE_ERROR](state, value) {
		state.codeError = value;
	}
};

export {
	namespace, state, getters, actions, mutations
};

const confirmPhoneModule = {
	namespace, state, getters, actions, mutations, namespaced: true
};

export default confirmPhoneModule;
