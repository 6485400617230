import FormState from "@/store/shared/form/models/formState";
import LegalPersonCounterparty from "@/store/modules/counterparty/modules/legalPersonCounterparty/types/legalPersonCounterparty";
import LegalPersonFnsUpdate from "@/store/modules/counterparty/modules/legalPersonCounterparty/types/legalPersonFnsUpdate";
import IPageState from "@/store/shared/base/types/pageState";

export default class LegalPersonCounterpartyState implements IPageState {
	constructor(
		public form: FormState,
		public snapshot: object,
		public counterparty: LegalPersonCounterparty = new LegalPersonCounterparty(),
		public fnsUpdate: LegalPersonFnsUpdate = new LegalPersonFnsUpdate(),
		public id: string = "",
		public isEgripLegalPersonLoading: boolean = false,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public isUpdateViaFnsOperationExecuting: boolean = false,
		public isCounterpartySuccessCreatedDialogOpened: boolean = false
	)
	{
	}
}
