import { IMiddleware } from "@/types/core/middleware";
import { NavigationGuardNext, RawLocation, Route } from "vue-router/types/router";
import SecurityService from "@/services/auth/securityService";
import { ProfileType } from "@/types/ProfileType";
import { RouteNames } from "@/router/routes";
import PermissionsService from "@/services/permissionsService";
import { Permissions } from "@/constants/permissions";
import ApiLegalEntityCounterpartyPersisted from "@/api/types/counterparty/apiLegalEntityCounterpartyPersisted";
import { AuthorizationScopeType } from "@/types/authorization/authorizationScopeType";
import ApiLegalPersonCounterpartyPersisted from "@/api/types/counterparty/apiLegalPersonCounterpartyPersisted";
import AbortService from "@/services/abortService";
import { CounterpartyController } from "@/api/counterparty";
import ApiCounterpartyPersistedBase from "@/api/types/counterparty/apiCounterpartyPersistedBase";

const securityService = new SecurityService();

const abortService = new AbortService();
const counterpartyController = new CounterpartyController(abortService);

export default class RedirectMiddleware implements IMiddleware {
	constructor() {

	}

	async invoke(to: Route, from: Route) {
		try {
			if(to.name === RouteNames.ROOT) {
				let user = await securityService.getUser();

				if(!user.profile)
					return { name: RouteNames.ERROR_ACCESS_DENIED };

				switch (user.profile.type) {
					case ProfileType.MASTER_ACCOUNT_LEGAL_ENTITY:
					case ProfileType.MASTER_ACCOUNT_ENTREPRENEUR:
					{
						abortService.initialize();

						let counterparty = await counterpartyController.getCounterparty("",
							ApiCounterpartyPersistedBase, AuthorizationScopeType.OWN);

						const counterpartyId = counterparty.id;

						let counterpartyEmployees = await counterpartyController.getCounterpartyEmployees(counterpartyId,
							AuthorizationScopeType.OWN);

						if(counterpartyEmployees.length)
							return { name: RouteNames.COUNTERPARTY_EMPLOYEES, params: { id: counterpartyId } } as RawLocation;
						else
							return { name: RouteNames.COUNTERPARTY_EMPLOYEE_CREATE, params: { counterpartyId } } as RawLocation;

					}
					case ProfileType.OFFICE_EMPLOYEE:
					{
						return { name: RouteNames.COUNTERPARTIES };
					}
					case ProfileType.COUNTERPARTY_LEGAL_ENTITY_EMPLOYEE:
					case ProfileType.COUNTERPARTY_ENTREPRENEUR_EMPLOYEE:
					case ProfileType.COUNTERPARTY_PHYSICAL_PERSON_EMPLOYEE:
					case ProfileType.COUNTERPARTY_FOREIGN_ORGANIZATION_EMPLOYEE:
					{
						const permissionsService = new PermissionsService();

						if(await permissionsService.check([Permissions.GLOBAL_COUNTERPARTIES_READ])) {
							return { name: RouteNames.COUNTERPARTIES };
						} else {
							return { name: RouteNames.COUNTERPARTY_CREATE };
						}
					}
					default:
						return;
				}
			}
			if(to.name === RouteNames.APPS) {
				let user = await securityService.getUser();

				if(!user.profile)
					return { name: RouteNames.ERROR_ACCESS_DENIED };
			}
		} catch (e) {
			console.error(e);
		}
	}
}
