export default class ApiOffice {
	inn: string;
	ogrn: string;
	kpp: string;
	shortName: string;
	longName: string;
	description: string;

	constructor(inn = "", ogrn = "", kpp = "", shortName = "", longName = "", description = "") {
		this.inn = inn;
		this.ogrn = ogrn;
		this.kpp = kpp;
		this.shortName = shortName;
		this.longName = longName;
		this.description = description;
	}
}
