<template>
	<frp-nested-content-layout>
		<template #title>
			<frp-custom-title-loader/>
		</template>
		<template #content>
			<frp-tabs-loader></frp-tabs-loader>

			<template v-if="isGeneralPage">
				<frp-account-general-loader/>
			</template>

			<template v-else-if="isProfilesPage">
				<frp-account-profiles-loader/>
			</template>

		</template>
	</frp-nested-content-layout>
</template>

<script>
import FrpBackRouteLoader from "@/components/loaders/common/FrpBackRouteLoader";
import FrpTabsLoader from "@/components/loaders/common/FrpTabsLoader";
import FrpNestedContentLayout from "Components/layouts/FrpNestedContentLayout";
import FrpCustomTitleLoader from "Components/loaders/common/FrpCustomTitleLoader";
import FrpFormCard from "Components/markup/FrpFormCard";
import FrpFormCardBlocks from "Components/markup/FrpFormCardBlocks";
import FrpFormCardBlock from "Components/markup/FrpFormCardBlock";
import FrpFormCardBlockRow from "Components/markup/FrpFormCardBlockRow";
import FrpFormCardBlockCol from "Components/markup/FrpFormCardBlockCol";
import FrpFormItem from "Components/markup/FrpFormItem";
import FrpSwitchLoader from "Components/loaders/form/FrpSwitchLoader";
import FrpTextareaLoader from "Components/loaders/form/FrpTextareaLoader";
import { RouteNames } from "Router/routes";
import FrpAccountGeneralLoader from "Views/account/sections/FrpAccountGeneralLoader";
import FrpAccountProfilesLoader from "Views/account/sections/FrpAccountProfilesLoader";
import FrpCounterpartyEmployeeGeneralLoader from "Views/counterpartyEmployee/FrpCounterpartyEmployeeGeneralLoader";
import FrpCounterpartyEmployeeProfileLoader from "Views/counterpartyEmployee/FrpCounterpartyEmployeeProfileLoader";
import FrpCounterpartyEmployeeTrustsLoader from "Views/counterpartyEmployee/FrpCounterpartyEmployeeTrustsLoader";

export default {
	data() {
		return {
			RouteNames
		};
	},
	computed: {
		isGeneralPage() {
			return this.$route.name === this.RouteNames.ACCOUNT_GENERAL;
		},
		isProfilesPage() {
			return this.$route.name === this.RouteNames.ACCOUNT_PROFILES;
		}
	},
	components: {
		FrpAccountProfilesLoader,
		FrpAccountGeneralLoader,
		FrpCounterpartyEmployeeTrustsLoader,
		FrpCounterpartyEmployeeProfileLoader,
		FrpCounterpartyEmployeeGeneralLoader,
		FrpCustomTitleLoader,
		FrpTabsLoader,
		FrpBackRouteLoader,
		FrpNestedContentLayout,
		FrpFormCardBlocks,
		FrpFormCard,
		FrpFormCardBlock,
		FrpFormCardBlockCol,
		FrpFormCardBlockRow,
		FrpFormItem,
		FrpSwitchLoader,
		FrpTextareaLoader
	}
};
</script>
