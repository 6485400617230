<template>
	<div>
		<v-subheader v-if="label" class="pa-0 mb-1 grey--text text-caption" style="height: fit-content">
			<span>{{ label }}</span>
			<span v-if="required">*</span>
		</v-subheader>
		<v-combobox
			:menu-props="{contentClass: 'frp-combobox'}"
			:readonly="readonly"
			:rules="[...defaultRules, ...rules]"
			:background-color="colors.white.base"
			:hide-details="hideDetails"
			:append-icon="appendIcon"
			:loading="loading"
			:items="filteredItems"
			:item-text="itemText"
			:item-value="itemValue"
			:placeholder="placeholder"
			:search-input.sync="search"
			:disabled="disabled"
			v-model="search"
			v-mask="mask"
			hide-no-data
			hide-selected
			validate-on-blur
			return-object
			@change="handleChange"
			outlined
			dense
			elevation
			class="frp-combobox frp-text-field text-body-2"
			ref="input">
			<template #append>
				<span></span>
			</template>
		</v-combobox>
	</div>
</template>

<script>
import FrpIcon from "Components/icon/FrpIcon";
import colorsMixin from "Mixins/colorsMixin";
import { requiredRule } from "Utils/validation";

export default {
	mixins: [colorsMixin],
	model: {
		prop: "value",
		event: "update:value"
	},
	props: {
		rules: {
			type: Array,
			default: () => []
		},
		loading: Boolean,
		placeholder: String,
		readonly: Boolean,
		label: String,
		disabled: Boolean,
		required: Boolean,
		items: Array,
		value: String,
		mask: String,
		hideDetails: Boolean,
		appendIcon: String,
		icon: String,
		itemText: {
			type: String,
			default: "title"
		},
		itemValue: {
			type: String,
			default: "id"
		}
	},
	computed: {
		defaultRules() {
			if(this.required)
				return [requiredRule()];
			else
				return [];
		},
		search: {
			get() {
				return this.value || "";
			},
			set(value) {
				if(this.value !== (value || "")) {
					this.$emit("update:value", value || "");
				}
			}
		},
		filteredItems() {
			return this.items.filter(item =>
				item.title.toLowerCase().includes(this.value.toLowerCase())) || this.items;
		}
	},
	methods: {
		handleChange(item) {
			if(item) {
				this.$emit("select:value", item);
			}
		}
	},
	components: {
		FrpIcon
	}
};
</script>
